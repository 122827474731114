import React from 'react'

const AdditionalInfo = () => {
  return (
    <div className='w-6/12 mx-auto flex flex-col mt-[30px] gap-4'>
        <div className='flex items-center justify-between'>
            <p>Package Size</p>
            <p>1kg</p>
        </div>
        <hr />
        <div className='flex items-center justify-between'>
            <p>Intensity</p>
            <p>Strong</p>
        </div>
        <hr />
        <div className='flex items-center justify-between'>
            <p>Aromatic Profile</p>
            <p>Nutty</p>
        </div>
        <hr />
        <div className='flex items-center justify-between'>
            <p>Roasting</p>
            <p>Medium</p>
        </div>
        <hr />
        <div className='flex items-center justify-between'>
            <p>Roasting Coffee Area</p>
            <p>Medium</p>
        </div>
        <hr />
        <div className='flex items-center justify-between'>
            <p>Coffee Origin</p>
            <p>Medium</p>
        </div>
        <hr />
        <div className='flex items-center justify-between'>
            <p>Coffee Preparation</p>
            <p>Medium</p>
        </div>
        <hr />
    </div>
  )
}

export default AdditionalInfo