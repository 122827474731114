import React from 'react'
export default function CartSelect({placeholder, children, onChange}) {
    return (
        <div className="relative">
            <select
                className="text-grey-50 cursor-pointer appearance-none bg-no-repeat text-sm w-full h-[50px] px-4 py-1 border-grey-200 border rounded-lg focus:outline-none"
                onChange={onChange}  // Attach the onChange event here
                value={placeholder}  // Set the current value        
            >
                <option value={placeholder}>{placeholder}</option>
                {children}
            </select>
            <svg className='absolute right-[25px] -mt-[25px]' width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.364 5.55986L11.314 0.609863L12.728 2.02386L6.364 8.38886L0 2.02486L1.414 0.610864L6.364 5.55986Z" fill="black"/>
            </svg>
        </div>
    )
}