import React, { Fragment } from 'react'
import { capitalizeString } from '../../utils/helperFuncs'
import ProductBox from '../product/ProductBox'
import { useLocation, useNavigate } from 'react-router-dom'
import Empty from './Empty'
import Bus from '../../assets/svg/deliver.svg'

export default function CoffeeRelatedProducts({data}) {
    const navigate = useNavigate()

    console.log(data, "lamb")

    const color = true

    const visibleProducts = data?.products?.filter(product => product.visibility);
    console.log(visibleProducts, "visibleProducts")

    const location = useLocation()

    return (
        <div className='mt-[66px] bg-[#fff] xl:px-[4%] px-5 py-[62px] xl:h-[799px] flex flex-col'>
            <h1 className='nunito-bold md:text-[40px] text-[28px] text-primary'>Related Products </h1>
            {data?.products === 0 ? (
                <Empty />
            ) : (
                <div className='mt-5'>
                    <div className='grid xl:grid-cols-4 grid-cols-2 gap-4 w-full mb-5'>
                        {data?.slice(0, 5)?.map((d, index) => {
                            console.log(d, "mask")
                            return (
                            <Fragment key={index}>
                                <div className={`hover:shadow-[0_0_10px_3px_#0000001A] delay-[0.5s] cursor-pointer transition-all ease-in-out rounded-b-[20px] rounded-t-lg `}>
                                    <div className='bg-white w-full border border-grey-200 rounded-t-lg sm:pt-[10px] pt-[7px] pb-1 items-center flex flex-col gap-3'>
                                        <div className='sm:px-[14px] px-[7px] w-full'>
                                            <img src={d?.products[0]?.image} alt='coffee' className='w-full md:h-[285px] h-[182px] object-cover rounded-md'/>
                                        </div>
                                        <div className='flex flex-col gap-2 px-[14px] w-full'>
                                            <p className='sm:text-[19px] text-sm truncate'>{d?.products[0]?.name}</p>
                                            <div className='flex items-center gap-[17px]'>
                                                <svg width="63" height="12" viewBox="0 0 63 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.37302 9.256L2.29702 11.548L3.13302 7.271L0.39502 4.411L3.98902 4.06L5.37302 0L6.75802 4.06L10.352 4.411L7.61402 7.271L8.45002 11.548L5.37302 9.256Z" fill="#503225"/>
                                                    <path d="M18.352 9.256L15.276 11.548L16.112 7.271L13.374 4.411L16.968 4.06L18.352 0L19.737 4.06L23.331 4.411L20.593 7.271L21.429 11.548L18.352 9.256Z" fill="#503225"/>
                                                    <path d="M31.331 9.256L28.255 11.548L29.091 7.271L26.353 4.411L29.947 4.06L31.331 0L32.716 4.06L36.31 4.411L33.572 7.271L34.408 11.548L31.331 9.256Z" fill="#503225"/>
                                                    <path d="M44.31 9.256L41.234 11.548L42.07 7.271L39.332 4.411L42.926 4.06L44.31 0L45.695 4.06L49.289 4.411L46.551 7.271L47.387 11.548L44.31 9.256Z" fill="#503225"/>
                                                    <path d="M57.289 9.256L54.213 11.548L55.049 7.271L52.311 4.411L55.905 4.06L57.289 0L58.674 4.06L62.268 4.411L59.53 7.271L60.366 11.548L57.289 9.256Z" fill="#DDDDDD"/>
                                                </svg>
                                                <p className='sm:text-[13px] text-xs open-sans'>0 Sold</p>
                                            </div>
                                            <div className='flex flex-col gap-2 mb-2'>
                                                <div className='flex sm:gap-[15.1px] gap-[3.6px] items-center'>
                                                    {/* <p className='font-bold text-grey-100 sm:text-[16px] text-[11px]'>{data.currency}<span className='sm:text-2xl text-[17px]'>{wholeNumber}</span>.{decimal ? decimal : `00`}</p> */}
                                                    {/* <p className='text-grey-50 open-sans sm:text-[16px] text-xs'>$524.03</p> */}
                                                </div>
                                                {!color && <div className='sm:flex gap-[10.33px] items-center hidden'>
                                                    <img src={Bus} alt='bus' />
                                                    <p className='text-[13px] open-sans text-[#2ABB07]'>5-7 Workdays</p>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${location.pathname === "/cartbag" ? "hidden" :  'bg-secondary rounded-b-[20px] w-full sm:h-[58px] h-9 flex justify-center items-center gap-[10px]'}`}>
                                        <svg className='sm:block hidden' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.238 4.657L0 1.414L1.414 0L4.657 3.24H19.898C20.0539 3.23999 20.2076 3.27641 20.3469 3.34637C20.4862 3.41632 20.6072 3.51786 20.7002 3.6429C20.7933 3.76793 20.8559 3.91298 20.8829 4.06649C20.9099 4.21999 20.9007 4.37769 20.856 4.527L18.456 12.527C18.3943 12.7331 18.2677 12.9138 18.0951 13.0422C17.9225 13.1707 17.7131 13.24 17.498 13.24H5.238V15.24H16.238V17.24H4.238C3.97278 17.24 3.71843 17.1346 3.53089 16.9471C3.34336 16.7596 3.238 16.5052 3.238 16.24V4.657ZM5.238 5.24V11.24H16.754L18.554 5.24H5.238ZM4.738 21.24C4.44133 21.24 4.15132 21.152 3.90464 20.9872C3.65797 20.8224 3.46571 20.5881 3.35218 20.314C3.23865 20.0399 3.20894 19.7383 3.26682 19.4474C3.3247 19.1564 3.46756 18.8891 3.67734 18.6793C3.88712 18.4696 4.15439 18.3267 4.44536 18.2688C4.73634 18.2109 5.03794 18.2407 5.31203 18.3542C5.58611 18.4677 5.82038 18.66 5.9852 18.9066C6.15003 19.1533 6.238 19.4433 6.238 19.74C6.238 20.1378 6.07996 20.5194 5.79866 20.8007C5.51736 21.082 5.13582 21.24 4.738 21.24ZM16.738 21.24C16.4413 21.24 16.1513 21.152 15.9046 20.9872C15.658 20.8224 15.4657 20.5881 15.3522 20.314C15.2387 20.0399 15.2089 19.7383 15.2668 19.4474C15.3247 19.1564 15.4676 18.8891 15.6773 18.6793C15.8871 18.4696 16.1544 18.3267 16.4454 18.2688C16.7363 18.2109 17.0379 18.2407 17.312 18.3542C17.5861 18.4677 17.8204 18.66 17.9852 18.9066C18.15 19.1533 18.238 19.4433 18.238 19.74C18.238 20.1378 18.08 20.5194 17.7987 20.8007C17.5174 21.082 17.1358 21.24 16.738 21.24Z" fill="white"/>
                                        </svg>
                                        <p className='sm:block hidden font-medium text-xl text-white'>Add to Cart</p>
                                        <p className='sm:hidden block font-bold text-xs text-white'>Order now</p>
                                    </div>
                                </div>
                            </Fragment>
                        )})}
                    </div>
                    <div className={`${location.pathname === "/cartbag" ? "hidden" : 'w-full flex justify-between items-center'}`} >
                        <div onClick={() => navigate('/products', { state: data})} className='border border-secondary rounded-lg w-[117px] h-[43px] px-5 flex items-center cursor-pointer'>
                            <p className='font-medium text-sm'>View More</p>
                        </div>
                        <div className='xl:flex hidden gap-[21.87px] items-center'>
                            <svg className='cursor-pointer' width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M7.89 11.134L4.22 7.46398H16.689V5.75398H4.22L7.89 2.08398L6.681 0.875977L0.947998 6.60898L6.681 12.342L7.89 11.134Z" fill="#00000080"/>
                            </svg>
                            <svg className='cursor-pointer' width="38" height="13" viewBox="0 0 38 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.731 2.08477L34.401 5.75477L0.560974 5.75477V7.46377L34.401 7.46377L30.731 11.1338L31.94 12.3428L37.673 6.60977L31.94 0.876774L30.731 2.08477Z" fill="#F1944E"/>
                            </svg>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
