import React from 'react'

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <div className='bg-secondary sm:h-[110px] h-[143px] flex flex-col justify-center items-center text-[18px] text-white nunito-bold'>
            <p className='text-center sm:w-auto w-[295px]'>All prices incl. value added tax plus shipping costs</p>
            <div className='flex sm:gap-5 gap-[7.5px] items-center'>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.047 7.428C14.4844 6.49036 13.63 5.763 12.6146 5.35733C11.5991 4.95166 10.4787 4.89005 9.42491 5.18193C8.37111 5.47382 7.44206 6.10311 6.78005 6.97341C6.11804 7.84371 5.75959 8.90703 5.75959 10.0005C5.75959 11.094 6.11804 12.1573 6.78005 13.0276C7.44206 13.8979 8.37111 14.5272 9.42491 14.8191C10.4787 15.111 11.5991 15.0494 12.6146 14.6437C13.63 14.238 14.4844 13.5106 15.047 12.573L13.332 11.545C12.9945 12.1076 12.4818 12.544 11.8726 12.7874C11.2633 13.0308 10.591 13.0678 9.95876 12.8926C9.32648 12.7175 8.76905 12.3399 8.37185 11.8178C7.97464 11.2956 7.75957 10.6576 7.75957 10.0015C7.75957 9.34542 7.97464 8.70743 8.37185 8.18525C8.76905 7.66307 9.32648 7.28549 9.95876 7.11036C10.591 6.93523 11.2633 6.9722 11.8726 7.2156C12.4818 7.459 12.9945 7.89542 13.332 8.458L15.047 7.428ZM20.759 10C20.759 8.02219 20.1725 6.08879 19.0737 4.4443C17.9749 2.79981 16.4131 1.51809 14.5859 0.761209C12.7586 0.00433284 10.7479 -0.193701 8.80814 0.192152C6.86833 0.578004 5.08649 1.53041 3.68797 2.92894C2.28944 4.32746 1.33704 6.10929 0.951185 8.0491C0.565333 9.98891 0.763366 11.9996 1.52024 13.8268C2.27712 15.6541 3.55884 17.2159 5.20334 18.3147C6.84783 19.4135 8.78123 20 10.759 20C13.4112 20 15.9547 18.9464 17.8301 17.0711C19.7055 15.1957 20.759 12.6522 20.759 10ZM2.75904 10C2.75904 8.41775 3.22823 6.87104 4.10728 5.55544C4.98633 4.23985 6.23576 3.21447 7.69757 2.60897C9.15938 2.00347 10.7679 1.84504 12.3198 2.15372C13.8716 2.4624 15.2971 3.22433 16.4159 4.34315C17.5347 5.46197 18.2966 6.88743 18.6053 8.43928C18.914 9.99113 18.7556 11.5997 18.1501 13.0615C17.5446 14.5233 16.5192 15.7727 15.2036 16.6518C13.888 17.5308 12.3413 18 10.759 18C8.63731 18 6.60247 17.1572 5.10218 15.6569C3.60189 14.1566 2.75904 12.1217 2.75904 10Z" fill="white"/>
                </svg>
                <p>{year} Italian Coffee</p>
            </div>
        </div>
    )
}
