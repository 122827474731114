import React, { useEffect, useState } from 'react'
import { Skeleton } from '@mui/material';
import { db } from '../firebase-config';
import { MdDeleteForever } from 'react-icons/md';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { FaRegEdit } from 'react-icons/fa';

import Search from "../assets/svg/search.svg"
import { toast } from 'react-toastify';
import UpdateData from './UpdateData';
import ModalPop from '../components/modal/modalPop';
import DeleteData from './DeleteData';
import EditTag from './EditTag';
import DeleteTag from './DeleteTag';
import EditCategory from './EditCategory';
import DeleteCategory from './DeleteCategory';
import EditRegion from './EditRegion';
import DeleteRegion from './DeleteRegion';
import EditManufacturerType from './EditManufacturerType';
import DeleteManufacturerType from './DeleteManufacturerType';


const ViewFilters = () => {
    const [loading, setLoading] = useState(false)
    const [tagsData, setTagsData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [regionData, setRegionData] = useState([])
    const [manufacturerTypeData, setManufacturerTypeData] = useState([])

    const [openTag, setOpenTag] = useState(false)
    const [openCategory, setOpenCategory] = useState(false)
    const [openRegion, setOpenRegion] = useState(false)
    const [openManufacturerType, setOpenManufacturerType] = useState(false)


    const [text, setText] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [currentCategoryPage, setCurrentCategoryPage] = useState(1);
    const [currentRegionPage, setCurrentRegionPage] = useState(1);
    const [currentManufacturerTypePage, setCurrentManufacturerTypePage] = useState(1);

    const [tagData, setTagData] = useState([])
    const [deleteTagLoading, setDeleteTagLoading] = useState(false)
    const [openDeleteTag, setOpenDeleteTag] = useState(false)
    const [deleteTagId, setDeleteTagId] = useState()
    const [updateEditTagLoading, setUpdateEditTagLoading] = useState(false)
    
    const [singleCategoryData, setSingleCategoryData] = useState([])
    const [deleteCategoryLoading, setDeleteCategoryLoading] = useState(false)
    const [openDeleteCategory, setOpenDeleteCategory] = useState(false)
    const [deleteCategoryId, setDeleteCategoryId] = useState()
    const [updateEditCategoryLoading, setUpdateEditCategoryLoading] = useState(false)

    const [singleRegionData, setSingleRegionData] = useState([])
    const [deleteRegionLoading, setDeleteRegionLoading] = useState(false)
    const [openDeleteRegion, setOpenDeleteRegion] = useState(false)
    const [deleteRegionId, setDeleteRegionId] = useState()
    const [updateEditRegionLoading, setUpdateEditRegionLoading] = useState(false)

    const [singleManufacturerTypeData, setSingleManufacturerTypeData] = useState([])
    const [deleteManufacturerTypeLoading, setDeleteManufacturerTypeLoading] = useState(false)
    const [openDeleteManufacturerType, setOpenDeleteManufacturerType] = useState(false)
    const [deleteManufacturerTypeId, setDeleteManufacturerTypeId] = useState()
    const [updateEditManufacturerTypeLoading, setUpdateEditManufacturerTypeLoading] = useState(false)


    const itemsPerPage = 5; 

    const colRef = collection(db, "tags") 
    const colCatRef = collection(db, "category") 
    const colRegRef = collection(db, "region") 
    const colManTypeRef = collection(db, "manufacturerType") 


    //Tags 
    const fetchTagData = () => {
        setLoading(true)
        getDocs(colRef)
        .then((snapshot) => {
            setLoading(false)
           let tags = []
           snapshot.docs.forEach((doc) => {
            tags.push({ ...doc.data(), id: doc.id })
           })
          
           console.log(tags, "ouan")
           setTagsData(tags)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchTagData()
    }, [deleteTagLoading, updateEditTagLoading])

    const handleSearch = (e) => {
        setText(e.target.value);
    };

    const filteredData = tagsData?.filter(item =>
        item?.tag?.toLowerCase().includes(text?.toLowerCase())
    );


     // Calculate total pages
     const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

     // Get current items for the page
     const currentData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
 
     const handlePageChange = (pageNumber) => {
         setCurrentPage(pageNumber);
     };


    //Category 
    const fetchCategoryData = () => {
        setLoading(true)
        getDocs(colCatRef)
        .then((snapshot) => {
            setLoading(false)
           let category = []
           snapshot.docs.forEach((doc) => {
            category.push({ ...doc.data(), id: doc.id })
           })
          
           console.log(category, "ouan")
           setCategoryData(category)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchCategoryData()
    }, [deleteCategoryLoading, updateEditCategoryLoading])

   

    const filteredCategoryData = categoryData?.filter(item =>
        item?.category?.toLowerCase().includes(text?.toLowerCase())
    );


     // Calculate total pages
     const totalCategoryPages = Math.ceil(filteredCategoryData?.length / itemsPerPage);

     // Get current items for the page
     const currentCategoryData = filteredCategoryData?.slice((currentCategoryPage - 1) * itemsPerPage, currentCategoryPage * itemsPerPage);
 
     const handleCategoryPageChange = (pageNumber) => {
         setCurrentCategoryPage(pageNumber);
     };


    //Region 
    const fetchRegionData = () => {
        setLoading(true)
        getDocs(colRegRef)
        .then((snapshot) => {
            setLoading(false)
           let region = []
           snapshot.docs.forEach((doc) => {
            region.push({ ...doc.data(), id: doc.id })
           })
          
           console.log(region, "region")
           setRegionData(region)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchRegionData()
    }, [deleteRegionLoading, updateEditRegionLoading])

   

    const filteredRegionData = regionData?.filter(item =>
        item?.region?.toLowerCase().includes(text?.toLowerCase())
    );


     // Calculate total pages
     const totalRegionPages = Math.ceil(filteredRegionData?.length / itemsPerPage);

     // Get current items for the page
     const currentRegionData = filteredRegionData?.slice((currentRegionPage - 1) * itemsPerPage, currentRegionPage * itemsPerPage);
 
     const handleRegionPageChange = (pageNumber) => {
         setCurrentRegionPage(pageNumber);
     };


     //Manufacturer Type 
    const fetchManufacturerTypeData = () => {
        setLoading(true)
        getDocs(colManTypeRef)
        .then((snapshot) => {
            setLoading(false)
           let manufacturerType = []
           snapshot.docs.forEach((doc) => {
            manufacturerType.push({ ...doc.data(), id: doc.id })
           })
          
           console.log(manufacturerType, "manufacturerType")
           setManufacturerTypeData(manufacturerType)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchManufacturerTypeData()
    }, [deleteManufacturerTypeLoading, updateEditManufacturerTypeLoading])

   
    console.log(manufacturerTypeData, "manufacturerTypeData")

    const filteredManufacturerTypeData = manufacturerTypeData?.filter(item =>
        item?.manufacturerType?.typeName?.toLowerCase().includes(text?.toLowerCase())
    );

    console.log(filteredManufacturerTypeData, "filteredManufacturerTypeData")


     // Calculate total pages
     const totalManufacturerTypePages = Math.ceil(filteredManufacturerTypeData?.length / itemsPerPage);

     // Get current items for the page
     const currentManufacturerTypeData = filteredManufacturerTypeData?.slice((currentManufacturerTypePage - 1) * itemsPerPage, currentManufacturerTypePage * itemsPerPage);
     console.log(currentManufacturerTypeData, "currentManufacturerTypeData")

     const handleManufacturerTypePageChange = (pageNumber) => {
         setCurrentManufacturerTypePage(pageNumber);
     };


  return (
    <div className='w-full lg:overflow-x-hidden mt-[100px] flex flex-col gap-10 lg:px-5'>
        <div className='flex justify-between flex-col gap-4 fixed lg:relative lg:gap-0 lg:flex-row lg:items-center '>
            <h1 className='text-3xl font-bold'>View Filters</h1>
            <div className='flex flex-col lg:flex-row invisible lg:items-center gap-[9px]'>
                <div className='rounded-[23px] border border-[#CCCCCC] w-[300px] lg:w-[372px] h-[46px] flex items-center gap-2 p-3'>
                    <img src={Search} alt='Search' className='w-4 h-4' />
                    <input 
                        placeholder='Search by company name or email'
                        type='text'
                        name='search'
                        value={text}
                        className='outline-none bg-transparent w-full'
                        onChange={handleSearch}
                    />
                </div>
            </div>
        </div>
        <div className='flex w-full justify-between'>
            <div className='flex flex-col w-5/12'>
                {
                    loading || deleteTagLoading || updateEditTagLoading ?
                    <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                    :
                    <>
                        <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                            <tr className='h-[32px] whitespace-nowrap  border-0' >
                                <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                    Tag Name
                                </th>
                                <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                    Action
                                </th>
                            </tr>
                            {currentData?.length > 0 ? currentData?.map((item, index) => (
                                <tr key={index} className={`bg-transparent h-[55px]   border-b border-grey-100`} >
                                    <td className='h-[55px] flex items-center cursor-pointer '>
                                        <p className='text-sm font-kumbh'>{item?.tag}</p> 
                                    </td>
                            
                                    <td className='h-[55px] px-4 '>
                                        <div className='flex items-center gap-2'>
                                            <FaRegEdit className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() => {setOpenTag(true); setTagData(item)}} />
                                            <MdDeleteForever className='text-[#f00] w-5 h-5 cursor-pointer' onClick={() =>{setOpenDeleteTag(true); setDeleteTagId(item?.id)}}/>
                                        </div>
                                    </td> 
                                </tr>
                            )) : (
                                <tr className='h-[654px] bg-white border-t border-grey-100'>
                                    <td colSpan="8" className="relative">
                                        <div className='absolute inset-0 flex items-center justify-center'>
                                            <div className='flex flex-col gap-2 items-center'>
                                                {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                                <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Tags Available</p>
                                                <p>Oops! Nothing to see here.</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            )
                        }
                        </table>
                        <div className="flex justify-center mt-1 mb-10">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`px-3 py-1 mx-1 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </>
                }

            </div>
            <div  className='flex flex-col w-5/12'>
            {
                loading || deleteCategoryLoading || updateEditCategoryLoading ?
                <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                :
                <>
                    <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                        <tr className='h-[32px] whitespace-nowrap  border-0' >
                            <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Category Name
                            </th>
                            <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Action
                            </th>
                        </tr>
                        {currentCategoryData?.length > 0 ? currentCategoryData?.map((item, index) => (
                            <tr key={index} className={`bg-transparent h-[55px]   border-b border-grey-100`} >
                                <td className='h-[55px] flex items-center cursor-pointer '>
                                    <p className='text-sm font-kumbh'>{item?.category}</p> 
                                </td>
                        
                                <td className='h-[55px] px-4 '>
                                    <div className='flex items-center gap-2'>
                                        <FaRegEdit className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() =>  {setOpenCategory(true); setSingleCategoryData(item)}} />
                                        <MdDeleteForever className='text-[#f00] w-5 h-5 cursor-pointer' onClick={() =>{setOpenDeleteCategory(true); setDeleteCategoryId(item?.id)}}/>
                                    </div>
                                </td> 
                            </tr>
                        )) : (
                            <tr className='h-[654px] bg-white border-t border-grey-100'>
                                <td colSpan="8" className="relative">
                                    <div className='absolute inset-0 flex items-center justify-center'>
                                        <div className='flex flex-col gap-2 items-center'>
                                            {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                            <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Category Available</p>
                                            <p>Oops! Nothing to see here.</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        )
                    }
                    </table>
                    <div className="flex justify-center mt-1 mb-10">
                        {Array.from({ length: totalCategoryPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handleCategoryPageChange(index + 1)}
                                className={`px-3 py-1 mx-1 ${currentCategoryPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            }
            </div>
        </div>
        <div className='flex w-full justify-between'>
            <div className='flex flex-col mt-5 w-5/12'>
                {
                    loading || deleteRegionLoading || updateEditRegionLoading ?
                    <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                    :
                    <>
                        <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                            <tr className='h-[32px] whitespace-nowrap  border-0' >
                                <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                    Region Name
                                </th>
                                <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                    Action
                                </th>
                            </tr>
                            {currentRegionData?.length > 0 ? currentRegionData?.map((item, index) => (
                                <tr key={index} className={`bg-transparent h-[55px]   border-b border-grey-100`} >
                                    <td className='h-[55px] flex items-center cursor-pointer ' onClick={() => {}}>
                                        <p className='text-sm font-kumbh'>{item?.region}</p> 
                                    </td>
                            
                                    <td className='h-[55px] px-4 '>
                                        <div className='flex items-center gap-2'>
                                            <FaRegEdit className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() => {setOpenRegion(true); setSingleRegionData(item)}} />
                                            <MdDeleteForever className='text-[#f00] w-5 h-5 cursor-pointer' onClick={() =>{setOpenDeleteRegion(true); setDeleteRegionId(item?.id)}}/>
                                        </div>
                                    </td> 
                                </tr>
                            )) : (
                                <tr className='h-[500px] bg-white border-t border-grey-100'>
                                    <td colSpan="8" className="relative">
                                        <div className='absolute inset-0 flex items-center justify-center'>
                                            <div className='flex flex-col gap-2 items-center'>
                                                {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                                <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Region Available</p>
                                                <p>Oops! Nothing to see here.</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            )
                        }
                        </table>
                        <div className="flex justify-center mt-1 mb-10">
                            {Array.from({ length: totalRegionPages }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`px-3 py-1 mx-1 ${currentRegionPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </>
                }
            </div>
            
            <div className='flex flex-col mt-5 w-5/12'>
            {
                loading || deleteManufacturerTypeLoading || updateEditManufacturerTypeLoading ?
                <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                :
                <>
                    <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden table-fixed border-collapse'>
                        <thead>
                            <tr className='h-[32px] whitespace-nowrap  border-0' >
                                <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                    Name
                                </th>
                                <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                    Image
                                </th>
                                <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {manufacturerTypeData?.length > 0 ? manufacturerTypeData?.map((item, index) => {
                                console.log(item, "vavoom")
                                return (
                                <tr key={index} className={`bg-transparent h-[55px]   border-b border-grey-100`} >
                                    <td className='h-[55px]  items-center cursor-pointer' onClick={() => {}}>
                                        <p className='text-sm font-kumbh'>{item?.manufacturerType[0]?.typeName}</p> 
                                    </td>
                                    <td className='h-[55px] items-center cursor-pointer' onClick={() => {}}>
                                        <img src={item?.manufacturerType[0]?.typeImage} alt='image' className='w-6 h-6' />
                                    </td>
                            
                                    <td className='h-[55px] px-4 '>
                                        <div className='flex items-center gap-2'>
                                            <FaRegEdit className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() => {setOpenManufacturerType(true); setSingleManufacturerTypeData(item)}} />
                                            <MdDeleteForever className='text-[#f00] w-5 h-5 cursor-pointer' onClick={() =>{setOpenDeleteManufacturerType(true); setDeleteManufacturerTypeId(item?.id)}}/>
                                        </div>
                                    </td> 
                                </tr>
                                )}) : (
                                    <tr className='h-[500px] bg-white border-t border-grey-100'>
                                        <td colSpan="8" className="relative">
                                            <div className='absolute inset-0 flex items-center justify-center'>
                                                <div className='flex flex-col gap-2 items-center'>
                                                    {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                                    <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Manufacturer Type Available</p>
                                                    <p>Oops! Nothing to see here.</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                )
                            }

                        </tbody>
                    </table>

                    <div className="flex justify-center mt-1 mb-10">
                        {Array.from({ length: totalManufacturerTypePages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={`px-3 py-1 mx-1 ${currentManufacturerTypePage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            }

        </div>
        </div>


        <ModalPop isOpen={openTag}>
            <EditTag 
                handleClose={() => setOpenTag(false)} 
                tagData={tagData}
                updateEditTagLoading={updateEditTagLoading}
                setUpdateEditTagLoading={setUpdateEditTagLoading}
            />
        </ModalPop>
        <ModalPop isOpen={openDeleteTag}>
            <DeleteTag 
                handleClose={() => setOpenDeleteTag(false)}
                deleteTagId={deleteTagId}
                deleteTagLoading={deleteTagLoading}
                setDeleteTagLoading={setDeleteTagLoading} 
            />
        </ModalPop>

        <ModalPop isOpen={openCategory}>
            <EditCategory 
                handleClose={() => setOpenCategory(false)} 
                singleCategoryData={singleCategoryData}
                updateEditCategoryLoading={updateEditCategoryLoading}
                setUpdateEditCategoryLoading={setUpdateEditCategoryLoading}
            />
        </ModalPop>
        <ModalPop isOpen={openDeleteCategory}>
            <DeleteCategory 
                handleClose={() => setOpenDeleteCategory(false)}
                deleteCategoryId={deleteCategoryId}
                deleteCategoryLoading={deleteCategoryLoading}
                setDeleteCategoryLoading={setDeleteCategoryLoading} 
            />
        </ModalPop>

        <ModalPop isOpen={openRegion}>
            <EditRegion 
                handleClose={() => setOpenRegion(false)} 
                singleRegionData={singleRegionData}
                updateEditRegionLoading={updateEditRegionLoading}
                setUpdateEditRegionLoading={setUpdateEditRegionLoading}
            />
        </ModalPop>
        <ModalPop isOpen={openDeleteRegion}>
            <DeleteRegion 
                handleClose={() => setOpenDeleteRegion(false)}
                deleteRegionId={deleteRegionId}
                deleteRegionLoading={deleteRegionLoading}
                setDeleteRegionLoading={setDeleteRegionLoading} 
            />
        </ModalPop>

        <ModalPop isOpen={openManufacturerType}>
            <EditManufacturerType 
                handleClose={() => setOpenManufacturerType(false)} 
                singleManufacturerTypeData={singleManufacturerTypeData}
                updateEditManufacturerTypeLoading={updateEditManufacturerTypeLoading}
                setUpdateEditManufacturerTypeLoading={setUpdateEditManufacturerTypeLoading}
            />
        </ModalPop>
        <ModalPop isOpen={openDeleteManufacturerType}>
            <DeleteManufacturerType 
                handleClose={() => setOpenDeleteManufacturerType(false)}
                deleteManufacturerTypeId={deleteManufacturerTypeId}
                deleteManufacturerTypeLoading={deleteManufacturerTypeLoading}
                setDeleteManufacturerTypeLoading={setDeleteRegionLoading} 
            />
        </ModalPop>

    </div>
  )
}

export default ViewFilters