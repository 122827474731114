import React from 'react';
import { SwipeableDrawer, Box } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useDrawerStyles = makeStyles((theme) => ({
    drawer: {
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
        "& .MuiPaper-root": {
            height: "100%",
        },
    },
}));

const useDrawerStyles2 = makeStyles((theme) => ({
    drawer: {
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
        "& .MuiPaper-root": {
            height: "auto",
        },
    },
}));

export default function TemporaryDrawer({open, setOpen, children, auto}) {
    const classes = useDrawerStyles();
    const classes2 = useDrawerStyles2();
    return (
        <SwipeableDrawer
            className={auto ? classes2.drawer : classes.drawer}
            anchor={"right"}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={{ width: 322 }} role='presentation'>
                {children}
            </Box>
        </SwipeableDrawer>    
    );
}