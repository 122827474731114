import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { signInWithEmailAndPassword } from 'firebase/auth';
import PasswordField from '../components/InputFields/PasswordField';
import { auth, db } from '../firebase-config';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();


  const from = location.state?.from?.pathname || '/view-manufacturer';

  const listAllAdmins = async () => {
    const querySnapshot = await getDocs(collection(db, 'admins'));
    querySnapshot.forEach((doc) => {
      console.log(`Massa ${doc.id} => ${doc.data()}`);
    });
  };

  const checkIfAdmin = async (userEmail, userPassword) => {
    console.log(userEmail, "userEmail");
    try {
      const q = query(collection(db, 'admins'), where('email', '==', userEmail));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        for (const doc of querySnapshot.docs) {
          const adminData = doc.data();
          if (adminData.password === userPassword) {
            console.log('Admin document data:', adminData);
            return adminData; // Return admin data if email and password match
          }
        }
        console.log('Password mismatch.');
        return null;
      } else {
        console.log('No matching admin document found.');
        return null;
      }
    } catch (err) {
      console.error('Error checking admin status:', err);
      return null;
    }
  };

  const submitForm = async (values) => {
    setLoading(true);
    try {
      const data = {
        email: values.email,
        password: values.password,
      };
  
      const adminData = await checkIfAdmin(data.email, data.password);
      console.log('Is admin:', adminData);
  
      if (adminData) {
        localStorage.setItem('userRole', adminData.role);
        localStorage.setItem('adminName', `${adminData.firstName} ${adminData.lastName}`)
        localStorage.setItem('isLoggedIn', true);
        
        toast(`Login Successful`, {
          position: 'top-right',
          autoClose: 3500,
          closeOnClick: true,
        });
        navigate(from, { replace: true });
      } else {
        toast(`Only admins are allowed to login`, {
          position: 'top-right',
          autoClose: 3500,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error('Login error:', error);
      toast(`Invalid Email/Password`, {
        position: 'top-right',
        autoClose: 3500,
        closeOnClick: true,
      });
    }
    setLoading(false);
  };
  
  useEffect(() => {
    listAllAdmins();
  }, []);
  
  

  // const submitForm = async (values) => {
  //   setLoading(true);
  //   try {
  //     // const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
  //     // const user = userCredential.user;

  //     // console.log('User credential:', userCredential);
  //     // console.log('Signed-in user:', user);

  //     const data = {
  //       email: values.email,
  //       password: values.password
  //     }

  //     const isAdmin = await checkIfAdmin(data?.email);
  //     console.log('Is admin:', isAdmin);

  //     if (isAdmin) {
  //       const userDoc = await getDoc(doc(db, 'admins')); //, user.uid
  //       const userData = userDoc.data();
  //       localStorage.setItem('userRole', userData.role);

  //       toast(`Login Successful`, {
  //         position: 'top-right',
  //         autoClose: 3500,
  //         closeOnClick: true,
  //       });
  //       navigate(from, { replace: true });
  //     } else {
  //       toast(`Only admins are allowed to login`, {
  //         position: 'top-right',
  //         autoClose: 3500,
  //         closeOnClick: true,
  //       });
  //       auth.signOut();
  //     }
  //   } catch (error) {
  //     console.error('Login error:', error);
  //     toast(`Invalid Email/Password`, {
  //       position: 'top-right',
  //       autoClose: 3500,
  //       closeOnClick: true,
  //     });
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   listAllAdmins()
  // }, [])

  // const submitForm = async (values) => {
  //   setLoading(true);
  //   try {
  //     const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
  //     const user = userCredential.user;

  //     toast(`Login Successful`, {
  //       position: 'top-right',
  //       autoClose: 3500,
  //       closeOnClick: true,
  //     });
  //     navigate(from, { replace: true });
  //   } catch (error) {
  //     toast(`Invalid Email/Password`, {
  //       position: 'top-right',
  //       autoClose: 3500,
  //       closeOnClick: true,
  //     });
  //   }
  //   setLoading(false);
  // };

  return (
    <div className='mb-5 bg-[#FAFAFA] h-screen flex flex-col px-2 lg:px-0'>
      {/* <img src={Logo} alt='logo' className='w-[141px] px-5 mt-5 cursor-pointer'/> */}
      <div className='w-full md:w-[480px] mx-auto lg:mt-[5%] mt-[50%] my-auto h-[430px] bg-[#fff] shadow rounded-lg border flex flex-col border-solid border-[#E6E7EC] p-8'>
        <div className='flex flex-col justify-center gap-1 items-center'>
          <p className="text-xl font-medium text-[#101828]">Welcome back!</p>
          <p className='text-[#828282] text-sm'>Sign in to access</p>
        </div>
        <div className="h-[300px] mt-5">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            // validationSchema={formValidationSchema}
            onSubmit={(values) => {
              window.scrollTo(0, 0);
              submitForm(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit} className="flex flex-col lg:items-center">
                <div className='flex flex-col gap-6 lg:items-center h-[47px]'>
                  <div className="flex flex-col ">
                    <label htmlFor='email' className="text-xs font-normal text-[#101828]">Email</label>
                    <input
                      name="email"
                      placeholder="youremail@domain.com"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      className="rounded-lg border-[#D0D5DD] xs:w-full outline-none lg:w-[420px] mt-1.5 h-[51px] border-solid p-3 border"
                    />
                    {errors.email && touched.email ? (
                      <div className='text-red-500'>{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="flex flex-col xs:w-full lg:w-[420px]">
                    <label htmlFor='password' className="text-xs font-normal text-[#101828]">Password</label>
                    <PasswordField
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      className="border lg:w-[420px] h-[51px] rounded-lg border-[#D0D5DD] mt-1.5"
                      onChange={handleChange}
                    />
                    {errors.password && touched.password ? (
                      <div className='text-red-500'>{errors.password}</div>
                    ) : null}
                  </div>

                  <button
                    className="bg-[#503225] mt-5 xs:w-full lg:w-[420px] text-[#fff] rounded-lg p-3 cursor-pointer w-full h-[54px] flex justify-center"
                    type="submit"
                  >
                    <p className='text-[#fff] text-sm text-center font-medium'>
                      {loading ? <CgSpinner className="animate-spin text-lg" /> : 'Login'}
                    </p>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
