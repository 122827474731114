import React from 'react'
import Navigation from '../components/Navigation'

export default function HomeLayout({children}) {
    return (
        <div className='flex flex-col'>
            <Navigation />
            <div className='mt-[100px]'>
                {children}
            </div>
        </div>
    )
}
