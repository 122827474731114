import React from 'react'
import History from '../../assets/img/history.png'
import { capitalizeSentences } from '../../utils/helperFuncs'

export default function CoffeeHistory({data}) {
    return (
        <div className='xl:pl-[4%] xl:pr-[10.24px] xl:pb-[100px] pb-[45px] xl:pt-[74px] pt-10 flex xl:flex-row flex-col w-full xl:gap-[146px] gap-[19px]'>
            <div className='w-full px-5 xl:px-0'>
                <h1 className='nunito-bold md:text-[40px] text-[28px] text-primary'>A Brief History About Us</h1>
                {data.year && <div className='flex gap-[22px] items-center'>
                    <p className='nunito-bold text-2xl mt-[11px] text-grey-100'>Year Of Origin</p>
                    <div className='w-20 h-[35px] bg-pink flex justify-center items-center rounded-[10px]'>
                        <p className='font-bold text-tertiary'>{data.year}</p>
                    </div>
                </div>}
                <p className='mt-5 nunito-normal text-[18px] text-grey-150 text-justify'>{capitalizeSentences(data.history)}</p>
            </div>
            <div className='w-full flex xl:justify-end pl-5 xl:pl-0'>
                <img src={History} alt='history' className='xl:w-[530px] w-full xl:h-[550px] h-[371px] object-contain'/>
            </div>
        </div>
    )
}
