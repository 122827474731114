import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@mui/material'

import Search from "../assets/svg/search.svg"

const Orders = () => {
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState("")
    const [ordersData, setOrdersData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const apiUrl = process.env.REACT_APP_BASE_URL;

    const itemsPerPage = 10; 

    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const res = await axios.get(`${apiUrl}/api/orders/coffee`)
            console.log(res, "samba")
            setOrdersData(res?.data)
        } catch (err) {
            console.log(err, "err")
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleSearch = (e) => {
        setText(e.target.value);
    }; 

    const filteredData = ordersData?.filter(item =>
        item?.userId?.name?.toLowerCase().includes(text?.toLowerCase())
    );

    // Calculate total pages
    const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

    // Get current items for the page
    const currentData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

  return (
    <div className='w-full lg:overflow-x-hidden mt-[100px] flex flex-col gap-10 lg:px-5'>
        <div className='flex justify-between flex-col gap-4 fixed lg:relative lg:gap-0 lg:flex-row lg:items-center '>
            <h1 className='text-3xl font-bold'>View Orders</h1>
            <div className='flex flex-col lg:flex-row lg:items-center gap-[9px]'>
                <div className='rounded-[23px] border border-[#CCCCCC] w-[300px] lg:w-[372px] h-[46px] flex items-center gap-2 p-3'>
                    <img src={Search} alt='Search' className='w-4 h-4' />
                    <input 
                        placeholder='Search Name'
                        type='text'
                        name='search'
                        value={text}
                        className='outline-none bg-transparent w-full'
                        onChange={handleSearch}
                    />
                </div>
            </div>
        </div>
        <div className='flex flex-col w-full'>
            {
                loading ?
                <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                :
                <>
                    <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                        <tr className='h-[32px] whitespace-nowrap  border-0' >
                            <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Name
                            </th>
                            <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Email
                            </th>
                            <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                items Count
                            </th>
                            <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Address
                            </th>
                            <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                City
                            </th>
                            <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Country
                            </th>
                        </tr>
                        {currentData?.length > 0 ? currentData?.map((item, index) => {
                            return (
                                <tr key={index} className={`bg-transparent h-[55px]   border-b border-grey-100`} >
                                    <td className='h-[55px] flex items-center cursor-pointer '>
                                        <p className='text-sm font-kumbh capitalize'>{item?.userId?.name}</p> 
                                    </td>
                                    <td className='h-[55px] px-4'>
                                        <p className='text-sm font-kumbh'>{`${item?.deliveryInfo?.email}`}</p>
                                    </td>
                                    <td className='h-[55px] px-4'>
                                        <p className='text-sm font-kumbh'>{item?.items?.length}</p>
                                    </td>
                                    <td className='h-[55px] px-4 '>
                                        <p className='text-sm font-kumbh'>{`${item?.deliveryInfo?.address}`}</p>
                                    </td> 
                                    <td className='h-[55px] px-4 '>
                                        <p className='text-sm font-kumbh'>{`${item?.deliveryInfo?.city}`}</p>
                                    </td> 
                                    <td className='h-[55px] px-4 '>
                                        <p className='text-sm font-kumbh'>{`${item?.deliveryInfo?.country}`}</p>
                                    </td> 
                                    
                                    
                                </tr>
                            )   
                        
                        }) : (
                                <tr className='h-[654px] bg-white border-t border-grey-100'>
                                    <td colSpan="8" className="relative">
                                        <div className='absolute inset-0 flex items-center justify-center'>
                                            <div className='flex flex-col gap-2 items-center'>
                                                {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                                <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Products Available</p>
                                                <p>Oops! Nothing to see here.</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            )
                        }
                    </table>
                    <div className="flex justify-center mt-1 mb-10">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={`px-3 py-1 mx-1 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            }
        </div>
 
    </div>
  )
}

export default Orders