import React from 'react'
import Arrow from '../assets/svg/arrow.svg'

export default function Select({label, placeholder, children, value, handleChange}) {
    return (
        <div className="relative">
            <label className='absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>
                {label}
            </label>
            <select
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                className="text-grey-50 cursor-pointer appearance-none bg-no-repeat text-sm w-full h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none"
            >
                <option value={placeholder}>{placeholder}</option>
                {children}
            </select>
            <img src={Arrow} alt='arrow' className='absolute right-[25px] -mt-[25px]'/>
        </div>
    )
}