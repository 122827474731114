import React from 'react'
import Direct from '../../assets/svg/web.svg'
import Direct2 from '../../assets/svg/phone.svg'
import Direct3 from '../../assets/svg/direction.svg'
import { capitalizeString } from '../../utils/helperFuncs'
import { useNavigate } from 'react-router-dom'

export default function CoffeeBase({data}) {
    const cleanedLink = data.contact?.replace(/^0+/, "");;

  //(window.location.href = data.website)
 
    return (
        <div className='xl:-mt-20 xl:absolute flex w-full'>
            <div className='w-[31%] xl:flex hidden justify-center items-start'>
                <img src={data.logo_small ? data.logo_small : data.logo} alt='coffee' className='size-[91px] -mt-6 object-contain rounded-full' />
                <h1 className='font-bold text-2xl text-white'>{capitalizeString(data?.company)}</h1>
            </div>
            <div className='flex-1 flex md:flex-row flex-col shadow-[10px_10px_80px_0_#22326333] items-center rounded-l-lg bg-white'>
                <div className='flex md:gap-5 gap-[10px] md:pl-5 px-5 md:pr-0 md:h-[62px] h-[94.5px] md:border-0 border-b border-grey-200 w-full items-center justify-center md:flex-1'>
                    {data?.website ? (
                        <>
                            <div className='flex gap-[9px] items-center cursor-pointer' onClick={() => window.open(`https://${data?.website}`, "_blank")} >
                                <p className='font-bold md:text-[18px]'>Visit Website</p>
                                <img src={Direct} className='cursor-pointer'/>
                            </div>
                            <div className='bg-blue w-[1px] h-[52px] opacity-10'></div>
                        </>
                    ) : <p className='font-bold md:text-[18px]'>No Website</p>}
                    <div className='flex gap-[9px] items-center'>
                        <p className='font-bold md:text-[18px]'>{data?.contact}</p>
                        <a href={`tel:${data?.contact}`}>
                            <img src={Direct2} className='cursor-pointer'/>
                        </a>
                    </div>
                    <div className='bg-blue w-[1px] h-[52px] opacity-10 md:block hidden'></div>
                    <div className='md:flex hidden gap-[9px] items-center'>
                        <div className='flex flex-col'>
                            <p className='font-bold text-[18px]'>Get Direction</p>
                            <p className='text-blue text-sm opacity-50'>{data?.address}</p>
                        </div>
                        <img src={Direct3} className='cursor-pointer'/>
                    </div>
                </div>
                <div className='flex justify-between md:w-auto w-full'>
                    <div className='md:hidden flex gap-[9px] items-center pl-5'>
                        <div className='flex flex-col'>
                            <p className='font-bold text-[18px]'>Get Direction</p>
                            <p className='text-blue text-sm opacity-50'>{data?.address}</p>
                        </div>
                        <img src={Direct3} className='cursor-pointer'/>
                    </div>
                    <div onClick={() => window.location.href = `https://api.whatsapp.com/send?phone=${cleanedLink}&text=Hello,%20I%20would%20love%20your%20services`} className='bg-secondary size-[120px] rounded-l-lg flex flex-col justify-center gap-[11px] items-center cursor-pointer'>
                        <svg className='cursor-pointer' width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.759 0C22.0243 0 22.2786 0.105357 22.4661 0.292893C22.6537 0.48043 22.759 0.734784 22.759 1V17.007C22.7572 17.2696 22.6521 17.521 22.4665 17.7068C22.2809 17.8926 22.0297 17.9979 21.767 18H3.75103C3.48785 17.9997 3.23553 17.895 3.04952 17.7088C2.86352 17.5226 2.75903 17.2702 2.75903 17.007V16H20.759V4.3L12.759 11.5L2.75903 2.5V1C2.75903 0.734784 2.86439 0.48043 3.05193 0.292893C3.23946 0.105357 3.49382 0 3.75903 0H21.759ZM8.75903 12V14H0.759033V12H8.75903ZM5.75903 7V9H0.759033V7H5.75903ZM20.325 2H5.19303L12.759 8.809L20.325 2Z" fill="white"/>
                        </svg>
                        <p className='font-bold text-[18px] text-white'>Message</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
