import React, { useState } from 'react'
import Effect from '../../assets/svg/effect.svg'
import Background from '../../assets/img/bg-5.png'
import Qts from '../../assets/svg/qts.svg'
import ReviewBox from '../ReviewBox'

export default function CoffeeReview({data}) {
    const [count, setCount] = useState(0)
    
    const handleNext = () => {
        if (count < data?.reviews?.length - 1) {
          setCount(prevCount => prevCount + 1);
        }
    };
      
    const handlePrevious = () => {
        if (count > 0) {
          setCount(prevCount => prevCount - 1);
        }
    };

    return (
        data?.reviews?.length > 0 && <div className='mt-[25%] md:mt-[10%] w-full flex md:flex-row flex-col md:gap-[77px] gap-0'>
            <div className='flex flex-1 justify-between'>
                <div className='w-full flex'>
                    <img src={Effect} alt='effect'/>
                    <div className='xl:left-[4%] left-[2%] absolute flex flex-col xl:gap-[122px] gap-5 md:w-[52%] w-[96%]'>
                        <h1 className='nunito-bold md:text-[40px] text-2xl text-primary'>Reviews & Ratings</h1>
                        <div className='flex flex-col xl:self-end gap-[19.13px] md:w-[459px] w-full'>
                            {data?.reviews[count] && <ReviewBox data={data.reviews[count]}/>}
                            {data?.reviews?.length > 1 && (
                                <div className='flex gap-[21.87px] mt-[19.13px] items-center'>
                                    <svg onClick={handlePrevious} className='cursor-pointer' width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.6" d="M7.89 11.134L4.22 7.46398H16.689V5.75398H4.22L7.89 2.08398L6.681 0.875977L0.947998 6.60898L6.681 12.342L7.89 11.134Z" fill="#F1944E"/>
                                    </svg>
                                    <svg onClick={handleNext} className='cursor-pointer' width="38" height="13" viewBox="0 0 38 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.731 2.08477L34.401 5.75477L0.560974 5.75477V7.46377L34.401 7.46377L30.731 11.1338L31.94 12.3428L37.673 6.60977L31.94 0.876774L30.731 2.08477Z" fill="#7E3701"/>
                                    </svg>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='xl:w-[543px] md:mt-[19.2px] sm:-mt-[25%] -mt-[45%] flex justify-end xl:pr-[4%] pr-5'>
                <div className='bg-[#EBF0FF] sm:block hidden w-[427.42px] h-[491.53px] rounded-tl-[10px] rounded-br-[10px]'></div>
                <div className='absolute mt-[74px] sm:mr-[62.34px] flex'>
                    <img src={Background} alt='back' className='sm:w-[427.42px] w-[330px] sm:h-[491.53px] h-[400px]'/>
                    <div className='sm:-ml-[90px] -ml-[40px] sm:mt-0 -mt-[100px] absolute h-[491.53px] flex items-end'>
                        <img src={Qts} alt='quote' className='sm:size-auto size-[100px] object-cover'/>
                    </div>
                </div>
            </div>
        </div>
    )
}
