// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDOLl5z9E-Q72ijjDqT7-VD2GBYQ2zp1Lc",
//   authDomain: "coffepro-ca90e.firebaseapp.com",
//   projectId: "coffepro-ca90e",
//   storageBucket: "coffepro-ca90e.appspot.com",
//   messagingSenderId: "114729834863",
//   appId: "1:114729834863:web:ffe44edc9ce9991782309a",
//   measurementId: "G-G50REGGVXN"
// };

const firebaseConfig = {
     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
     appId: process.env.REACT_APP_FIREBASE_APP_ID,
     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

setPersistence(auth, browserLocalPersistence)
     .then(() => {
          console.log("Auth persistence set successfully");
     })
     .catch((error) => {
          console.error("Error setting auth persistence:", error);
     });

export { db, auth, storage, functions };
