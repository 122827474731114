import React, { useEffect, useState } from 'react'
import { Skeleton } from '@mui/material';
import { db } from '../firebase-config';
import { MdDeleteForever } from 'react-icons/md';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { FaRegEdit } from 'react-icons/fa';

import Search from "../assets/svg/search.svg"
import { toast } from 'react-toastify';
import UpdateData from './UpdateData';
import ModalPop from '../components/modal/modalPop';
import { Navigate, useNavigate } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import AddAdmin from './AddAdmin';
import EditAdmin from './EditAdmin';
import DeleteAdmin from './DeleteAdmin';

const Admins = () => {
    const [loading, setLoading] = useState(false)
    const [adminData, setAdminData] = useState([])
    const [userAdminData, setUserAdminData] = useState([])
    const [text, setText] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [deleteItemId, setDeleteItemId] = useState()
    const [openAddAdmin, setOpenAddAdmin] = useState(false)
    const [openEditAdmin, setOpenEditAdmin] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    const [addAdminLoading, setAddAdminLoading] = useState(false)

    const itemsPerPage = 10; 

    const colRef = collection(db, "admins") 

    const fetchData = () => {
        setLoading(true)
        getDocs(colRef)
        .then((snapshot) => {
            setLoading(false)
           let admins = []
           snapshot.docs.forEach((doc) => {
            admins.push({ ...doc.data(), id: doc.id })
           })
          
           console.log(admins, "ouan")
           setAdminData(admins)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchData()
    }, [deleteLoading, editLoading, addAdminLoading, ])

    const handleSearch = (e) => {
        setText(e.target.value);
    };

    const filteredData = adminData?.filter(item =>
        item?.role?.toLowerCase().includes(text?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(text?.toLowerCase())
    );


     // Calculate total pages
     const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

     // Get current items for the page
     const currentData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
 
     const handlePageChange = (pageNumber) => {
         setCurrentPage(pageNumber);
     };

     const navigate = useNavigate()
    

  return (
      <div className='w-full lg:overflow-x-hidden mt-[100px] flex flex-col gap-10 lg:px-5'>
        <div className='flex flex-col gap-5'>
            <div className='flex justify-between flex-col gap-4 fixed lg:relative lg:gap-0 lg:flex-row lg:items-center '>
                <h1 className='text-3xl font-bold'>View Admins</h1>
                <button
                    className='w-[150px] flex items-center justify-center h-[48px] rounded-lg bg-secondary'
                    type='button'
                    onClick={() => setOpenAddAdmin(true)}
                >
                    <p className='text-[#fff] font-Kumbh font-medium'>Add New Admin</p>
                </button>
            </div>
            <div className='flex flex-col lg:flex-row justify-end lg:items-center gap-[9px]'>
                <div className='rounded-[23px] border border-[#CCCCCC] w-[300px] lg:w-[372px] h-[46px] flex items-center gap-2 p-3'>
                    <img src={Search} alt='Search' className='w-4 h-4' />
                    <input 
                        placeholder='Search by email'
                        type='text'
                        name='search'
                        value={text}
                        className='outline-none bg-transparent w-full'
                        onChange={handleSearch}
                    />
                </div>
                {/* <div className='w-[101px] h-[46px] rounded-[23px] border border-[#CCCCCC] flex items-center justify-center'>
                    <p className='font-kumbh text-sm text-[#A3A3A3]'>Filter</p>
                </div> */}
            </div>

        </div>
        {
            loading || deleteLoading || editLoading || addAdminLoading ?
            <Skeleton variant="rectangular" width={1185} height={1000} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
            :
            <>
                <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                    <tr className='h-[32px] whitespace-nowrap  border-0' >
                        <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Email
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            FirstName
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            LastName
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Role
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Action
                        </th>
                    </tr>
                    {currentData?.length > 0 ? currentData?.map((item, index) => (
                        <tr key={index} className={`bg-transparent h-[55px] border-b border-grey-100`}>
                            <td className='h-[55px] flex items-center cursor-pointer '>
                                <p className='text-sm font-kumbh'>{item?.email}</p> 
                            </td>
                            <td className='h-[55px] px-4'>
                                <p className='text-sm font-kumbh'>{item?.firstName || "N/A"}</p>
                            </td>
                            <td className='h-[55px] px-4 '>
                                <p className='text-sm font-kumbh'>{`${item?.lastName}`}</p>
                            </td> 
                            <td className='h-[55px] px-4 '>
                                <p className='text-sm font-kumbh capitalize'>{`${item?.role}`}</p>
                            </td> 
                            <td className='h-[55px] px-4 '>
                                <div className='flex items-center gap-2'>
                                    <FaRegEdit className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() => {setOpenEditAdmin(true); setUserAdminData(item)}} />
                                    <MdDeleteForever className='text-[#f00] w-5 h-5 cursor-pointer' onClick={() =>{setOpenDelete(true); setDeleteItemId(item?.id)}}/>
                                </div>
                            </td> 
                        </tr>
                    )) : (
                        <tr className='h-[654px] bg-white border-t border-grey-100'>
                            <td colSpan="8" className="relative">
                                <div className='absolute inset-0 flex items-center justify-center'>
                                    <div className='flex flex-col gap-2 items-center'>
                                        {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                        <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Admins Available</p>
                                        <p>Oops! Nothing to see here.</p>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    )
                }
                </table>
                <div className="flex justify-center mt-1 mb-10">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-3 py-1 mx-1 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
        }
        <ModalPop isOpen={openAddAdmin}>
            <AddAdmin 
                handleClose={() => setOpenAddAdmin(false)}
                // addAdminLoading={addAdminLoading}
                setAddAdminLoading={setAddAdminLoading} 
            />
        </ModalPop>
        <ModalPop isOpen={openEditAdmin}>
            <EditAdmin 
                handleClose={() => setOpenEditAdmin(false)} 
                editLoading={editLoading}
                setEditLoading={setEditLoading}
                userAdminData={userAdminData}
            />
        </ModalPop>
        <ModalPop isOpen={openDelete}>
            <DeleteAdmin 
                handleClose={() => setOpenDelete(false)}
                deleteItemId={deleteItemId}
                deleteLoading={deleteLoading}
                setDeleteLoading={setDeleteLoading} 
            />
        </ModalPop>
    </div>
  )
}

export default Admins