import * as React from 'react';
import Button from '@mui/material/Button';

export default function BasicButtons({text, functions, width, icon, font, bg, height}) {
    return (
        <Button 
            sx={{
                background: bg ? 'transparent' : '#503225',
                fontFamily: font ? font : 'inherit',
                fontSize: '16px',
                textTransform: 'initial',
                width: width? width : '100%',
                height: height ? height : '44px',
                borderRadius: '8px',
                fontWeight: '400',
                display: 'flex',
                border: bg && '1px solid #503225',
                gap: '10px',
                color: bg && '#503225',
                boxShadow: bg && 'none',
                ":hover": {
                    background: bg ? 'transparent' : '#503225'
                }
            }}
            variant="contained"
            onClick={functions}
        >
            {icon && <img src={icon} alt='icon' />}
            {text}
        </Button>
    );
}