import React, { useEffect, useState } from 'react'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import { useNavigate } from 'react-router-dom';

import Search from "../assets/svg/search.svg"
import { Skeleton } from '@mui/material';
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import ModalPop from '../components/modal/modalPop';
import DeleteEmail from './DeleteEmail';

const EmailData = () => {
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState("")
    const [subscribersData, setSubscribersData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const [deleteEmailLoading, setDeleteEmailLoading] = useState(false)
    const [openDeleteEmail, setOpenDeleteEmail] = useState(false)
    const [deleteEmailId, setDeleteEmailId] = useState()

    const itemsPerPage = 10; 

    const navigate = useNavigate()

    const colRef = collection(db, "subscribers") 

    const fetchData = () => {
        setLoading(true)
        getDocs(colRef)
        .then((snapshot) => {
            setLoading(false)
           let subscribers = []
           snapshot.docs.forEach((doc) => {
            subscribers.push({ ...doc.data(), id: doc.id })
           })
           console.log(subscribers, "ouan")
           setSubscribersData(subscribers)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
        })
    }


    useEffect(() => {
        fetchData()
    }, [deleteEmailLoading])

    const handleSearch = (e) => {
        setText(e.target.value);
    }; 

    const filteredData = subscribersData?.filter(item =>
        item?.email?.toLowerCase().includes(text?.toLowerCase())
    );

    // Calculate total pages
    const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

    // Get current items for the page
    const currentData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


  return (
    <div className='w-full lg:overflow-x-hidden mt-[100px] flex flex-col gap-10 lg:px-5'>
        <div className='flex justify-between flex-col gap-4 fixed lg:relative lg:gap-0 lg:flex-row lg:items-center '>
            <h1 className='text-3xl font-bold'>View Emails</h1>
            <div className='flex flex-col lg:flex-row lg:items-center gap-[9px]'>
                <div className='rounded-[23px] border border-[#CCCCCC] w-[300px] lg:w-[372px] h-[46px] flex items-center gap-2 p-3'>
                    <img src={Search} alt='Search' className='w-4 h-4' />
                    <input 
                        placeholder='Search email'
                        type='text'
                        name='search'
                        value={text}
                        className='outline-none bg-transparent w-full'
                        onChange={handleSearch}
                    />
                </div>
            </div>
        </div>
        <div className='flex flex-col w-5/12'>
            {
                loading || deleteEmailLoading ?
                <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                :
                <>
                    <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                        <tr className='h-[32px] whitespace-nowrap  border-0' >
                            <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Email
                            </th>
                            <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                                Action
                            </th>
                        </tr>
                        {currentData?.length > 0 ? currentData?.map((item, index) => (
                            <tr key={index} className={`bg-transparent h-[55px]   border-b border-grey-100`} >
                                <td className='h-[55px] flex items-center cursor-pointer '>
                                    <p className='text-sm font-kumbh'>{item?.email}</p> 
                                </td>
                                <td className='h-[55px] px-4 '>
                                    <div className='flex items-center gap-2'>
                                        <MdDeleteForever className='text-[#f00] w-5 h-5 cursor-pointer' onClick={() =>{setOpenDeleteEmail(true); setDeleteEmailId(item?.id)}}/>
                                    </div>
                                </td> 
                            </tr>
                        )) : (
                            <tr className='h-[354px] bg-white border-t border-grey-100'>
                                <td colSpan="8" className="relative">
                                    <div className='absolute inset-0 flex items-center justify-center'>
                                        <div className='flex flex-col gap-2 items-center'>
                                            {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                            <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Emails Available</p>
                                            <p>Oops! Nothing to see here.</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        )
                    }
                    </table>
                    <div className="flex justify-center mt-1 mb-10">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={`px-3 py-1 mx-1 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            }
        </div>
        <ModalPop isOpen={openDeleteEmail}>
            <DeleteEmail 
                handleClose={() => setOpenDeleteEmail(false)}
                deleteEmailId={deleteEmailId}
                deleteEmailLoading={deleteEmailLoading}
                setDeleteEmailLoading={setDeleteEmailLoading} 
            />
        </ModalPop>
    </div>
  )
}

export default EmailData