import React from 'react'

export default function Socials({data}) {
    console.log(data)
    return (
        <div className='flex flex-col gap-[18px]'>
            <div className='flex flex-col gap-[9px]'>
                <h3 className='text-[18px] nunito-bold'>SERVICE HOTLINE</h3>
                <div className='text-[18px] nunito-bold'>
                    <p>{data?.contact}</p>
                    <p>Mon-Fri: 9 am - 5 pm</p>
                </div>
            </div>
            <div className='text-[18px] nunito-bold'>
                <p>Or via E-Mail:</p>
                <p>{data?.email}</p>
            </div>
            <div className='flex gap-5 items-center'>
                {data?.socials?.instagram && <div onClick={() => window.location.href = data?.socials.instagram} className='cursor-pointer flex justify-center items-center size-[50px] rounded-full bg-white shadow-[0_0_6px_0_#0000001A]'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7581 7C10.1647 7 9.58469 7.17595 9.09135 7.50559C8.598 7.83524 8.21348 8.30377 7.98642 8.85195C7.75936 9.40013 7.69994 10.0033 7.8157 10.5853C7.93146 11.1672 8.21718 11.7018 8.63674 12.1213C9.05629 12.5409 9.59084 12.8266 10.1728 12.9424C10.7547 13.0581 11.3579 12.9987 11.9061 12.7716C12.4543 12.5446 12.9228 12.1601 13.2525 11.6667C13.5821 11.1734 13.7581 10.5933 13.7581 10C13.7581 9.20435 13.442 8.44129 12.8794 7.87868C12.3168 7.31607 11.5537 7 10.7581 7ZM10.7581 5C11.747 5 12.7137 5.29324 13.5359 5.84265C14.3582 6.39206 14.999 7.17295 15.3775 8.08658C15.7559 9.00021 15.8549 10.0055 15.662 10.9755C15.4691 11.9454 14.9929 12.8363 14.2936 
                        13.5355C13.5943 14.2348 12.7034 14.711 11.7335 14.9039C10.7636 15.0969 9.75827 14.9978 8.84464 14.6194C7.93101 14.241 7.15012 13.6001 6.60071 12.7779C6.0513 11.9556 5.75806 10.9889 5.75806 10C5.75806 8.67392 6.28484 7.40215 7.22252 6.46447C8.1602 5.52678 9.43197 5 10.7581 5ZM17.2581 4.749C17.2583 4.99631 17.1851 5.23812 17.0478 5.44384C16.9105 5.64955 16.7153 5.80992 16.4869 5.90466C16.2584 5.99939 16.007 6.02423 15.7644 5.97603C15.5219 5.92783 15.2991 5.80876 15.1242 5.63388C14.9493 5.45901 14.8302 5.23619 14.782 4.99362C14.7338 4.75105 14.7587 4.49963 14.8534 4.27118C14.9481 4.04274 15.1085 3.84752 15.3142 3.71025C15.5199 3.57297 15.7617 3.4998 16.0091 3.5C16.3401 3.50053 16.6575 3.63229 16.8916 3.86641C17.1258 4.10053 17.2575 4.41791 17.2581 4.749ZM10.7581 2C8.28306 2 7.88006 2.007 6.72906 2.058C6.11461 2.06621 5.506 2.17846 4.92906 2.39C4.52081 2.53936 4.15137 2.7786 3.84806 3.09C3.53639 3.3931 3.29711 3.7626 3.14806 4.171C2.93613 4.74783 2.82386 5.35652 2.81606 5.971C2.76506 7.075 2.75806 7.461 2.75806 10C2.75806 12.474 2.76506 12.878 2.81606 14.029C2.82434 14.6434 2.9366 15.252 3.14806 15.829C3.30418 16.2341 3.54339 16.6021 3.85031 16.9091C4.15722 17.2162 4.52503 17.4557 4.93006 17.612C5.50696 17.8238 6.11556 17.9364 6.73006 17.945C7.83006 17.997 8.22006 18.003 10.7591 
                        18.003C13.2331 18.003 13.6371 17.996 14.7881 17.945C15.4025 17.9364 16.011 17.8242 16.5881 17.613C16.9934 17.457 17.3615 17.2178 17.6688 16.9109C17.976 16.604 18.2156 16.2361 18.3721 15.831C18.5836 15.254 18.6958 14.6454 18.7041 14.031C18.7561 12.931 18.7621 12.541 18.7621 10.002C18.7621 7.528 18.7551 7.124 18.7041 5.973C18.6955 5.35859 18.5832 4.75004 18.3721 4.173C18.2223 3.76494 17.9831 3.39557 17.6721 3.092C17.3691 2.78017 16.9995 2.54087 16.5911 2.392C16.0142 2.18015 15.4055 2.06788 14.7911 2.06C13.6841 2.006 13.2981 2 10.7581 2ZM10.7581 0C13.4751 0 13.8141 0.00999994 14.8811 0.0599999C15.7105 0.0770519 16.5311 0.234269 17.3081 0.525C17.976 0.775003 18.5809 1.1686 19.0801 1.678C19.5889 2.1778 19.9827 2.7825 20.2341 3.45C20.5241 4.22753 20.6813 5.04832 20.6991 5.878C20.7471 6.944 20.7581 7.283 20.7581 10C20.7581 12.717 20.7481 13.056 20.6981 14.122C20.6805 14.9514 20.5233 15.7719 20.2331 16.549C19.9763 17.2135 19.5834 17.8169 19.0797 18.3206C18.576 18.8244 17.9725 19.2172 17.3081 19.474C16.5308 19.7639 15.7104 19.9211 14.8811 19.939C13.8141 19.986 13.4751 19.999 10.7581 19.999C8.04106 19.999 7.70206 19.989 6.63506 19.939C5.80568 19.9214 4.98519 19.7643 4.20806 19.474C3.54384 19.2173 2.94061 18.8245 2.43707 18.321C1.93353 17.8174 1.5408 17.2142 1.28406 16.55C0.993715 15.7729 0.83651 14.9524 0.819057 14.123C0.771057 13.056 0.758057 12.717 0.758057 10C0.758057 7.283 0.768057 6.944 0.818057 5.878C0.83522 5.04816 0.992781 4.22723 1.28406 3.45C1.53426 2.78216 1.92783 2.1773 2.43706 1.678C2.93679 1.16931 3.54153 0.775816 4.20906 0.525C4.98614 0.234584 5.80666 0.0773771 6.63606 0.0599999C7.70306 0.0129999 8.04206 0 10.7581 0Z" fill="#503225"/>
                    </svg>
                </div>}
                {data?.socials?.facebook && <div onClick={() => window.location.href = data?.socials.facebook} className='cursor-pointer flex justify-center items-center size-[50px] rounded-full bg-white shadow-[0_0_6px_0_#0000001A]'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7581 17.938C13.7771 17.6836 15.6233 16.6694 16.9209 15.1017C18.2184 13.534 18.8698 11.5308 18.7424 9.49977C18.6149 7.46875 17.7183 5.56264 16.2349 4.16946C14.7516 2.77628 12.7931 2.00075 10.7581 2.00075C8.72305 2.00075 6.76453 2.77628 5.28119 4.16946C3.79785 5.56264 2.9012 7.46875 2.77376 9.49977C2.64632 11.5308 3.29768 13.534 4.59524 15.1017C5.8928 16.6694 7.73901 17.6836 9.75806 17.938V12H7.75806V10H9.75806V8.346C9.67724 7.55358 9.81561 6.75417 10.1581 6.035C10.4141 5.55115 10.81 5.15564 11.2941 4.9C11.8216 4.65063 12.3976 4.52055 12.9811 4.519C13.4084 4.50386 13.8361 4.53099 14.2581 4.6V6.5H13.7581C13.2448 6.44567 12.7259 6.50159 12.2361 6.664C12.1012 6.73342 11.9915 6.84319 11.9221 6.978C11.8021 7.2 11.7581 7.429 11.7581 8.346V10H14.2581L13.7581 12H11.7581V17.938ZM10.7581 20C8.78025 20 6.84685 19.4135 5.20236 18.3147C3.55787 17.2159 2.27614 15.6541 1.51927 13.8268C0.762389 11.9996 0.564356 9.98891 0.950208 8.0491C1.33606 6.10929 2.28847 4.32746 3.68699 2.92894C5.08552 1.53041 6.86735 0.578004 8.80716 0.192152C10.747 -0.193701 12.7576 0.00433284 14.5849 0.761209C16.4122 1.51809 17.9739 2.79981 19.0728 4.4443C20.1716 6.08879 20.7581 8.02219 20.7581 10C20.7581 12.6522 19.7045 15.1957 17.8291 17.0711C15.9538 18.9464 13.4102 20 10.7581 20Z" fill="#503225"/>
                    </svg>
                </div>}
                {data?.socials?.twitter && <div onClick={() => window.location.href = data?.socials.twitter} className='cursor-pointer flex justify-center items-center size-[50px] rounded-full bg-white shadow-[0_0_6px_0_#0000001A]'>
                    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.75903 0H0.759033L9.02003 11.014L1.20903 20H3.85903L10.247 12.651L15.759 20H22.759L14.151 8.522L21.559 0H18.909L12.923 6.886L7.75903 0ZM16.759 18L4.75903 2H6.75903L18.759 18H16.759Z" fill="#503225"/>
                    </svg>
                </div>}
            </div>
        </div>
    )
}
