import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdFactory, MdOutlineFilterTiltShift, MdOutlineGridView } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { IoMdMail } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { LuShoppingBag } from "react-icons/lu";

import Home from "../../assets/svg/home.svg"
import Document from "../../assets/svg/document.svg"

const Sidebar = () => {
    const [role, setRole] = useState('');
    const navigate = useNavigate()


    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        setRole(userRole);
      }, []);

    const logout = () => {
        localStorage.removeItem('userRole');
        localStorage.removeItem('adminName');
        localStorage.removeItem('isLoggedIn');
        navigate("/login")
    }

  return (
    <div className='w-full flex flex-col gap-[55px] pb-5 overflow-y-scroll h-full items-center px-[30px] pt-[37px] border border-y-0 border-l-[#E0E0E0]'>
        <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/")}>
            <img src={Home} alt='Home' className='w-[21px] h-[20px]' />
            <p className='text-[11px] font-hanken'>Home</p>
        </div>

        <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/add-manufacturer")}>
            <MdFactory  className='text-[21px] '/>
    
            <p className='text-[11px] font-hanken'>Add Manufacturers</p>
        </div>

        <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/view-manufacturer")}>
            <MdOutlineGridView className='text-[21px] ' />
            <p className='text-[11px] font-hanken'>View Manufacturers</p>
        </div>

        {role === 'superadmin' && (
            <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/admins")}>
            <RiAdminFill className='text-[21px] ' />
            <p className='text-[11px] font-hanken'>Admins</p>
            </div>
        )}

        <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/orders")}>
            <LuShoppingBag className='text-[21px] '/>
            <p className='text-[11px] font-hanken'>Orders</p>
        </div>
        
        <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/subscriptions")}>
            <IoMdMail className='text-[21px] '/>
            <p className='text-[11px] font-hanken'>Subscriptions</p>
        </div>

        <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/add-filters")}>
            <IoFilter  className='text-[21px] '/>
            <p className='text-[11px] font-hanken'>Add Filters</p>
        </div>

        <div className='flex flex-col cursor-pointer items-center gap-2' onClick={() => navigate("/view-filters")}>
            <MdOutlineFilterTiltShift className='text-[21px] ' />
            <p className='text-[11px] font-hanken'>View Filters</p>
        </div>

        <button className='bg-[#f00] w-24 rounded-lg p-2 mt-20 flex items-center justify-center' onClick={() => logout()}>
            <p className='font-hanken text-[#fff]'>Logout</p>
        </button>

    </div>
  )
}

export default Sidebar