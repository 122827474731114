import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../routes/Home';
import Cart from '../routes/Cart';
import Wishlist from '../routes/Wishlist';
import Coffee from '../routes/Coffee';
import Auth from '../routes/Auth';
import Gallery from '../routes/Gallery';
import Products from '../routes/Products';
import AllBrands from '../routes/AllBrands';
import CartBag from '../routes/CartBag';
import Review from '../routes/Review';
import CreateData from '../routes/CreateData';
import Admin from '../layout/admin';
import ViewData from '../routes/ViewData';
import AdminLogin from '../routes/AdminLogin';
import UpdateData from '../routes/UpdateData';
import ViewInfo from '../routes/ViewInfo';
import AddFilters from '../routes/AddFilters';
import NotFound from '../routes/NotFound';
import ViewFilters from '../routes/ViewFilters';
import EmailData from '../routes/EmailData';
import AdminRoute from '../routes/AdminRoute';
import Admins from '../routes/Admins';
import ForgotPassword from '../routes/ForgotPassword';
import Success from '../routes/Success';
import Cancel from '../routes/Cancel';
import Orders from '../routes/Orders';
import ManufacturerSettings from '../routes/ManufacturerSettings';


const Routers = () => {

    const { pathname } = useLocation();
  
    useEffect(() => {
      //scroll fix..
      window.scrollTo(0, 0);
    }, [pathname]);
    
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route path='/auth' element={<Auth />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/wishlist' element={<Wishlist />} />
      <Route path='/coffee' element={<Coffee />} />
      <Route path='/gallery' element={<Gallery />} />
      <Route path='/products' element={<Products />} />
      <Route path='/brands' element={<AllBrands />} />
      <Route path='/cartbag' element={<CartBag />} />
      <Route path='/review' element={<Review />} />
      <Route path='/login' element={<AdminLogin />} />
      <Route path='/*' element={<NotFound />} />
      <Route path='/success' element={<Success />} />
      <Route path='/cancel' element={<Cancel />} />
      <Route element={<Admin />}>
        <Route path='/add-manufacturer' element={<AdminRoute><CreateData /></AdminRoute>} />
        <Route path='/view-manufacturer' element={<AdminRoute><ViewData /></AdminRoute>} />
        <Route path='/view-settings' element={<AdminRoute><ManufacturerSettings /></AdminRoute>} />
        <Route path='/view-info' element={<AdminRoute><ViewInfo /></AdminRoute>} />
        <Route path='/edit-manufacturer' element={<AdminRoute><UpdateData /></AdminRoute>} />
        <Route path='/add-filters' element={<AdminRoute><AddFilters /></AdminRoute>} />
        <Route path='/view-filters' element={<AdminRoute><ViewFilters /></AdminRoute>} />
        <Route path='/admins' element={<AdminRoute><Admins /></AdminRoute>} />
        <Route path='/subscriptions' element={<AdminRoute><EmailData /></AdminRoute>} />
        <Route path='/orders' element={<AdminRoute><Orders /></AdminRoute>} />
      </Route>
    </Routes>
  )
}

export default Routers