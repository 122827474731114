import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export function capitalizeSentences(text) {
    // Split the text into sentences
    const sentences = text.split('. ');

    // Process each sentence
    const processedSentences = sentences.map((sentence) => {
        // Convert all letters to lowercase
        let processedSentence = sentence.toLowerCase();

        // Capitalize the first letter of the sentence
        processedSentence = processedSentence.charAt(0).toUpperCase() + processedSentence.slice(1);

        // Capitalize the first letter after each full stop
        processedSentence = processedSentence.replace(/(\. [a-z])/g, match => match.toUpperCase());

        return processedSentence;
    });

    // Join the processed sentences back into a single string
    return processedSentences.join('. ');
}

export function capitalizeString(inputString) {
    // Split the string into words
    const words = inputString.split(' ');

    // Process each word
    const processedWords = words.map((word) => {
        // Capitalize the first letter of the word and convert the rest to lowercase
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the processed words back into a single string
    return processedWords.join(' ');
}

export function getDateRelative(createdAt) {
    // Assuming createdAt is an object with seconds and nanoseconds properties
    const { seconds, nanoseconds } = createdAt;

    // Convert timestamp to milliseconds
    const timestampInMilliseconds = seconds * 1000 + nanoseconds / 1e6;

    // Convert timestamp to dayjs object
    const createdAtDate = dayjs(timestampInMilliseconds);

    // Calculate relative time
    const relativeTimeText = createdAtDate.fromNow();

    return relativeTimeText
}

export const handleReview = async (docId, uid, name, comment, rate) => {
    // Get the document reference
    const docRef = doc(db, "manufacturer", docId);

    try {
        // Get the current data of the document
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // Get the existing data
            const data = docSnap.data();
            
            // If the review property doesn't exist, initialize it as an array
            if (!data.reviews) {
                data.reviews = [];
            }
            
            // Add the new review to the reviews array
            data.reviews.push({
                uid,
                name,
                comment,
                rate,
                createdAt: new Date()
            });

            // Update the document with the new data
            await updateDoc(docRef, data);
        } else {
            console.log("Document does not exist!");
        }
    } catch (error) {
        console.error("Error adding review: ", error);
    }
}

export function bookmarkPage() {
    const pageUrl = window.location.href;
    const pageTitle = document.title;

    // Check if the browser supports automatic bookmarking
    if (window.sidebar && window.sidebar.addPanel) {
        // For Firefox <=22
        window.sidebar.addPanel(pageTitle, pageUrl, "");
    } else if (window.external && window.external.AddFavorite) {
        // For IE
        window.external.AddFavorite(pageUrl, pageTitle);
    } else {
        // For modern browsers, show instructions to manually bookmark
        alert('Press Ctrl+D (Windows) or Command+D (Mac) to bookmark this page.');
    }
}


export function savePage() {
    const pageContent = document.documentElement.outerHTML;
    const blob = new Blob([pageContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = 'saved_page.html';
    a.click();
    
    URL.revokeObjectURL(url);
}

export function printPage() {
    window.print();
}

export function convertEUR(priceEUR, targetCurrency) {
    switch (targetCurrency) {
      case "$":
        return priceEUR * 1.08;
      case "£":
        return priceEUR / 1.17;
      default:
        return priceEUR;
    }
}
  
export function convertUSD(priceUSD, targetCurrency) {
    switch (targetCurrency) {
      case "€":
        return priceUSD / 1.08;
      case "£":
        return priceUSD / 1.26;
      default:
        return priceUSD;
    }
}
  
export function convertGBP(priceGBP, targetCurrency) {
    switch (targetCurrency) {
      case "$":
        return priceGBP * 1.26;
      case "€":
        return priceGBP * 1.17;
      default:
        return priceGBP;
    }
}
  