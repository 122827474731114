import React, { useState, useEffect } from 'react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import UploadIcon from "../assets/img/upload_icon.png"
import CloseIcon from "../assets/svg/closeIcon.svg"
import { toast } from 'react-toastify'
import { db } from '../firebase-config'
import { CgSpinner } from 'react-icons/cg'
import axios from 'axios'

const EditManufacturerType = ({ 
    handleClose, 
    singleManufacturerTypeData, 
    updateEditManufacturerTypeLoading, 
    setUpdateEditManufacturerTypeLoading 
}) => {
    const [typeName, setTypeName] = useState(singleManufacturerTypeData?.manufacturerType[0]?.typeName || "");
    const [typeImage, setTypeImage] = useState(null);

    console.log(singleManufacturerTypeData, "singleManufacturerTypeData")

    const handleFileUpload = async (e) => {
        const files = e.target.files;
        if (files) {
            const file = files[0]; 
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'rztljgso');

            try {
                const uploadResponse = await axios.post("https://api.cloudinary.com/v1_1/dizlp3hvp/upload", formData);
                const data = uploadResponse.data;
                console.log(data, "data");
                setTypeImage(data.secure_url); // Store secure URL for image
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    // Update function
    const updateManufacturerType = async () => {
        setUpdateEditManufacturerTypeLoading(true);

         const updatedData = {
            "manufacturerType": [
                {
                    typeName: typeName || singleManufacturerTypeData?.manufacturerType[0]?.typeName,  // Use updated or fallback value
                    typeImage: typeImage || singleManufacturerTypeData?.manufacturerType[0]?.typeImage  // Use updated or fallback value
                }
            ]
        };

        try {
            const docRef = doc(db, 'manufacturerType', singleManufacturerTypeData?.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                await updateDoc(docRef, updatedData);
                toast(`Manufacturer Type Updated Successfully`, { 
                    position: "top-right",
                    autoClose: 3500, 
                    closeOnClick: true,
                });
                setTypeName("");
                setTypeImage("");
                handleClose();
            } else {
                toast(`No document found to update`, { 
                    position: "top-right",
                    autoClose: 3500, 
                    closeOnClick: true,
                });
            }
        } catch (error) {
            console.error('Error updating document: ', error);
        } finally {
            setUpdateEditManufacturerTypeLoading(false);
        }
    };

    return (
        <div className='bg-[#fff] w-[600px] h-[300px] flex flex-col gap-4 overflow-y-scroll rounded-lg p-4'>
            <div className='flex items-center justify-between'>
                <p className='font-medium font-Hat text-[#3F434A]  text-[24px] text-[#000]'>Edit Manufacturer Type</p>
                <button className="flex justify-center items-center" onClick={handleClose}> 
                    <img src={CloseIcon} alt='close' />
                </button>
            </div>

            <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-3'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Name</label>
                        <input 
                            type="text" 
                            value={typeName} 
                            onChange={(e) => setTypeName(e.target.value)} 
                            className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                        />
                    </div>
                    <div className='flex flex-col w-full bg-transparent rounded-xl items-center border-dashed border-[#D0D5DD] border px-6 py-[8px] gap-[16px]'>
                        <div className='p-[9px] w-full cursor-pointer flex justify-center gap-[16px]'>
                            {  
                                typeImage ? 
                                    <div className='flex flex-col gap-1'>
                                        <div className='flex items-center justify-between'>
                                            <img src={typeImage} alt="Roasting Image" className='w-6 h-6' />
                                            <p className='text-[#000] text-[11px]'>Completed</p>
                                        </div>
                                        <div className='w-[266px] h-[5px] bg-[#51E38B] rounded-lg'></div>
                                    </div> 
                                    :
                                    <div className='flex items-center gap-[16px]'>
                                        <img src={UploadIcon} alt='upload' className='w-6 h-6' />
                                        <label htmlFor="fileInput" className='cursor-pointer flex items-center text-[#000] text-sm'>
                                            Click to upload <span className='text-[#475367] ml-1'>image file</span>
                                            <input
                                                name='logo'
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }} 
                                                onChange={(e) => handleFileUpload(e)}
                                            />
                                        </label>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <button 
                    type='button' 
                    className='bg-secondary rounded-lg p-2 w-full flex justify-center text-white items-center' 
                    onClick={updateManufacturerType}
                >
                    {updateEditManufacturerTypeLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Update" }
                </button>
            </div>
        </div>
    )
}

export default EditManufacturerType;
