import React from 'react'

const Success = () => {
  return (
    <div className='w-full'>
        <div className=' lg:w-[500px] lg:mx-auto'>
            <div className='flex flex-col items-center mt-[100px] gap-10'>
                <img src="https://funtura.in/tvm/wp-content/themes/funtura/assets/images/success.svg" alt='success' className='xs:w-[200px] lg:w-[300px] ' />
                <p className='font-inter text-2xl font-semibold text-[#000]'>Payment Success</p>
            </div>
        </div>

    </div>
  )
}

export default Success