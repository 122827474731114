import React, { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import BasicButtons from './Button'
import { useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth';

export default function ProfileBar() {
    const navigate = useNavigate();
    const auth = getAuth();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };


    return (
        // <Menu as="div" className="relative">
        //     <Menu.Button className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
        //         <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        //             <path d="M16 21H14V19C14 18.2044 13.6839 17.4413 13.1213 16.8787C12.5587 16.3161 11.7956 16 11 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21H0V19C0 17.6739 0.526784 16.4022 1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H11C12.3261 14 13.5979 14.5268 14.5355 15.4645C15.4732 16.4022 16 17.6739 16 19V21ZM8 12C6.81331 12 5.65327 11.6481 4.66658 10.9888C3.67988 10.3295 2.91085 9.39246 2.45672 8.2961C2.0026 7.19975 1.88378 5.99335 2.11529 4.82946C2.3468 3.66558 2.91824 2.59648 3.75736 1.75736C4.59647 0.918247 5.66557 0.346802 6.82946 0.115291C7.99334 -0.11622 9.19974 0.00259972 10.2961 0.456726C11.3925 0.910851 12.3295 1.67989 12.9888 2.66658C13.6481 3.65328 14 4.81331 14 6C14 7.5913 13.3679 9.11742 12.2426 10.2426C11.1174 11.3679 9.5913 12 8 12ZM8 10C8.79113 10 9.56448 9.76541 10.2223 9.32588C10.8801 8.88636 11.3928 8.26164 11.6955 7.53074C11.9983 6.79983 12.0775 5.99557 11.9231 5.21964C11.7688 4.44372 11.3878 3.73099 10.8284 3.17158C10.269 2.61217 9.55628 2.2312 8.78036 2.07686C8.00444 1.92252 7.20017 2.00173 6.46927 2.30448C5.73836 2.60724 5.11365 3.11993 4.67412 3.77772C4.2346 4.43552 4 5.20888 4 6C4 7.06087 4.42143 8.07828 5.17157 8.82843C5.92172 9.57858 6.93913 10 8 10Z" fill="#503225"/>
        //         </svg>
        //     </Menu.Button>
        //     <Transition
        //         as={Fragment}
        //         enter="transition ease-out duration-100"
        //         enterFrom="transform opacity-0 scale-95"
        //         enterTo="transform opacity-100 scale-100"
        //         leave="transition ease-in duration-75"
        //         leaveFrom="transform opacity-100 scale-100"
        //         leaveTo="transform opacity-0 scale-95"
        //     >
        //         <Menu.Items className='cursor-pointer absolute z-10 border right-1 mt-1 w-[270px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none flex flex-col items-center'>
        //             <Menu.Item>
        //                 <div className="py-[15px]">
        //                     <p className='nunito-bold'>Customer Account</p>
        //                 </div>
        //             </Menu.Item>
        //             <hr className='w-full'/>
        //             <Menu.Item>
        //                 <div className="py-[15px] flex flex-col gap-4 w-[180px]">
        //                     <BasicButtons text={'Login'} functions={() => navigate('/auth')}/>
        //                     <p className='nunito-bold text-center' onClick={() => navigate('/auth')}>Register</p>
        //                 </div>
        //             </Menu.Item>
        //             <hr className='w-full'/>
        //             <Menu.Item>
        //                 <div className="py-[15px] w-[240px]">
        //                     <p className='nunito-normal text-center'>After logging in, you can access your customer area here.</p>
        //                 </div>
        //             </Menu.Item>
        //         </Menu.Items>
        //     </Transition>
        // </Menu>
        <Menu as="div" className="relative">
            <Menu.Button className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                {user ? (
                    <span className='text-xl font-bold'>{user.displayName ? user.displayName.charAt(0) : 'U'}</span>
                ) : (
                    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 21H14V19C14 18.2044 13.6839 17.4413 13.1213 16.8787C12.5587 16.3161 11.7956 16 11 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21H0V19C0 17.6739 0.526784 16.4022 1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H11C12.3261 14 13.5979 14.5268 14.5355 15.4645C15.4732 16.4022 16 17.6739 16 19V21ZM8 12C6.81331 12 5.65327 11.6481 4.66658 10.9888C3.67988 10.3295 2.91085 9.39246 2.45672 8.2961C2.0026 7.19975 1.88378 5.99335 2.11529 4.82946C2.3468 3.66558 2.91824 2.59648 3.75736 1.75736C4.59647 0.918247 5.66557 0.346802 6.82946 0.115291C7.99334 -0.11622 9.19974 0.00259972 10.2961 0.456726C11.3925 0.910851 12.3295 1.67989 12.9888 2.66658C13.6481 3.65328 14 4.81331 14 6C14 7.5913 13.3679 9.11742 12.2426 10.2426C11.1174 11.3679 9.5913 12 8 12ZM8 10C8.79113 10 9.56448 9.76541 10.2223 9.32588C10.8801 8.88636 11.3928 8.26164 11.6955 7.53074C11.9983 6.79983 12.0775 5.99557 11.9231 5.21964C11.7688 4.44372 11.3878 3.73099 10.8284 3.17158C10.269 2.61217 9.55628 2.2312 8.78036 2.07686C8.00444 1.92252 7.20017 2.00173 6.46927 2.30448C5.73836 2.60724 5.11365 3.11993 4.67412 3.77772C4.2346 4.43552 4 5.20888 4 6C4 7.06087 4.42143 8.07828 5.17157 8.82843C5.92172 9.57858 6.93913 10 8 10Z" fill="#503225"/>
                    </svg>
                )}
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className='cursor-pointer absolute z-10 border right-1 mt-1 w-[270px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none flex flex-col items-center'>
                    <Menu.Item>
                        <div className="py-[15px]">
                            <p className='nunito-bold'>Customer Account</p>
                        </div>
                    </Menu.Item>
                    <hr className='w-full'/>
                    {user ? (
                        <Menu.Item>
                            <div className="py-[15px] flex flex-col gap-4 w-[180px]">
                                <BasicButtons text={'Logout'} functions={handleLogout}/>
                                {/* <p className='nunito-bold text-center cursor-pointer' onClick={handleLogout}>Logout</p> */}
                            </div>
                        </Menu.Item>
                    ) : (
                        <>
                            <Menu.Item>
                                <div className="py-[15px] flex flex-col gap-4 w-[180px]">
                                    <BasicButtons text={'Login'} functions={() => navigate('/auth')}/>
                                    <p className='nunito-bold text-center cursor-pointer' onClick={() => navigate('/auth')}>Register</p>
                                </div>
                            </Menu.Item>
                            <hr className='w-full'/>
                            <Menu.Item>
                                <div className="py-[15px] w-[240px]">
                                    <p className='nunito-normal text-center'>After logging in, you can access your customer area here.</p>
                                </div>
                            </Menu.Item>
                        </>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}