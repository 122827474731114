import React, { useState, useEffect } from 'react'
import { Skeleton } from '@mui/material';
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteForever, MdKeyboardBackspace } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import ModalPop from '../components/modal/modalPop';
import AddCountries from './AddCountries';
import AddRates from './AddRates';
import EditCountry from './EditCountry';
import DeleteCountry from './DeleteCountry';
import DeleteRate from './DeleteRate';
import EditRate from './EditRate';
import AddPayment from './AddPayment';
import DeletePayment from './DeletePayment';
import EditPayment from './EditPayment';
import AddDelivery from './AddDelivery';
import EditDelivery from './EditDelivery';
import DeleteDelivery from './DeleteDelivery';

const ManufacturerSettings = () => {
    //Countries
    const [loading, setLoading] = useState(false)
    const [countriesData, setCountriesData] = useState([])
    const [companyCountriesData, setCompanyCountriesData] = useState([]) // Updated state for matched data
    const [deleteCountryLoading, setDeleteCountryLoading] = useState(false)
    const [openDeleteCountry, setOpenDeleteCountry] = useState(false)
    const [deleteCountryId, setDeleteCountryId] = useState()
    const [currentPage, setCurrentPage] = useState(1) // Initialize current page to 1
    const [editCountryLoading, setEditCountryLoading] = useState(false)
    const [openEditCountry, setOpenEditCountry] = useState(false)
    const [editCountryData, setEditCountryData] = useState("")
    const [openAddCountry, setOpenAddCountry] = useState(false)
    const [addCountryLoading, setAddCountryLoading] = useState(false)
    const [countryToDelete, setCountryToDelete] = useState()
    const [editCountryId, setEditCountryId] = useState()


    //Currencies
    const [currencyLoading, setCurrencyLoading] = useState(false)
    const [currenciesData, setCurrenciesData] = useState([])
    const [companyCurrenciesData, setCompanyCurrenciesData] = useState([]) // Updated state for matched data
    const [deleteCurrencyLoading, setDeleteCurrencyLoading] = useState(false)
    const [openDeleteCurrency, setOpenDeleteCurrency] = useState(false)
    const [deleteCurrencyId, setDeleteCurrencyId] = useState()
    const [currenciesCurrentPage, setCurrenciesCurrentPage] = useState(1) // Initialize current page to 1
    const [editCurrencyLoading, setEditCurrencyLoading] = useState(false)
    const [openEditCurrency, setOpenEditCurrency] = useState(false)
    const [currencyData, setCurrencyData] = useState([])
    const [openAddCurrency, setOpenAddCurrency] = useState(false)
    const [addCurrencyLoading, setAddCurrencyLoading] = useState(false)
    const [currencyToDelete, setCurrencyToDelete] = useState()
    const [editCurrencyId, setEditCurrencyId] = useState()


     //Countries
     const [paymentLoading, setPaymentLoading] = useState(false)
     const [paymentsData, setPaymentsData] = useState([])
     const [companyPaymentsData, setCompanyPaymentsData] = useState([]) // Updated state for matched data
     const [deletePaymentLoading, setDeletePaymentLoading] = useState(false)
     const [openDeletePayment, setOpenDeletePayment] = useState(false)
     const [deletePaymentId, setDeletePaymentId] = useState()
     const [currentPaymentPage, setCurrentPaymentPage] = useState(1) // Initialize current page to 1
     const [editPaymentLoading, setEditPaymentLoading] = useState(false)
     const [openEditPayment, setOpenEditPayment] = useState(false)
     const [editPaymentData, setEditPaymentData] = useState("")
     const [openAddPayment, setOpenAddPayment] = useState(false)
     const [addPaymentLoading, setAddPaymentLoading] = useState(false)
     const [paymentToDelete, setPaymentToDelete] = useState()
     const [editPaymentId, setEditPaymentId] = useState()


    //Deliveries
    const [deliveryLoading, setDeliveryLoading] = useState(false)
    const [deliveriesData, setDeliveriesData] = useState([])
    const [companyDeliveriesData, setCompanyDeliveriesData] = useState([]) // Updated state for matched data
    const [deleteDeliveryLoading, setDeleteDeliveryLoading] = useState(false)
    const [openDeleteDelivery, setOpenDeleteDelivery] = useState(false)
    const [deleteDeliveryId, setDeleteDeliveryId] = useState()
    const [deliveriesCurrentPage, setDeliveriesCurrentPage] = useState(1) // Initialize current page to 1
    const [editDeliveryLoading, setEditDeliveryLoading] = useState(false)
    const [openEditDelivery, setOpenEditDelivery] = useState(false)
    const [deliveryData, setDeliveryData] = useState([])
    const [openAddDelivery, setOpenAddDelivery] = useState(false)
    const [addDeliveryLoading, setAddDeliveryLoading] = useState(false)
    const [deliveryToDelete, setDeliveryToDelete] = useState()
    const [editDeliveryId, setEditDeliveryId] = useState()

    const { state } = useLocation()
    const navigate = useNavigate()

    const itemsPerPage = 5; 

    const colRef = collection(db, "countries") 
    const currencyColRef = collection(db, "currency") 
    const deliveryColRef = collection(db, "delivery") 
    const paymentColRef = collection(db, "payment")

    // Fetch Countries Data from Firestore
    const fetchCountriesData = () => {
        setLoading(true)
        getDocs(colRef)
        .then((snapshot) => {
            setLoading(false)
            const countries = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setCountriesData(countries)

            // Match company name in Firestore data with the state
            const matchedData = countries.find(item => item.company?.toLowerCase() === state?.company?.toLowerCase());
            setCompanyCountriesData(matchedData || { country: [] }); // Set matched data or empty
        })
        .catch((err) => {
            setLoading(false)
            console.error(err.message)
        })
    }

    useEffect(() => {
        fetchCountriesData()
    }, [deleteCountryLoading, editCountryLoading, addCountryLoading])

    // Calculate total pages
    const totalPages = Math.ceil((companyCountriesData?.country?.length || 0) / itemsPerPage);

    // Get current items for the page
    const currentData = companyCountriesData?.country?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    console.log(companyCountriesData, "companyCountriesData")

     // Fetch Currencies Data from Firestore
     const fetchCurrenciesData = () => {
        setCurrencyLoading(true)
        getDocs(currencyColRef)
        .then((snapshot) => {
            setCurrencyLoading(false)
            const currencies = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setCurrenciesData(currencies)

            // Match company name in Firestore data with the state
            const matchedData = currencies.find(item => item.company?.toLowerCase() === state?.company?.toLowerCase());
            setCompanyCurrenciesData(matchedData || { currency: [] }); // Set matched data or empty
        })
        .catch((err) => {
            setCurrencyLoading(false)
            console.error(err.message)
        })
    }

    console.log(companyCurrenciesData, "companyCurrenciesData")

    useEffect(() => {
        fetchCurrenciesData()
    }, [deleteCurrencyLoading, editCurrencyLoading, addCurrencyLoading])

    // Calculate total pages
    const currenciesTotalPages = Math.ceil((companyCurrenciesData?.currency?.length || 0) / itemsPerPage);

    // Get current items for the page
    const currenciesCurrentData = companyCurrenciesData?.currency?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleCurrencyPageChange = (pageNumber) => {
        setCurrenciesCurrentPage(pageNumber);
    };



    
    // Fetch Countries Data from Firestore
    const fetchPaymentsData = () => {
        setPaymentLoading(true)
        getDocs(paymentColRef)
        .then((snapshot) => {
            setPaymentLoading(false)
            const payments = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setPaymentsData(payments)

            // Match company name in Firestore data with the state
            const matchedData = payments.find(item => item.company?.toLowerCase() === state?.company?.toLowerCase());
            setCompanyPaymentsData(matchedData || { payment: [] }); // Set matched data or empty
        })
        .catch((err) => {
            setPaymentLoading(false)
            console.error(err.message)
        })
    }

    useEffect(() => {
        fetchPaymentsData()
    }, [deletePaymentLoading, editPaymentLoading, addPaymentLoading])

    // Calculate total pages
    const paymentsTotalPages = Math.ceil((companyPaymentsData?.payment?.length || 0) / itemsPerPage);

    // Get current items for the page
    const paymentsCurrentData = companyPaymentsData?.payment?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePaymentPageChange = (pageNumber) => {
        setCurrentPaymentPage(pageNumber);
    };

    console.log(companyPaymentsData, "companyPaymentsData")


         // Fetch Deliveries Data from Firestore
         const fetchDeliveriesData = () => {
            setDeliveryLoading(true)
            getDocs(deliveryColRef)
            .then((snapshot) => {
                setDeliveryLoading(false)
                const deliveries = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setDeliveriesData(deliveries)
    
                // Match company name in Firestore data with the state
                const matchedData = deliveries.find(item => item.company?.toLowerCase() === state?.company?.toLowerCase());
                setCompanyDeliveriesData(matchedData || { delivery: [] }); // Set matched data or empty
            })
            .catch((err) => {
                setDeliveryLoading(false)
                console.error(err.message)
            })
        }
    
        console.log(companyCurrenciesData, "companyCurrenciesData")
    
        useEffect(() => {
            fetchDeliveriesData()
        }, [deleteDeliveryLoading, editDeliveryLoading, addDeliveryLoading])
    
        // Calculate total pages
        const deliveriesTotalPages = Math.ceil((companyDeliveriesData?.delivery?.length || 0) / itemsPerPage);
    
        // Get current items for the page
        const deliveriesCurrentData = companyDeliveriesData?.delivery?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    
        const handleDeliveryPageChange = (pageNumber) => {
            setDeliveriesCurrentPage(pageNumber);
        };
    

    return (
        <div className='w-full lg:overflow-x-hidden mt-[100px] flex flex-col gap-10 lg:px-5'>
            <div className='flex items-center gap-2' onClick={() => navigate(-1)}>
                <MdKeyboardBackspace className="text-secondary"/>
                <p className='text-sm text-secondary'>Back</p>
            </div>
            <div className='flex justify-between flex-col gap-4 fixed lg:relative lg:gap-0 lg:flex-row lg:items-center '>
                <h1 className='text-3xl font-bold'>Manufacturer Settings</h1>
                <div className='flex items-center gap-5'>
                    <button
                        className='w-32 rounded-lg p-2 flex items-center justify-center bg-secondary text-white'
                        onClick={() => setOpenAddCountry(true)}
                        type='button'
                    >
                        Add Country
                    </button>
                    <button
                        className='w-32 rounded-lg p-2 flex items-center justify-center bg-secondary text-white'
                        onClick={() => setOpenAddCurrency(true)}
                        type='button'
                    >
                        Add Currency
                    </button>
                    <button
                        className='w-32 rounded-lg p-2 flex items-center justify-center bg-secondary text-white'
                        onClick={() => setOpenAddPayment(true)}
                        type='button'
                    >
                        Add Payment
                    </button>
                    <button
                        className='w-32 rounded-lg p-2 flex items-center justify-center bg-secondary text-white'
                        onClick={() => setOpenAddDelivery(true)}
                        type='button'
                    >
                        Add Delivery
                    </button>
                </div>
            </div>
            <div className='flex w-full justify-between'>
                <div className='flex flex-col w-5/12'>
                    {
                        loading || deleteCountryLoading || editCountryLoading || addCountryLoading ?
                        <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                        :
                        <>
                            <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                                <thead>
                                    <tr className='h-[32px] whitespace-nowrap  border-0'>
                                        <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">Country Name</th>
                                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.length > 0 ? currentData?.map((item, index) => (
                                        <tr key={index} className={`bg-transparent h-[55px] border-b border-grey-100`}>
                                            <td className='h-[55px] flex items-center cursor-pointer'>
                                                <p className='text-sm font-kumbh'>{item}</p> 
                                            </td>
                                            <td className='h-[55px] px-4 '>
                                                <div className='flex items-center gap-2'>
                                                    <FaRegEdit 
                                                        className='text-[#818181] w-5 h-5 cursor-pointer' 
                                                        onClick={() => { setOpenEditCountry(true); setEditCountryData(item); setEditCountryId(companyCountriesData?.id)}} 
                                                    />
                                                    <MdDeleteForever 
                                                        className='text-[#f00] w-5 h-5 cursor-pointer' 
                                                        onClick={() => {setOpenDeleteCountry(true); setDeleteCountryId(companyCountriesData?.id); setCountryToDelete(item)}} 
                                                    />
                                                </div>
                                            </td> 
                                        </tr>
                                    )) : (
                                        <tr className='h-[654px] bg-white border-t border-grey-100'>
                                            <td colSpan="8" className="relative">
                                                <div className='absolute inset-0 flex items-center justify-center'>
                                                    <div className='flex flex-col gap-2 items-center'>
                                                        <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No countries Available</p>
                                                        <p>Oops! Nothing to see here.</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="flex justify-center mt-1 mb-10">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={`px-3 py-1 mx-1 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>
                        </>
                    }
                </div>
                <div className='flex flex-col w-5/12'>
                    {
                        currencyLoading || deleteCurrencyLoading || editCurrencyLoading || addCurrencyLoading ?
                        <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                        :
                        <>
                        <table className='w-full mt-[150px] lg:mt-0 table-fixed border-collapse'>
                            <thead>
                            <tr className='h-[32px] whitespace-nowrap'>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    Name
                                </th>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    Symbol
                                </th>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    Rate
                                </th>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {currenciesCurrentData?.length > 0 ? currenciesCurrentData?.map((item, index) => (
                                <tr key={index} className="h-[55px] border-b border-grey-100">
                                    <td className="py-2">
                                        <p className='text-sm font-kumbh'>{item?.name}</p>
                                    </td>
                                    <td className="py-2">
                                        <p className='text-sm font-kumbh'>{item?.symbol}</p>
                                    </td>
                                    <td className="py-2">
                                        <p className='text-sm font-kumbh'>{item?.rate}</p>
                                    </td>
                                    <td className="py-2">
                                        <div className='flex items-center gap-2'>
                                        <FaRegEdit 
                                            className='text-[#818181] w-5 h-5 cursor-pointer' 
                                            onClick={() => { setOpenEditCurrency(true); setCurrencyData(item); setEditCurrencyId(companyCurrenciesData?.id);}} 
                                        />
                                        <MdDeleteForever 
                                            className='text-[#f00] w-5 h-5 cursor-pointer' 
                                            onClick={() => { setOpenDeleteCurrency(true); setDeleteCurrencyId(companyCurrenciesData?.id); setCurrencyToDelete(item) }} 
                                        />
                                        </div>
                                    </td>
                                </tr>
                            )) : (
                                <tr className='h-[654px] bg-white border-t border-grey-100'>
                                <td colSpan="3" className="relative">
                                    <div className='absolute inset-0 flex items-center justify-center'>
                                    <div className='flex flex-col gap-2 items-center'>
                                        {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                        <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Currency Available</p>
                                        <p>Oops! Nothing to see here.</p>
                                    </div>
                                    </div>
                                </td>
                                </tr>
                            )}
                            </tbody>
                        </table>

                        <div className="flex justify-center mt-4 mb-10">
                            {Array.from({ length: currenciesTotalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handleCurrencyPageChange(index + 1)}
                                className={`px-3 py-1 mx-1 ${currenciesCurrentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                            >
                                {index + 1}
                            </button>
                            ))}
                        </div>
                        </>
                    }
                </div>
            </div>

            <div className='w-full flex justify-between mt-5'>
                <div className='flex flex-col w-5/12'>
                    {
                        paymentLoading || deletePaymentLoading || editPaymentLoading || addPaymentLoading ?
                        <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                        :
                        <>
                            <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                                <thead>
                                    <tr className='h-[32px] whitespace-nowrap  border-0'>
                                        <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">Payment Name</th>
                                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentsCurrentData?.length > 0 ? paymentsCurrentData?.map((item, index) => (
                                        <tr key={index} className={`bg-transparent h-[55px] border-b border-grey-100`}>
                                            <td className='h-[55px] flex items-center cursor-pointer'>
                                                <p className='text-sm font-kumbh'>{item}</p> 
                                            </td>
                                            <td className='h-[55px] px-4 '>
                                                <div className='flex items-center gap-2'>
                                                    <FaRegEdit 
                                                        className='text-[#818181] w-5 h-5 hidden cursor-pointer' 
                                                        onClick={() => { setOpenEditPayment(true); setEditPaymentData(item); setEditPaymentId(companyPaymentsData?.id)}} 
                                                    />
                                                    <MdDeleteForever 
                                                        className='text-[#f00] w-5 h-5 cursor-pointer' 
                                                        onClick={() => {setOpenDeletePayment(true); setDeletePaymentId(companyPaymentsData?.id); setPaymentToDelete(item)}} 
                                                    />
                                                </div>
                                            </td> 
                                        </tr>
                                    )) : (
                                        <tr className='h-[654px] bg-white border-t border-grey-100'>
                                            <td colSpan="8" className="relative">
                                                <div className='absolute inset-0 flex items-center justify-center'>
                                                    <div className='flex flex-col gap-2 items-center'>
                                                        <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Provider Available</p>
                                                        <p>Oops! Nothing to see here.</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="flex justify-center mt-1 mb-10">
                                {Array.from({ length: paymentsTotalPages }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={`px-3 py-1 mx-1 ${currentPaymentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>
                        </>
                    }
                </div>
                <div className='flex flex-col w-5/12'>
                    {
                        deliveryLoading || deleteDeliveryLoading || editDeliveryLoading || addDeliveryLoading ?
                        <Skeleton variant="rectangular" width={500} height={500} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
                        :
                        <>
                        <table className='w-full mt-[150px] lg:mt-0 table-fixed border-collapse'>
                            <thead>
                            <tr className='h-[32px] whitespace-nowrap'>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    Name
                                </th>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    TimeFrame
                                </th>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    Price
                                </th>
                                <th className="w-1/3 font-medium text-[18px] text-[#0D0D0D] font-Kumbh text-left pb-2">
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {deliveriesCurrentData?.length > 0 ? deliveriesCurrentData?.map((item, index) => (
                                <tr key={index} className="h-[55px] border-b border-grey-100">
                                    <td className="py-2">
                                        <p className='text-sm font-kumbh'>{item?.name}</p>
                                    </td>
                                    <td className="py-2">
                                        <p className='text-sm font-kumbh'>{item?.timeFrame}</p>
                                    </td>
                                    <td className="py-2">
                                        <p className='text-sm font-kumbh'>{item?.price}</p>
                                    </td>
                                    <td className="py-2">
                                        <div className='flex items-center gap-2'>
                                        <FaRegEdit 
                                            className='text-[#818181] w-5 h-5 cursor-pointer' 
                                            onClick={() => { setOpenEditDelivery(true); setDeliveryData(item); setEditDeliveryId(companyDeliveriesData?.id);}} 
                                        />
                                        <MdDeleteForever 
                                            className='text-[#f00] w-5 h-5 cursor-pointer' 
                                            onClick={() => { setOpenDeleteDelivery(true); setDeleteDeliveryId(companyDeliveriesData?.id); setDeliveryToDelete(item) }} 
                                        />
                                        </div>
                                    </td>
                                </tr>
                            )) : (
                                <tr className='h-[654px] bg-white border-t border-grey-100'>
                                <td colSpan="3" className="relative">
                                    <div className='absolute inset-0 flex items-center justify-center'>
                                    <div className='flex flex-col gap-2 items-center'>
                                        {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                        <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Delivery Available</p>
                                        <p>Oops! Nothing to see here.</p>
                                    </div>
                                    </div>
                                </td>
                                </tr>
                            )}
                            </tbody>
                        </table>

                        <div className="flex justify-center mt-4 mb-10">
                            {Array.from({ length: deliveriesTotalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handleDeliveryPageChange(index + 1)}
                                className={`px-3 py-1 mx-1 ${deliveriesCurrentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                            >
                                {index + 1}
                            </button>
                            ))}
                        </div>
                        </>
                    }
                </div>
            </div>

            <ModalPop isOpen={openAddCountry}>
                <AddCountries 
                    manufacturerData={state}
                    handleClose= {() => setOpenAddCountry(false)}
                    setAddCountryLoading={setAddCountryLoading}
                />
            </ModalPop>

            <ModalPop isOpen={openDeleteCountry}>
                <DeleteCountry 
                    handleClose= {() => setOpenDeleteCountry(false)}
                    setDeleteCountryLoading={setDeleteCountryLoading}
                    deleteCountryLoading={deleteCountryLoading}
                    deleteCountryId={deleteCountryId}
                    countryToDelete={countryToDelete}
                />
            </ModalPop>

            <ModalPop isOpen={openEditCountry}>
                <EditCountry
                    handleClose= {() => setOpenEditCountry(false)}
                    setEditCountryLoading={setEditCountryLoading}
                    editCountryLoading={editCountryLoading}
                    editCountryData={editCountryData}
                    editCountryId={editCountryId}
                />
            </ModalPop>

            <ModalPop isOpen={openAddCurrency}>
                <AddRates 
                    manufacturerData={state}
                    handleClose={() => setOpenAddCurrency(false)}
                    setAddCurrencyLoading={setAddCurrencyLoading}
                />
            </ModalPop>

            <ModalPop isOpen={openDeleteCurrency}>
                <DeleteRate 
                    handleClose={() => setOpenDeleteCurrency(false)}
                    setDeleteCurrencyLoading={setDeleteCurrencyLoading}
                    deleteCurrencyLoading={deleteCurrencyLoading}
                    deleteCurrencyId={deleteCurrencyId}
                    currencyToDelete={currencyToDelete}
                />
            </ModalPop>

            <ModalPop isOpen={openEditCurrency}>
                <EditRate
                    handleClose= {() => setOpenEditCurrency(false)}
                    setEditCurrencyLoading={setEditCurrencyLoading}
                    editCurrencyLoading={editCurrencyLoading}
                    currencyData={currencyData}
                    editCurrencyId={editCurrencyId}
                />
            </ModalPop>

            <ModalPop isOpen={openAddPayment}>
                <AddPayment 
                    manufacturerData={state}
                    handleClose= {() => setOpenAddPayment(false)}
                    setAddPaymentLoading={setAddPaymentLoading}
                />
            </ModalPop>

            <ModalPop isOpen={openDeletePayment}>
                <DeletePayment 
                    handleClose={() => setOpenDeletePayment(false)}
                    setDeletePaymentLoading={setDeletePaymentLoading}
                    deletePaymentLoading={deletePaymentLoading}
                    deletePaymentId={deletePaymentId}
                    paymentToDelete={paymentToDelete}
                />
            </ModalPop>

            <ModalPop isOpen={openEditPayment}>
                <EditPayment
                    handleClose= {() => setOpenEditPayment(false)}
                    setEditPaymentLoading={setEditPaymentLoading}
                    editPaymentLoading={editPaymentLoading}
                    editPaymentData={editPaymentData}
                    editPaymentId={editPaymentId}
                />
            </ModalPop>

            <ModalPop isOpen={openAddDelivery}>
                <AddDelivery 
                    manufacturerData={state}
                    handleClose={() => setOpenAddDelivery(false)}
                    setAddDeliveryLoading={setAddDeliveryLoading}
                />
            </ModalPop>

            <ModalPop isOpen={openEditDelivery}>
                <EditDelivery
                    handleClose= {() => setOpenEditDelivery(false)}
                    setEditDeliveryLoading={setEditDeliveryLoading}
                    editDeliveryLoading={editDeliveryLoading}
                    deliveryData={deliveryData}
                    editDeliveryId={editDeliveryId}
                />
            </ModalPop>

            <ModalPop isOpen={openDeleteDelivery}>
                <DeleteDelivery 
                    handleClose={() => setOpenDeleteDelivery(false)}
                    setDeleteDeliveryLoading={setDeleteDeliveryLoading}
                    deleteDeliveryLoading={deleteDeliveryLoading}
                    deleteDeliveryId={deleteDeliveryId}
                    deliveryToDelete={deliveryToDelete}
                />
            </ModalPop>

        </div>
    )
}

export default ManufacturerSettings;
