import React, { useState } from 'react'

export default function ManufacturingProcess({ data }) {
    const [active, setActive] = useState("Cultivation")

    const handleChange = (value) => {
        setActive(value)
    }

    return (
        <div className='flex flex-col'>
            <h1 className='nunito-bold text-primary md:text-[40px] text-[28px] xl:px-0 px-5'>The Manufacturing Process</h1>
            <p className='mt-2 text-[18px] text-grey-100 opacity-50 xl:px-0 px-5'>Contrary to popular belief and going through the cites of the word source.</p>
            
            {
                data?.processing === "NO INFORMATION AVAILABLE" ||   data?.processing === "NO INFORMATION AVAILABLE" ?
                <p className='mt-10 text-[20px] text-grey-100 font-semibold font-nunito'>NO INFORMATION AVAILABLE</p>
                :
                <div className='xl:mt-[30px] mt-5 flex-col flex gap-6'>
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex flex-col gap-5 w-full' onClick={() => handleChange("Cultivation")}>
                            <h1 className={`${active === "Cultivation" ? "" : "opacity-50"} text-xl text-primary nunito text-center`}>Cultivation</h1>
                            <div className={`${active === "Cultivation" ? "bg-tertiary" : "bg-[#F3D3BB]"} h-1  w-full`}></div>
                        </div>
                        <div className='flex flex-col gap-5 w-full' onClick={() => handleChange("Harvesting")}>
                            <h1 className={`${active === "Harvesting" ? "" : "opacity-50"} text-xl text-primary nunito text-center`}>Harvesting</h1>
                            <div className={`${active === "Harvesting" ? "bg-tertiary" : "bg-[#F3D3BB]"} h-1  w-full`}></div>
                        </div>
                        <div className='flex flex-col gap-5 w-full' onClick={() => handleChange("Processing")}>
                            <h1 className={`${active === "Processing" ? "" : "opacity-50"} text-xl text-primary nunito text-center`}>Processing</h1>
                            <div className={`${active === "Processing" ? "bg-tertiary" : "bg-[#F3D3BB]"} h-1  w-full`} ></div>
                        </div>
                    </div>
                    
                    <div className='xl:px-0 px-5 flex xl:flex-row flex-col xl:items-center justify-between w-full'>
                        <div className='flex flex-col gap-[27px]'>
                            <div>
                                <h1 className={`${active === "Cultivation" ? "text-tertiary" : " text-[#F3D3BB]"} text-[50px] nunito-bold`}>01</h1>
                                <p className='text-xl nunito-normal text-blue'>Plant Coffee</p>
                            </div>
                            <p className={`${active === "Cultivation" ? "" : "opacity-50"} nunito-normal text-blue text-[18px]  xl:w-[350px] w-full `}>Combined with a handful of model senten of structures, to generate lorem ipsum in which looks reasonable. The generated to </p>
                        </div>
                        <div className='flex flex-col gap-[27px]'>
                            <div>
                                <h1 className={`${active === "Harvesting" ? "text-tertiary" : " text-[#F3D3BB]"} text-[50px] nunito-bold`}>02</h1>
                                <p className='text-xl nunito-normal text-blue'>Allow to Mature</p>
                            </div>
                            <p className={`${active === "Harvesting" ? "" : "opacity-50"} nunito-normal text-blue text-[18px]  xl:w-[350px] w-full `}>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was </p>
                        </div>
                        <div className='flex flex-col gap-[27px]'>
                            <div>
                                <h1 className={`${active === "Processing" ? "text-tertiary" : " text-[#F3D3BB]"} text-[50px] nunito-bold`}>03</h1>
                                <p className='text-xl nunito-normal text-blue'>Cherries Containing Seeds</p>
                            </div>
                            <p className={`${active === "Processing" ? "" : "opacity-50"} nunito-normal text-blue text-[18px]  xl:w-[350px] w-full `}>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for </p>
                        </div>
                    </div>
                </div>
            }


        </div>
    )
}
