import React, { Fragment, useContext, useState } from 'react'
import BasicButtons from '../Button'
import ReviewBox from '../ReviewBox'
import { handleReview } from '../../utils/helperFuncs'
import { UserContext } from '../../context/UserContext'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

export default function CoffeeConversations({data}) {
    const { user } = useContext(UserContext)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [comment, setComment] = useState('')
    const uid = user?.uid;
    const displayName = user?.displayName
    const docId = data?.id

    const handleSubmit = async () => {
        if (!comment) {
            enqueueSnackbar(`Please put in a review!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return;
        }
        if (!uid) {
            enqueueSnackbar(`Please sign in for your reviews to be saved!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return;
        }
        await handleReview(docId, uid, displayName, comment, 0)
        setComment('')
        enqueueSnackbar(`Review added successfully!`, {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            style: { 
                backgroundColor: '#503225'
            },
        });
        setTimeout(() => {
            navigate('/')
        }, 1000)
    }
    
    return (
        <div className='sm:mt-[120px] mt-[500px] flex flex-col xl:px-[4%] px-5 gap-[39px]'>
            <h1 className='nunito-bold md:text-[40px] text-2xl text-primary'>Join The Conversations</h1>
            <div className='flex xl:flex-row flex-col-reverse xl:justify-between gap-9 w-full'>
                <div className='xl:w-[60%] w-full flex flex-col gap-5'>
                    <div className='h-[238px] xl:w-[615px] w-full border border-grey-200 rounded-lg px-[26px] py-[30px]'>
                        <textarea 
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            className='w-full h-full bg-transparent focus:outline-none text-grey-100 placeholder:text-grey-100'
                            placeholder='Type your comment here...'
                        />
                    </div>
                    <BasicButtons functions={() => handleSubmit()} width={'111px'} text={'Comment'} />
                </div>
                {data?.reviews?.length > 0 && <div className='flex-1 flex flex-col gap-[38.71px]'>
                    {data?.reviews?.slice(0, 2)?.map((d, index) => (
                        <Fragment key={index}>
                            <div className='flex flex-col gap-[9px]'>
                                <ReviewBox font={true} data={d}/>
                            </div>
                            <div className='w-full h-[1px] bg-grey-200'></div>
                        </Fragment>
                    ))}
                </div>}
            </div>
        </div>
    )
}
