import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import Map from '../../assets/img/map-2.png'
import MarkerIcon from "../../assets/img/marker.png";
import MachineIcon from "../../assets/img/machine.png";
import SparePartIcon from "../../assets/img/spare-part.png";

import '../../routes/components/leafletMap.css'


export default function CoffeeRegion({ data }) {

    console.log(data, "beske");

    const createCustomIcon = (logoUrl) => {
        return new L.Icon({
            iconUrl: logoUrl,
            iconSize: [50, 50], // size of the icon
            iconAnchor: [19, 38], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -38] // point from which the popup should open relative to the iconAnchor
        });
    };

    return (
        <div className='bg-grey-250 xl:px-[4%] px-5 pt-10 pb-[50px]'>
            <h1 className='nunito-bold md:text-[40px] text-[28px] text-primary'>Worldwide Presence</h1>
            <p className='nunito-normal text-[18px] mt-1 text-grey-150'>
                We are children of a passion. The passion for coffee. We offer a superior, artisanal product, but we do it in the most innovative way possible. You will hardly find our product in large-scale retail trade and in large shopping centers. Our company has been active since {data?.year}, and our product is found in {data?.global?.length} countries worldwide.
            </p>
           
            <div className="flex-1 w-full h-full xl:mt-[45px] mt-10 xl:block hidden">
                <MapContainer 
                    center={[40.8518, 14.2681]} 
                    zoom={5} 
                    style={{ height: '30vh', width: '100%' }} 
                >
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://carto.com/attributions">CARTO</a>'
                        style={{
                            filter: 'brightness(1.5) contrast(1.3) saturate(1.5) hue-rotate(10deg)',
                        }}
                    />
                    {data?.global.length > 0 ? data?.global?.map((city, index) => (
                        <Marker 
                            position={[city?.latitude, city?.longitude]}
                            key={index} 
                            icon={createCustomIcon(MarkerIcon)}
                        >
                            <Popup>
                                <p className="cursor-pointer text-[#000] capitalize">{city?.name}</p>
                            </Popup>
                        </Marker>
                    )) : ( 
                    <Marker 
                        position={[40.8518, 14.2681]}
                        // icon={createCustomIcon(MarkerIcon)}
                    >
                        <Popup>
                            <p className="cursor-pointer text-[#000] capitalize">Italy</p>
                        </Popup>
                    </Marker>
                    )}
                </MapContainer>
            </div>
        </div>
    )
}

