import React from 'react'
import { toast } from 'react-toastify'
import { db } from '../firebase-config'
import { functions } from '../firebase-config'
import { deleteDoc, doc } from 'firebase/firestore'
import { CgSpinner } from 'react-icons/cg'
import { httpsCallable } from 'firebase/functions'


const DeleteAdmin = ({ handleClose, deleteItemId, deleteLoading, setDeleteLoading}) => {

    // const deleteItem = () => {
    //     setDeleteLoading(true)
    //     const docRef = doc(db, "admins", deleteItemId)
    //     deleteDoc(docRef)
    //     .then(() => {
    //         setDeleteLoading(false)
    //         toast(`Admin Deleted Successfully`, { 
    //             position: "top-right",
    //             autoClose: 3500,
    //             closeOnClick: true,
    //         });
    //         handleClose()
    //     })
    //     .catch((err) => {
    //         setDeleteLoading(false)
    //         console.log(err,  "mystic")
    //     })
    // }

    const deleteItem = async () => {
        setDeleteLoading(true)
        const docRef = doc(db, "admins", deleteItemId)

        try {
            // Delete user from Firestore
            await deleteDoc(docRef)

            // Call the cloud function to delete user from Firebase Authentication
            const deleteUser = httpsCallable(functions, 'deleteUser')
            await deleteUser({ uid: deleteItemId })
            setDeleteLoading(false)
            toast(`Admin Deleted Successfully`, {
                position: "top-right",
                autoClose: 3500,
                closeOnClick: true,
            })
            handleClose()
        } catch (err) {
            setDeleteLoading(false)
            handleClose()
            console.log(err, "mystic")
        }
    }

  return (
    <div className='w-full flex items-center justify-center'>
        <div className='bg-[#fff] w-full lg:w-[400px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
            <div className='flex flex-col justify-center items-center gap-6'>
            <p className='font-bold text-[24px] '>Delete Admin 🗑 </p>
        
            <div className='bg-[#EDF2F780] px-4 py-2.5 w-[378px] h-[68px] rounded flex items-center gap-3'>
                <p className='font-Mont text-sm text-[#5C6F7F]'>
                    When you click Yes, Delete,  this admin will be Deleted
                </p>
            </div>
            <div className='flex items-center gap-[18px]'>
                <button
                    type='button'
                    className='w-[160px] h-[48px] bg-[#fff] border border-[#5C6F7F] rounded'
                    onClick={handleClose}
                >
                    <p className='font-medium text-base'>Cancel</p>
                </button>
                <button
                    type='button'
                    className='w-[160px] h-[48px] bg-[#f00] text-center flex items-center justify-center rounded'
                    onClick={() => deleteItem()}
                >
                    <p className='text-[#fff] font-medium text-base'>{deleteLoading ? <CgSpinner className='animate-spin text-lg'/> : " Yes, Delete"}</p>
                </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export default DeleteAdmin