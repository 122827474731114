import React, { useState, useEffect, useContext } from 'react'
import Logo from '../assets/img/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import ProfileBar from './Profile'
import TemporaryDrawer from './Drawer'
import Filter from './filters/Filter'
import FilterProducts from './filters/FilterProducts'
import { auth } from '../firebase-config'
import { CoffeeContext } from '../context/CoffeeContext'
import { ProductContext } from '../context/ProductsContext'
import { CartsContext } from '../context/CartsContext'

export default function Navigation() {
    const { filterProducts } = useContext(ProductContext)
    const { setCartSearch } = useContext(CartsContext)
    const { setActive, setShowList, showList } = useContext(CoffeeContext)
    const navigate = useNavigate()
    const location = useLocation()
    const active = location.pathname
    const [user, setUser] = useState(null);
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleCart = () => {
        if (user) {
            navigate('/cart')
        } else {
            navigate('/auth')
        }
    }

    const holder = (active === '/wishlist' || active === '/cart' || active === '/products' || active === '/cartbag') ? 'Search Products' : 'Search Manufactures, Brands'
    const placeholder = active === '/coffee' ? 'Search' : holder;

    const handleSearch = (e) => {
        const value = e.target.value?.toUpperCase()
        setSearchText(value)

        if (active === '/coffee' || active === '/') {
            setActive(value)
            setShowList(!showList)
            navigate('/', { state: showList})
        } else if (active === '/products') {
            // filterProducts(searchText)
        } else if (active === '/cart') {
            setCartSearch(value)
        }
    }

    return (
        <div className='fixed bg-white w-full z-50 flex justify-between items-center xl:pl-[4%] xl:pr-[3.2%] px-5 h-[100px] shadow-[0_0_4px_0_#00000029]'>
            <img src={Logo} alt='logo' className='hidden xl:block cursor-pointer w-[163px] h-10' onClick={() => navigate('/')}/>
            <div className='xl:hidden size-10 flex items-center justify-center border border-secondary rounded-lg' onClick={() => navigate('/')}>
                <p className='poppins-bold text-xl'>IT</p>
            </div>
            {active !== '/gallery' && <div className={`border border-secondary h-[50px] rounded-full pl-5 ${(active === '/wishlist' || active === '/cart' || active === '/products' || active === '/cartbag') ? 'md:w-[407px] w-[65%]' : 'md:w-[620px] w-[65%]'} flex md:gap-5 gap-2 items-center`}>
                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 0.843018C10.78 0.843018 12.5201 1.37086 14.0001 2.35979C15.4802 3.34872 16.6337 4.75433 17.3149 6.39887C17.9961 8.0434 18.1743 9.853 17.8271 11.5988C17.4798 13.3447 16.6226 14.9483 15.364 16.207C14.1053 17.4657 12.5016 18.3228 10.7558 18.6701C9.00998 19.0174 7.20038 18.8391 5.55585 18.1579C3.91131 17.4767 2.50571 16.3232 1.51677 14.8431C0.527841 13.3631 0 11.623 0 9.84302C0 7.45607 0.948211 5.16688 2.63604 3.47906C4.32387 1.79123 6.61305 0.843018 9 0.843018ZM9 16.843C10.3845 16.843 11.7378 16.4325 12.889 15.6633C14.0401 14.8941 14.9373 13.8009 15.4672 12.5218C15.997 11.2427 16.1356 9.83525 15.8655 8.47739C15.5954 7.11952 14.9287 5.87224 13.9497 4.89327C12.9708 3.9143 11.7235 3.24762 10.3656 2.97752C9.00776 2.70742 7.6003 2.84605 6.32122 3.37586C5.04213 3.90567 3.94888 4.80288 3.17971 5.95403C2.41054 7.10517 2 8.45855 2 9.84302C2 11.6995 2.7375 13.48 4.05025 14.7928C5.36301 16.1055 7.14348 16.843 9 16.843ZM17.485 16.914L20.314 19.743L18.9 21.157L16.072 18.329L17.485 16.914Z" fill="#503225"/>
                </svg>
                <input 
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                    className='placeholder:text-grey-50  w-full h-full bg-transparent focus:outline-none md:text-[16px] text-sm'
                    placeholder={placeholder}
                />
            </div>}
            <div  className='xl:hidden cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                <ProfileBar />
                {/* <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 14V16H3V14H15ZM18 7V9H0V7H18ZM15 0V2H3V0H15Z" fill="#503225"/>
                </svg> */}
            </div>
            <div className='hidden xl:flex items-center gap-5'>
                {(active !== '/products' && active !== '/cartbag') && <div onClick={() => setOpen(!open)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 4.375C0.75 3.50971 1.00659 2.66385 1.48732 1.94438C1.96805 1.22492 2.65133 0.664162 3.45076 0.333029C4.25019 0.00189561 5.12985 -0.084744 5.97852 0.0840664C6.82719 0.252877 7.60674 0.669555 8.21859 1.28141C8.83045 1.89326 9.24713 2.67281 9.41594 3.52148C9.58475 4.37015 9.49811 5.24982 9.16697 6.04924C8.83584 6.84867 8.27509 7.53195 7.55562 8.01268C6.83615 8.49341 5.99029 8.75 5.125 8.75C3.96468 8.75 2.85188 8.28907 2.03141 7.46859C1.21094 6.64812 0.75 5.53532 0.75 4.375ZM22 5.625H12V3.125H22V5.625ZM14.5 16.875C14.5 16.0097 14.7566 15.1638 15.2373 14.4444C15.7181 13.7249 16.4013 13.1642 17.2008 12.833C18.0002 12.5019 18.8799 12.4153 19.7285 12.5841C20.5772 12.7529 21.3567 13.1696 21.9686 13.7814C22.5804 14.3933 22.9971 15.1728 23.1659 16.0215C23.3347 16.8701 23.2481 17.7498 22.917 18.5492C22.5858 19.3487 22.0251 20.0319 21.3056 20.5127C20.5862 20.9934 19.7403 21.25 18.875 21.25C17.7147 21.25 16.6019 20.7891 15.7814 19.9686C14.9609 19.1481 14.5 18.0353 14.5 16.875ZM12 15.625V18.125H2V15.625H12Z" fill="#503225"/>
                    </svg>
                </div>}
                {(active === '/products' || active === '/cartbag') && <div onClick={() => setOpen2(!open2)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 4.375C0.75 3.50971 1.00659 2.66385 1.48732 1.94438C1.96805 1.22492 2.65133 0.664162 3.45076 0.333029C4.25019 0.00189561 5.12985 -0.084744 5.97852 0.0840664C6.82719 0.252877 7.60674 0.669555 8.21859 1.28141C8.83045 1.89326 9.24713 2.67281 9.41594 3.52148C9.58475 4.37015 9.49811 5.24982 9.16697 6.04924C8.83584 6.84867 8.27509 7.53195 7.55562 8.01268C6.83615 8.49341 5.99029 8.75 5.125 8.75C3.96468 8.75 2.85188 8.28907 2.03141 7.46859C1.21094 6.64812 0.75 5.53532 0.75 4.375ZM22 5.625H12V3.125H22V5.625ZM14.5 16.875C14.5 16.0097 14.7566 15.1638 15.2373 14.4444C15.7181 13.7249 16.4013 13.1642 17.2008 12.833C18.0002 12.5019 18.8799 12.4153 19.7285 12.5841C20.5772 12.7529 21.3567 13.1696 21.9686 13.7814C22.5804 14.3933 22.9971 15.1728 23.1659 16.0215C23.3347 16.8701 23.2481 17.7498 22.917 18.5492C22.5858 19.3487 22.0251 20.0319 21.3056 20.5127C20.5862 20.9934 19.7403 21.25 18.875 21.25C17.7147 21.25 16.6019 20.7891 15.7814 19.9686C14.9609 19.1481 14.5 18.0353 14.5 16.875ZM12 15.625V18.125H2V15.625H12Z" fill="#503225"/>
                    </svg>
                </div>}
                {active !== '/gallery' && <div onClick={handleCart} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.78903 5.03603L0.551025 1.79303L1.96503 0.379028L5.20803 3.61903H20.449C20.6049 3.61902 20.7586 3.65544 20.8979 3.7254C21.0372 3.79535 21.1582 3.89689 21.2513 4.02192C21.3443 4.14696 21.4069 4.29201 21.4339 4.44551C21.461 4.59902 21.4518 4.75672 21.407 4.90603L19.007 12.906C18.9453 13.1121 18.8187 13.2928 18.6461 13.4212C18.4736 13.5497 18.2642 13.619 18.049 13.619H5.78903V15.619H16.789V17.619H4.78903C4.52381 17.619 4.26946 17.5137 4.08192 17.3261C3.89438 17.1386 3.78903 16.8842 3.78903 16.619V5.03603ZM5.78903 5.61903V11.619H17.305L19.105 5.61903H5.78903ZM5.28903 21.619C4.99235 21.619 4.70234 21.5311 4.45567 21.3662C4.209 21.2014 4.01674 20.9671 3.90321 20.6931C3.78967 20.419 3.75997 20.1174 3.81785 19.8264C3.87573 19.5354 4.01859 19.2681 4.22836 19.0584C4.43814 18.8486 4.70542 18.7057 4.99639 18.6479C5.28736 18.59 5.58896 18.6197 5.86305 18.7332C6.13714 18.8467 6.37141 19.039 6.53623 19.2857C6.70105 19.5323 6.78903 19.8224 6.78903 20.119C6.78903 20.5169 6.63099 20.8984 6.34969 21.1797C6.06838 21.461 5.68685 21.619 5.28903 21.619ZM17.289 21.619C16.9924 21.619 16.7023 21.5311 16.4557 21.3662C16.209 21.2014 16.0167 20.9671 15.9032 20.6931C15.7897 20.419 15.76 20.1174 15.8178 19.8264C15.8757 19.5354 16.0186 19.2681 16.2284 19.0584C16.4381 18.8486 16.7054 18.7057 16.9964 18.6479C17.2874 18.59 17.589 18.6197 17.8631 18.7332C18.1371 18.8467 18.3714 19.039 18.5362 19.2857C18.7011 19.5323 18.789 19.8224 18.789 20.119C18.789 20.5169 18.631 20.8984 18.3497 21.1797C18.0684 21.461 17.6869 21.619 17.289 21.619Z" fill="#503225"/>
                    </svg>
                </div>}
                {/* {(active !== '/wishlist' && active !== '/cart' && active !== '/gallery' && active !== '/products' && active !== '/cartbag') && (
                    <div onClick={() => navigate('/wishlist')} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99999 2.28698C11.1427 1.268 12.6318 0.724439 14.1623 0.767585C15.6927 0.810731 17.1488 1.43732 18.2323 2.51906C19.3158 3.60079 19.9448 5.05582 19.9905 6.58621C20.0362 8.1166 19.4951 9.60655 18.478 10.751L9.99999 19.243L1.52099 10.751C0.502097 9.60674 -0.0404661 8.11607 0.00454553 6.5846C0.0495572 5.05312 0.678733 3.59689 1.76307 2.51447C2.84741 1.43205 4.30476 0.805454 5.8363 0.763154C7.36785 0.720854 8.85756 1.26606 9.99999 2.28698ZM16.827 3.92798C16.1043 3.20654 15.1331 2.7888 14.1124 2.76036C13.0917 2.73191 12.0987 3.09491 11.337 3.77498L9.99999 4.97298L8.66399 3.77298C7.90559 3.09353 6.91661 2.7289 5.89866 2.7534C4.88071 2.7779 3.91042 3.1897 3.18559 3.90484C2.46075 4.61999 2.03593 5.58464 1.99773 6.60217C1.95953 7.6197 2.31082 8.61349 2.97999 9.38098L9.99999 16.412L17.02 9.38098C17.6841 8.61644 18.0334 7.62849 17.9976 6.61646C17.9618 5.60443 17.5434 4.64365 16.827 3.92798Z" fill="#503225"/>
                        </svg>
                    </div>
                )} */}
                {(active !== '/wishlist' && active !== '/cart' && active !== '/gallery' && active !== '/products' && active !== '/cartbag') && (
                    <ProfileBar />
                )}
            </div>

            <TemporaryDrawer open={open} setOpen={setOpen}>
                <Filter setOpen={setOpen}/>
            </TemporaryDrawer>
            <TemporaryDrawer open={open2} setOpen={setOpen2} auto={true}>
                <FilterProducts setOpen={setOpen2}/>
            </TemporaryDrawer>
        </div>
    )
}
