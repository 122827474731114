import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { toast } from 'react-toastify';
import { db } from '../firebase-config';

const AddAdmin = ({ handleClose, setAddAdminLoading }) => {
  
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: 'admin',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    role: Yup.string().required('Role is required'),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setAddAdminLoading(true)
    try {
      const adminCollection = collection(db, "admins");
      const emailQuery = query(adminCollection, where("email", "==", values.email));
      const emailQuerySnapshot = await getDocs(emailQuery);


      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role,
        password: values.password
      }
      console.log(data, "data")
      
      if (!emailQuerySnapshot.empty) {
        // throw new Error('email-already-in-use');
          toast(`Email Already in use`, { 
            position: "top-right",
            autoClose: 3500, 
            closeOnClick: true,
        });
        setAddAdminLoading(false);
        handleClose()
      } else {
        const docRef = await addDoc(adminCollection, data)
        console.log("Document written with ID: ", docRef.id);
        resetForm();
        setAddAdminLoading(false);
          handleClose()
          toast(`Admin Added Successfully`, { 
            position: "top-right",
            autoClose: 3500, 
            closeOnClick: true,
        });
      }
    } catch (error) {
      console.error('Error adding admin: ', error);
	    setAddAdminLoading(false);
        toast(`Error Adding Admin`, { 
          position: "top-right",
          autoClose: 3500, 
          closeOnClick: true,
      });
    
    }
    setSubmitting(false);
  };

  return (
    <div className='w-full flex items-center justify-center'>
      <div className='bg-[#fff] w-[500px] h-[600px] overflow-y-auto px-5 items-center flex flex-col gap-10  py-8'>
          <h1 className='text-3xl font-bold'>Add Admin</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
        <Form className='w-full flex flex-col gap-4'>
          <div>
          <label htmlFor='firstName'>First Name</label>
          <Field type='text' id='firstName' name='firstName' className='border p-2 outline-none rounded w-full' />
          <ErrorMessage name='firstName' component='div' className='text-red-500' />
          </div>

          <div>
          <label htmlFor='lastName'>Last Name</label>
          <Field type='text' id='lastName' name='lastName' className='border p-2 outline-none rounded w-full' />
          <ErrorMessage name='lastName' component='div' className='text-red-500' />
          </div>

          <div>
          <label htmlFor='email'>Email</label>
          <Field type='email' id='email' name='email' className='border p-2 rounded outline-none w-full' />
          <ErrorMessage name='email' component='div' className='text-red-500' />
          </div>

          <div>
          <label htmlFor='password'>Password</label>
          <Field type='text' id='password' name='password' className='border p-2 rounded outline-none w-full' />
          <ErrorMessage name='password' component='div' className='text-red-500' />
          </div>

          <div>
          <label htmlFor='role'>Role</label>
          <Field as='select' id='role' name='role' className='border p-2 outline-none rounded w-full'>
            <option value='admin'>Admin</option>
            <option value='superadmin'>Super Admin</option>
          </Field>
          <ErrorMessage name='role' component='div' className='text-red-500' />
          </div>

          <div className='flex items-center justify-between gap-5 w-full'>
          <button type='button' onClick={handleClose} className='bg-[#fff] w-6/12 text-secondary border border-secondary p-2 rounded'>
            Cancel
          </button>
          <button type='submit' disabled={isSubmitting} className='bg-secondary w-6/12 text-white p-2 rounded'>
            {isSubmitting ? 'Adding...' : 'Add Admin'}
          </button>
          </div>

        </Form>
        )}
      </Formik>
      </div>
    </div>
  );
};

export default AddAdmin;
