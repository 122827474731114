import React, { useState } from 'react'
import { addDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { countriesOptions } from '../utils/countriesOptions';

const AddCountries = ({ handleClose, manufacturerData, setAddCountryLoading }) => {
    const [countriesLoading, setCountriesLoading] = useState(false)
    const [country, setCountry] = useState("")


    const createCountries = async () => {
        setCountriesLoading(true);
        setAddCountryLoading(true)
        try {
            const countriesCollection = collection(db, "countries");
            const q = query(countriesCollection, where("company", "==", manufacturerData?.company));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                // Create new document if it doesn't exist
                const data = {
                    company: manufacturerData?.company,
                    country: [country]
                };
                const docRef = await addDoc(countriesCollection, data);
                console.log("New document created with ID: ", docRef.id);
            } else {
                // Update existing document
                const doc = querySnapshot.docs[0];
                const existingCountries = doc.data().country || [];
                if (!existingCountries.includes(country)) {
                    await updateDoc(doc.ref, {
                        country: [...existingCountries, country]
                    });
                    console.log("Document updated with ID: ", doc.id);
                } else {
                    console.log("Country already exists for this company");
                    toast("Country already exists for this company", {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                    });
                    setCountriesLoading(false);
                    setAddCountryLoading(false)
                    return;
                }
            }

            setCountriesLoading(false);
            setAddCountryLoading(false)
            toast("Country Added Successfully", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
            setCountry("");
            handleClose()
        } catch (error) {
            console.error("Error creating document: ", error);
            setCountriesLoading(false);
            setAddCountryLoading(false)
            toast("Error", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            }) 
        }
    }

  return (
    <div className='bg-[#efefef] w-full lg:w-[400px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
        <div className='flex flex-col gap-10'>
            <h1 className='text-3xl text-center font-bold'>Add Country</h1>
            <div className='flex'>
                
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Country</label>
                    <select  onChange={(e) => setCountry(e.target.value)}  value={country}  className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                        <option>Select</option>
                        {
                            countriesOptions?.map((item, index) => {
                                return(
                                    <option key={index} className='w-full' value={item}>
                                        {item}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className='flex justify-between'>
                <button 
                    type='button' 
                    className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
                    onClick={createCountries}
                >
                    {countriesLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
                </button>
                <button 
                    type='button' 
                    className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
                    onClick={handleClose}
                >
                    Cancel
                </button>

            </div>
        </div>

    </div>
  )
}

export default AddCountries
