import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import Review from '../../assets/svg/star3.svg'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { useNavigate } from 'react-router-dom';

export default function SwiperBrand({width, data, brand}) {
    const navigate = useNavigate()

    console.log(data[brand], "viva")

    return (
        <Swiper
            style={{width: '100%', height: width ? '452px' : '530px'}}
            modules={[Navigation, Scrollbar]}
            navigation
            slidesPerView={'auto'}
            scrollbar={{ draggable: true }}
            className='brand__swiper'
        >
            {data[brand]?.map((d, index) => (
                <SwiperSlide key={index} style={{width: width ? '348.63px' : '424.21px', height: width ? '401px' : '487.94px', marginRight: width ? '18px' : '21.38px'}}>
                    <div className={`${(!d?.visibility) || (!data?.esePodsVisibility && !data?.compatibleCapsuleVisibility && !data?.grindedCoffeeVisibility && !data?.coffeeBeansVisibility) ? "hidden" : "h-full flex cursor-pointer md:w-[424.21px] w-[348.63px]"}`} onClick={() => navigate('/cartbag', { state: { data:d, manufacturerData:data} })}>
                        <img src={d?.image} alt='coffee' className='w-full h-full rounded-tl-[30px] rounded-br-[31px] object-cover'/>
                        <div className='absolute flex items-end md:w-[424px] w-[348.63px] rounded-br-[30px] h-full bottom-0 gradient'> 
                            <div className='pl-[26px] py-7 flex gap-2 '>
                                <img src={data?.logo_small} alt='profile' className='size-[47px] rounded-full object-contain' />
                                <div>
                                    <p className='font-bold xl:text-xl text-white'>{d?.name}</p>
                                    <img src={Review} alt='review' />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}
//h-[141.94px] 
