import React, { Fragment, useContext, useState, useEffect } from 'react'
import HomeLayout from '../layout/HomeLayout'
import { useNavigate, useLocation } from 'react-router-dom'
import Country from '../assets/img/country.png'
import ProductBox from '../components/product/ProductBox'
import { capitalizeString } from '../utils/helperFuncs'
import Empty from '../components/coffee/Empty'
import { ProductContext } from '../context/ProductsContext'
import { convertEUR, convertGBP, convertUSD } from '../utils/helperFuncs'
import { CartsContext } from '../context/CartsContext'
import { useSnackbar } from 'notistack'

export default function Products() {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const location = useLocation()
    const { cartData } = useContext(CartsContext)
    const {state} = location;
    const { filteredProduct, setProduct } = useContext(ProductContext)
    const [convertedProducts, setConvertedProducts] = useState(filteredProduct);
    const [selectedCurrency, setSelectedCurrency] = useState('€');
    
    useEffect(() => {
        if (state.products) {
            setProduct(state.products)
        }
    }, [filteredProduct])

    const handleCurrencyChange = (event) => {
        if (cartData?.length > 0) {
            enqueueSnackbar('Please clear your cart first!', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return
        } else {
            const newCurrency = event.target.value;
            setSelectedCurrency(newCurrency);
            
            const convertedProducts = filteredProduct.map(product => {
                let convertedPrice = product.price;
                switch (product.currency) {
                    case "€":
                        convertedPrice = convertEUR(product.price, newCurrency);
                        break;
                    case "$":
                        convertedPrice = convertUSD(product.price, newCurrency);
                        break;
                    case "£":
                        convertedPrice = convertGBP(product.price, newCurrency);
                        break;
                    default:
                        console.error(`Unsupported currency: ${product.currency}`);
                        break;
                }
                return { ...product, currency: newCurrency, price: convertedPrice.toFixed(2) };
            });
            setConvertedProducts(convertedProducts);
        }
    };

    console.log(convertedProducts)

    return (
        <HomeLayout>
            <div className='z-10 fixed w-full bg-white border border-grey-200 xl:pl-[4%] pl-5 h-[71px] flex sm:gap-5 gap-[10px] items-center'>
                <div onClick={() => navigate(-1)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.9403 6.99998H16.1123V8.99998H3.9403L9.30431 14.364L7.8903 15.778L0.112305 7.99998L7.8903 0.221985L9.30431 1.63598L3.9403 6.99998Z" fill="#7E3701"/>
                    </svg>
                </div>
                <h1 className='nunito-bold text-primary md:text-2xl text-xl'>All Products From {capitalizeString(state?.company)} </h1>
            </div>
            <div className='xl:px-[4%] px-5 mt-[84px] flex flex-col gap-[21px]'>
                <div className='flex gap-[11px] items-center'>
                    <p>Currency:</p>
                    <div className='flex gap-2 items-center cursor-pointer'>
                        <img src={Country} alt='country' className='size-6'/>
                        <select
                            value={selectedCurrency}
                            onChange={handleCurrencyChange}
                            className='cursor-pointer bg-transparent appearance-none font-bold text-grey-100 focus:outline-none'
                        >
                            <option value="$">USD</option>
                            <option value="£">GBP</option>
                            <option value="€">EUR</option>
                        </select>
                        <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.364 5.55999L11.314 0.609985L12.728 2.02399L6.364 8.38899L0 2.02499L1.414 0.610986L6.364 5.55999Z" fill="black"/>
                        </svg>
                    </div>
                </div>
                {(convertedProducts && convertedProducts?.length >= 1) ? (
                    <div className='grid md:grid-cols-4 grid-cols-2 sm:gap-4 gap-[9px] w-full mb-5'>
                        {convertedProducts?.map((d, index) => (
                            <Fragment key={index}>
                                <ProductBox data={d}/>
                            </Fragment>
                        ))}
                    </div>
                ) :
                <div className='mt-[10%]'>
                    <Empty />
                </div>}
            </div>
        </HomeLayout>
    )
}
