import React, { Fragment } from 'react'
import { capitalizeString } from '../../utils/helperFuncs'
import ProductBox from '../product/ProductBox'
import { useNavigate } from 'react-router-dom'
import Empty from './Empty'

export default function CoffeeProducts({data}) {
    const navigate = useNavigate()

    console.log(data, "lambo")

    const visibleProducts = data?.products?.filter(product => product.visibility);
    console.log(visibleProducts, "visibleProducts")

    return (
        <div className='mt-[66px] bg-pink xl:px-[4%] px-5 py-[62px] xl:h-[799px] flex flex-col'>
            <h1 className='nunito-bold md:text-[40px] text-[28px] text-primary'>Products Own by {capitalizeString(data?.company)}</h1>
            <p className='nunito-normal text-[18px] opacity-50'>Contrary to popular belief and going through the cites of the word source.</p>
            {data?.products[0]?.price === 0 || data?.products?.length === 0 || visibleProducts?.length === 0 ? (
                <Empty />
            ) : (
                <div className='mt-5'>
                    <div className='grid xl:grid-cols-4 grid-cols-2 gap-4 w-full mb-5'>
                        {data.products?.slice(0, 4)?.map((d, index) => (
                            <Fragment key={index}>
                                <ProductBox data={d} manufacturerData={data} color={true}/>
                            </Fragment>
                        ))}
                    </div>
                    <div className='w-full flex justify-between items-center'>
                        <div onClick={() => navigate('/products', { state: data})} className='border border-secondary rounded-lg w-[117px] h-[43px] px-5 flex items-center cursor-pointer'>
                            <p className='font-medium text-sm'>View More</p>
                        </div>
                        <div className='xl:flex hidden gap-[21.87px] items-center'>
                            <svg className='cursor-pointer' width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M7.89 11.134L4.22 7.46398H16.689V5.75398H4.22L7.89 2.08398L6.681 0.875977L0.947998 6.60898L6.681 12.342L7.89 11.134Z" fill="#00000080"/>
                            </svg>
                            <svg className='cursor-pointer' width="38" height="13" viewBox="0 0 38 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.731 2.08477L34.401 5.75477L0.560974 5.75477V7.46377L34.401 7.46377L30.731 11.1338L31.94 12.3428L37.673 6.60977L31.94 0.876774L30.731 2.08477Z" fill="#F1944E"/>
                            </svg>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
