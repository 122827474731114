// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Language": "Language",
      "English": "English",
      "Deutsch": "Deutsch",
      "French": "French",
      "Polski": "Polski"
    }
  },
  de: {
    translation: {
      "Language": "Sprache",
      "English": "Englisch",
      "Deutsch": "Deutsch",
      "French": "Französisch",
      "Polski": "Polnisch"
    }
  },
  fr: {
    translation: {
      "Language": "Langue",
      "English": "Anglais",
      "Deutsch": "Allemand",
      "French": "Français",
      "Polski": "Polonais"
    }
  },
  pl: {
    translation: {
      "Language": "Język",
      "English": "Angielski",
      "Deutsch": "Niemiecki",
      "French": "Francuski",
      "Polski": "Polski"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
