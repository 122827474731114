import React from 'react'
import Star from '../assets/svg/star.svg'
import Star2 from '../assets/svg/star2.svg'
import Avatar from '@mui/material/Avatar';
import { getDateRelative } from '../utils/helperFuncs'

export default function ReviewBox({font, data}) {
    const renderStars = (rate) => {
        // Ensure rate is within the range of 0 to 5
        const clampedRate = Math.max(0, Math.min(5, rate));
        
        // Calculate star1Count and star2Count based on rate
        const star1Count = Math.min(clampedRate, 5); // Maximum 5 stars
        const star2Count = Math.max(0, 5 - clampedRate); // Maximum 5 stars
        const stars = [];
        
        // Render star1
        for (let i = 0; i < star1Count; i++) {
          stars.push(<img key={`star1_${i}`} src={Star} className='size-[17px]' alt="Star1" />);
        }
        
        // Render star2
        for (let i = 0; i < star2Count; i++) {
          stars.push(<img key={`star2_${i}`} src={Star2} className='size-[17px]' alt="Star2" />);
        }
        
        return stars;
    };  
      
    return (
        <>
            <div className='flex gap-[10px] items-center'>
                <Avatar sx={{ bgcolor: '#503225' }}>{data?.name?.charAt(0)}</Avatar>
                <div className='flex flex-col'>
                    <p className='open-sans--bold text-[18px] text-black'>{data?.name}</p>
                    <div className='flex gap-5 items-center'>
                        <div className='flex items-center'>
                            {renderStars(data?.company_rate)}
                        </div>
                        <p className='open-sans text-black'>{data?.createdAt && getDateRelative(data?.createdAt)}</p>
                    </div>
                </div>
            </div>
            <div>
                <p className={`${font ? 'text-sm text-black font-normal': 'nunito-normal text-blue opacity-50 text-[18px]'}`}>{data?.comment}</p>
            </div>
        </>
    )
}
