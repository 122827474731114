import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import mapboxgl from "mapbox-gl";
// import './ShopMap.css'; // Ensure this CSS file exists for styling
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { Marker } from "../Marker/Marker";

const ZOOM_LEVEL_TIERS = {
     country: [0, 4],
     state: [5, 7],
     city: [8, 10],
     town: [11, 13],
     location: [14, 16],
     destination: [17, 22], // Assuming 22 as the maximum zoom level
};
const mockAreaCenters = {
     country: [{ lat: 41.9028, lng: 12.4964 }], // Rome as the center for Italy (country level)
     state: [
          { lat: 45.4642, lng: 9.19 }, // Milan as a mock center for a state/region
          { lat: 40.8518, lng: 14.2681 }, // Naples as another example
     ],
     city: [
          { lat: 43.7696, lng: 11.2558 }, // Florence as a city center
          { lat: 41.9028, lng: 12.4964 }, // Rome as a city center
     ],
     town: [
          { lat: 44.4949, lng: 11.3426 }, // Bologna as a town center
          { lat: 45.4384, lng: 10.9916 }, // Verona as another example
     ],
     location: [
          { lat: 40.6333, lng: 14.6029 }, // Sorrento as a specific location
          { lat: 44.4056, lng: 8.9463 }, // Genoa as another specific location
     ],
     destination: [
          { lat: 45.4384, lng: 10.9916 }, // Verona as a specific destination
          { lat: 40.8518, lng: 14.2681 }, // Naples as another specific destination
     ],
     other: [
          { lat: 39.2238, lng: 9.1217 }, // Cagliari for 'other' category
          { lat: 42.4072, lng: 12.8659 }, // Central Italy for 'other' category
     ],
};

mapboxgl.accessToken =
     "pk.eyJ1Ijoic2hhbG9tLXJlYWN0IiwiYSI6ImNsc3MyaTRxbjBiNzEya211NHg1dHV4YTMifQ.FYlBvYS-9vo4KETteVxXTw";
export const ShowMap = () => {
     const [shops, setShops] = useState([]);
     const [zoom, setZoom] = useState(8);
     const mapContainerRef = useRef(null);
     const mapRef = useRef(null);
     const markersRef = useRef([]);

     console.log("zoom", zoom);

     function determineAreaType(latitude, longitude) {
          const types = ["country", "state", "city", "other"];
          return types[Math.floor(Math.random() * types.length)];
     }

     function countShopsInArea(areaType, shops) {
          return shops.filter((shop) => shop.areaType === areaType).length;
     }

     const handleZoomIn = () => {
          setZoom(zoom + 1);
     };

     const handleZoomOut = () => {
          if (zoom > 0) setZoom(zoom - 1);
     };

     useEffect(() => {
          const fetchShops = async () => {
               try {
                    const response = await axios.get("/shops.json");
                    setShops(response.data);
               } catch (error) {
                    console.error("Error fetching shop data:", error);
               }
          };

          fetchShops();
     }, []);

     useEffect(() => {
          if (shops.length > 0) {
               initializeMap();
          }
          return () => {
               if (mapRef.current) {
                    mapRef.current.off("zoomend");

                    // mapRef?.current?.remove?.();
               }
          };
     }, [shops, zoom]);

     //  const updateShopsDisplay = (newZoom) => {
     //       let tier;
     //       for (const [key, range] of Object.entries(ZOOM_LEVEL_TIERS)) {
     //            if (newZoom >= range[0] && newZoom <= range[1]) {
     //                 tier = key;
     //                 console.log("tier", tier, newZoom);
     //                 break;
     //            }
     //       }

     //       markersRef.current.forEach((marker) => marker.remove());
     //       markersRef.current = [];

     //       shops.forEach((shop) => {
     //            const areaType = determineAreaType(shop.latitude, shop.longitude);
     //            let count = "";
     //            if (areaType === tier) {
     //                 count = countShopsInArea(areaType, shops).toString();
     //                 console.log("counts", count, tier);
     //                 const markerEl = document.createElement("div");
     //                 const root = createRoot(markerEl);
     //                 root.render(<Marker count={count} />);

     //                 const marker = new mapboxgl.Marker(markerEl)
     //                      .setLngLat([shop.longitude, shop.latitude])
     //                      .setPopup(new mapboxgl.Popup().setText(shop.shopName))
     //                      .addTo(mapRef.current);

     //                 markersRef.current.push(marker);
     //            }
     //       });
     //  };

     const updateShopsDisplay = (newZoom, roundedCenter) => {
          markersRef.current.forEach((marker) => marker.remove());
          markersRef.current = [];

          const areaType = getAreaType(newZoom);
          const centers = mockAreaCenters[areaType]; // Centers for the current area type

          centers.forEach((center) => {
               console.log("forEach", areaType, centers, roundedCenter);

               const count = calculateShopCountInArea(areaType, center);
               const markerEl = document.createElement("div");
               const root = createRoot(markerEl);
               root.render(<Marker count={count} />);

               const marker = new mapboxgl.Marker(markerEl)
                    .setLngLat([center.lng, center.lat])
                    .addTo(mapRef.current);

               markersRef.current.push(marker);
          });
     };

     const initializeMap = () => {
          if (shops.length > 0 && !mapRef.current) {
               mapRef.current = new mapboxgl.Map({
                    container: mapContainerRef.current,
                    style: "mapbox://styles/mapbox/streets-v11",
                    center: [12.5674, 41.8719], // Modify as needed
                    zoom: zoom,
               });

               mapRef.current.addControl(new mapboxgl.NavigationControl());
               updateShopsDisplay(zoom);

               mapRef.current.on("move", () => {
                    const newZoom = mapRef.current.getZoom().toFixed(2);
                    const newCenter = mapRef.current.getCenter();

                    const roundedCenter = {
                         lat: parseFloat(newCenter.lat.toFixed(4)),
                         lng: parseFloat(newCenter.lng.toFixed(4)),
                    };

                    if (zoom !== newZoom) {
                         setZoom(+newZoom);
                         updateShopsDisplay(newZoom, roundedCenter);
                    }
               });

               //    shops.forEach((shop) => {
               //         const areaType = determineAreaType(shop.latitude, shop.longitude);
               //         let count = "";
               //         if (areaType === "country" || areaType === "state" || areaType === "city") {
               //              count = countShopsInArea(areaType, shops).toString();
               //         }
               //         //    console.log("counts", count);
               //         const markerEl = document.createElement("div");
               //         const root = createRoot(markerEl);
               //         root.render(<Marker count={count} />);

               //         new mapboxgl.Marker(markerEl)
               //              .setLngLat([shop.longitude, shop.latitude])
               //              .setPopup(new mapboxgl.Popup().setText(shop.shopName))
               //              .addTo(mapRef.current);
               //    });
               //   mapRef.current = map;
          }
     };

     const isShopInsideArea = (shop, center, radius) => {
          const toRadian = (angle) => (Math.PI / 180) * angle;
          const distance = (a, b) => {
               const R = 6371; // Earth's radius in km
               const deltaLat = toRadian(b.lat - a.lat);
               const deltaLng = toRadian(b.lng - a.lng);
               const h =
                    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                    Math.cos(toRadian(a.lat)) *
                         Math.cos(toRadian(b.lat)) *
                         Math.sin(deltaLng / 2) *
                         Math.sin(deltaLng / 2);
               const c = 2 * Math.atan2(Math.sqrt(h), Math.sqrt(1 - h));
               return R * c;
          };

          const distanceToCenter = distance({ lat: shop.latitude, lng: shop.longitude }, center);
          return distanceToCenter <= radius;
     };

     const calculateShopCountInArea = (areaType, center) => {
          const radius = determineRadiusForArea(areaType);
          return shops.filter((shop) => isShopInsideArea(shop, center, radius)).length;
     };

     const determineRadiusForArea = (areaType) => {
          switch (areaType) {
               case "country":
                    return 500; // Radius in kilometers for a country
               case "state":
                    return 100; // Radius for a state
               case "city":
                    return 30; // Radius for a city
               case "town":
                    return 10; // Radius for a town
               case "location":
                    return 5; // Radius for a specific location
               case "destination":
                    return 1; // Radius for a very specific destination
               case "other":
                    return 20; // Default radius for other types
               default:
                    return 20; // Default radius if area type is not recognized
          }
     };

     const getAreaType = (zoomLevel) => {
          if (
               zoomLevel >= ZOOM_LEVEL_TIERS.country[0] &&
               zoomLevel <= ZOOM_LEVEL_TIERS.country[1]
          ) {
               return "country";
          } else if (
               zoomLevel >= ZOOM_LEVEL_TIERS.state[0] &&
               zoomLevel <= ZOOM_LEVEL_TIERS.state[1]
          ) {
               return "state";
          } else if (
               zoomLevel >= ZOOM_LEVEL_TIERS.city[0] &&
               zoomLevel <= ZOOM_LEVEL_TIERS.city[1]
          ) {
               return "city";
          } else if (
               zoomLevel >= ZOOM_LEVEL_TIERS.town[0] &&
               zoomLevel <= ZOOM_LEVEL_TIERS.town[1]
          ) {
               return "town";
          } else if (
               zoomLevel >= ZOOM_LEVEL_TIERS.location[0] &&
               zoomLevel <= ZOOM_LEVEL_TIERS.location[1]
          ) {
               return "location";
          } else if (
               zoomLevel >= ZOOM_LEVEL_TIERS.destination[0] &&
               zoomLevel <= ZOOM_LEVEL_TIERS.destination[1]
          ) {
               return "destination";
          } else {
               return "other";
          }
     };

     return (
          <div style={{ position: "relative", border: "1px solid red" }}>
               <div ref={mapContainerRef} id="map" style={{ width: "100%", height: "80vh" }} />;
          </div>
     );
};
