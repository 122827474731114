import React, { useState, useEffect } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase-config'
import { toast } from 'react-toastify'
import { CgSpinner } from 'react-icons/cg'

import UploadIcon from "../assets/img/upload_icon.png"
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import BasicButtons from '../components/Button'
import { useLocation, useNavigate,   } from 'react-router-dom'
import { MdKeyboardBackspace } from 'react-icons/md'


const ViewInfo = () => {
    const [updateDataLoading, setUpdateDataLoading] = useState(false)
    // const [whatsapp, setWhatsapp] = useState('')
    // const [province, setProvince] = useState('')
    // const [logo, setLogo] = useState('')
    // const [company, setCompany] = useState('')
    // const [youtube, setYoutube] = useState('')
    // const [academy, setAcademy] = useState(true)
    // const [global, setGlobal] = useState('')
    // const [email, setEmail] = useState('')
    // const [emails, setEmails] = useState('')
    // const [profitProject, setProfitProject] = useState('')
    // const [contact, setContact] = useState('')
    // const [phone, setPhone] = useState([])
    // const [website, setWebsite] = useState('')
    // const [address, setAddress] = useState('')
    // const [award, setAward] = useState('')
    // const [certificates, setCertificates] = useState('')
    // const [certificate, setCertificate] = useState('')
    // const [merchandise, setMerchandise] = useState([])
    // const [merch, setMerch] = useState('')
    // const [sustainable, setSustainable] = useState(true)
    // const [brand, setBrand] = useState('')
    // const [otherBrands, setOtherBrands] = useState([])
    // const [blog, setBlog] = useState(true)
    // const [notOnlyCoffee, setNotOnlyCoffee] = useState([])
    // const [others, setOthers] = useState('')
    // const [year, setYear] = useState('')
    // const [history, setHistory] = useState('')
    // const [newsletter, setNewsletter] = useState('')
    // const [team, setTeam] = useState('')
    // const [legal, setLegal] = useState('')
    // const [gallery, setGallery] = useState([])
    // const [rangeProducts, setRangeProducts] = useState([])
    // const [range, setRange] = useState('')
    // const [long, setLong] = useState()
    // const [lat, setLat] = useState()
    // const [pic, setPic] = useState('')
    // const [salesOnline, setSalesOnline] = useState(true)
    // const [processingMethod, setProcessingMethod] = useState('')
    // const [rate, setRate] = useState(0)
    // const [social, setSocial] = useState([]);
    // const [type, setType] = useState('');
    // const [link, setLink] = useState('');
    // const [shipping, setShipping] = useState('');
    // const [delivery, setDelivery] = useState('');
    // const [privacy, setPrivacy] = useState('')
    // const [terms, setTerms] = useState('')
    // const [faq, setFaq] = useState('')
    // const [cookies, setCookies] = useState('')
    const [userImage, setUserImage] = useState(null)
    // const [coffeeBeans, setCoffeeBeans] = useState([
    //     { rate: 5, currency: '€', name: '', price: 0, images: [''], description: '', quantity: '' }
    // ])
    // const [compatibleCapsules, setCompatibleCapsules] = useState([
    //     { rate: 5, currency: '€', name: '', price: 0, images: [''], description: '', quantity: '' }
    // ])
    // const [esePods, setEsePods] = useState([
    //     { rate: 5, currency: '€', name: '', price: 0, images: [''], description: '', quantity: '' }
    // ])
    // const [grindedCoffee, setGrindedCoffee] = useState([
    //     { rate: 5, currency: '€', name: '', price: 0, images: [''], description: '', quantity: '' }
    // ])

    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        merchandise: "",
        email: "",
        certificate: [],
        award_winners: "",
        profit_project: "",
        logo: "",
        gallery: [],
        website: "",
        company_rate: 0,
        position: [],
        contact: "",
        other_brands: "",
        price_products: "",
        "ESE PODS": [],
        company: "",
        address: "",
        "GRINDED COFFEE": [],
        "COMPATIBLE CAPSULES": [],
        "COFFEE BEANS": [],
        delivery_link: "",
        sales_online: false,
        merchandise: [],
        year: "",
        global: "",
        blog: false,
        province: "",
        sustainability: false,
        history: "",
        academy: "",
        processing: "",
        intl_certification: "",
        logo_small: "",
        socials: { facebook: "" },
        range_products: [],
        youtube_link: "",
        not_only_coffee: "",
        id: "",
        team: "",
        legal: "",
        newsletter: "",
        cookies: "",
        faq: "",
        terms: "",
        privacy: "",
        shipping: "",
        others: "",
        certificate: "",
        type: "",
        whatsapp: "",
        rate: ""
      });
    const [draft, setDraft] = useState(null);

    const history = useNavigate();
    const { state } = useLocation()
    const manufacturerInfo = state

    useEffect(() => {
        if (manufacturerInfo) {
          setFormData(manufacturerInfo);
        }
      }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleNestedChange = (e, index, key, nestedKey) => {
        const newArray = formData[key].map((item, i) =>
          i === index ? { ...item, [nestedKey]: e.target.value } : item
        );
        setFormData({
          ...formData,
          [key]: newArray,
        });
      };
    
    const handleArrayChange = (e, index, key) => {
        const newArray = formData[key].map((item, i) =>
          i === index ? e.target.value : item
        );
        setFormData({
          ...formData,
          [key]: newArray,
        });
    };

    const handleAddItem = (key, defaultValue) => {
        setFormData({
          ...formData,
          [key]: [...formData[key], defaultValue],
        });
    };
   

    const handleFileUpload = async (acceptedFiles) => {
        const files = acceptedFiles;
        if (files) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'rztljgso');
    
            try {
              const uploadResponse = await axios.post("https://api.cloudinary.com/v1_1/dizlp3hvp/upload", formData);
              const data = uploadResponse.data;
              console.log(data, "data")
              setUserImage(data);
           
            } catch (error) {
              console.error('Error uploading file:', error);
            }
        }
        }
    };

    const onDrop = (acceptedFiles) => {
        handleFileUpload(acceptedFiles)
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    console.log(formData, "formData")


    // const handleCoffeeBeansChange = (e, index, field) => {
    //     const newCoffeeBeans = [...coffeeBeans];
    //     newCoffeeBeans[index][field] = e.target.value;
    //     setCoffeeBeans(newCoffeeBeans);
    //   };
    
    // const handleAddCoffeeBeans = () => {
    //     setCoffeeBeans([...coffeeBeans, { rate: 0, currency: '', name: '', price: 0, images: [''], description: '', quantity: '' }]);
    // };

    // const handleCoffeeBeanImageChange = (e, productIndex, imageIndex) => {
    //     const newCoffeeBeans = [...coffeeBeans];
    //     newCoffeeBeans[productIndex].images[imageIndex] = e.target.value;
    //     setCoffeeBeans(newCoffeeBeans);
    // };

    // const handleAddCoffeeBeanImage = (productIndex) => {
    //     const newCoffeeBeans = [...coffeeBeans];
    //     newCoffeeBeans[productIndex].images.push('');
    //     setCoffeeBeans(newCoffeeBeans);
    // };


    // const handleCompatibleCapsulesChange = (e, index, field) => {
    //     const newCompatibleCapsules = [...compatibleCapsules];
    //     newCompatibleCapsules[index][field] = e.target.value;
    //     setCompatibleCapsules(newCompatibleCapsules);
    // };
    
    // const handleAddCompatibleCapsules = () => {
    //     setCompatibleCapsules([...compatibleCapsules, { rate: 0, currency: '', name: '', price: 0, image: [''], description: '', quantity: '' }]);
    // };

    // const handleCompatibleCapsulesImageChange = (e, productIndex, imageIndex) => {
    //     const newCompatibleCapsules = [...compatibleCapsules];
    //     newCompatibleCapsules[productIndex].images[imageIndex] = e.target.value;
    //     setCompatibleCapsules(newCompatibleCapsules);
    // };

    // const handleAddCompatibleCapsulesImage = (productIndex) => {
    //     const newCompatibleCapsules = [...compatibleCapsules];
    //     newCompatibleCapsules[productIndex].images.push('');
    //     setCompatibleCapsules(newCompatibleCapsules);
    // };

    // const handleEsePodsChange = (e, index, field) => {
    //     const newEsePods = [...esePods];
    //     newEsePods[index][field] = e.target.value;
    //     setEsePods(newEsePods);
    // };
    
    // const handleAddEsePods = () => {
    //     setEsePods([...esePods, { rate: 0, currency: '', name: '', price: 0, image: [''], description: '', quantity: '' }]);
    // };

    // const handleEsePodsImageChange = (e, productIndex, imageIndex) => {
    //     const newEsePods = [...esePods];
    //     newEsePods[productIndex].images[imageIndex] = e.target.value;
    //     setEsePods(newEsePods);
    // };

    // const handleAddEsePodsImage = (productIndex) => {
    //     const newEsePods = [...esePods];
    //     newEsePods[productIndex].images.push('');
    //     setEsePods(newEsePods);
    // };

    // const handleGrindedCoffeeChange = (e, index, field) => {
    //     const newGrindedCoffee = [...grindedCoffee];
    //     newGrindedCoffee[index][field] = e.target.value;
    //     setGrindedCoffee(newGrindedCoffee);
    // };
    
    // const handleAddGrindedCoffee = () => {
    //     setGrindedCoffee([...grindedCoffee, { rate: 0, currency: '', name: '', price: 0, image: [''], description: '', quantity: '' }]);
    // };

    // const handleGrindedCoffeeImageChange = (e, productIndex, imageIndex) => {
    //     const newGrindedCoffee = [...grindedCoffee];
    //     newGrindedCoffee[productIndex].images[imageIndex] = e.target.value;
    //     setGrindedCoffee(newGrindedCoffee);
    // };

    // const handleAddGrindedCoffeeImage = (productIndex) => {
    //     const newGrindedCoffee = [...grindedCoffee];
    //     newGrindedCoffee[productIndex].images.push('');
    //     setGrindedCoffee(newGrindedCoffee);
    // };

    // const handleSaveToDraft = () => {
    //     const draftData = {
    //         whatsapp,
    //         province,
    //         logo,
    //         company,
    //         youtube,
    //         academy,
    //         global,
    //         email,
    //         emails,
    //         profitProject,
    //         contact,
    //         phone,
    //         website,
    //         address,
    //         award,
    //         certificates,
    //         certificate,
    //         merchandise,
    //         merch,
    //         sustainable,
    //         brand,
    //         otherBrands,
    //         blog,
    //         notOnlyCoffee,
    //         others,
    //         year,
    //         history,
    //         newsletter,
    //         team,
    //         legal,
    //         gallery,
    //         rangeProducts,
    //         range,
    //     };
    //     setDraft(draftData);
    //     localStorage.setItem('draftData', JSON.stringify(draftData));
    //     alert('Draft saved!');
    // };

    // useEffect(() => {
    //     const savedDraft = localStorage.getItem('draftData');
    //     if (savedDraft) {
    //         const draftData = JSON.parse(savedDraft);
    //         setWhatsapp(draftData.whatsapp || manufacturerInfo?.whatsapp || '');
    //         setProvince(draftData.province || manufacturerInfo?.province || '');
    //         setLogo(draftData.logo || manufacturerInfo?.logo || '');
    //         setCompany(draftData.company || manufacturerInfo?.company || '');
    //         setYoutube(draftData.youtube || manufacturerInfo?.youtube_link || '');
    //         setAcademy(draftData.academy || manufacturerInfo?.academy || true);
    //         setGlobal(draftData.global || manufacturerInfo?.global || '');
    //         setEmail(draftData.email || manufacturerInfo?.email ||'');
    //         setEmails(draftData.emails ||  '');
    //         setProfitProject(draftData.profitProject ||  manufacturerInfo?.profit_project || '');
    //         setContact(draftData.contact || manufacturerInfo?.contact || '');
    //         setPhone(draftData.phone || manufacturerInfo?.contact || []);
    //         setWebsite(draftData.website || manufacturerInfo?.website || '');
    //         setAddress(draftData.address || manufacturerInfo?.address || '');
    //         setAward(draftData.award || manufacturerInfo?.award_winners || '');
    //         setCertificates(draftData.certificates || '');
    //         setCertificate(draftData.certificate || '');
    //         setMerchandise(draftData.merchandise || []);
    //         setMerch(draftData.merch || manufacturerInfo?.merchandise || '');
    //         setSustainable(draftData.sustainable || manufacturerInfo?.sustainability || true);
    //         setBrand(draftData.brand || '');
    //         setOtherBrands(draftData.otherBrands ||  manufacturerInfo?.other_brands || []);
    //         setBlog(draftData.blog || manufacturerInfo?.blog || true);
    //         setNotOnlyCoffee(draftData.notOnlyCoffee ||  manufacturerInfo?.not_only_coffee || []);
    //         setOthers(draftData.others || '');
    //         setYear(draftData.year || manufacturerInfo?.year || '');
    //         setHistory(draftData.history || manufacturerInfo?.history || '');
    //         setNewsletter(draftData.newsletter || '');
    //         setTeam(draftData.team || '');
    //         setLegal(draftData.legal || '');
    //         setGallery(draftData.gallery || []);
    //         setRangeProducts(draftData.rangeProducts || manufacturerInfo?.range_products || []);
    //         setRange(draftData.range || '');
    //     }
    // }, []);
    


    // const handleGallery = () => {
    //     setGallery([...gallery, pic])
    //     setPic('')
    // };

    // const handleRangeProducts = () => {
    //     setRangeProducts([...rangeProducts, range])
    //     setRange('')
    // };

    // const handleEmails = () => {
    //     setEmails([...emails, email])
    //     setEmail('')
    // };

    // const handleContact = () => {
    //     setPhone([...phone, contact])
    //     setContact('')
    // };

    // const handleCertificate = () => {
    //     setCertificates([...certificates, certificate])
    //     setCertificate('')
    // };


    // const handleBrand = () => {
    //     setOtherBrands([...otherBrands, brand])
    //     setBrand('')
    // };

    // const handleMerch = () => {
    //     setMerchandise([...merchandise, merch])
    //     setMerch('')
    // };

    // const handleNof = () => {
    //     setNotOnlyCoffee([...notOnlyCoffee, others])
    //     setOthers('')
    // };

    // const handleRate = (event) => {
    //     const value = event.target.value
    //     if (value < 0) {
    //         setRate(0)
    //     } else if (value > 5) {
    //         setRate(5)
    //     } else {
    //         setRate(value)
    //     }
    // }

    // const handleAddSocial = () => {
    //     const newSocials = [...social, { type, link }];
    //     setSocial(newSocials);
    //     setType('');
    //     setLink('');
    // };

   

    const submitForm = async () => {
        setUpdateDataLoading(true)
        try {
            const docRef = doc(db, 'manufacturer', formData.id);
            await updateDoc(docRef, formData)
            setUpdateDataLoading(false)
            toast(`Manufacturer Updated Successfully`, { 
                position: "top-right",
                autoClose: 3500, 
                closeOnClick: true,
            });
            history.push('/view-manufacturer'); // Redirect after success
          } catch (error) {
            console.error('Error updating document: ', error);
            setUpdateDataLoading(false)
          }

      
    }


  return (
    <div className='flex flex-col w-full min-h-screen py-20 gap-10'>
        <div className='flex items-center cursor-pointer px-5 gap-2' onClick={() => navigate(-1)}>
            <MdKeyboardBackspace className="text-secondary"/>
            <p className='text-sm text-secondary'>Back</p>
        </div>
        <div className='items-center flex flex-col gap-10'>
            <h1 className='text-3xl font-bold'>View Manufacturer</h1>
            <form className='flex flex-col gap-[21.5px] w-[1000px]'>

                <div className='flex gap-3 items-start'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-1 -top-[20px] text-grey-100'>Manufacturer Type</label>
                        <div className='relative'>
                            <div
                                disabled
                                // value={formData?.manufacturerType[0]?.typeName}
                                className='placeholder:text-grey-50 font-normal  bg-[#fff] w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none appearance-none'
                            >
                          
                                {formData?.manufacturerType?.map((option, index) => (
                                    <div key={index} value={option.typeName} className='flex items-center justify-between'>
                                        <p>{option.typeName}</p>
                                        <img src={option.typeImage} alt="Type Image" className='w-6 h-6' />
                                    </div>
                                ))}
                            </div>
                            {/* <div className='absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none'>
                                {formData?.manufacturerType?.typeImage && (
                                    <img src={formData?.manufacturerTypes[0]?.typeImage} alt="Image" className="w-6 h-6" />
                                )}
                            </div> */}
                        </div>
                    </div>
                     <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Logo</label>
                        <img src={formData.logo} alt='Logo' className=' w-full text-sm h-auto pb-1 border-grey-200 border rounded-lg '/>
                    </div>
                </div>

                <div className='flex gap-3 items-start'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Province</label>
                        <input value={formData.province}  disabled className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Manufacturer Country</label>
                        <input type="text" value={formData?.manufacturerCountry} disabled className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Company Name</label>
                        <input value={formData.company} disabled className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Youtube Video Link</label>
                        <input value={formData.youtube_link} disabled className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
                <div className='flex gap-3 items-start'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Gallery</label>
                        <div className='flex flex-col gap-2'>
                            {formData.gallery.map((url, index) => (
                                <div key={index} className='w-full flex gap-3 items-center h-[50px]'>
                                    <input
                                        type="text"
                                        value={url}
                                        disabled
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                        onChange={(e) => handleArrayChange(e, index, "gallery")}
                                    />
                            
                                </div>
                            ))}

                        </div>

                        {/* <div className='w-full flex gap-3 items-center h-[50px]'>
                            <input value={pic} onChange={(e) => setPic(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                            <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleGallery}>Add</button>
                        </div> */}
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Company Rate</label>
                        <input value={formData.rate} disabled onChange={handleChange} type='number' className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
            
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Academy</label>
                        <select onChange={handleChange} value={formData.academy} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                            <option>Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>WorldWide Presence</label>
                        <input value={formData.global} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>No Profit Project</label>
                        <input value={formData.profit_project} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Email</label>
                        <div className='w-full flex gap-3 items-center h-[50px]'>
                            <input type='email' value={formData.email} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleEmails}>Add</button> */}
                        </div>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Contact Person</label>
                        <div className='w-full flex gap-3 items-center h-[50px]'>
                            <input value={formData.contact} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleContact}>Add</button> */}
                        </div>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Website</label>
                        <input value={formData.website} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Whatsapp Link</label>
                        <input value={formData.whatsapp} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Social links</label>
                        <div className='w-full flex gap-3 items-center h-[50px]'>
                            <input
                                type='text'
                                value={formData.type}
                                onChange={handleChange}
                                placeholder='facebook'
                                className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                            />
                            <input
                                type='text'
                                value={formData.socials}
                                onChange={handleChange}
                                disabled
                                placeholder='www.facebook.com'
                                className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                            />
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[30%] h-full flex justify-center text-white items-center' onClick={handleAddSocial}>Add</button> */}
                        </div>
                    </div>    
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Address</label>
                        <input value={formData.address} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Awards Winning Coffee</label>
                        <input value={formData.award_winners} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>    
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>International Certification</label>
                        {/* {formData.certificate.map((url, index) => (
                            <div key={index} className='w-full flex gap-3 items-center h-[50px]'>
                                <input
                                    type="text"
                                    value={url}
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    onChange={(e) => handleArrayChange(e, index, "certificate")}
                                />
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("certificate", "")}>Add</button>
                            </div>
                        ))} */}
                        <div className='w-full flex gap-3 items-center h-[50px]'>
                            <input value={formData.intl_certification} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleCertificate}>Add</button> */}
                        </div>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Merchandising</label>
                        {/* {formData.merchandise.map((url, index) => (
                            <div key={index} className='w-full flex gap-3 items-center h-[50px]'>
                                <input
                                    type="text"
                                    value={url}
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    onChange={(e) => handleArrayChange(e, index, "merchandise")}
                                />
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("merchandise", "")}>Add</button>
                            </div>
                        ))} */}
                        <div className='w-full flex gap-3 items-center h-[50px]'>
                            <input value={formData.merchandise} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleMerch}>Add</button> */}
                        </div>
                    </div>    
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Sustainability</label>
                        <select value={formData.sustainability} onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                            <option>Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Others Brand</label>
                            {/* {formData.other_brands.map((url, index) => (
                            <div key={index} className='w-full flex gap-3 items-center h-[50px]'>
                                <input
                                    type="text"
                                    value={url}
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    onChange={(e) => handleArrayChange(e, index, "other_brands")}
                                />
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("other_brands", "")}>Add</button>
                            </div>
                        ))} */}
                        
                        <div className='w-full flex gap-3 items-center h-[50px]'>
                            <input value={formData.other_brands} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleBrand}>Add</button> */}
                        </div>
                    </div>    
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Community & Blog</label>
                        <select value={formData.blog} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                            <option>Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Not only coffee</label>
                        {/* {formData.others.map((url, index) => (
                            <div key={index} className='w-full flex gap-3 items-center h-[50px]'>
                                <input
                                    type="text"
                                    value={url}
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    onChange={(e) => handleArrayChange(e, index, "others")}
                                />
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("others", "")}>Add</button>
                            </div>
                        ))} */}
                        <div className='w-full flex gap-3 items-center h-[50px]'> 
                            <input value={formData.others} onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleNof}>Add</button> */}
                        </div>
                    </div>    
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Year of Origin</label>
                        <input value={formData.year} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>History</label>
                        <input value={formData.history} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>    
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Shipping Details</label>
                        <input value={formData.shipping} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Delivery Information Link</label>
                        <input value={formData.delivery_link} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>    
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Privacy Policy Link</label>
                        <input value={formData.privacy} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Terms & Condition Link</label>
                        <input value={formData.terms} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>FAQ Link</label>
                        <input value={formData.faq} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Cookies Link</label>
                        <input value={formData.cookies} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Newsletter Link</label>
                        <input value={formData.newsletter} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Team Link</label>
                        <input value={formData.team} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Sales Online</label>
                        <select value={formData.sales_online} onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                            <option>Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>    
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Legal Notices</label>
                        <input value={formData?.legal} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div> 
                </div>
                <div className='flex gap-3 items-start'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Processing Method</label>
                        <input value={formData?.processing} disabled onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>  
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Range Products</label>
                        <div className='flex flex-col gap-2'>
                            {formData.range_products.map((url, index) => (
                                <div key={index} className='w-full flex gap-3 items-center h-[50px]'>
                                    <input
                                        type="text"
                                        value={url}
                                        disabled
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                        onChange={(e) => handleArrayChange(e, index, "range_products")}
                                    />
                                    {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("range_products", "")}>Add</button> */}
                                </div>
                            ))}
                        </div>
                        {/* <div className='w-full flex gap-3 items-center h-[50px]'> */}
                            {/* <input value={formData.range_products} onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/> */}
                            {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("gallery", "")}>Add</button> */}
                        {/* </div> */}
                    </div>  
                </div>
                <div className='flex gap-3 items-center'>
                    <div className='relative w-full'> {/* long */}
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Longitude</label>
                        <input value={formData.position[0]} disabled type='number' placeholder='0' onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                    <div className='relative w-full'> {/* lat */}
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Latitude</label>
                        <input value={formData.position[0]} disabled type='number' placeholder='0' onChange={handleChange} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    </div>
                </div>
            
                {formData["COFFEE BEANS"].map((product, index) => (
                    <div key={index} className='flex flex-col gap-5'>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Beans Name</label>
                                <input
                                    value={product.name}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index, "COFFEE BEANS", 'name')}
                                    placeholder='Name'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                <input
                                    type='number'
                                    value={product.price}
                                    onChange={(e) => handleNestedChange(e, index,  "COFFEE BEANS", 'price')}
                                    placeholder='Price'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                <input
                                    value={product.currency}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index,  "COFFEE BEANS", 'currency')}
                                    placeholder='Currency'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                <input
                                    type='number'
                                    value={product.rate}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index,  "COFFEE BEANS", 'rate')}
                                    placeholder='Rate'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                <input
                                    value={product.description}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index,  "COFFEE BEANS", 'description')}
                                    placeholder='Description'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                <input
                                    value={product.quantity}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index,  "COFFEE BEANS", 'quantity')}
                                    placeholder='kg'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 '>
                            {/* {product.image.map((image, imageIndex) => (   key={imageIndex} */}
                                {/* <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL </label>
                                    <input
                                        value={product.image}
                                        onChange={(e) => handleNestedChange(e, index, "COFFEE BEANS", 'image')} //imageIndex {imageIndex + 1}
                                        placeholder={`Image URL `} // ${image + 1}
                                        className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div> */}
                            {/*  ))} */}
                            {/* <button type='button' className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddCoffeeBeanImage(index)}>Add Image</button> */}
                        </div>
                        {/* <div className='w-full flex justify-end h-[50px] my-2'>
                            <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center'  onClick={() => handleAddItem("COFFEE BEANS", { name: "", price: "", rate: "", currency: "", images: [''] , description: "", quantity: "" })}>Add</button>
                        </div> */}
                    </div>
                ))}
                {formData["COMPATIBLE CAPSULES"].map((product, index) => (
                    <div key={index} className='flex flex-col gap-5'>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Compatible Capsules Name</label>
                                <input
                                    value={product.name}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index, "COMPATIBLE CAPSULES", 'name')}
                                    placeholder='Name'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                <input
                                    type='number'
                                    value={product.price}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index, "COMPATIBLE CAPSULES", 'price')}
                                    placeholder='Price'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                <input
                                    value={product.currency}
                                    onChange={(e) => handleNestedChange(e, index, "COMPATIBLE CAPSULES", 'currency')}
                                    placeholder='Currency'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                <input
                                    type='number'
                                    value={product.rate}
                                    onChange={(e) => handleNestedChange(e, index, "COMPATIBLE CAPSULES",  'rate')}
                                    placeholder='Rate'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center '>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                <input
                                    value={product.quantity}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index, "COMPATIBLE CAPSULES",  'quantity')}
                                    placeholder='kg'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                <input
                                    value={product.description}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index, "COMPATIBLE CAPSULES", 'description')}
                                    placeholder='description'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 '>
                            {/* {product.image.map((image, imageIndex) => (
                                <div key={imageIndex} className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                    <input
                                        value={image}
                                        onChange={(e) => handleNestedChange(e, index, "COMPATIBLE CAPSULES", imageIndex)}
                                        placeholder={`Image URL ${imageIndex + 1}`}
                                        className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            ))} */}
                            {/* <button type='button' className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddCompatibleCapsulesImage(index)}>Add Image</button> */}
                        </div>
                        {/* <div className='w-full flex justify-end h-[50px] my-2'>
                            <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("COMPATIBLE CAPSULES", { name: "", price: "", rate: "", currency: "", images: [''] , description: "", quantity: "" })}>Add</button>
                        </div> */}
                    </div>
                ))}
                {formData["ESE PODS"].map((product, index) => (
                    <div key={index} className='flex flex-col gap-5'>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>ESE PODS Name</label>
                                <input
                                    value={product.name}
                                    onChange={(e) => handleNestedChange(e, index, "ESE PODS", 'name')}
                                    placeholder='Name'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                <input
                                    type='number'
                                    value={product.price}
                                    disabled
                                    onChange={(e) => handleNestedChange(e, index, "ESE PODS", 'price')}
                                    placeholder='Price'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                <input
                                    value={product.currency}
                                    onChange={(e) => handleNestedChange(e, index, "ESE PODS", 'currency')}
                                    placeholder='Currency'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                <input
                                    type='number'
                                    value={product.rate}
                                    onChange={(e) => handleNestedChange(e, index, "ESE PODS", 'rate')}
                                    placeholder='Rate'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center '>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                <input
                                    value={product.quantity}
                                    onChange={(e) => handleNestedChange(e, index, "ESE PODS", 'quantity')}
                                    placeholder='kg'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                <input
                                    value={product.description}
                                    onChange={(e) => handleNestedChange(e, index, "ESE PODS", 'description')}
                                    placeholder='description'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 '>
                            {/* {product.images.map((image, imageIndex) => (
                                <div key={imageIndex} className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                    <input
                                        value={image}
                                        onChange={(e) => handleNestedChange(e, index, "ESE PODS", imageIndex)}
                                        placeholder={`Image URL ${imageIndex + 1}`}
                                        className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            ))} */}
                            {/* <button type='button' className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddEsePodsImage(index)}>Add Image</button> */}
                        </div>
                        {/* <div className='w-full flex justify-end h-[50px] my-2'>
                            <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("ESE PODS", { name: "", price: "", rate: "", currency: "", images: [''] , description: "", quantity: "" })}>Add</button>
                        </div> */}
                    </div>
                ))}
                {formData["GRINDED COFFEE"].map((product, index) => (
                    <div key={index} className='flex flex-col gap-5'>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Grinded Coffee Name</label>
                                <input
                                    value={product.name}
                                    onChange={(e) => handleNestedChange(e, index, "GRINDED COFFEE", 'name')}
                                    placeholder='Name'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                <input
                                    type='number'
                                    value={product.price}
                                    onChange={(e) => handleNestedChange(e, index, "GRINDED COFFEE", 'price')}
                                    placeholder='Price'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center'>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                <input
                                    value={product.currency}
                                    onChange={(e) => handleNestedChange(e, index, "GRINDED COFFEE", 'currency')}
                                    placeholder='Currency'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                <input
                                    type='number'
                                    value={product.rate}
                                    onChange={(e) => handleNestedChange(e, index, "GRINDED COFFEE", 'rate')}
                                    placeholder='Rate'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 items-center '>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                <input
                                    value={product.quantity}
                                    onChange={(e) => handleNestedChange(e, index, "GRINDED COFFEE", 'quantity')}
                                    placeholder='kg'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                            <div className='relative w-full'>
                                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                <input
                                    value={product.description}
                                    onChange={(e) => handleNestedChange(e, index, "GRINDED COFFEE", 'description')}
                                    placeholder='description'
                                    disabled
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-3 '>
                            {/* {product.images.map((image, imageIndex) => (
                                <div key={imageIndex} className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                    <input
                                        value={image}
                                        onChange={(e) => handleNestedChange(e, index, "GRINDED COFFEE", imageIndex)}
                                        placeholder={`Image URL ${imageIndex + 1}`}
                                        className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            ))} */}
                            {/* <button type='button' className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddGrindedCoffeeImage(index)}>Add Image</button> */}
                        </div>
                        {/* <div className='w-full flex justify-end h-[50px] my-2'>
                            <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleAddItem("GRINDED COFFEE", { name: "", price: "", rate: "", currency: "", images: [''] , description: "", quantity: "" })}>Add</button>
                        </div> */}
                    </div>
                ))}
            </form>
            <div className='flex items-center justify-end gap-5'>
                {/* <BasicButtons 
                    functions={handleSaveToDraft} 
                    width={'300px'} 
                    text={'Save to Draft'} 
                    font={'Nunito'}
                /> */}
                {/* <BasicButtons 
                    functions={submitForm} 
                    width={'300px'} 
                    text={updateDataLoading ? 'loading...' : 'Update'} 
                    font={'Nunito'}
                /> */}

            </div>
        </div>
    </div>
  )
}

export default ViewInfo