import React, { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { toast } from 'react-toastify';

const EditDelivery = ({ handleClose, editDeliveryLoading, setEditDeliveryLoading, deliveryData, editDeliveryId }) => {
    const [timeFrame, setTimeFrame] = useState(deliveryData?.timeFrame)
    const [price, setPrice] = useState(deliveryData?.price)
    const [name, setName] = useState(deliveryData?.name)

    console.log(deliveryData, 'deliveryData')
    console.log(editDeliveryId, 'editDeliveryId')

    const submitForm = async () => {
        // setEditDeliveryLoading(true);
        try {
            const docRef = doc(db, 'delivery', editDeliveryId); // Replace with your Firestore collection and document ID
    
            // Get the current document
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                const currentData = docSnapshot.data();
                const deliveryArray = currentData.delivery;

                // Find the specific delivery partner to update
                const updatedDeliveryArray = deliveryArray.map((item) => {
                    console.log(item, "saske")
                    if (item.name === deliveryData.name) {
                        return {
                            ...item,
                            name:name, 
                            timeFrame:timeFrame,
                            price:price 
                        } // Update the Delivery Partner details
                    }  else {
                        return item
                    } 
                });
              
                // Update the document with the modified array
                await updateDoc(docRef, {
                    delivery: updatedDeliveryArray,
                });
                setEditDeliveryLoading(false);
                console.log('Document successfully updated');
                toast(`Delivery Partner Updated Successfully`, { 
                    position: "top-right",
                    autoClose: 3500,
                    closeOnClick: true,
                }); 
                handleClose();
            } else {
                console.log('No such document!');
                setEditDeliveryLoading(false);
                toast(`Delivery Partner Does Not Exist`, { 
                    position: "top-right",
                    autoClose: 3500,
                    closeOnClick: true,
                }); 
                handleClose();
            }
        } catch (error) {
            console.error('Error updating document: ', error);
            toast(`Error Updating Delivery Partner`, { 
                position: "top-right",
                autoClose: 3500,
                closeOnClick: true,
            }); 
            handleClose();
        } finally {
            setEditDeliveryLoading(false);
        }
    };

  return (
    <div className='bg-[#efefef] w-full lg:w-[500px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
        <div className='flex flex-col gap-10'>
        <h1 className='text-3xl text-center font-bold'>Edit Delivery</h1>
        <div className='flex'>
            <div className='relative w-full'>
                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Name</label>
                <input 
                    type="text"
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    className='placeholder:text-grey-50 font-normal w-full capitalize text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                />
            </div>
            <div className='relative w-full'>
                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Time Frame</label>
                <input 
                    type="text" 
                    value={timeFrame} 
                    onChange={(e) => setTimeFrame(e.target.value)} 
                    className='placeholder:text-grey-50 font-normal  w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                />
            </div>
            <div className='relative w-full'>
                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                <input 
                    type="number" 
                    value={price} 
                    onChange={(e) => setPrice(e.target.value)} 
                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                />
            </div>
        </div>
        <div className='flex justify-between'>
            <button 
                type='button' 
                className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
                onClick={submitForm}
            >
                {editDeliveryLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Update" }
            </button>
            <button 
                type='button' 
                className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
                onClick={handleClose}
            >
                Cancel
            </button>

        </div>
        </div>
    </div>
  )
}

export default EditDelivery