import React, { Fragment, useContext } from 'react'
import CoffeeNav from './CoffeeNav'
import CoffeeBox from './CoffeeBox'
import { CoffeeContext } from '../../context/CoffeeContext';

export default function CoffeeList({setOpen, open}) {
    const { coffee, active, setActive, loading} = useContext(CoffeeContext)

    console.log(coffee, "coffee")

     // Filter coffee based on active letter
     const filteredCoffee = coffee.filter(c => c.company.startsWith(active));

    return (
        <div className='xl:w-[53%] w-full xl:pl-[4%] xl:pr-[19px] h-full flex flex-col'>
            <CoffeeNav setOpen={setOpen} open={open} active={active} setActive={setActive}/>
            <div className='md:mt-[100px] mt-[160px] pt-3 xl:px-2 px-6 pb-20 w-full grid xl:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-5 flex-1 overflow-scroll'>
                {/* {loading ? <p>loading...</p>:
                filteredCoffee?.map((data, index) => (
                    <Fragment key={index}>
                        <CoffeeBox data={data}/>
                    </Fragment>
                ))} */}
                {loading ? (
                    <p>Loading...</p>
                ) : filteredCoffee?.length > 0 ? (
                    filteredCoffee?.map((data, index) => (
                        <Fragment key={index}>
                            <CoffeeBox data={data} />
                        </Fragment>
                    ))
                ) : (
                    <p>No data available</p>
                )}
            </div>
        </div>
    )
}
