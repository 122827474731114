import React, { useState, useContext, Fragment } from 'react'
import HomeLayout from '../layout/HomeLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import Rate from '../components/Rate'
import BasicButtons from '../components/Button'
import ReviewBox from '../components/ReviewBox'
import { UserContext } from '../context/UserContext'
import { useSnackbar } from 'notistack'
import { handleReview } from '../utils/helperFuncs'

export default function Review() {
    const { user } = useContext(UserContext)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const location = useLocation()
    const { state } = location
    const [comment, setComment] = useState('')
    const uid = user?.uid;
    const displayName = user?.displayName
    const docId = state?.id
    const [rate, setRate] = useState(0)
    const [write, setWrite] = useState('write')

    const handleSubmit = async () => {
        if (!comment) {
            enqueueSnackbar(`Please put in a review!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return;
        }
        if (!uid) {
            enqueueSnackbar(`Please sign in for your reviews to be saved!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return;
        }
        await handleReview(docId, uid, displayName, comment, rate)
        setComment('')
        enqueueSnackbar(`Review added successfully!`, {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            style: { 
                backgroundColor: '#503225'
            },
        });
        setTimeout(() => {
            navigate('/')
        }, 1000)
    }

    return (
        <HomeLayout>
            <div className='z-10 fixed w-full bg-white border border-grey-200 xl:px-[4%] px-5 h-[71px] flex gap-5 items-center'>
                <div onClick={() => navigate(-1)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.9403 6.99998H16.1123V8.99998H3.9403L9.30431 14.364L7.8903 15.778L0.112305 7.99998L7.8903 0.221985L9.30431 1.63598L3.9403 6.99998Z" fill="#7E3701"/>
                    </svg>
                </div>
                <div className='flex h-full justify-between items-center flex-1'>
                    <h1 onClick={() => setWrite('write')} className={`h-full flex items-center cursor-pointer nunito-bold ${write === 'write' ? 'text-primary border-b-[2px] border-primary' : 'text-[#646464]'}`}>Write Reviews </h1>
                    <h1 onClick={() => setWrite('read')} className={`h-full flex items-center cursor-pointer nunito-bold ${write === 'read' ? 'text-primary border-b-[2px] border-primary' : 'text-[#646464]'}`}>Recent Reviews </h1>
                </div>
            </div>
            <div className='xl:px-[4%] px-5 pt-[95px] pb-6 w-full'>
                {write === 'write' && (
                    <div className='flex flex-col gap-[26px]'>
                        <div className='border border-grey-200 w-full h-[441px] rounded-lg py-[26px] px-4 flex flex-col gap-[39px]'>
                            <div className='flex gap-[17px] items-center'>
                                <div className='flex items-center gap-[6px]'>
                                   <Rate setRate={setRate} rate={rate} count={1}/>
                                   <Rate setRate={setRate} rate={rate} count={2}/>
                                   <Rate setRate={setRate} rate={rate} count={3}/>
                                   <Rate setRate={setRate} rate={rate} count={4}/>
                                   <Rate setRate={setRate} rate={rate} count={5}/>
                                </div>
                                <p className='text-grey-50 text-sm'>Select your rating</p>
                            </div>
                            <textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className='flex-1 w-full bg-transparent focus:outline-none placeholder:text-grey-100 text-grey-100'
                                placeholder='Type your review here...'
                            />
                        </div>
                        <div className='flex w-full justify-center'>
                            <BasicButtons functions={() => handleSubmit()} text={'Post Review'} width={'370px'}/>
                        </div>
                    </div>
                )}
                {write === 'read' && (
                    <div className='flex flex-col'>
                        {state?.reviews?.map((d, index) => (
                            <Fragment key={index}>
                                <div className='w-full px-[10px] mb-[38.71px]'>
                                    <ReviewBox font={true} data={d}/>
                                </div>
                                <div className='w-full h-[1px] bg-grey-200 mb-[25.5px]'></div>
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>
        </HomeLayout>
    )
}
