import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      const userRole = localStorage.getItem('userRole');

      if (isLoggedIn && (userRole === 'admin' || userRole === 'superadmin')) {
        setIsAdmin(true);
      } else {
        navigate('/login');
      }
      setLoading(false);
    };

    checkAuth();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading spinner or message
  }

  return isAdmin ? children : null;
};

export default AdminRoute;
