import React from 'react'
import Company from '../assets/img/image 64.png'
import Company2 from '../assets/img/image 65.png'
import Company3 from '../assets/img/image 66.png'
import Company4 from '../assets/img/image 67.png'
import Company5 from '../assets/img/image 68.png'
import Company6 from '../assets/img/image 69.png'
import Company7 from '../assets/img/image 70.png'
import Company8 from '../assets/img/image 71.png'
import Company9 from '../assets/img/image 72.png'
import Socials from './Socials'

export default function Contact({data}) {
    return (
        <div className='w-full bg-grey-250 pt-[30px] pb-[68.39px] xl:px-[4%] px-5 flex flex-col xl:gap-[102px] gap-5'>
            <div className='w-full xl:justify-between flex xl:flex-row gap-[19px] xl:gap-0 flex-col'>
                <div className='xl:block hidden'>
                    <Socials data={data}/>
                </div>
                <div className='flex flex-col gap-[9px] text-[18px] nunito-bold'>
                    <h3>SHOP SERVICE</h3>
                    {data.delivery_link && <p onClick={() => window.location.href = data?.delivery_link} className='cursor-pointer'>Delivery information</p>}
                    <p className='cursor-pointer'>Returns</p>
                    {data.faq && <p onClick={() => window.location.href = data?.faq} className='cursor-pointer'>Frequently Asked Questions (FAQs)</p>}
                    <p className='cursor-pointer'>Product advice</p>
                    <p className='cursor-pointer'>Freshness Guaranteed</p>
                    <p className='cursor-pointer'>Contact</p>
                </div>
                <div className='flex flex-col gap-[9px] text-[18px] nunito-bold'>
                    <h3>INFORMATION</h3>
                    {data.terms_condition === "NOT AVAILABLE" || data.terms_condition === "" ? null  : <p onClick={() =>  window.open(data?.terms_condition, "_blank")} className='cursor-pointer'>Terms & Conditions</p>}
                    {data.team === "NOT AVAILABLE" || data.team === "" ? null  :  <p className='cursor-pointer'>Our Team</p>}
                    <p className='cursor-pointer'>Our philosophy</p>
                    <p className='cursor-pointer'>Newsletter subscription</p>
                    <p className='cursor-pointer'>Unsubscribe from </p>
                    {data.newsletter === "NOT AVAILABLE" || data.newsletter === "" ? null  : <p className='cursor-pointer'>newsletter</p>}
                </div>
                <div className='flex flex-col gap-[9px] text-[18px] nunito-bold'>
                    <h3>LEGALLY</h3>
                    {data.terms_condition === "NOT AVAILABLE" || data.terms_condition === "" ? null  : <p onClick={() =>  window.open(data?.terms_condition, "_blank")} className='cursor-pointer'>Terms & Conditions</p>}
                    {data.cookies === "NOT AVAILABLE" || data.cookies === "" ? null : <p onClick={() => window.location.href = data?.cookies} className='cursor-pointer'>Cookie settings</p>}
                    {data.legal === "NOT AVAILABLE" || data.legal === "" ? null : <p className='cursor-pointer'>Legal notices</p>}
                    {data.privacy_policy === "NOT AVAILABLE" || data.privacy_policy === "" ? null : <p onClick={() => window.location.href = data?.privacy_policy} className='cursor-pointer'>Privacy Policy</p>}
                    <p className='cursor-pointer'>Withdrawal</p>
                </div>
                <div className='xl:hidden block'>
                    <Socials />
                </div>
            </div>
            <div className='flex md:flex-row flex-col gap-[24.7px] md:gap-0 md:w-[594px]'>
                <div className='flex flex-col gap-[9.7px] md:w-[70%]'>
                    <h3 className='text-[18px] nunito-bold'>PAYMENTS</h3>
                    <div className='flex flex-wrap md:gap-4 gap-[10px]'>
                        <img src={Company} alt='company' className='w-[78px] h-[39px]' />
                        <img src={Company2} alt='company' className='w-[78px] h-[39px]' />
                        <img src={Company3} alt='company' className='w-[78px] h-[39px]' />
                        <img src={Company4} alt='company' className='w-[78px] h-[39px]' />
                        <img src={Company5} alt='company' className='w-[78px] h-[39px]' />
                        <img src={Company6} alt='company' className='w-[78px] h-[39px]' />
                        <img src={Company7} alt='company' className='w-[78px] h-[39px]' />
                    </div>
                </div>
                <div className='flex flex-col gap-[9.7px] flex-1'>
                    <h3 className='text-[18px] nunito-bold'>SHIPPING</h3>
                    <div className='w-full flex flex-wrap md:gap-4 gap-[10px]'>
                        <img src={Company8} alt='company' className='w-[78px] h-[39px]' />
                        <img src={Company9} alt='company' className='w-[78px] h-[39px]' />
                    </div>
                </div>
            </div>
        </div>
    )
}
