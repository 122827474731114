import React from 'react'
import { toast } from 'react-toastify'
import { db } from '../firebase-config'
import { deleteDoc, doc } from 'firebase/firestore'
import { CgSpinner } from 'react-icons/cg'

const DeleteTag = ({ handleClose, deleteEmailId, deleteEmailLoading, setDeleteEmailLoading}) => {
    console.log(deleteEmailId, "deleteItemId")

    const deleteItem = () => {
        setDeleteEmailLoading(true)
        const docRef = doc(db, "subscribers", deleteEmailId)
        deleteDoc(docRef)
        .then(() => {
            setDeleteEmailLoading(false)
            toast(`Email Deleted Successfully`, { 
                position: "top-right",
                autoClose: 3500,
                closeOnClick: true,
            });
            handleClose()
        })
        .catch((err) => {
            setDeleteEmailLoading(false)
            console.log(err,  "mystic")
        })
    }

  return (
    <div className='bg-[#fff] w-full lg:w-[400px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
         <div className='flex flex-col justify-center items-center gap-6'>
            <p className='font-bold text-[24px] '>Delete Email 🗑 </p>
        
            <div className='bg-[#EDF2F780] px-4 py-2.5 w-[378px] h-[68px] rounded flex items-center gap-3'>
                <p className='font-Mont text-sm text-[#5C6F7F]'>
                    When you click Yes, Delete,  this Email will be Deleted
                </p>
            </div>
            <div className='flex items-center gap-[18px]'>
                <button
                    type='button'
                    className='w-[160px] h-[48px] bg-[#fff] border border-[#5C6F7F] rounded'
                    onClick={handleClose}
                >
                    <p className='font-medium text-base'>Cancel</p>
                </button>
                <button
                    type='button'
                    className='w-[160px] h-[48px] bg-[#f00] text-center flex items-center justify-center rounded'
                    onClick={() => deleteItem()}
                >
                    <p className='text-[#fff] font-medium text-base'>{deleteEmailLoading ? <CgSpinner className='animate-spin text-lg'/> : " Yes, Delete"}</p>
                </button>
            </div>
        </div>

    </div>
  )
}

export default DeleteTag