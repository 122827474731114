// import React, { useState } from 'react'
// import { CgSpinner } from 'react-icons/cg'
// import { doc, getDoc, addDoc, updateDoc, arrayUnion, collection, getDocs, where, query } from 'firebase/firestore'
// import { toast } from 'react-toastify' 
// import { db } from '../firebase-config'
// import { currencyOptions } from '../utils/currencyOptions'

// const AddRates = ({ handleClose, manufacturerData, setAddCurrencyLoading }) => {
//     const [currencyLoading, setCurrencyLoading] = useState(false)
//     const [symbol, setSymbol] = useState("")
//     const [rate, setRate] = useState(0)
//     const [name, setName] = useState("")

//     const createCurrencies = async () => {
//         if (!name || !symbol || !rate) {
//             toast.error("Please fill all fields")
//             return
//         }

//         setCurrencyLoading(true)
//         setAddCurrencyLoading(true)
//         try {
//             const currencyCollection = collection(db, "currency");
//             const q = query(currencyCollection, where("company", "==", manufacturerData?.company));
//             const querySnapshot = await getDocs(q);

//             if (querySnapshot.empty) {
//                 // Create new document if it doesn't exist
//                 const data = {
//                     company: manufacturerData?.company,
//                     currency: [{ name, symbol, rate: parseFloat(rate) }] 
//                 };
//                 const docRef = await addDoc(currencyCollection, data);
//                 console.log("New document created with ID: ", docRef.id);
//                 toast.success("Currency added successfully");
//                 setCurrencyLoading(false)
//                 setAddCurrencyLoading(false)
//                 handleClose()
//             } else {
//                 // Update existing document
//                 const doc = querySnapshot.docs[0];
//                 const existingCurrencies = doc.data().currency || [];
//                 const currencyExists = existingCurrencies.some(c => c.name === name || c.symbol === symbol);

//                 if (currencyExists) {
//                     console.log("Currency already exists for this company");
//                     toast.error("Currency already exists for this company");
//                 } else {
//                     await updateDoc(doc.ref, {
//                         currency: [...existingCurrencies, { name: name.toUpperCase(), symbol:symbol , rate: parseFloat(rate) }]
//                     });
//                     console.log("Document updated with ID: ", doc.id);
//                     toast.success("Currency added successfully");
//                 }
//             }

//             handleClose();
//         } catch (error) {
//             console.error("Error adding currency:", error);
//             toast.error("Failed to add currency");
//         } finally {
//             setCurrencyLoading(false);
//             setAddCurrencyLoading(false)
//         }
//     }

//   return (
//     <div className='bg-[#efefef] w-full lg:w-[500px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
//          <div className='flex flex-col gap-10'>
//             <h1 className='text-3xl text-center font-bold'>Add Currency</h1>
//             <div className='flex'>
//                 <div className='relative w-full'>
//                     <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Name</label>
//                     <input 
//                         type="text"
//                         value={name} 
//                         onChange={(e) => setName(e.target.value)} 
//                         className='placeholder:text-grey-50 font-normal w-full capitalize text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
//                     />
//                 </div>
//                 <div className='relative w-full'>
//                     <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Symbol</label>
//                     <input 
//                         type="text" 
//                         value={symbol} 
//                         onChange={(e) => setSymbol(e.target.value)} 
//                         className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
//                     />
//                 </div>
//                 <div className='relative w-full'>
//                     <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
//                     <input 
//                         type="number" 
//                         value={rate} 
//                         onChange={(e) => setRate(e.target.value)} 
//                         className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
//                     />
//                 </div>
//             </div>
//             <div className='flex justify-between'>
//                 <button 
//                     type='button' 
//                     className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
//                     onClick={createCurrencies}
//                 >
//                     {currencyLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
//                 </button>
//                 <button 
//                     type='button' 
//                     className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
//                     onClick={handleClose}
//                 >
//                     Cancel
//                 </button>

//             </div>
//         </div>
//     </div>
//   )
// }

// export default AddRates


import React, { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { doc, getDoc, addDoc, updateDoc, arrayUnion, collection, getDocs, where, query } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from '../firebase-config'
import { currencyOptions } from '../utils/currencyOptions'

const AddRates = ({ handleClose, manufacturerData, setAddCurrencyLoading }) => {
    const [currencyLoading, setCurrencyLoading] = useState(false)
    const [symbol, setSymbol] = useState("")
    const [rate, setRate] = useState(0)
    const [name, setName] = useState("")

    const handleCurrencyChange = (e) => {
        const selectedCurrency = e.target.value
        const currency = currencyOptions.find(cur => cur.name === selectedCurrency)
        setName(selectedCurrency)
        setSymbol(currency?.symbol || "")
    }

    const createCurrencies = async () => {
        if (!name || !symbol || !rate) {
            toast.error("Please fill all fields")
            return
        }

        setCurrencyLoading(true)
        setAddCurrencyLoading(true)
        try {
            const currencyCollection = collection(db, "currency");
            const q = query(currencyCollection, where("company", "==", manufacturerData?.company));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                // Create new document if it doesn't exist
                const data = {
                    company: manufacturerData?.company,
                    currency: [{ name, symbol, rate: parseFloat(rate) }] 
                };
                const docRef = await addDoc(currencyCollection, data);
                console.log("New document created with ID: ", docRef.id);
                toast.success("Currency added successfully");
                setCurrencyLoading(false)
                setAddCurrencyLoading(false)
                handleClose()
            } else {
                // Update existing document
                const doc = querySnapshot.docs[0];
                const existingCurrencies = doc.data().currency || [];
                const currencyExists = existingCurrencies.some(c => c.name === name || c.symbol === symbol);

                if (currencyExists) {
                    console.log("Currency already exists for this company");
                    toast.error("Currency already exists for this company");
                } else {
                    await updateDoc(doc.ref, {
                        currency: [...existingCurrencies, { name: name.toUpperCase(), symbol:symbol , rate: parseFloat(rate) }]
                    });
                    console.log("Document updated with ID: ", doc.id);
                    toast.success("Currency added successfully");
                }
            }

            handleClose();
        } catch (error) {
            console.error("Error adding currency:", error);
            toast.error("Failed to add currency");
        } finally {
            setCurrencyLoading(false);
            setAddCurrencyLoading(false)
        }
    }

    return (
        <div className='bg-[#efefef] w-full lg:w-[500px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
            <div className='flex flex-col gap-10'>
                <h1 className='text-3xl text-center font-bold'>Add Currency</h1>
                <div className='flex'>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Name</label>
                        <select 
                            value={name} 
                            onChange={handleCurrencyChange} 
                            className='placeholder:text-grey-50 font-normal w-full capitalize text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                        >
                            <option value="">Select Currency</option>
                            {currencyOptions.map((currency) => (
                                <option key={currency.name} value={currency.name}>
                                    {currency.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Symbol</label>
                        <input 
                            type="text" 
                            value={symbol} 
                            readOnly 
                            className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                        />
                    </div>
                    <div className='relative w-full'>
                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                        <input 
                            type="number" 
                            value={rate} 
                            onChange={(e) => setRate(e.target.value)} 
                            className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                        />
                    </div>
                </div>
                <div className='flex justify-between'>
                    <button 
                        type='button' 
                        className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
                        onClick={createCurrencies}
                    >
                        {currencyLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
                    </button>
                    <button 
                        type='button' 
                        className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddRates
