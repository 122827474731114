import React, { createContext, useState, useEffect } from 'react'
import { db } from '../firebase-config'
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';

export const CoffeeContext = createContext()

export default function CoffeeProvider({children}) {
    const [loading, setLoading] = useState(false)
    const [coffee, setCoffee] = useState([])
    const [mapFilter, setMapFilter] = useState([])
    const [active, setActive] = useState('')
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        if (coffee.length === 0) {
            fetchList()
        }
    }, [coffee])

    useEffect(() => {
        fetchList()
    }, [active])
    
    const fetchList = () => {
        setLoading(true)
        const q = query(collection(db, 'manufacturer'), orderBy('company'));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const coffeeData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            // Filter coffeeData based on the active state
            const filteredCoffeeData = active ? coffeeData.filter(coffee => coffee.company.toLowerCase().includes(active.toLowerCase())) : coffeeData;
            setCoffee(filteredCoffeeData);
            setLoading(false)
        });
    
        // Return a cleanup function that unsubscribes from both listeners
        return () => {
          unsubscribe();
        };
    };

    return (
        <CoffeeContext.Provider value={{coffee, setCoffee, loading, fetchList, setActive, active, showList, setShowList, mapFilter, setMapFilter}}>
            {children}
        </CoffeeContext.Provider>
    )
}