import React, { useState } from 'react'
import BasicButtons from '../Button'
import Share from '../Share'
import Modals from '../Modals'
import WriteReview from '../WriteReview'
import { useNavigate } from 'react-router-dom'
import { printPage, savePage, bookmarkPage } from '../../utils/helperFuncs'

export default function CoffeeMenu({data}) {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    return (
        <div className='flex gap-5 items-center'>
            <Share data={data} />
            <div onClick={bookmarkPage} className='border border-secondary h-[42px] rounded-lg pl-5 pr-[19px] cursor-pointer flex gap-[10px] items-center'>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.73804 16V10H14.738V16H16.738V4.828L13.91 2H2.73804V16H4.73804ZM1.73804 0H14.738L18.738 4V17C18.738 17.2652 18.6327 17.5196 18.4451 17.7071C18.2576 17.8946 18.0033 18 17.738 18H1.73804C1.47282 18 1.21847 17.8946 1.03093 17.7071C0.843394 17.5196 0.738037 17.2652 0.738037 17V1C0.738037 0.734784 0.843394 0.48043 1.03093 0.292893C1.21847 0.105357 1.47282 0 1.73804 0ZM6.73804 12V16H12.738V12H6.73804Z" fill="#503225"/>
                </svg>
                <p className='font-bold xl:block hidden'>Save</p>
            </div>
            <div onClick={printPage} className='border border-secondary h-[42px] rounded-lg pl-5 pr-[19px] cursor-pointer flex gap-[10px] items-center'>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.738 0C16.0033 0 16.2576 0.105357 16.4451 0.292893C16.6327 0.48043 16.738 0.734784 16.738 1V5H19.738C20.0033 5 20.2576 5.10536 20.4451 5.29289C20.6327 5.48043 20.738 5.73478 20.738 6V16C20.738 16.2652 20.6327 16.5196 20.4451 16.7071C20.2576 16.8946 20.0033 17 19.738 17H16.738V19C16.738 19.2652 16.6327 19.5196 16.4451 19.7071C16.2576 19.8946 16.0033 20 15.738 20H5.73804C5.47282 20 5.21847 19.8946 5.03093 19.7071C4.84339 19.5196 4.73804 19.2652 4.73804 19V17H1.73804C1.47282 17 1.21847 16.8946 1.03093 16.7071C0.843394 16.5196 0.738037 16.2652 0.738037 16V6C0.738037 5.73478 0.843394 5.48043 1.03093 5.29289C1.21847 5.10536 1.47282 5 1.73804 5H4.73804V1C4.73804 0.734784 4.84339 0.48043 5.03093 0.292893C5.21847 0.105357 5.47282 0 5.73804 0H15.738ZM14.738 15H6.73804V18H14.738V15ZM18.738 7H2.73804V15H4.73804V14C4.73804 13.7348 4.84339 13.4804 5.03093 13.2929C5.21847 13.1054 5.47282 13 5.73804 13H15.738C16.0033 13 16.2576 13.1054 16.4451 13.2929C16.6327 13.4804 16.738 13.7348 16.738 14V15H18.738V7ZM6.73804 8V10H3.73804V8H6.73804ZM14.738 2H6.73804V5H14.738V2Z" fill="#503225"/>
                </svg>
                <p className='font-bold xl:block hidden'>Print</p>
            </div>
            <div onClick={() => setOpen(!open)} className='hidden border border-secondary h-[42px] rounded-lg pl-5 pr-[19px] cursor-pointer xl:flex gap-[10px] items-center'>
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.248 14.955L4.37104 18.545L5.97104 11.845L0.738037 7.36502L7.60404 6.81502L10.248 0.455017L12.893 6.81402L19.759 7.36402L14.528 11.845L16.128 18.545L10.248 14.955ZM10.248 12.611L13.065 14.331L12.299 11.121L14.806 8.97402L11.516 8.71002L10.248 5.66302L8.98104 8.71002L5.69104 8.97402L8.19804 11.121L7.43204 14.331L10.248 12.611Z" fill="#503225"/>
                </svg>
                <p className='font-bold xl:block hidden'>Write a Review</p>
            </div>
            <div onClick={() => navigate('/review', { state: data })} className='xl:hidden border border-secondary h-[42px] rounded-lg pl-5 pr-[19px] cursor-pointer flex gap-[10px] items-center'>
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.248 14.955L4.37104 18.545L5.97104 11.845L0.738037 7.36502L7.60404 6.81502L10.248 0.455017L12.893 6.81402L19.759 7.36402L14.528 11.845L16.128 18.545L10.248 14.955ZM10.248 12.611L13.065 14.331L12.299 11.121L14.806 8.97402L11.516 8.71002L10.248 5.66302L8.98104 8.71002L5.69104 8.97402L8.19804 11.121L7.43204 14.331L10.248 12.611Z" fill="#503225"/>
                </svg>
                <p className='font-bold xl:block hidden'>Write a Review</p>
            </div>
            <div className='xl:block hidden'>
                <BasicButtons text={'SHOP NOW'} width={'152px'} functions={() => navigate('/products', { state: data })}/>
            </div>
            <Modals open={open} setOpen={setOpen}>
                <WriteReview setOpen={setOpen} data={data}/>
            </Modals>
        </div>
    )
}
