import React from 'react'

export default function CartTable2({data, selectedCurrency, handleRemove, deliveryTimeFrame, handleIncrement, handleDecrement}) {
    return (
        <div className='w-full flex flex-col gap-[10px]'>
            {data?.map((data, index) => (
                <div key={index} className='shadow-[0_0_10px_0_#0000001A] bg-white rounded-lg p-5 flex justify-between gap-2'>
                    <img src={data?.product?.image} alt='product' className='w-[62px] h-[65px] object-contain rounded-full'/>
                    <div className='w-[87%] flex flex-col gap-[11.9px]'>
                        <div className='gap-5 flex items-center'>
                            <div className='flex flex-col gap-[1.1px]'>
                                <p className='text-[19px]'>{data?.product?.name}</p>
                                <p className='text-grey-50'>Standard Delivery
                                    <span className='text-secondary font-bold ml-1'>{deliveryTimeFrame || "1-7"} Workdays</span>
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-[13px] items-center'>
                            <div onClick={() => handleDecrement(index)} className='size-[29px] cursor-pointer rounded-full bg-[#DCD6D3] flex justify-center items-center'>
                                <p className='text-xl font-bold'>-</p>
                            </div>
                            <p className='font-bold text-[19px]'>{data?.quantity}</p>
                            <div onClick={() => handleIncrement(index)} className='size-[29px] cursor-pointer rounded-full bg-[#DCD6D3] flex justify-center items-center'>
                                <p className='text-xl font-bold'>+</p>
                            </div>
                        </div>
                        <div className='flex gap-[14px] items-center mt-[11px]'>
                            <p className='font-bold text-[19px]'>Price per unit:</p>
                            <p className='text-[19px]'>{selectedCurrency?.symbol ? selectedCurrency?.symbol : data?.currency} {selectedCurrency?.rate ? selectedCurrency?.rate * data?.product?.price : data?.product?.price }</p>
                        </div>
                        <div className='flex gap-[14px] items-center'>
                            <p className='font-bold text-[19px]'>Sum:</p>
                            <p className='text-[19px]'>{selectedCurrency?.symbol ? selectedCurrency?.symbol : data?.currency} {selectedCurrency?.rate ? selectedCurrency?.rate * data?.sum : data?.sum}</p>
                        </div>
                    </div>
                    <div className=''>
                        <div onClick={() => handleRemove(index)} className='w-full flex justify-center'>
                            <svg className='cursor-pointer' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5V0H15V2ZM7 7V15H9V7H7ZM11 7V15H13V7H11Z" fill="#E00707"/>
                            </svg>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
