import React, { useContext, useEffect, useState } from 'react'
import HomeLayout from '../layout/HomeLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import CartTable from '../components/CartTable'
import BasicButtons from '../components/Button'
import CartSelect from '../components/CartSelect'
import CartTable2 from '../components/CartTable2'
import { doc, getDoc, updateDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase-config'
import { UserContext } from '../context/UserContext'
import { CartsContext } from '../context/CartsContext'
import axios from 'axios'
import { countries } from '../utils/countries'

export default function Cart() {
    const navigate = useNavigate()
    const { cartData, setCartData, fetchCartItems, cartSearch } = useContext(CartsContext)
    const { user } = useContext(UserContext);
    const [sum, setSum] = useState(0)
    const [userAddress, setUserAddress] = useState("")
    const [userCity, setUserCity] = useState("")
    const [userPostalCode, setUserPostalCode] = useState("")
    const [selectedPaymentOption, setSelectedPaymentOption] = useState("Select"); // State for selected payment option
    const [selectedCountryOption, setSelectedCountryOption] = useState(""); 
    const [selectedShippingMethodOption, setSelectedShippingMethodOption] = useState("Select")
    const [shippingAmount, setShippingAmount] = useState(0)
    const currency = cartData && (cartData?.length > 0) ? cartData[0].currency : '€'

    console.log(selectedShippingMethodOption, "selectedShippingMethodOption")

    //Countries 
    const [countriesData, setCountriesData] = useState([])
    const [companyCountriesData, setCompanyCountriesData] = useState([]) 

    //Payment
    const [paymentsData, setPaymentsData] = useState([])
    const [companyPaymentsData, setCompanyPaymentsData] = useState([]) 

    //Delivery
    const [deliveriesData, setDeliveriesData] = useState([])
    const [companyDeliveriesData, setCompanyDeliveriesData] = useState([]) 
    const [deliveryTimeFrame, setDeliveryTimeFrame] = useState()

    const location = useLocation()
    const {selectedCurrency, manufacturerData} = location.state || {}

    const formatter = new Intl.NumberFormat('en-US');
 
    console.log(selectedCurrency, "selectedCurrency")
    console.log(selectedCountryOption, "selectedCountryOption")

    const shippingMethod = [
        {name: 'DHL Delivery', price: "£10.24"},
        {name: 'DHL Express', price: "£6.90" }
    ]

    useEffect(() => {
        if (user) {
            fetchCartItems(user);
        }
    }, [user, cartSearch]);

    useEffect(() => {
        const totalSum = cartData.reduce((total, item) => total + parseFloat(item.sum), 0);
        setSum(totalSum);
    }, [cartData]);

    const apiUrl = process.env.REACT_APP_BASE_URL;
    console.log(apiUrl, "apiUrl")
    
    const removeCartItem = async (index) => {
        const userId = user.uid;
        const userCartRef = doc(db, 'carts', userId);
        try {
            const updatedCartData = cartData.filter((_, i) => i !== index);
            await updateDoc(userCartRef, {
                items: updatedCartData
            });
            setCartData(updatedCartData);
        } catch (error) {
            console.error('Error removing cart item:', error);
        }
    };

    const updateCartItem = async (index, updatedItem) => {
        const userId = user.uid;
        const userCartRef = doc(db, 'carts', userId);
        try {
            const cartSnapshot = await getDoc(userCartRef);
            if (cartSnapshot.exists()) {
                const cartDatas = cartSnapshot.data();
                cartDatas.items[index] = updatedItem;
                await updateDoc(userCartRef, { items: cartDatas.items });
                setCartData([...cartDatas.items]);
            }
        } catch (error) {
            console.error('Error updating cart item:', error);
        }
    };

    console.log(cartData, "cartData")
    console.log(user, "userData")

    const handleIncrement = (index) => {
        const updatedCartData = [...cartData];
        updatedCartData[index].quantity += 1;
        updatedCartData[index].sum = (updatedCartData[index].product.price * updatedCartData[index].quantity).toFixed(2);
        updateCartItem(index, updatedCartData[index]);
    };
    
    const handleDecrement = (index) => {
        const updatedCartData = [...cartData];
        if (updatedCartData[index].quantity > 1) {
            updatedCartData[index].quantity -= 1;
            updatedCartData[index].sum = (updatedCartData[index].product.price * updatedCartData[index].quantity).toFixed(2);
            updateCartItem(index, updatedCartData[index]);
        }
    };

    const handlePaymentChange = (event) => {
        setSelectedPaymentOption(event.target.value);
    }

    const handleShippingMethodChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedShippingMethodOption(selectedOption);
    
        // Capture delivery details based on the selected option
        const selectedDelivery = companyDeliveriesData?.delivery?.find(
            delivery => delivery.name === selectedOption
        );
    
        if (selectedDelivery) {
            setShippingAmount(parseFloat(selectedDelivery.price)); // Ensure price is captured correctly
        }

        if (selectedDelivery) {
            setDeliveryTimeFrame(selectedDelivery.timeFrame); // Ensure Time Frame is captured correctly
        }
    };
    

    const handleCountryChange = (event) => {
        console.log(event.target.value, "control")
        setSelectedCountryOption(event.target.value);
    };

    console.log(user, "userfast")

    const handleUserAddressChange = (e) => {
        setUserAddress(e.target.value)
    }

    const handleUserPostalCodeChange = (e) => {
        setUserPostalCode(e.target.value)
    }

    const handleUserCityChange = (e) => {
        setUserCity(e.target.value)
    }

    const handleCheckout = async () => {
        const data = {
            "userId": {
                name: user?.displayName
            },
            "items": cartData?.map((item, index) => (
                {
                    "productId": index,
                    "quantity": item?.quantity,
                    // "size": "M",
                    "name": item?.product?.name,
                    "price": item?.product?.price,
                }
            )),
            "deliveryInfo": {
                "address": userAddress,
                "email": user?.email,
                "city": userCity,
                "postalCode": userPostalCode,
                "country": selectedCountryOption,
            }
        }
        if (selectedPaymentOption !== "AmazonPay") {
            try {
                const res = await axios.post(`${apiUrl}/api/orders/coffee`, data)
                console.log(res, "afar")
                window.open(res?.data?.checkoutUrl)
            } catch (err) {
                console.log(err, "errop")
            }
        } else {
            return null
        }
    }


    const colRef = collection(db, "countries") 
    const paymentColRef = collection(db, "payment")
    const deliveryColRef = collection(db, "delivery")

    // Fetch Countries Data from Firestore
    const fetchCountriesData = () => {
        getDocs(colRef)
        .then((snapshot) => {
            const countries = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setCountriesData(countries)

            // Match company name in Firestore data with the state
            const matchedData = countries.find(item => item.company?.toLowerCase() === manufacturerData?.company?.toLowerCase());
            setCompanyCountriesData(matchedData || { country: [] }); // Set matched data or empty
        })
        .catch((err) => {
            console.error(err.message)
        })
    }

    console.log(companyCountriesData, "companyCountriesData")

    useEffect(() => {
        fetchCountriesData()
    }, [])

    // Fetch Payment Processor Data from Firestore
    const fetchPaymentsData = () => {
        getDocs(paymentColRef)
        .then((snapshot) => {
            const payments = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setPaymentsData(payments)

            // Match company name in Firestore data with the state
            const matchedData = payments.find(item => item.company?.toLowerCase() === manufacturerData?.company?.toLowerCase());
            setCompanyPaymentsData(matchedData || { payment: [] }); // Set matched data or empty
        })
        .catch((err) => {
            console.error(err.message)
        })
    }

    useEffect(() => {
        fetchPaymentsData()
    }, [])

     // Fetch Currencies Data from Firestore
     const fetchDeliveriesData = () => {
        getDocs(deliveryColRef)
        .then((snapshot) => {
            const deliveries = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            console.log(deliveries, "deliveries")
            setDeliveriesData(deliveries)

            // Match company name in Firestore data with the state
            const matchedData = deliveries?.find(item => item.company?.toLowerCase() === manufacturerData?.company?.toLowerCase());
            setCompanyDeliveriesData(matchedData || { delivery: [] }); // Set matched data or empty
        })
        .catch((err) => {
            console.error(err.message)
        })
    }

    console.log(companyDeliveriesData, "companyDeliveriesData")

    useEffect(() => {
        fetchDeliveriesData()
    }, [])


    // useEffect(() => {
    //     if(selectedShippingMethodOption === "DHL Delivery") {
    //         setShippingAmount(10.24)
    //     } else {
    //         setShippingAmount(6.94) 
    //     }

    // }, [selectedShippingMethodOption])
            
    // const handleCheckout = () => {
    //     switch (selectedPaymentOption) {
    //         case 'Stripe':
    //             // Handle Paypal checkout process

    //             navigate('/checkout/paypal');
    //             break;
    //         case 'Amazon Pay':
    //             // Handle Amazon Pay checkout process
    //             navigate('/checkout/amazon-pay');
    //             break;
    //         default:
    //             break;
    //     }
    // };

    return (
        <HomeLayout>
            <div className='z-10 fixed w-full bg-white border border-grey-200 xl:pl-[4%] pl-5 h-[71px] flex gap-5 items-center'>
                <div onClick={() => navigate(-1)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.9403 6.99998H16.1123V8.99998H3.9403L9.30431 14.364L7.8903 15.778L0.112305 7.99998L7.8903 0.221985L9.30431 1.63598L3.9403 6.99998Z" fill="#7E3701"/>
                </svg>
                </div>
                <h1 className='nunito-bold text-primary md:text-2xl text-xl'>Cart</h1>
            </div>
            <div className='xl:px-[4%] px-5 mt-[84px] mb-10 md:block hidden'>
                <CartTable 
                    handleIncrement={handleIncrement} 
                    handleDecrement={handleDecrement} 
                    data={cartData} 
                    deliveryTimeFrame={deliveryTimeFrame}
                    selectedCurrency={selectedCurrency}
                    handleRemove={removeCartItem}
                />      
            </div>
            <div className='xl:px-[4%] mt-[84px] md:hidden'>
                <CartTable2 
                    handleIncrement={handleIncrement} 
                    handleDecrement={handleDecrement} 
                    data={cartData} 
                    deliveryTimeFrame={deliveryTimeFrame}
                    selectedCurrency={selectedCurrency}
                    handleRemove={removeCartItem}
                />      
            </div>
            <div className='w-full bg-grey-200 mb-5 h-[1px]'></div>
            <div className='xl:px-[4%] px-5 mx-10 py-5 bg-[#efefef] flex md:flex-row flex-col-reverse justify-between mb-[37px]'>
                <div className='md:mt-10 mt-[18px] flex-col flex gap-[21px] md:w-[454px] w-full'>
                    <div className='h-[50px] w-full flex'>
                        <input placeholder='Enter voucher code' className='rounded-l-lg text-[18px] placeholder:text-grey-100 focus:outline-none pl-5 md:w-[386px] flex-1 bg-grey-200 h-full'/>
                        <div className='w-[68px] h-full bg-secondary rounded-r-lg justify-center flex items-center'>
                            <svg className='cursor-pointer' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.172 6.778L6.808 1.414L8.222 0L16 7.778L8.222 15.556L6.808 14.142L12.172 8.778H0V6.778H12.172Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[9px]'>
                        <p className='font-bold text-grey-100'>Shipping costs
                        <br /> Country of delivery</p>
                        <CartSelect placeholder={selectedCountryOption}  onChange={handleCountryChange}>
                            {countries?.map((c, index) => (
                                <option key={index} value={c}>{c}</option>
                            ))}
                            {/* {companyCountriesData?.country?.map((c, index) => (
                                <option key={index} value={c}>{c}</option>
                            ))} */}
                        </CartSelect>
                    </div>
                    {
                        selectedCountryOption ? 
                        <>
                            <div className='flex flex-col gap-[9px]'>
                                <p className='font-bold text-grey-100'>Address</p>
                                <input 
                                    type='text'
                                    onChange={(e) => handleUserAddressChange(e)}
                                    value={userAddress}
                                    className="text-grey-50 outline-none text-sm w-full h-[50px] px-4 py-1 border-grey-200 border rounded-lg focus:outline-none"
                                    placeholder='Address'
                                />
                            </div>
                            <div className='flex flex-col gap-[9px]'>
                                <p className='font-bold text-grey-100'>City</p>
                                <input 
                                    type='text'
                                    onChange={(e) => handleUserCityChange(e)}
                                    value={userCity}
                                    className="text-grey-50 outline-none text-sm w-full h-[50px] px-4 py-1 border-grey-200 border rounded-lg focus:outline-none"
                                    placeholder='City'
                                />
                            </div>
                            <div className='flex flex-col gap-[9px]'>
                                <p className='font-bold text-grey-100'>Postal Code</p>
                                <input 
                                    type='number'
                                    onChange={(e) => handleUserPostalCodeChange(e)}
                                    value={userPostalCode}
                                    className="text-grey-50 outline-none text-sm w-full h-[50px] px-4 py-1 border-grey-200 border rounded-lg focus:outline-none"
                                    placeholder='Postal Code'
                                />
                            </div>
                        </> 
                        :
                        null
                    }
                    <div className='flex flex-col gap-[9px]'>
                        <p className='font-bold text-grey-100'> Payment method</p>
                        <CartSelect  placeholder={selectedPaymentOption} onChange={handlePaymentChange}>
                            {companyPaymentsData?.payment?.map((p, index) => ( //placeholder={paymentOptions[1]}
                                <option key={index} value={p}>{p}</option>
                            ))}
                        </CartSelect>
                    </div>
                    <div className='flex flex-col gap-[9px]'>
                        <p className='font-bold text-grey-100'>Shipping method</p>
                        <CartSelect  placeholder={selectedShippingMethodOption} onChange={(e) => handleShippingMethodChange(e)}>
                            {companyDeliveriesData?.delivery?.map((p, index) => (
                                <option key={index} value={p?.name}>{`${p?.name}`}</option>
                            ))}
                        </CartSelect>
                    </div>
                    <p className='text-grey-50'>Standard Delivery  <span className='text-secondary font-bold'>{deliveryTimeFrame || "1-7"} Workdays</span></p>
                    <BasicButtons text={'CHECK OUT'} functions={() => handleCheckout()}/>
                </div>
                <div className='md:w-[454px] w-full mt-[15px] flex flex-col gap-[10px]'>
                    <div className='flex justify-between'>
                        <p className='text-xl'>Sum:</p>
                        <p className='font-bold text-xl'>{(selectedCurrency?.symbol ? selectedCurrency?.symbol : currency) + (selectedCurrency?.rate ? formatter.format(selectedCurrency?.rate * sum) : sum)}</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='text-xl'>Shipping costs:</p>
                        <p className='font-bold text-xl'>
                            {selectedCurrency?.symbol ? selectedCurrency?.symbol : currency}{`${(selectedCurrency?.rate ? formatter.format(selectedCurrency?.rate * shippingAmount) : shippingAmount) || 0}`}
                        </p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='text-2xl font-bold'>Total amount:</p>
                        <p className='font-bold text-2xl'>{(selectedCurrency?.symbol ? selectedCurrency?.symbol : currency) + (selectedCurrency?.rate ?  ((sum + shippingAmount) * selectedCurrency?.rate) : (sum + shippingAmount)).toFixed(2)}</p>
                    </div>
                </div>
            </div>
        </HomeLayout>
    )
}