import React, { useState } from 'react'
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { CgSpinner } from 'react-icons/cg';
import { db } from '../firebase-config';
import { toast } from 'react-toastify';

const EditCountry = ({ handleClose,  editCountryLoading, setEditCountryLoading, editCountryId, editCountryData}) => {
    console.log(editCountryData, 'editCountryData')
    const [country, setCountry] = useState(editCountryData) 

    const submitForm = async () => {
        setEditCountryLoading(true);
        try {
            const docRef = doc(db, 'countries', editCountryId);
            
            // Remove the old country from the array
            await updateDoc(docRef, {
                country: arrayRemove(editCountryData)
            });

            // Add the updated country to the array
            await updateDoc(docRef, {
                country: arrayUnion(country)
            });

            toast(`Country Updated Successfully`, { 
                position: "top-right",
                autoClose: 3500,
                closeOnClick: true,
            }); 
            handleClose();
        } catch (error) {
            console.error('Error updating document: ', error);
            toast(`Error Updating Country`, { 
                position: "top-right",
                autoClose: 3500,
                closeOnClick: true,
            }); 
            handleClose();
        } finally {
            setEditCountryLoading(false);
        }
    };

  return (
    <div className='bg-[#efefef] w-full lg:w-[400px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
        <div className='flex flex-col gap-10'>
            <h1 className='text-3xl text-center font-bold'>Edit Country</h1>
            <div className='flex'>
                
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Country</label>
                    <input 
                        type="text" 
                        value={country} 
                        onChange={(e) => setCountry(e.target.value)} 
                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                    />
                </div>
            </div>
            <div className='flex justify-between'>
                <button 
                    type='button' 
                    className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
                    onClick={submitForm}
                >
                    {editCountryLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Update" }
                </button>
                <button 
                    type='button' 
                    className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
                    onClick={handleClose}
                >
                    Cancel
                </button>

            </div>
        </div>

    </div>
  )
}

export default EditCountry