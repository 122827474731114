import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="flex items-center w-full justify-center min-h-screen bg-[#ff] bg-fixed bg-cover bg-bottom error-bg">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 text-gray-50 text-center -mt-52">
                        <div className="relative ">
                            <h1 className="relative text-9xl tracking-tighter-less text-shadow text-tertiary font-bold">
                                <span>4</span><span>0</span><span>4</span></h1>
                            <span className="absolute top-0 -ml-12  text-tertiary  font-semibold">Oops!</span>
                        </div>
                        <h5 className=" text-tertiary  font-semibold -mr-10 -mt-3">Page not found</h5>
                        <p className=" text-tertiary  mt-2 mb-6">We are sorry, but the page you requested was not found</p>
                        <div className='w-full flex justify-center'>
                            <div
                                onClick={() => navigate(-1)}
                                className="bg-tertiary w-[131px] cursor-pointer px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-gray-50 rounded-full hover:shadow-lg">
                                Go Back
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

