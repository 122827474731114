import React, { useState, useContext, useEffect } from 'react'
import HomeLayout from '../layout/HomeLayout'
import { useNavigate, useLocation } from 'react-router-dom'
import BasicButtons from '../components/Button'
import Modals from '../components/Modals'
import AddToCart from '../components/AddToCart'
import { capitalizeString } from '../utils/helperFuncs'
import { auth, db } from '../firebase-config'
import { setDoc, doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore'
import { useSnackbar } from 'notistack'
import { CgArrowsExchangeAltV, CgSpinner } from 'react-icons/cg'
import { BiSolidCoffeeBean } from "react-icons/bi";
import { CartsContext } from '../context/CartsContext'
import Subscription from '../components/Subscription'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import CoffeeRelatedProducts from '../components/coffee/CoffeeRelatedProducts'
import { FaLeaf } from 'react-icons/fa'
import { TbTruckDelivery } from "react-icons/tb";
import AdditionalInfo from './AdditionalInfo'
import ProductVideo from './ProductVideo'
import ProductPhoto from './ProductPhoto'

export default function CartBag() {
    const { enqueueSnackbar } = useSnackbar()
    const { cartData } = useContext(CartsContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { data, manufacturerData } = location.state || {};
    const [relatableProducts, setRelatableProducts] = useState([]);
    const [active, setActive] = useState("Info")

  
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showImage, setShowImage] = useState(data?.image)
    const [count, setCount] = useState(1)
    const price = data?.price?.toString(); // Ensure data.price is a string
    console.log(manufacturerData, "scam")
    console.log(data, "scam data")
    const [wholeNumber, decimal] = price?.split('.'); // Split into whole number and decimal parts

    const [currenciesData, setCurrenciesData] = useState([])
    const [companyCurrenciesData, setCompanyCurrenciesData] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState({})

    const formatter = new Intl.NumberFormat('en-US');

    const handleChange = (value) => {
        setActive(value)
    }

    const currencyColRef = collection(db, "currency") 

    const handleAddToCart = async (product, quantity) => {
        const user = auth.currentUser;
        if (user) {
            const userId = user.uid;
            await addToCart(userId, product, quantity);
        } else {
            navigate('/auth')
        }
    };

    
    const addToCart = async (userId, product, quantity) => {
        if (cartData.length > 0 && cartData.filter((f) => f.currency !== product.currency)) {
            enqueueSnackbar(`Please clear your cart first to work with ${product.currency} currency!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return
        }
        setLoading(true)
        try {
            // Define the data you want to add to the cart
            const cartItem = {
                product: product,
                quantity: quantity,
                currency: product.currency,
                sum: parseFloat((product.price * quantity).toFixed(2)),
                addedAt: new Date()
            };
    
            // Reference to the user's cart in the database
            const userCartRef = doc(db, "carts", userId);
    
            // Fetch the existing cart data
            const cartSnapshot = await getDoc(userCartRef);
            const existingCartData = cartSnapshot.exists() ? cartSnapshot.data() : { items: [] };
    
            // Update the cart with the new item
            existingCartData.items.push(cartItem);
    
            // Save the updated cart data back to the database
            await setDoc(userCartRef, existingCartData);
            setLoading(false)
            setOpen(!open)
        } catch (error) {
            setLoading(false)
            console.error("Error adding item to cart:", error);
            enqueueSnackbar(`Error adding item to cart!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
        }
    };


     // Fetch Currencies Data from Firestore
     const fetchCurrenciesData = () => {
        getDocs(currencyColRef)
        .then((snapshot) => {
            const currencies = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setCurrenciesData(currencies)

            // Match company name in Firestore data with the state
            const matchedData = currencies.find(item => item.company?.toLowerCase() === manufacturerData?.company?.toLowerCase());
            setCompanyCurrenciesData(matchedData || { currency: [] }); // Set matched data or empty
        })
        .catch((err) => {
            console.error(err.message)
        })
    }

    console.log(companyCurrenciesData, "companyCurrenciesData")
    console.log(relatableProducts, "relatableProducts")

    useEffect(() => {
        fetchCurrenciesData()
    }, [])

    const handleCurrencyChange = (event) => {
        const selectedSymbol = event.target.value;
        const currencyData = companyCurrenciesData?.currency?.find(c => c.symbol === selectedSymbol);
        setSelectedCurrency(currencyData);
    };
    console.log(selectedCurrency, "selectedCurrency")

    const getManufacturersWithSameRoast = async (roastType) => {
        const manufacturersSnapshot = await getDocs(collection(db, "manufacturer"));
        const manufacturersWithSameRoast = [];
        setRelatableProducts(manufacturersWithSameRoast);
        console.log(manufacturersWithSameRoast, "manufacturersWithSameRoast");
      

        manufacturersSnapshot.forEach(doc => {
            const manufacturerData = doc.data();
            const matchingProducts = manufacturerData.products.filter(product => product.roast === roastType);
    
            if (matchingProducts.length > 0) {
                manufacturersWithSameRoast.push({
                    manufacturer: manufacturerData,
                    products: matchingProducts
                });
            }
        });
    
        return manufacturersWithSameRoast;
    };
    
    console.log(manufacturerData.products, "products");
    console.log(relatableProducts, "relatablePro")

    const roastData = data?.roast

    const fetchRelatableProducts = async() => {
      const result = await getManufacturersWithSameRoast(roastData);
      setRelatableProducts(result);
    }

  useEffect(() => {
    fetchRelatableProducts();
  }, []);

    return (
        <HomeLayout>
            <div className='z-10 fixed w-full bg-white  xl:pl-[4%] pl-5 h-[71px] flex justify-between items-center'> {/* border border-grey-200 */}
                <div className='flex gap-5 items-center'>
                    <div onClick={() => navigate(-1)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.9403 6.99998H16.1123V8.99998H3.9403L9.30431 14.364L7.8903 15.778L0.112305 7.99998L7.8903 0.221985L9.30431 1.63598L3.9403 6.99998Z" fill="#7E3701"/>
                        </svg>
                    </div>
                    <h1 className='nunito-bold text-primary md:text-2xl text-xl'>{data?.name}</h1>
                </div>
                <img src={manufacturerData?.logo} alt='Logo' className='mr-5 hidden lg:flex ' />
            </div>
            <div className='xl:px-[4%] px-5 mt-[84px] mb-10 flex xl:flex-row flex-col justify-between'>
                <div className='xl:w-[45%] w-full flex items-start gap-3'>
                    <div className='flex w-[30%] mt-6 xl:mt-8 flex-col gap-4'>
                        {
                            data?.image ? 
                                <div 
                                    className=' rounded-[4px] w-[100%] h-[60px]  xl:h-[150px]'
                                    onClick={() => setShowImage(data?.image)}
                                >
                                    <img src={data?.image} alt='Product' className='w-full h-full'/>
                                </div>
                            
                            :
                            <p>No Image Available </p>
                        }
                    </div>
                    <div className='w-[60%] xl:w-[70%] h-[80%] mb-[10px]'>
                        <img src={showImage} alt='Product' className='w-full h-full' />
                    </div>
                    {/* <img src={showImage} alt='Product' className='w-[60%] xl:w-[70%] h-[80%]' /> w-full h-full */}
                    {/* <div className='absolute xl:left-[40%] right-[10%] size-[50px] rounded-full flex justify-center items-center bg-white mt-10 cursor-pointer'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99999 2.28701C11.1427 1.26803 12.6318 0.724469 14.1623 0.767615C15.6927 0.810761 17.1488 1.43735 18.2323 2.51909C19.3158 3.60082 19.9448 5.05585 19.9905 6.58624C20.0362 8.11663 19.4951 9.60658 18.478 10.751L9.99999 19.243L1.52099 10.751C0.502097 9.60677 -0.0404661 8.1161 0.00454553 6.58463C0.0495572 5.05316 0.678733 3.59692 1.76307 2.5145C2.84741 1.43208 4.30476 0.805484 5.8363 0.763184C7.36785 0.720884 8.85756 1.26609 9.99999 2.28701ZM16.827 3.92801C16.1043 3.20657 15.1331 2.78883 14.1124 2.76039C13.0917 2.73194 12.0987 3.09494 11.337 3.77501L9.99999 4.97301L8.66399 3.77301C7.90559 3.09356 6.91661 2.72893 5.89866 2.75343C4.88071 2.77793 3.91042 3.18973 3.18559 3.90487C2.46075 4.62002 2.03593 5.58467 1.99773 6.6022C1.95953 7.61973 2.31082 8.61352 2.97999 9.38101L9.99999 16.412L17.02 9.38101C17.6841 8.61647 18.0334 7.62852 17.9976 6.61649C17.9618 5.60446 17.5434 4.64368 16.827 3.92801Z" fill="#503225"/>
                        </svg>
                    </div>   */}
                </div>
                <div className='flex-1 xl:pl-4 pt-7'>
                    <div className='flex flex-col gap-5 xl:w-[602px] w-full'>

                        <div className=' sm:px-10'>
                            
                            <p className='text-[19px]'>{capitalizeString(data.name)}</p>
                            <div className='mt-3 flex items-center gap-[17px]'>
                                <svg width="63" height="12" viewBox="0 0 63 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.37302 9.256L2.29702 11.548L3.13302 7.271L0.39502 4.411L3.98902 4.06L5.37302 0L6.75802 4.06L10.352 4.411L7.61402 7.271L8.45002 11.548L5.37302 9.256Z" fill="#503225"/>
                                    <path d="M18.352 9.256L15.276 11.548L16.112 7.271L13.374 4.411L16.968 4.06L18.352 0L19.737 4.06L23.331 4.411L20.593 7.271L21.429 11.548L18.352 9.256Z" fill="#503225"/>
                                    <path d="M31.331 9.256L28.255 11.548L29.091 7.271L26.353 4.411L29.947 4.06L31.331 0L32.716 4.06L36.31 4.411L33.572 7.271L34.408 11.548L31.331 9.256Z" fill="#503225"/>
                                    <path d="M44.31 9.256L41.234 11.548L42.07 7.271L39.332 4.411L42.926 4.06L44.31 0L45.695 4.06L49.289 4.411L46.551 7.271L47.387 11.548L44.31 9.256Z" fill="#503225"/>
                                    <path d="M57.289 9.256L54.213 11.548L55.049 7.271L52.311 4.411L55.905 4.06L57.289 0L58.674 4.06L62.268 4.411L59.53 7.271L60.366 11.548L57.289 9.256Z" fill="#DDDDDD"/>
                                </svg>
                                <p className='text-[13px] open-sans text-black'>0 Sold</p>
                            </div>

                            <div className='flex flex-col gap-2'>
                                <div className='flex items-center gap-[13.1px]'>
                                    <p className='font-bold text-grey-100 text-[37px]'>
                                        {selectedCurrency?.rate ? formatter.format(wholeNumber * selectedCurrency?.rate) : formatter.format(wholeNumber)}<span className='text-[25px] ml-2'>{selectedCurrency?.symbol ? selectedCurrency?.symbol : data?.currency}</span>
                                    </p> {/* .{decimal ? decimal : '00'} */}
                                    <CgArrowsExchangeAltV className='mt-3 text-[32px]' />
                                    <select onChange={handleCurrencyChange} className='mt-3 cursor-pointer border border-grey-300 px-2 w-20 outline-none text-[24px] border-grey-5' value={selectedCurrency.symbol || ''}>
                                        {companyCurrenciesData?.currency?.map((c, index) => (
                                            <option key={index} value={c.symbol}>{c.symbol}</option>
                                        ))}
                                    </select>
                                    {/* <p className='font-bold text-grey-100 text-[25px]'>{data?.currency}<span className='text-[37px]'>{wholeNumber}</span>.{decimal ? decimal : '00'}</p> */}
                                    {/* <p className='text-grey-50 open-sans text-xl line-through'>$524.03</p> */}
                                </div>
                                <p>(31.60 {`${selectedCurrency?.symbol ? selectedCurrency?.symbol : data?.currency}`}  / 1 kg) | Prices incl. VAT plus shipping costs</p>
                            </div>

                            <div className='mt-2'>
                                {data?.description}
                                {/* <p>Decaffeinated espresso coffee from Marziali Caffe</p>
                                <p>Freshly roasted in Rome</p>
                                <p>Flavours of chocolate, cinnamon and nuts</p> */}
                            </div>
                        </div>
                        {/* <div className='xl:block hidden w-full h-[1px] bg-grey-200'></div> */}
                        <div className='bg-white rounded-lg w-full py-[21px]  sm:px-10'> {/* pl-5 pr-[15px] shadow-[0_0_10px_0_#0000001A] */}
                            <div className='flex items-center hidden justify-between'>
                                <h3 className='font-bold text-[18px]'>Shipping to</h3>
                                <div className='flex gap-3 items-center'>
                                    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 19.4L13.95 14.45C14.9289 13.471 15.5955 12.2238 15.8656 10.8659C16.1356 9.50807 15.9969 8.10064 15.4671 6.8216C14.9373 5.54256 14.04 4.44935 12.8889 3.68021C11.7378 2.91107 10.3844 2.50055 9 2.50055C7.61557 2.50055 6.26222 2.91107 5.11109 3.68021C3.95996 4.44935 3.06275 5.54256 2.53292 6.8216C2.00308 8.10064 1.86442 9.50807 2.13445 10.8659C2.40449 12.2238 3.07111 13.471 4.05 14.45L9 19.4ZM9 22.228L2.636 15.864C1.37734 14.6054 0.520187 13.0017 0.172928 11.2559C-0.17433 9.51007 0.00390685 7.70047 0.685099 6.05595C1.36629 4.41142 2.51984 3.00582 3.99988 2.01689C5.47992 1.02796 7.21998 0.500122 9 0.500122C10.78 0.500122 12.5201 1.02796 14.0001 2.01689C15.4802 3.00582 16.6337 4.41142 17.3149 6.05595C17.9961 7.70047 18.1743 9.51007 17.8271 11.2559C17.4798 13.0017 16.6227 14.6054 15.364 15.864L9 22.228ZM9 11.5C9.39557 11.5 9.78225 11.3827 10.1111 11.163C10.44 10.9432 10.6964 10.6309 10.8478 10.2654C10.9991 9.89996 11.0387 9.49783 10.9616 9.10986C10.8844 8.7219 10.6939 8.36554 10.4142 8.08583C10.1345 7.80613 9.77815 7.61564 9.39018 7.53847C9.00222 7.4613 8.60009 7.50091 8.23464 7.65228C7.86918 7.80366 7.55683 8.06001 7.33706 8.3889C7.1173 8.7178 7 9.10448 7 9.50004C7 10.0305 7.21072 10.5392 7.58579 10.9143C7.96086 11.2893 8.46957 11.5 9 11.5ZM9 13.5C8.20888 13.5 7.43552 13.2654 6.77772 12.8259C6.11993 12.3864 5.60724 11.7617 5.30449 11.0308C5.00173 10.2999 4.92252 9.49561 5.07686 8.71968C5.2312 7.94376 5.61217 7.23103 6.17158 6.67162C6.73099 6.11221 7.44372 5.73124 8.21964 5.5769C8.99557 5.42256 9.79983 5.50178 10.5307 5.80453C11.2616 6.10728 11.8864 6.61997 12.3259 7.27776C12.7654 7.93556 13 8.70892 13 9.50004C13 10.5609 12.5786 11.5783 11.8284 12.3285C11.0783 13.0786 10.0609 13.5 9 13.5Z" fill="#503225"/>
                                    </svg>
                                    <h3 className='font-bold text-[18px]'>Worldwide</h3>
                                </div>
                            </div>
                            <div className='my-5 w-full h-[1px] bg-grey-200'></div>
                            <div className='flex sm:gap-[13.1px] gap-[10px] flex-col'>
                                <div className='flex sm:flex-row flex-col sm:gap-[18px] gap-[10px] sm:items-center'>
                                    <div className='flex items-center gap-1'>
                                        <BiSolidCoffeeBean />
                                        <p className='text-grey-50'>Degree Of Roast: <span className='font-bold text-secondary'>Medium</span> </p>
                                    </div>
                                    {/* <div className='flex items-center gap-1'>
                                        <FaLeaf />
                                        <p className='text-grey-50'>Best Before:  <span className='font-bold text-secondary'>04.2025</span></p>
                                    </div> */}
                                </div>
                                <div className='flex sm:flex-row flex-col sm:gap-[18px] gap-[10px] sm:items-center'>
                                    <div className='flex items-center gap-1'>
                                        <BiSolidCoffeeBean />
                                        <p className='text-grey-50'>Roasting Date: <span className='font-bold text-secondary'>05.2023</span> </p>
                                    </div>
                                    <div className='flex items-center gap-1'>
                                        <FaLeaf />
                                        <p className='text-grey-50'>Best Before:  <span className='font-bold text-secondary'>04.2025</span></p>
                                    </div>
                                </div>
                                <div className='flex sm:flex-row flex-col sm:gap-[18px] gap-[10px] sm:items-center'>
                                    <div className='flex items-center gap-1'>
                                        <TbTruckDelivery />
                                        <p className='text-grey-50'>Estimated Delivery  <span className='font-bold text-secondary'>5-7 Workdays</span> </p>
                                    </div>
                                    <p className='text-grey-150 sm:inline-flex hidden'>|</p>
                                    <p className='text-grey-50'>Article No:<span className='font-bold text-secondary'>1234</span></p>
                                </div>
                            </div>
                            <div className='my-5 w-full h-[1px] bg-grey-200'></div>
                            {/* <div className='flex flex-col gap-[10px]'>
                                <h3 className='font-bold text-[18px]'>Services</h3>
                                <div className='flex gap-[10px] items-center'>
                                    <p>Buyers Protection</p>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.172 7.674H0V9.674H12.172L6.808 15.038L8.222 16.452L16 8.674L8.222 0.895996L6.808 2.31L12.172 7.674Z" fill="#503225"/>
                                    </svg>
                                </div>
                            </div> */}
                            {/* <div className='my-5 w-full h-[1px] bg-grey-200'></div> */}
                            <div className='flex flex-col gap-[10px]'>
                                <h3 className='font-bold text-[18px]'>Quantity</h3>
                                <div className='flex gap-4 items-center'>
                                    <div onClick={() => count > 1 ? setCount(count - 1): setCount(1)} className='size-[29px] cursor-pointer rounded-full bg-[#DCD6D3] flex justify-center items-center'>
                                        <p className='text-xl font-bold'>-</p>
                                    </div>
                                    <p>{count}</p>
                                    <div onClick={() => setCount(count + 1)} className='size-[29px] cursor-pointer rounded-full bg-[#DCD6D3] flex justify-center items-center'>
                                        <p className='text-xl font-bold'>+</p>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-[16.5px]'>
                                <BasicButtons 
                                    text={loading ? <CgSpinner className='animate-spin text-lg text-white'/> : 'Add to Cart'} 
                                    functions={() => handleAddToCart(data, count)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
                <div className='xl:px-[4%] px-5 mt-[66px]'>
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex flex-col gap-5 cursor-pointer w-full' onClick={() => handleChange("Info")}>
                            <h1 className={`${active === "Info" ? "" : "opacity-50"} text-xl text-primary nunito text-center`}>Additional Info</h1>
                            <div className={`${active === "Info" ? "bg-tertiary" : "bg-[#F3D3BB]"} h-1  w-full`}></div>
                        </div>
                        <div className='flex flex-col gap-5 cursor-pointer w-full' onClick={() => handleChange("Videos")}>
                            <h1 className={`${active === "Videos" ? "" : "opacity-50"} text-xl text-primary nunito text-center`}>Videos</h1>
                            <div className={`${active === "Videos" ? "bg-tertiary" : "bg-[#F3D3BB]"} h-1  w-full`}></div>
                        </div>
                        <div className='flex flex-col gap-5 cursor-pointer w-full' onClick={() => handleChange("Photos")}>
                            <h1 className={`${active === "Photos" ? "" : "opacity-50"} text-xl text-primary nunito text-center`}>Photos</h1>
                            <div className={`${active === "Photos" ? "bg-tertiary" : "bg-[#F3D3BB]"} h-1  w-full`} ></div>
                        </div>
                    </div>
                    
                    {active === "Info" && <AdditionalInfo />}
                    {active === "Videos" && <ProductVideo />}
                    {active === "Photos" && <ProductPhoto />}
                </div>

                

            {
                relatableProducts?.length > 0 ?
                <CoffeeRelatedProducts data={relatableProducts} />
                :
                null
            }
            <Subscription />
            <Contact data={manufacturerData} />
            <Footer />

            <Modals open={open} setOpen={setOpen}>
                <AddToCart setOpen={setOpen} manufacturerData={manufacturerData} selectedCurrency={selectedCurrency} />
            </Modals>
        </HomeLayout>
    )
}
