import React, { useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { CoffeeContext } from '../../context/CoffeeContext';

export default function CoffeeNav({setOpen, open, active, setActive}) {
    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
    const data = ['<', ...alphabet, '>']

    const handleActive = (letter) => {
        if (letter === '<') {
            setActive('A')
        } else if (letter === '>') {
            setActive('Z') 
        } else {
            setActive(letter)
        }
    }

    const { coffee } = useContext(CoffeeContext);

    console.log(coffee, "lak")

    // Filter coffee based on active letter
    const filteredCoffee = coffee.filter(c => c.company.startsWith(active));

    return (
        <div className='flex flex-col md:gap-[13px] gap-[10px] xl:w-[48%] w-full md:h-20 h-[160px] fixed pt-[10px] bg-white'>
            <div className='xl:pl-0 pl-5 md:pr-0 pr-5'>
                <Swiper
                    style={{width: '100%', height: '35px'}}            
                    modules={[Navigation, Scrollbar]}
                    navigation
                    slidesPerView={'auto'}
                    scrollbar={{ draggable: true }}
                    className='coffee__nav'
                >
                    {data?.map((a, index) => (
                        <SwiperSlide key={index} style={{height: '100%', marginRight: '18px', width:'35px', display: 'flex', alignItems: 'center'}}>
                            <div onClick={() => handleActive(a)} className={`size-7 rounded-lg  text-grey-100 ${active === a ? 'bg-secondary text-white' : 'bg-[#EEEEEE] hover:text-white hover:bg-[#22222280] transition-all ease-in-out'} flex justify-center items-center cursor-pointer`}>
                                <p>{a}</p>                           
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className='w-full md:hidden h-[1px] bg-grey-200'></div>
            <div className='px-5 flex justify-between items-center'>
                <p className='sm:w-full w-[280px] nunito-bold text-xl text-grey-100'>{filteredCoffee?.length} Coffee Manufactures found</p> {/* Near Campania, Italy */}
                <div onClick={() => setOpen(!open)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 4.375C0.75 3.50971 1.00659 2.66385 1.48732 1.94438C1.96805 1.22492 2.65133 0.664162 3.45076 0.333029C4.25019 0.00189561 5.12985 -0.084744 5.97852 0.0840664C6.82719 0.252877 7.60674 0.669555 8.21859 1.28141C8.83045 1.89326 9.24713 2.67281 9.41594 3.52148C9.58475 4.37015 9.49811 5.24982 9.16697 6.04924C8.83584 6.84867 8.27509 7.53195 7.55562 8.01268C6.83615 8.49341 5.99029 8.75 5.125 8.75C3.96468 8.75 2.85188 8.28907 2.03141 7.46859C1.21094 6.64812 0.75 5.53532 0.75 4.375ZM22 5.625H12V3.125H22V5.625ZM14.5 16.875C14.5 16.0097 14.7566 15.1638 15.2373 14.4444C15.7181 13.7249 16.4013 13.1642 17.2008 12.833C18.0002 12.5019 18.8799 12.4153 19.7285 12.5841C20.5772 12.7529 21.3567 13.1696 21.9686 13.7814C22.5804 14.3933 22.9971 15.1728 23.1659 16.0215C23.3347 16.8701 23.2481 17.7498 22.917 18.5492C22.5858 19.3487 22.0251 20.0319 21.3056 20.5127C20.5862 20.9934 19.7403 21.25 18.875 21.25C17.7147 21.25 16.6019 20.7891 15.7814 19.9686C14.9609 19.1481 14.5 18.0353 14.5 16.875ZM12 15.625V18.125H2V15.625H12Z" fill="#503225"/>
                    </svg>
                </div>
            </div>

            {/* <div className='xl:pl-0 pl-5 md:pb-0 pb-2 flex gap-[22px] items-center md:pr-0 pr-5'>
                <p className='text-sm text-grey-50'>Sort By:</p>
                <div className='flex gap-1 items-center cursor-pointer'>
                    <p className='font-bold text-sm'>Recently Added</p>
                    {/* <p className='font-bold text-sm'>Highest Rated</p> 
                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.364 5.58498L11.314 0.634979L12.728 2.04898L6.364 8.41398L0 2.04998L1.414 0.63598L6.364 5.58498Z" fill="#503225"/>
                    </svg>
                </div>
            </div> */}

        </div>
    )
}
