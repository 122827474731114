import React from 'react'

const Cancel = () => {
  return (
    <div className=''>
        <div className=' lg:w-[500px] lg:mx-auto'>
            <div className='flex flex-col items-center mt-[100px] gap-10'>
                <img src="https://ortuk.org/wp-content/themes/ort/images/fail-img.png" alt='cancel' className='xs:w-[200px] lg:w-[300px]' />
                <p className='font-inter text-2xl font-semibold text-[#000]'>Payment Failed</p>
            </div>
        </div>

    </div>
  )
}

export default Cancel