import React from 'react'
import Modal from '@mui/material/Modal';
import { makeStyles } from "@mui/styles";

const useModalStyles = makeStyles((theme) => ({
    modal: {
        "& .MuiModal-backdrop": {
          background: "#22222280",
          backdropFilter: "blur(7px)"
        },
    },
}));

export default function Modals({open, setOpen, children}) {
    const classes = useModalStyles();
    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {children}
        </Modal>
    )
}
