import React, { useState, useEffect } from 'react'
import BasicButtons from '../components/Button'
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from '../firebase-config';
import { FaCoffee, FaCog, FaTools } from 'react-icons/fa';
import Select from "react-select"

import UploadIcon from "../assets/img/upload_icon.png"
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { currencyOptions } from '../utils/currencyOptions';
import { countriesLocationOptions } from '../utils/countriesLocation';
import { countries } from '../utils/countries';

export default function CreateData() {
    const [loading, setLoading] = useState(false)
    const [addProductRelatable, setAddProductRelatable] = useState(false)
    const [manufacturerTypes, setManufacturerTypes] = useState([])
    const [manufacturerCountry, setManufacturerCountry] = useState("")
    const [selectedManufacturerType, setSelectedManufacturerType] = useState(null)
    const [uploadGalleryLoading, setUploadGalleryLoading] = useState(false)
    const [whatsapp, setWhatsapp] = useState('')
    const [province, setProvince] = useState('')
    const [logo, setLogo] = useState('')
    const [company, setCompany] = useState('')
    const [youtube, setYoutube] = useState('')
    const [academy, setAcademy] = useState(true)
    const [global, setGlobal] = useState([])
    const [globalLong, setGlobalLong] = useState(0)
    const [globalLat, setGlobalLat] = useState(0)
    const [email, setEmail] = useState('')
    const [emails, setEmails] = useState('')
    const [profitProject, setProfitProject] = useState('')
    const [contact, setContact] = useState('')
    const [phone, setPhone] = useState([])
    const [website, setWebsite] = useState('')
    const [address, setAddress] = useState('')
    const [award, setAward] = useState('')
    const [certificates, setCertificates] = useState('')
    const [certificate, setCertificate] = useState('')
    const [merchandise, setMerchandise] = useState([])
    const [merch, setMerch] = useState('')
    const [sustainable, setSustainable] = useState(true)
    const [brand, setBrand] = useState('')
    const [otherBrands, setOtherBrands] = useState([])
    const [blog, setBlog] = useState(true)
    const [notOnlyCoffee, setNotOnlyCoffee] = useState([])
    const [others, setOthers] = useState('')
    const [year, setYear] = useState('')
    const [history, setHistory] = useState('')
    const [newsletter, setNewsletter] = useState('')
    const [team, setTeam] = useState('')
    const [legal, setLegal] = useState('')
    const [gallery, setGallery] = useState([])
    const [rangeProducts, setRangeProducts] = useState([])
    const [range, setRange] = useState('')
    const [long, setLong] = useState()
    const [lat, setLat] = useState()
    const [pic, setPic] = useState('')
    const [salesOnline, setSalesOnline] = useState(true)
    const [processingMethod, setProcessingMethod] = useState('')
    const [rate, setRate] = useState(0)
    const [social, setSocial] = useState([]);
    const [type, setType] = useState('');
    const [link, setLink] = useState('');
    const [shipping, setShipping] = useState('');
    const [delivery, setDelivery] = useState('');
    const [privacy, setPrivacy] = useState('')
    const [terms, setTerms] = useState('')
    const [faq, setFaq] = useState('')
    const [cookies, setCookies] = useState('')
    const [userImage, setUserImage] = useState(null)
    const [coffeeBeans, setCoffeeBeans] = useState([
        { 
            rate: 5, 
            currency: '€', 
            name: '', 
            price: 0, 
            images: [''], 
            description: '', 
            quantity: '', 
            intensity: "",
            weight: "",
            profile: "",
            origin: "",
            preparation: "",
            roast: "",
            area: "",
            videos: [''],
            bestBefore: "",
            roastingDate: ""
        }
    ])
    const [manufacturerProducts, setManufacturerProducts] = useState([
        { 
            rate: 5, 
            currency: '$', 
            name: '', 
            price: 0, 
            images:[''], 
            description:'', 
            visibility: true,  
            intensity: "",
            weight: "",
            profile: "",
            origin: "",
            preparation: "",
            roast: "",
            area: "",
            videos: [''],
            bestBefore: "",
            roastingDate: ""
        }
    ])
    const [compatibleCapsules, setCompatibleCapsules] = useState([
        { 
            rate: 5, 
            currency: '€', 
            name: '', 
            price: 0, 
            images: [''], 
            description: '', 
            quantity: '',
            intensity: "",
            weight: "",
            profile: "",
            origin: "",
            preparation: "",
            roast: "",
            area: "",
            videos: [''],
            bestBefore: "",
            roastingDate: ""
        }
    ])
    const [esePods, setEsePods] = useState([
        { 
            rate: 5, 
            currency: '€', 
            name: '', 
            price: 0, 
            images: [''], 
            description: '', 
            quantity: '',
            intensity: "",
            weight: "",
            profile: "",
            origin: "",
            preparation: "",
            roast: "",
            area: "",
            videos: [''],
            bestBefore: "",
            roastingDate: ""  
        }
    ])
    const [grindedCoffee, setGrindedCoffee] = useState([
        { 
            rate: 5, 
            currency: '€', 
            name: '', 
            price: 0, 
            images: [''], 
            description: '', 
            quantity: '',
            intensity: "",
            weight: "",
            profile: "",
            origin: "",
            preparation: "",
            roast: "",
            area: "",
            videos: [''],
            bestBefore: "",
            roastingDate: "" 
        }
    ])
    const [draft, setDraft] = useState(null);
    // const [visibility, setVisibility] = useState(true)
    const [compatibleCapsuleVisibility, setCompatibleCapsuleVisibility] = useState(true)
    const [esePodsVisibility, setEsePodsVisibility] = useState(true)
    const [grindedCoffeeVisibility, setGrindedCoffeeVisibility] = useState(true)
    const [coffeeBeansVisibility, setCoffeeBeansVisibility] = useState(true)


    const handleCountryChange = (selectedOptions) => {
        setGlobal(selectedOptions); // Set the selected countries
        
        if (selectedOptions.length > 0) {
            // If there is at least one selected country, update longitude and latitude with the first one
            setGlobalLong(selectedOptions[0].longitude);
            setGlobalLat(selectedOptions[0].latitude);
        }
    };


    const adminName = localStorage.getItem("adminName")
  
    console.log(globalLong, globalLat, global, "Goddess")

    const navigate = useNavigate()

    const handleFileUpload = async (acceptedFiles) => {
        const files = acceptedFiles;
        if (files) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'rztljgso');
    
            try {
              const uploadResponse = await axios.post("https://api.cloudinary.com/v1_1/dizlp3hvp/upload", formData);
              const data = uploadResponse.data;
              console.log(data, "data")
              setUserImage(data);
           
            } catch (error) {
              console.error('Error uploading file:', error);
            }
        }
        }
    };

    const handleGalleryFileUpload = async (e) => {
        const files = e.target.files;
        if (files) {
            setUploadGalleryLoading(true)
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'rztljgso');
    
            try {
              const uploadResponse = await axios.post("https://api.cloudinary.com/v1_1/dizlp3hvp/upload", formData);
              const data = uploadResponse.data;
              setUploadGalleryLoading(false)
              console.log(data, "data")
              setPic(data);
              toast("Image Uploaded Successfully", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                })
            } catch (error) {
              setUploadGalleryLoading(false)
                toast("Error Uploading Image", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                })
              console.error('Error uploading file:', error);
            }
        }
        }
    };

    const onDrop = (acceptedFiles) => {
        handleFileUpload(acceptedFiles)
    }

    const handleCountrySelect = (event) => {
        console.log(event.target.value, "control")
        setManufacturerCountry(event.target.value);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

        //COFFEE BEANS
    const handleCoffeeBeansChange = (e, index, field) => {
        const newCoffeeBeans = [...coffeeBeans];
        newCoffeeBeans[index][field] = e.target.value;
        setCoffeeBeans(newCoffeeBeans);
      };
    
    const handleAddCoffeeBeans = () => {
        setCoffeeBeans([
            ...coffeeBeans, 
            { 
                rate: 0, 
                currency: '', 
                name: '', 
                price: 0, 
                images: [''], 
                description: '', 
                quantity: '',
                intensity: "",
                weight: "",
                profile: "",
                origin: "",
                preparation: "",
                roast: "",
                area: "",
                videos: [''],
                bestBefore: "",
                roastingDate: ""
            }]);
    };

    const handleRemoveCoffeeBeans = (index) => {
        if (coffeeBeans?.length > 1) {
            const updatedCoffeeBeans = coffeeBeans?.filter((_, i) => i !== index);
            setCoffeeBeans(updatedCoffeeBeans);
        }
    };

    const handleCoffeeBeanImageChange = (e, productIndex, imageIndex) => {
        const newCoffeeBeans = [...coffeeBeans];
        newCoffeeBeans[productIndex].images[imageIndex] = e.target.value;
        setCoffeeBeans(newCoffeeBeans);
    };

    const handleCoffeeBeanVideoChange = (e, productIndex, videoIndex) => {
        const newCoffeeBeans = [...coffeeBeans];
        newCoffeeBeans[productIndex].videos[videoIndex] = e.target.value;
        setCoffeeBeans(newCoffeeBeans);
    };

    const handleAddCoffeeBeanImage = (productIndex) => {
        const newCoffeeBeans = [...coffeeBeans];
        newCoffeeBeans[productIndex].images.push('');
        setCoffeeBeans(newCoffeeBeans);
    };

    const handleAddCoffeeBeanVideo = (productIndex) => {
        const newCoffeeBeans = [...coffeeBeans];
        newCoffeeBeans[productIndex].videos.push('');
        setCoffeeBeans(newCoffeeBeans);
    };


  //COMPATIBLE CAPSULE 
    const handleCompatibleCapsulesChange = (e, index, field) => {
        const newCompatibleCapsules = [...compatibleCapsules];
        newCompatibleCapsules[index][field] = e.target.value;
        setCompatibleCapsules(newCompatibleCapsules);
    };
    
    const handleAddCompatibleCapsules = () => {
        setCompatibleCapsules([
            ...compatibleCapsules, 
            { 
                rate: 0, 
                currency: '', 
                name: '', 
                price: 0, 
                image: [''], 
                description: '', 
                quantity: '',
                intensity: "",
                weight: "",
                profile: "",
                origin: "",
                preparation: "",
                roast: "",
                area: "",
                videos: [''],
                bestBefore: "",
                roastingDate: ""  
            }]);
    };

    const handleRemoveCompatibleCapsules = (index) => {
        if (compatibleCapsules?.length > 1) {
            const updatedCompatibleCapsules = compatibleCapsules?.filter((_, i) => i !== index);
            setCompatibleCapsules(updatedCompatibleCapsules);
        }
    };

    const handleCompatibleCapsulesImageChange = (e, productIndex, imageIndex) => {
        const newCompatibleCapsules = [...compatibleCapsules];
        newCompatibleCapsules[productIndex].images[imageIndex] = e.target.value;
        setCompatibleCapsules(newCompatibleCapsules);
    };

    const handleCompatibleCapsulesVideoChange = (e, productIndex, videoIndex) => {
        const newCompatibleCapsules = [...compatibleCapsules];
        newCompatibleCapsules[productIndex].videos[videoIndex] = e.target.value;
        setCompatibleCapsules(newCompatibleCapsules);
    };

    const handleAddCompatibleCapsulesImage = (productIndex) => {
        const newCompatibleCapsules = [...compatibleCapsules];
        newCompatibleCapsules[productIndex].images.push('');
        setCompatibleCapsules(newCompatibleCapsules);
    };

    const handleAddCompatibleCapsulesVideo = (productIndex) => {
        const newCompatibleCapsules = [...compatibleCapsules];
        newCompatibleCapsules[productIndex].videos.push('');
        setCompatibleCapsules(newCompatibleCapsules);
    };

    //ESE PODS
    const handleEsePodsChange = (e, index, field) => {
        const newEsePods = [...esePods];
        newEsePods[index][field] = e.target.value;
        setEsePods(newEsePods);
    };
    
    const handleAddEsePods = () => {
        setEsePods([...esePods, 
            { 
                rate: 0, 
                currency: '', 
                name: '', 
                price: 0, 
                image: [''], 
                description: '', 
                quantity: '',
                intensity: "",
                weight: "",
                profile: "",
                origin: "",
                preparation: "",
                roast: "",
                area: "",
                videos: [''],
                bestBefore: "",
                roastingDate: "" 
            }]);
    };

    const handleRemoveEsePods = (index) => {
        if (esePods?.length > 1) {
            const updatedEsePods = esePods?.filter((_, i) => i !== index);
            setEsePods(updatedEsePods);
        }
    };

    const handleEsePodsImageChange = (e, productIndex, imageIndex) => {
        const newEsePods = [...esePods];
        newEsePods[productIndex].images[imageIndex] = e.target.value;
        setEsePods(newEsePods);
    };

    const handleEsePodsVideoChange = (e, productIndex, videoIndex) => {
        const newEsePods = [...esePods];
        newEsePods[productIndex].videos[videoIndex] = e.target.value;
        setEsePods(newEsePods);
    };

    const handleAddEsePodsImage = (productIndex) => {
        const newEsePods = [...esePods];
        newEsePods[productIndex].images.push('');
        setEsePods(newEsePods);
    };

    const handleAddEsePodsVideo = (productIndex) => {
        const newEsePods = [...esePods];
        newEsePods[productIndex].videos.push('');
        setEsePods(newEsePods);
    };


    //GRINDED COFFEE
    const handleGrindedCoffeeChange = (e, index, field) => {
        const newGrindedCoffee = [...grindedCoffee];
        newGrindedCoffee[index][field] = e.target.value;
        setGrindedCoffee(newGrindedCoffee);
    };
    
    const handleAddGrindedCoffee = () => {
        setGrindedCoffee([
            ...grindedCoffee, 
            { 
                rate: 0, 
                currency: '', 
                name: '', 
                price: 0, 
                image: [''], 
                description: '', 
                quantity: '',
                intensity: "",
                weight: "",
                profile: "",
                origin: "",
                preparation: "",
                roast: "",
                area: "",
                videos: [''],
                bestBefore: "",
                roastingDate: "" 
            }]);
    };

    const handleRemoveGrindedCoffee = (index) => {
        if (grindedCoffee?.length > 1) {
            const updatedGrindedCoffee = grindedCoffee?.filter((_, i) => i !== index);
            setGrindedCoffee(updatedGrindedCoffee);
        }
    };

    const handleGrindedCoffeeImageChange = (e, productIndex, imageIndex) => {
        const newGrindedCoffee = [...grindedCoffee];
        newGrindedCoffee[productIndex].images[imageIndex] = e.target.value;
        setGrindedCoffee(newGrindedCoffee);
    };

    const handleGrindedCoffeeVideoChange = (e, productIndex, videoIndex) => {
        const newGrindedCoffee = [...grindedCoffee];
        newGrindedCoffee[productIndex].videos[videoIndex] = e.target.value;
        setGrindedCoffee(newGrindedCoffee);
    };

    const handleAddGrindedCoffeeImage = (productIndex) => {
        const newGrindedCoffee = [...grindedCoffee];
        newGrindedCoffee[productIndex].images.push('');
        setGrindedCoffee(newGrindedCoffee);
    };

    const handleAddGrindedCoffeeVideo = (productIndex) => {
        const newGrindedCoffee = [...grindedCoffee];
        newGrindedCoffee[productIndex].videos.push('');
        setGrindedCoffee(newGrindedCoffee);
    };

    //MANUFACTURER PRODUCTS
    const handleManufacturerProductsChange = (e, index, field) => {
        const newManufacturerProducts = [...manufacturerProducts];
        newManufacturerProducts[index][field] = e.target.value;
        setManufacturerProducts(newManufacturerProducts);
    };
    
    const handleAddManufacturerProducts = () => {
        setManufacturerProducts([
            ...manufacturerProducts, 
            { 
                rate: 0, 
                currency: '', 
                name: '', 
                price: 0, 
                image: [''], 
                description:'', 
                visibility: true, 
                intensity: "",
                weight: "",
                profile: "",
                origin: "",
                preparation: "",
                roast: "",
                area: "",
                videos: [''],
                bestBefore: "",
                roastingDate: ""
            }]);
    };

    const handleRemoveManufacturerProducts = (index) => {
        if (manufacturerProducts?.length > 1) {
            const updatedManufacturerProducts = manufacturerProducts?.filter((_, i) => i !== index);
            setManufacturerProducts(updatedManufacturerProducts);
        }
    };

    const handleManufacturerProductsImageChange = (e, productIndex, imageIndex) => {
        const newManufacturerProducts = [...manufacturerProducts];
        newManufacturerProducts[productIndex].images[imageIndex] = e.target.value;
        setManufacturerProducts(newManufacturerProducts);
    };

    const handleManufacturerProductsVideoChange = (e, productIndex, videoIndex) => {
        const newManufacturerProducts = [...manufacturerProducts];
        newManufacturerProducts[productIndex].videos[videoIndex] = e.target.value;
        setManufacturerProducts(newManufacturerProducts);
    };

    const handleAddManufacturerProductsImage = (productIndex) => {
        const newManufacturerProducts = [...manufacturerProducts];
        newManufacturerProducts[productIndex].images.push('');
        setManufacturerProducts(newManufacturerProducts);
    };

    const handleAddManufacturerProductsVideo = (videoIndex) => {
        const newManufacturerProducts = [...manufacturerProducts];
        newManufacturerProducts[videoIndex].videos.push('');
        setManufacturerProducts(newManufacturerProducts);
    };


    const handleCreate = async () => {
        setLoading(true);
        if(!long && !lat) {
            setLoading(false);
            toast("Longitude and Latitude are Required", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            })
        } else {
            try {
                const manufacturerCollection = collection(db, "manufacturer");
                const data = {
                    province, company, gallery, global, academy, website, address, faq, cookies, manufacturerCountry,  //logo,  visibility,
                    merchandise, blog, year, history, shipping, newsletter, legal, team, compatibleCapsuleVisibility,
                    coffeeBeansVisibility, esePodsVisibility, grindedCoffeeVisibility,
                    logo: userImage?.secure_url,
                    manufacturerType: selectedManufacturerType,
                    email: email,  //emails,
                    youtube_link: youtube,
                    profit_project: profitProject,
                    contact: contact,             //phone,
                    company_rate: rate,
                    whatsapp_link: whatsapp,
                    socials: social,
                    privacy_policy: privacy,
                    terms_condition: terms,
                    award_winners: award,
                    intl_certification: certificates,
                    sustainability: sustainable,
                    other_brands: otherBrands,
                    delivery_link: delivery,
                    not_only_coffee: notOnlyCoffee,
                    sales_online: salesOnline,
                    processing: processingMethod,
                    position: [Number(long), Number(lat)],
                    createdAt: new Date(),
                    createdBy: adminName,
                    "COFFEE BEANS": coffeeBeans,
                    "COMPATIBLE CAPSULES": compatibleCapsules,
                    "ESE PODS": esePods,
                    "GRINDED COFFEE": grindedCoffee,
                    products: manufacturerProducts,
                    range_products: rangeProducts
    
                }
                console.log(data, "data")
                const docRef = await addDoc(manufacturerCollection, data)
                console.log("Document written with ID: ", docRef.id);
                setLoading(false);
                toast("Manufacturer Added Successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                })
            } catch (error) {
                console.error("Error creating document: ", error);
                setLoading(false);
                toast("Error", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                })
            }

        }
      
    };

    const handleSaveToDraft = () => {
        const draftData = {
            selectedManufacturerType,
            manufacturerCountry,
            whatsapp,
            province,
            logo,
            company,
            youtube,
            academy,
            global,
            email,
            emails,
            profitProject,
            contact,
            phone,
            website,
            address,
            award,
            certificates,
            certificate,
            merchandise,
            merch,
            sustainable,
            brand,
            otherBrands,
            blog,
            // visibility,
            compatibleCapsuleVisibility,
            coffeeBeansVisibility, 
            esePodsVisibility, 
            grindedCoffeeVisibility,
            notOnlyCoffee,
            others,
            year,
            history,
            newsletter,
            team,
            legal,
            gallery,
            rangeProducts,
            range,
        };
        setDraft(draftData);
        localStorage.setItem('draftData', JSON.stringify(draftData));
        alert('Draft saved!');
    };

    useEffect(() => {
        const savedDraft = localStorage.getItem('draftData');
        if (savedDraft) {
            const draftData = JSON.parse(savedDraft);
            setWhatsapp(draftData.whatsapp || '');
            setProvince(draftData.province || '');
            setLogo(draftData.logo || '');
            setCompany(draftData.company || '');
            setYoutube(draftData.youtube || '');
            setAcademy(draftData.academy || true);
            setGlobal(draftData.global || '');
            setEmail(draftData.email || '');
            setEmails(draftData.emails || '');
            setProfitProject(draftData.profitProject || '');
            setContact(draftData.contact || '');
            setPhone(draftData.phone || []);
            setWebsite(draftData.website || '');
            setAddress(draftData.address || '');
            setAward(draftData.award || '');
            setCertificates(draftData.certificates || '');
            setCertificate(draftData.certificate || '');
            setMerchandise(draftData.merchandise || []);
            setMerch(draftData.merch || '');
            setSustainable(draftData.sustainable || true);
            setBrand(draftData.brand || '');
            setOtherBrands(draftData.otherBrands || []);
            setBlog(draftData.blog || true);
            // setVisibility(draftData.visibility || true);
            setCompatibleCapsuleVisibility(draftData.compatibleCapsuleVisibility || true);
            setCoffeeBeansVisibility(draftData.coffeeBeansVisibility || true);
            setEsePodsVisibility(draftData.esePodsVisibility || true);
            setGrindedCoffeeVisibility(draftData.grindedCoffeeVisibility || true);
            setNotOnlyCoffee(draftData.notOnlyCoffee || []);
            setOthers(draftData.others || '');
            setYear(draftData.year || '');
            setHistory(draftData.history || '');
            setNewsletter(draftData.newsletter || '');
            setTeam(draftData.team || '');
            setLegal(draftData.legal || '');
            setGallery(draftData.gallery || []);
            setRangeProducts(draftData.rangeProducts || []);
            setRange(draftData.range || '');
        }
    }, []);
    

    console.log(pic, "zama")


    const handleGallery = () => {
        setGallery([...gallery, pic?.secure_url])
        setPic('')
    };

    const handleRangeProducts = () => {
        setRangeProducts([...rangeProducts, range])
        setRange('')
    };

    const handleEmails = () => {
        setEmails([...emails, email])
        setEmail('')
    };

    const handleContact = () => {
        setPhone([...phone, contact])
        setContact('')
    };

    const handleCertificate = () => {
        setCertificates([...certificates, certificate])
        setCertificate('')
    };


    const handleBrand = () => {
        setOtherBrands([...otherBrands, brand])
        setBrand('')
    };

    const handleMerch = () => {
        setMerchandise([...merchandise, merch])
        setMerch('')
    };

    const handleNof = () => {
        setNotOnlyCoffee([...notOnlyCoffee, others])
        setOthers('')
    };

    const handleRate = (event) => {
        const value = event.target.value
        if (value < 0) {
            setRate(0)
        } else if (value > 5) {
            setRate(5)
        } else {
            setRate(value)
        }
    }

    const handleAddSocial = () => {
        const newSocials = [...social, { type, link }];
        setSocial(newSocials);
        setType('');
        setLink('');
    };

     // Function to fetch manufacturer types from Firestore
     const fetchManufacturerTypes = async () => {
        try {
            // Reference to the manufacturerType collection
            const manufacturerTypeCollection = collection(db, "manufacturerType");

            // Get all documents from the collection
            const querySnapshot = await getDocs(manufacturerTypeCollection);

            // Extract the data from each document and set it into state
            const typesData = querySnapshot.docs.map(doc => ({
                id: doc.id,  // Optionally include document ID
                ...doc.data()  // Spread the document data
            }));

             // Assuming each document contains an array of manufacturerType objects
            //  const formattedTypes = typesData.flatMap((doc) => 
            //     doc.manufacturerType.map((type) => ({
            //         value: type.typeName,
            //         label: type.typeName,
            //         icon: <img src={type.icon} alt={`${type.typeName} Icon`} className="w-6 h-6" />
            //     }))
            // );

            // Assuming each document contains an array of manufacturerType objects
            const formattedTypes = typesData.flatMap((doc) => 
                doc.manufacturerType.map((type) => ({
                    ...type // Spread the fields: typeName, typeImage, icon
                }))
            );

            // Set the retrieved data to state
            setManufacturerTypes(formattedTypes);
        } catch (error) {
            console.error("Error fetching manufacturer types: ", error);
            
        }
    };

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchManufacturerTypes();
    }, []);

      // Handle selection of a manufacturer type
      const handleManufacturerTypeSelect = (e) => {
        const selectedValue = e.target.value;
        console.log(selectedValue, "selectedValue")
        // Find the selected manufacturer type object by its typeName or id
        const selectedType = manufacturerTypes.find(type => type.typeName === selectedValue);

        // Set the full object of the selected manufacturer type
        setSelectedManufacturerType(selectedType);
        console.log("Selected Manufacturer Type: ", selectedType); // Log full data for debugging
    };

    return (
        <div className='flex flex-col w-full min-h-screen py-20 gap-10'>
            <div className='flex items-center cursor-pointer px-4 gap-2' onClick={() => navigate(-1)}>
                <MdKeyboardBackspace className="text-secondary"/>
                <p className='text-sm text-secondary'>Back</p>
            </div>
            <div className='items-center flex flex-col gap-10 '>
                <h1 className='text-3xl font-bold'>Add A Manufacturer</h1>
                <form className='flex flex-col gap-[21.5px] w-[1000px]'>
                    
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-1 -top-[20px] text-grey-100'>Manufacturer Type</label>
                            <div className='relative'>
                                <select
                                    onChange={handleManufacturerTypeSelect}
                                    value={selectedManufacturerType?.typeName}
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none appearance-none'
                                >
                                    <option value="">Select</option>
                                    {manufacturerTypes?.map((option, index) => (
                                        <option key={index} value={option.typeName}>
                                            {option.typeName}
                                        </option>
                                    ))}
                                </select>
                                <div className='absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none'>
                                    {selectedManufacturerType?.icon && (
                                        <img src={selectedManufacturerType.typeImage} alt="Image" className="w-6 h-6" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Manufacturer Country</label>
                            
                            <select
                                onChange={handleCountrySelect}
                                value={manufacturerCountry}
                                className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none appearance-none'
                            >
                                <option value="">Select</option>
                                {countries?.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Province</label>
                            <input type="text" value={province} onChange={(e) => setProvince(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        {/* <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Logo</label>
                            <input value={logo} onChange={(e) => setLogo(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div> */}

                        <div className='flex flex-col  bg-transparent rounded-xl items-center w-full border-dashed border-[#D0D5DD] border px-6 py-[8px]  gap-[16px]'>
                            <div className='p-[9px] w-full cursor-pointer flex justify-center gap-[16px] '>
                                {  
                                    userImage?.original_filename ? 
                                        <div className='flex flex-col gap-1'>
                                            <div className='flex items-center justify-between'>
                                                <p className='text-[15px] font-hanken text-[#858585]'>{userImage?.original_filename}</p>
                                                <p className='text-[#000] text-[11px]'>Completed</p>
                                            </div>
                                            <div className='w-[266px] h-[5px] bg-[#51E38B] rounded-lg'></div>
                                        </div> 
                                        :
                                        <div className='flex items-center gap-[16px]' {...getRootProps()}>
                                            <img src={UploadIcon} alt='upload' className='w-6 h-6' />
                                
                                            <label htmlFor="fileInput" className='cursor-pointer flex  items-center text-[#000] text-sm '>
                                                Drag and Drop <span className='text-[#475367] ml-1'>or choose file</span>
                                                {/* <input
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileUpload(e)}
                                                /> */}
                                                <input
                                                    name='logo'
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: 'none' }} 
                                                    {...getInputProps()} 
                                                />
                                            </label>
                                        </div>
                                }
                            </div>
                        </div>

                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Company Name</label>
                            <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Youtube Video Link</label>
                            <input type="text" value={youtube} onChange={(e) => setYoutube(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Gallery</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <div className='flex flex-col bg-transparent rounded-xl items-center w-full border-dashed border-[#D0D5DD] border px-6 py-[8px]  gap-[16px]'>
                                    {
                                        uploadGalleryLoading ? 
                                        <p className='text-grey-100 font-normal'>Loading...</p>
                                        :
                                        <div className='p-[9px] w-full cursor-pointer flex justify-center gap-[16px] '>
                                            {  
                                                pic?.original_filename ? 
                                                    <div className='flex flex-col gap-1'>
                                                        <div className='flex items-center justify-between'>
                                                            <p className='text-[15px] font-hanken text-[#858585]'>{pic?.original_filename}</p>
                                                            <p className='text-[#000] text-[11px]'>Completed</p>
                                                        </div>
                                                        <div className='w-[266px] h-[5px] bg-[#51E38B] rounded-lg'></div>
                                                    </div> 
                                                    :
                                                    <div className='flex items-center gap-[16px]'>
                                                        <img src={UploadIcon} alt='upload' className='w-6 h-6' />
                                            
                                                        <label htmlFor="galleryFileInput" className='cursor-pointer flex  items-center text-[#000] text-sm '>
                                                        <span className='text-[#475367] ml-1'>Upload Image</span>
                                                            <input
                                                                name='gallery'
                                                                type="file"
                                                                id="galleryFileInput"
                                                                style={{ display: 'none' }} 
                                                                onChange={(e) => handleGalleryFileUpload(e)}
                                                            />
                                                        </label>
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleGallery}>Add</button>
                            </div>
                            {/* <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input value={pic} onChange={(e) => setPic(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleGallery}>Add</button>
                            </div> */}
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Company Rate</label>
                            <input value={rate} onChange={(e) => handleRate(e)} type='number' className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                    </div>
                
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Academy</label>
                            <select onChange={(e) => setAcademy(e.target.value)} value={academy} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>WorldWide Presence</label>
                            <Select
                                isMulti
                                options={countriesLocationOptions?.map(country => ({
                                    value: country.name,
                                    label: country.name,
                                    longitude: country.longitude,
                                    latitude: country.latitude,
                                }))}
                                onChange={handleCountryChange}
                                className='placeholder:text-grey-50 font-normal w-full text-sm h-auto px-4 pt-3 pb-1 rounded-lg outline-none'
                            />
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>No Profit Project</label>
                            <input type="text" value={profitProject} onChange={(e) => setProfitProject(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Email</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleEmails}>Add</button> */}
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Contact Phone</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input type="number" value={contact} onChange={(e) => setContact(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                {/* <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleContact}>Add</button> */}
                            </div>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Website</label>
                            <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Whatsapp Link</label>
                            <input type="text" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Social links</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input
                                    type='text'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    placeholder='facebook'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                                <input
                                    type='text'
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                    placeholder='www.facebook.com'
                                    className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                />
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[30%] h-full flex justify-center text-white items-center' onClick={handleAddSocial}>Add</button>
                            </div>
                        </div>    
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Address</label>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Awards Winning Coffee</label>
                            <input type="text" value={award} onChange={(e) => setAward(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>    
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>International Certification</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input value={certificate} onChange={(e) => setCertificate(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleCertificate}>Add</button>
                            </div>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Merchandising</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input type="text" value={merch} onChange={(e) => setMerch(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleMerch}>Add</button>
                            </div>
                        </div>    
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Sustainability</label>
                            <select value={sustainable} onChange={(e) => setSustainable(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Others Brand</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input type="text" value={brand} onChange={(e) => setBrand(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleBrand}>Add</button>
                            </div>
                        </div>    
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Community & Blog</label>
                            <select value={blog} onChange={(e) => setBlog(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Not only coffee</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input type="text" value={others} onChange={(e) => setOthers(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleNof}>Add</button>
                            </div>
                        </div>    
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Year of Origin</label>
                            <input type="number" value={year} onChange={(e) => setYear(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>History</label>
                            <input type="text" value={history} onChange={(e) => setHistory(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>    
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Shipping Details</label>
                            <input value={shipping} onChange={(e) => setShipping(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Delivery Information Link</label>
                            <input type="text" value={delivery} onChange={(e) => setDelivery(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>    
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Privacy Policy Link</label>
                            <input type="text" value={privacy} onChange={(e) => setPrivacy(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Terms & Condition Link</label>
                            <input type="text" value={terms} onChange={(e) => setTerms(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>FAQ Link</label>
                            <input type="text" value={faq} onChange={(e) => setFaq(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Cookies Link</label>
                            <input type="text" value={cookies} onChange={(e) => setCookies(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Newsletter Link</label>
                            <input type="text" value={newsletter} onChange={(e) => setNewsletter(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Team Link</label>
                            <input type="text" value={team} onChange={(e) => setTeam(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Sales Online</label>
                            <select value={salesOnline} onChange={(e) => setSalesOnline(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>    
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Legal Notices</label>
                            <input type="text" value={legal} onChange={(e) => setLegal(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div> 
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Processing Method</label>
                            <input type="text" value={processingMethod} onChange={(e) => setProcessingMethod(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>  
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Range Products</label>
                            <div className='w-full flex gap-3 items-center h-[50px]'>
                                <input value={range} onChange={(e) => setRange(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-full px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleRangeProducts}>Add</button>
                            </div>
                        </div>  
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Longitude</label>
                            <input value={long} type='number' placeholder='0' onChange={(e) => setLong(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Latitude</label>
                            <input value={lat} type='number' placeholder='0' onChange={(e) => setLat(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Grinded Coffee Visibility</label>
                            <select value={grindedCoffeeVisibility} onChange={(e) => setGrindedCoffeeVisibility(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Beans Visibility</label>
                            <select value={coffeeBeansVisibility} onChange={(e) => setCoffeeBeansVisibility(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Compatible Capsule Visibility</label>
                            <select value={compatibleCapsuleVisibility} onChange={(e) => setCompatibleCapsuleVisibility(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Ese Pods Visibility</label>
                            <select value={esePodsVisibility} onChange={(e) => setEsePodsVisibility(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex gap-3 hidden items-center'>
                        {/* <div className='relative w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Products Visibility</label>
                            <select value={visibility} onChange={(e) => setVisibility(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div> */}
                    
                        <div className='relative invisible w-full'>
                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Ese Pods Visibility</label>
                            <select value={esePodsVisibility} onChange={(e) => setEsePodsVisibility(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                <option>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                    </div>
                    {/* {coffeeBeans.map((product, index) => (
                        <div key={index} className='flex flex-col gap-5'>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Beans Name</label>
                                    <input
                                        value={product.name}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'name')}
                                        placeholder='Name'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                    <input
                                        type='number'
                                        value={product.price}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'price')}
                                        placeholder='Price'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                    <input
                                        value={product.currency}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'currency')}
                                        placeholder='Currency'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                    <input
                                        type='number'
                                        value={product.rate}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'rate')}
                                        placeholder='Rate'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center '>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL</label>
                                    <input
                                        value={product.image}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'image')}
                                        placeholder='Image URL'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                    <input
                                        value={product.description}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'description')}
                                        placeholder='description'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center '>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                    <input
                                        value={product.quantity}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'quantity')}
                                        placeholder='kg'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='w-full h-[50px]'>
                                    <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleAddCoffeeBeans}>Add</button>
                                </div>
                            </div>
                        </div>
                    ))} */}

                    {coffeeBeans?.map((product, index) => (
                        <div key={index} className='flex flex-col gap-5'>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Beans Name</label>
                                    <input
                                        value={product.name}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'name')}
                                        placeholder='Name'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                    <input
                                        type='number'
                                        value={product.price}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'price')}
                                        placeholder='Price'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                    
                                    <select
                                        value={product.currency}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'currency')}
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    >
                                        <option value="">Select</option>
                                        {currencyOptions?.map((item, index) => (
                                            <option key={index} value={item?.symbol}>{item?.symbol}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                    <input
                                        type='number'
                                        value={product.rate}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'rate')}
                                        placeholder='Rate'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                    <input
                                        value={product.description}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'description')}
                                        placeholder='Description'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                    <input
                                        value={product.quantity}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'quantity')}
                                        placeholder='kg'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Intensity</label>
                                    <input
                                        value={product.intensity}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'intensity')}
                                        placeholder='Intensity'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting</label>
                                    <select value={product.roast} onChange={(e) => handleCoffeeBeansChange(e, index, 'roast')} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                        <option>Select</option>
                                        <option value="medium">Medium</option>
                                        <option value="dark">Dark</option>
                                        <option value="blonde">Blonde</option>
                                    </select>
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Aromatic Profile</label>
                                    <input
                                        value={product.profile}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'profile')}
                                        placeholder='Profile'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Coffee Area</label>
                                    <input
                                        value={product.area}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'area')}
                                        placeholder='Area'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Origin</label>
                                    <input
                                        value={product.origin}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'origin')}
                                        placeholder='Origin'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Weight</label>
                                    <input
                                        value={product.weight}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'weight')}
                                        placeholder='1'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Date</label>
                                    <input
                                        value={product.roastingDate}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'roastingDate')}
                                        // placeholder='Date'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Best Before</label>
                                    <input
                                        value={product.bestBefore}
                                        onChange={(e) => handleCoffeeBeansChange(e, index, 'bestBefore')}
                                        // placeholder='1'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col gap-3 '>
                                {product?.images?.map((image, imageIndex) => (
                                    <div key={imageIndex} className='relative w-full'>
                                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                        <input
                                            value={image}
                                            type="text"
                                            onChange={(e) => handleCoffeeBeanImageChange(e, index, imageIndex)}
                                            placeholder={`Image URL ${imageIndex + 1}`}
                                            className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                        />
                                    </div>
                                ))}
                                <button 
                                    type='button' 
                                    className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                    onClick={() => handleAddCoffeeBeanImage(index)}
                                >
                                    Add Image
                                </button>
                                <div className='flex flex-col mt-5 gap-4'>
                                    {product?.videos?.map((video, videoIndex) => (
                                        <div key={videoIndex} className='relative w-full'>
                                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Video URL {videoIndex + 1}</label>
                                            <input
                                                value={video}
                                                onChange={(e) => handleCoffeeBeanVideoChange(e, index, videoIndex)}
                                                placeholder={`Video URL ${videoIndex + 1}`}
                                                className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                            />
                                        </div>
                                    ))}
                                    <button 
                                        type='button' 
                                        className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                        onClick={() => handleAddCoffeeBeanVideo(index)}
                                    >
                                        Add Video
                                    </button>
                                </div>
                            </div>
                            <div className='w-full flex justify-end gap-5 h-[50px] my-2'>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleAddCoffeeBeans}>Add</button>
                                {coffeeBeans?.length > 1 && (
                                    <button type='button' className='bg-red-500 rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleRemoveCoffeeBeans(index)}>Remove</button>
                                )}
                            </div>
                        </div>
                    ))}

                    {compatibleCapsules?.map((product, index) => (
                        <div key={index} className='flex flex-col gap-5'>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Compatible Capsules Name</label>
                                    <input
                                        value={product.name}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'name')}
                                        type="text"
                                        placeholder='Name'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                    <input
                                        type='number'
                                        value={product.price}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'price')}
                                        placeholder='Price'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                     <select
                                        value={product.currency}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'currency')}
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    >
                                        <option value="">Select</option>
                                        {currencyOptions?.map((item, index) => (
                                            <option key={index} value={item?.symbol}>{item?.symbol}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                    <input
                                        type='number'
                                        value={product.rate}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'rate')}
                                        placeholder='Rate'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center '>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                    <input
                                        value={product.quantity}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'quantity')}
                                        placeholder='kg'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                    <input
                                        value={product.description}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'description')}
                                        placeholder='description'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Intensity</label>
                                    <input
                                        value={product.intensity}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'intensity')}
                                        placeholder='Intensity'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting</label>
                                    <select value={product.roast} onChange={(e) => handleCompatibleCapsulesChange(e, index, 'roast')} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                        <option>Select</option>
                                        <option value="medium">Medium</option>
                                        <option value="dark">Dark</option>
                                        <option value="blonde">Blonde</option>
                                    </select>
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Aromatic Profile</label>
                                    <input
                                        value={product.profile}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'profile')}
                                        placeholder='Profile'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Coffee Area</label>
                                    <input
                                        value={product.area}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'area')}
                                        placeholder='Area'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Origin</label>
                                    <input
                                        value={product.origin}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'origin')}
                                        placeholder='Origin'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Weight</label>
                                    <input
                                        value={product.weight}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'weight')}
                                        placeholder='1'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Date</label>
                                    <input
                                        value={product.roastingDate}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'roastingDate')}
                                        // placeholder='Origin'
                                        type="date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Best Before</label>
                                    <input
                                        value={product.bestBefore}
                                        onChange={(e) => handleCompatibleCapsulesChange(e, index, 'bestBefore')}
                                        // placeholder='Origin'
                                        type="date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            
                            <div className='flex flex-col gap-3 '>
                                {product?.images?.map((image, imageIndex) => (
                                    <div key={imageIndex} className='relative w-full'>
                                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                        <input
                                            value={image}
                                            type="text"
                                            onChange={(e) => handleCompatibleCapsulesImageChange(e, index, imageIndex)}
                                            placeholder={`Image URL ${imageIndex + 1}`}
                                            className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                        />
                                    </div>
                                ))}
                                <button 
                                    type='button' 
                                    className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                    onClick={() => handleAddCompatibleCapsulesImage(index)}
                                >
                                    Add Image
                                </button>
                                <div className='flex flex-col mt-5 gap-4'>
                                    {product?.videos?.map((video, videoIndex) => (
                                        <div key={videoIndex} className='relative w-full'>
                                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Video URL {videoIndex + 1}</label>
                                            <input
                                                value={video}
                                                onChange={(e) => handleCompatibleCapsulesVideoChange(e, index, videoIndex)}
                                                placeholder={`Video URL ${videoIndex + 1}`}
                                                className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                            />
                                        </div>
                                    ))}
                                    <button 
                                        type='button' 
                                        className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                        onClick={() => handleAddCompatibleCapsulesVideo(index)}
                                    >
                                        Add Video
                                    </button>
                                </div>
                            </div>
                            <div className='w-full flex gap-5 justify-end h-[50px] my-2'>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleAddCompatibleCapsules}>Add</button>
                                {compatibleCapsules?.length > 1 && (
                                    <button type='button' className='bg-red-500 rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleRemoveCompatibleCapsules(index)}>Remove</button>
                                )}
                            </div>
                        </div>
                    ))}

                    {esePods?.map((product, index) => (
                        <div key={index} className='flex flex-col gap-5'>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>ESE PODS Name</label>
                                    <input
                                        value={product.name}
                                        onChange={(e) => handleEsePodsChange(e, index, 'name')}
                                        placeholder='Name'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                    <input
                                        type='number'
                                        value={product.price}
                                        onChange={(e) => handleEsePodsChange(e, index, 'price')}
                                        placeholder='Price'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                    <select
                                        value={product.currency}
                                        onChange={(e) => handleEsePodsChange(e, index, 'currency')}
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    >
                                        <option value="">Select</option>
                                        {currencyOptions?.map((item, index) => (
                                            <option key={index} value={item?.symbol}>{item?.symbol}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                    <input
                                        type='number'
                                        value={product.rate}
                                        onChange={(e) => handleEsePodsChange(e, index, 'rate')}
                                        placeholder='Rate'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center '>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                    <input
                                        value={product.quantity}
                                        onChange={(e) => handleEsePodsChange(e, index, 'quantity')}
                                        placeholder='kg'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                    <input
                                        value={product.description}
                                        onChange={(e) => handleEsePodsChange(e, index, 'description')}
                                        placeholder='description'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Intensity</label>
                                    <input
                                        value={product.intensity}
                                        onChange={(e) => handleEsePodsChange(e, index, 'intensity')}
                                        placeholder='Intensity'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting</label>
                                    <select value={product.roast} onChange={(e) => handleEsePodsChange(e, index, 'roast')} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                        <option>Select</option>
                                        <option value="medium">Medium</option>
                                        <option value="dark">Dark</option>
                                        <option value="blonde">Blonde</option>
                                    </select>
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Aromatic Profile</label>
                                    <input
                                        value={product.profile}
                                        onChange={(e) => handleEsePodsChange(e, index, 'profile')}
                                        placeholder='Profile'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Coffee Area</label>
                                    <input
                                        value={product.area}
                                        onChange={(e) => handleEsePodsChange(e, index, 'area')}
                                        placeholder='Area'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Origin</label>
                                    <input
                                        value={product.origin}
                                        onChange={(e) => handleEsePodsChange(e, index, 'origin')}
                                        placeholder='Origin'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Weight</label>
                                    <input
                                        value={product.weight}
                                        onChange={(e) => handleEsePodsChange(e, index, 'weight')}
                                        placeholder='1'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Date</label>
                                    <input
                                        value={product.roastingDate}
                                        onChange={(e) => handleEsePodsChange(e, index, 'roastingDate')}
                                        // placeholder='Origin'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Best Before</label>
                                    <input
                                        value={product.bestBefore}
                                        onChange={(e) => handleEsePodsChange(e, index, 'bestBefore')}
                                        // placeholder='1'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col gap-3 '>
                                {product?.images?.map((image, imageIndex) => (
                                    <div key={imageIndex} className='relative w-full'>
                                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                        <input
                                            value={image}
                                            type="text"
                                            onChange={(e) => handleEsePodsImageChange(e, index, imageIndex)}
                                            placeholder={`Image URL ${imageIndex + 1}`}
                                            className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                        />
                                    </div>
                                ))}
                                <button 
                                    type='button' 
                                    className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                    onClick={() => handleAddEsePodsImage(index)}
                                >
                                    Add Image
                                </button>
                                <div className='flex flex-col mt-5 gap-4'>
                                    {product?.videos?.map((video, videoIndex) => (
                                        <div key={videoIndex} className='relative w-full'>
                                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Video URL {videoIndex + 1}</label>
                                            <input
                                                value={video}
                                                onChange={(e) => handleEsePodsVideoChange(e, index, videoIndex)}
                                                placeholder={`Video URL ${videoIndex + 1}`}
                                                className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                            />
                                        </div>
                                    ))}
                                    <button 
                                        type='button' 
                                        className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                        onClick={() => handleAddEsePodsVideo(index)}
                                    >
                                        Add Video
                                    </button>
                                </div>
                            </div>
                            <div className='w-full flex  justify-end gap-5 h-[50px] my-2'>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleAddEsePods}>Add</button>
                                {esePods?.length > 1 && (
                                    <button type='button' className='bg-red-500 rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleRemoveEsePods(index)}>Remove</button>
                                )}
                            </div>
        
                        </div>
                    ))}

                    {grindedCoffee?.map((product, index) => (
                        <div key={index} className='flex flex-col gap-5'>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Grinded Coffee Name</label>
                                    <input
                                        value={product.name}
                                        type="text"
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'name')}
                                        placeholder='Name'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                    <input
                                        type='number'
                                        value={product.price}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'price')}
                                        placeholder='Price'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                    <select
                                        value={product.currency}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'currency')}
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    >
                                        <option value="">Select</option>
                                        {currencyOptions?.map((item, index) => (
                                            <option key={index} value={item?.symbol}>{item?.symbol}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                    <input
                                        type='number'
                                        value={product.rate}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'rate')}
                                        placeholder='Rate'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center '>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Quantity</label>
                                    <input
                                        value={product.quantity}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'quantity')}
                                        placeholder='kg'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                    <input
                                        value={product.description}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'description')}
                                        placeholder='description'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Intensity</label>
                                    <input
                                        value={product.intensity}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'intensity')}
                                        placeholder='Intensity'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting</label>
                                    <select value={product.roast} onChange={(e) => handleGrindedCoffeeChange(e, index, 'roast')} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                        <option>Select</option>
                                        <option value="medium">Medium</option>
                                        <option value="dark">Dark</option>
                                        <option value="blonde">Blonde</option>
                                    </select>
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Aromatic Profile</label>
                                    <input
                                        value={product.profile}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'profile')}
                                        placeholder='Profile'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Coffee Area</label>
                                    <input
                                        value={product.area}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'area')}
                                        placeholder='Area'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Origin</label>
                                    <input
                                        value={product.origin}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'origin')}
                                        placeholder='Origin'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Weight</label>
                                    <input
                                        value={product.weight}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'weight')}
                                        placeholder='1'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Date</label>
                                    <input
                                        value={product.roastingDate}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'roastingDate')}
                                        // placeholder='Origin'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Best Before</label>
                                    <input
                                        value={product.bestBefore}
                                        onChange={(e) => handleGrindedCoffeeChange(e, index, 'bestBefore')}
                                        // placeholder='1'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col gap-3 '>
                                {product?.images?.map((image, imageIndex) => (
                                    <div key={imageIndex} className='relative w-full'>
                                        <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                        <input
                                            value={image}
                                            type="text"
                                            onChange={(e) => handleGrindedCoffeeImageChange(e, index, imageIndex)}
                                            placeholder={`Image URL ${imageIndex + 1}`}
                                            className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                        />
                                    </div>
                                ))}
                                <button 
                                    type='button' 
                                    className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                    onClick={() => handleAddGrindedCoffeeImage(index)}
                                >
                                    Add Image
                                </button>
                                <div className='flex flex-col mt-5 gap-4'>
                                    {product?.videos?.map((video, videoIndex) => (
                                        <div key={videoIndex} className='relative w-full'>
                                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Video URL {videoIndex + 1}</label>
                                            <input
                                                value={video}
                                                onChange={(e) => handleGrindedCoffeeVideoChange(e, index, videoIndex)}
                                                placeholder={`Video URL ${videoIndex + 1}`}
                                                className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                            />
                                        </div>
                                    ))}
                                    <button 
                                        type='button' 
                                        className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                        onClick={() => handleAddGrindedCoffeeVideo(index)}
                                    >
                                        Add Video
                                    </button>
                                </div>
                            </div>
                            <div className='w-full flex  justify-end gap-5 h-[50px] my-2'>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleAddGrindedCoffee}>Add</button>
                                {grindedCoffee?.length > 1 && (
                                    <button type='button' className='bg-red-500 rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleRemoveGrindedCoffee(index)}>Remove</button>
                                )}
                            </div>
                        </div>
                    ))}

                    {manufacturerProducts.map((product, index) => (
                        <div key={index} className='flex flex-col gap-5'>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Product Name</label>
                                    <input
                                        value={product.name}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'name')}
                                        placeholder='Name'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                                    <input
                                        type='number'
                                        value={product.price}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'price')}
                                        placeholder='Price'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Currency</label>
                                      <select
                                        value={product.currency}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'currency')}
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    >
                                        <option value="">Select</option>
                                        {currencyOptions?.map((item, index) => (
                                            <option key={index} value={item?.symbol}>{item?.symbol}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                                    <input
                                        type='number'
                                        value={product.rate}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'rate')}
                                        placeholder='Rate'
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Products Visibility</label>
                                    <select value={product.visibility} onChange={(e) => handleManufacturerProductsChange(e, index, 'visibility')} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                        <option>Select</option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                    
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Description</label>
                                    <input
                                        value={product.description}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'description')}
                                        placeholder='description'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Intensity</label>
                                    <input
                                        value={product.intensity}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'intensity')}
                                        placeholder='Intensity'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting</label>
                                    <select value={product.roast} onChange={(e) => handleManufacturerProductsChange(e, index, 'roast')} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                                        <option>Select</option>
                                        <option value="medium">Medium</option>
                                        <option value="dark">Dark</option>
                                        <option value="blonde">Blonde</option>
                                    </select>
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Aromatic Profile</label>
                                    <input
                                        value={product.profile}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'profile')}
                                        placeholder='Profile'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Coffee Area</label>
                                    <input
                                        value={product.area}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'area')}
                                        placeholder='Area'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Coffee Origin</label>
                                    <input
                                        value={product.origin}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'origin')}
                                        placeholder='Origin'
                                        type="text"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Weight</label>
                                    <input
                                        value={product.weight}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'weight')}
                                        placeholder='1'
                                        type="number"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex gap-3 items-center'>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Roasting Date</label>
                                    <input
                                        value={product.roastingDate}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'roastingDate')}
                                        // placeholder='Origin'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Best Before</label>
                                    <input
                                        value={product.bestBefore}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'bestBefore')}
                                        // placeholder='1'
                                        type="Date"
                                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col gap-3 '>
                                {/* <div className='relative w-full'>
                                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL</label>
                                    <input
                                        type='text'
                                        value={product.image}
                                        onChange={(e) => handleManufacturerProductsChange(e, index, 'image')}
                                        placeholder='Image Url'
                                        className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                    />
                                </div> */}
                                <div className='flex flex-col gap-4'>
                                    {product?.images?.map((image, imageIndex) => (
                                        <div key={imageIndex} className='relative w-full'>
                                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Image URL {imageIndex + 1}</label>
                                            <input
                                                value={image}
                                                onChange={(e) => handleManufacturerProductsImageChange(e, index, imageIndex)}
                                                placeholder={`Image URL ${imageIndex + 1}`}
                                                className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                            />
                                        </div>
                                    ))}
                                    <button 
                                        type='button' 
                                        className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                        onClick={() => handleAddManufacturerProductsImage(index)}
                                    >
                                        Add Image
                                    </button>
                                </div>
                                <div className='flex flex-col mt-5 gap-4'>
                                    {product?.videos?.map((video, videoIndex) => (
                                        <div key={videoIndex} className='relative w-full'>
                                            <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Video URL {videoIndex + 1}</label>
                                            <input
                                                value={video}
                                                onChange={(e) => handleManufacturerProductsVideoChange(e, index, videoIndex)}
                                                placeholder={`Video URL ${videoIndex + 1}`}
                                                className='placeholder:text-grey-50 my-2 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                                            />
                                        </div>
                                    ))}
                                    <button 
                                        type='button' 
                                        className='bg-secondary h-[50px] rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                                        onClick={() => handleAddManufacturerProductsVideo(index)}
                                    >
                                        Add Video
                                    </button>
                                </div>
                            </div>

                            <div className='w-full flex  justify-end mt-10 gap-5 h-[50px] my-2'>
                                <button type='button' className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={handleAddManufacturerProducts}>Add</button>
                                {manufacturerProducts?.length > 1 && (
                                    <button type='button' className='bg-red-500 rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' onClick={() => handleRemoveManufacturerProducts(index)}>Remove</button>
                                )}
                            </div>
                           
                        </div>
                    ))}
                    
                </form>
                <div className='flex items-center justify-end gap-5'>
                    <BasicButtons 
                        functions={handleSaveToDraft} 
                        width={'300px'} 
                        text={'Save to Draft'} 
                        font={'Nunito'}
                    />
                    <BasicButtons 
                        functions={handleCreate} 
                        width={'500px'} 
                        text={loading ? 'loading...' : 'Submit form'} 
                        font={'Nunito'}
                    />

                </div>
            </div>

        </div>
    )
}