import React, { useEffect, useState } from 'react'
import Hero from '../../assets/img/hero.png'
import Hero2 from '../../assets/img/hero2.png'
import Hero3 from '../../assets/img/hero3.png'
import { useNavigate } from 'react-router-dom'

export default function CoffeeHeader({data}) {
    const galleryList = data?.gallery
    const [gallery, setGallery] = useState([])
    const navigate = useNavigate()

    console.log(galleryList, "galleryList")
    
    // useEffect(() => {
    //     const modifiedGallery = galleryList?.map(item => {
    //         const index = item.indexOf("/view");
    //         if (index !== -1) {
    //             const newLink = item.substring(0, index) + "/preview";
    //             return newLink;
    //         } else {
    //             return item;
    //         }
    //     });
    
    //     setGallery(modifiedGallery);
    // }, [galleryList]);

    useEffect(() => {
        const modifiedGallery = galleryList?.map(item => {
            if (item && item.indexOf("/view") !== -1) {
                const index = item.indexOf("/view");
                const newLink = item.substring(0, index) + "/preview";
                return newLink;
            } else {
                return item; // Return the item as is if it's null or doesn't contain "/view"
            }
        });
    
        setGallery(modifiedGallery);
    }, [galleryList]);
    
    return (
        <div className='flex xl:flex-row flex-col gap-[3px] w-full xl:h-[507px]'>
            <div className='h-full xl:w-[70%] w-full relative overflow-hidden'>
                {gallery[0] ? 
                // <iframe 
                //     src={gallery[0]} 
                //     // width={'100%'} 
                //     // height={'100%'} 
                //     // style={{border: 'none', zoom: '1'}} 
                //     allowfullscreen 
                //     frameborder="0" 
                //     // onScroll="no" 
                //     className='absolute top-0 bottom-0 h-full w-full border-0 '  //left-0 right-0 object-cover
                // ></iframe>
                <img 
                    src={data?.gallery[0]} 
                    alt="Gallery Image" 
                    className='absolute top-0 left-0 w-full h-full object-cover'
                />
                : 
                <img src={Hero} alt='hero' className='h-full w-full object-cover'/>
                }
            </div>
            <div className='flex xl:flex-col xl:flex-1 gap-[3px]'>
                {gallery[1] ? 
                    // <iframe src={gallery[1]} alt='hero' allowfullscreen frameborder="0" onScroll="no" className='xl:h-[40%] h-[115px] xl:w-full w-[50%] object-cover'></iframe>
                    <img src={gallery[1]} alt='hero' className='xl:h-[40%] h-[115px] xl:w-full w-[50%] object-cover'/>
                    :
                    <img src={Hero2} alt='hero' className='xl:h-[40%] h-[115px] xl:w-full w-[50%] object-cover'/>
                }

                <div className='flex xl:w-full xl:flex-1 w-[50%]' onClick={() => navigate('/gallery', {state: gallery})}>
                    {gallery[2] ? 
                        // <iframe src={gallery[2]} alt='hero' allowfullscreen frameborder="0" onScroll="no" className='xl:h-full h-[115px] w-full object-cover'></iframe>
                        <img src={gallery[2]} alt='hero' className='xl:h-full h-[115px] w-full object-cover'/>
                        :
                        <img src={Hero3} alt='hero' className='xl:h-full h-[115px] w-full object-cover'/>
                    }
                    <div className='cursor-pointer absolute bg-[#22222280] text-white xl:w-[29.8%] w-[49%] xl:h-[252px] h-[115px] flex justify-center items-center'>
                       <p className='font-bold xl:text-[70px] text-[36px]'>{galleryList.length > 3 && `+${galleryList?.length - 3}`}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
