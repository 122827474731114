export const countriesLocationOptions = [
    { name: 'Afghanistan', longitude: 67.709953, latitude: 33.93911 },
    { name: 'Albania', longitude: 20.168331, latitude: 41.153332 },
    { name: 'Algeria', longitude: 1.659626, latitude: 28.033886 },
    { name: 'Andorra', longitude: 1.601554, latitude: 42.546245 },
    { name: 'Angola', longitude: 17.873887, latitude: -11.202692 },
    { name: 'Antigua and Barbuda', longitude: -61.796428, latitude: 17.060816 },
    { name: 'Argentina', longitude: -63.616672, latitude: -38.416097 },
    { name: 'Armenia', longitude: 45.038189, latitude: 40.069099 },
    { name: 'Australia', longitude: 133.775136, latitude: -25.274398 },
    { name: 'Austria', longitude: 14.550072, latitude: 47.516231 },
    { name: 'Azerbaijan', longitude: 47.576927, latitude: 40.143105 },
    { name: 'Bahamas', longitude: -77.396279, latitude: 25.03428 },
    { name: 'Bahrain', longitude: 50.5577, latitude: 25.930414 },
    { name: 'Bangladesh', longitude: 90.356331, latitude: 23.684994 },
    { name: 'Barbados', longitude: -59.543198, latitude: 13.193887 },
    { name: 'Belarus', longitude: 27.953389, latitude: 53.709807 },
    { name: 'Belgium', longitude: 4.469936, latitude: 50.503887 },
    { name: 'Belize', longitude: -88.49765, latitude: 17.189877 },
    { name: 'Benin', longitude: 2.315834, latitude: 9.30769 },
    { name: 'Bhutan', longitude: 90.433601, latitude: 27.514162 },
    { name: 'Bolivia', longitude: -63.588653, latitude: -16.290154 },
    { name: 'Bosnia and Herzegovina', longitude: 17.679076, latitude: 43.915886 },
    { name: 'Botswana', longitude: 24.684866, latitude: -22.328474 },
    { name: 'Brazil', longitude: -51.92528, latitude: -14.235004 },
    { name: 'Brunei', longitude: 114.727669, latitude: 4.535277 },
    { name: 'Bulgaria', longitude: 25.48583, latitude: 42.733883 },
    { name: 'Burkina Faso', longitude: -1.561593, latitude: 12.238333 },
    { name: 'Burundi', longitude: 29.918886, latitude: -3.373056 },
    { name: 'Cabo Verde', longitude: -23.0418, latitude: 16.0021 },
    { name: 'Cambodia', longitude: 104.990963, latitude: 12.565679 },
    { name: 'Cameroon', longitude: 12.354722, latitude: 3.848 },
    { name: 'Canada', longitude: -106.346771, latitude: 56.130366 },
    { name: 'Central African Republic', longitude: 20.939444, latitude: 6.611111 },
    { name: 'Chad', longitude: 18.732207, latitude: 15.454166 },
    { name: 'Chile', longitude: -71.542969, latitude: -35.675147 },
    { name: 'China', longitude: 104.195397, latitude: 35.86166 },
    { name: 'Colombia', longitude: -74.297333, latitude: 4.570868 },
    { name: 'Comoros', longitude: 43.872219, latitude: -11.875001 },
    { name: 'Congo (Congo-Brazzaville)', longitude: 15.827659, latitude: -0.228021 },
    { name: 'Costa Rica', longitude: -83.753428, latitude: 9.748917 },
    { name: 'Croatia', longitude: 15.2, latitude: 45.1 },
    { name: 'Cuba', longitude: -77.781167, latitude: 21.521757 },
    { name: 'Cyprus', longitude: 33.429859, latitude: 35.126413 },
    { name: 'Czechia (Czech Republic)', longitude: 15.472962, latitude: 49.817492 },
    { name: 'Denmark', longitude: 9.501785, latitude: 56.26392 },
    { name: 'Djibouti', longitude: 42.590275, latitude: 11.825138 },
    { name: 'Dominica', longitude: -61.370976, latitude: 15.414999 },
    { name: 'Dominican Republic', longitude: -70.162651, latitude: 18.735693 },
    { name: 'Ecuador', longitude: -78.183406, latitude: -1.831239 },
    { name: 'Egypt', longitude: 30.802498, latitude: 26.820553 },
    { name: 'El Salvador', longitude: -88.89653, latitude: 13.794185 },
    { name: 'Equatorial Guinea', longitude: 10.267895, latitude: 1.650801 },
    { name: 'Eritrea', longitude: 39.782334, latitude: 15.179384 },
    { name: 'Estonia', longitude: 25.013607, latitude: 58.595272 },
    { name: 'Eswatini', longitude: 31.465866, latitude: -26.522503 },
    { name: 'Ethiopia', longitude: 39.782334, latitude: 9.145 },
    { name: 'Fiji', longitude: 178.065032, latitude: -17.713371 },
    { name: 'Finland', longitude: 25.748151, latitude: 61.92411 },
    { name: 'France', longitude: 2.213749, latitude: 46.603354 },
    { name: 'Gabon', longitude: 11.609444, latitude: -0.803689 },
    { name: 'Gambia', longitude: -15.310139, latitude: 13.443182 },
    { name: 'Georgia', longitude: 43.356892, latitude: 42.315407 },
    { name: 'Germany', longitude: 10.451526, latitude: 51.165691 },
    { name: 'Ghana', longitude: -1.023194, latitude: 7.946527 },
    { name: 'Greece', longitude: 21.824312, latitude: 39.074208 },
    { name: 'Grenada', longitude: -61.604171, latitude: 12.262776 },
    { name: 'Guatemala', longitude: -90.230759, latitude: 15.783471 },
    { name: 'Guinea', longitude: -9.696645, latitude: 9.945587 },
    { name: 'Guinea-Bissau', longitude: -15.180413, latitude: 11.803749 },
    { name: 'Guyana', longitude: -58.93018, latitude: 4.860416 },
    { name: 'Haiti', longitude: -72.285215, latitude: 18.971187 },
    { name: 'Honduras', longitude: -86.241905, latitude: 15.199999 },
    { name: 'Hungary', longitude: 19.503304, latitude: 47.162494 },
    { name: 'Iceland', longitude: -19.020835, latitude: 64.963051 },
    { name: 'India', longitude: 78.96288, latitude: 20.593684 },
    { name: 'Indonesia', longitude: 113.921327, latitude: -0.789275 },
    { name: 'Iran', longitude: 53.688046, latitude: 32.427908 },
    { name: 'Iraq', longitude: 43.679291, latitude: 33.223191 },
    { name: 'Ireland', longitude: -8.24389, latitude: 53.41291 },
    { name: 'Israel', longitude: 34.851612, latitude: 31.046051 },
    { name: 'Italy', longitude: 12.56738, latitude: 41.87194 },
    { name: 'Jamaica', longitude: -77.297508, latitude: 18.109581 },
    { name: 'Japan', longitude: 138.252924, latitude: 36.204824 },
    { name: 'Jordan', longitude: 36.238414, latitude: 30.585164 },
    { name: 'Kazakhstan', longitude: 66.923684, latitude: 48.019573 },
    { name: 'Kenya', longitude: 37.906193, latitude: -1.292066 },
    { name: 'Kiribati', longitude: -157.363026, latitude: 1.870883 },
    { name: 'Kuwait', longitude: 47.481766, latitude: 29.31166 },
    { name: 'Kyrgyzstan', longitude: 74.766098, latitude: 41.20438 },
    { name: 'Laos', longitude: 102.495496, latitude: 19.85627 },
    { name: 'Latvia', longitude: 24.603189, latitude: 56.879635 },
    { name: 'Lebanon', longitude: 35.862285, latitude: 33.854721 },
    { name: 'Lesotho', longitude: 28.233608, latitude: -29.609988 },
    { name: 'Liberia', longitude: -9.429499, latitude: 6.428055 },
    { name: 'Libya', longitude: 17.228331, latitude: 26.3351 },
    { name: 'Liechtenstein', longitude: 9.555373, latitude: 47.166 },
    { name: 'Lithuania', longitude: 23.881275, latitude: 55.169438 },
    { name: 'Luxembourg', longitude: 6.129583, latitude: 49.815273 },
    { name: 'Madagascar', longitude: 46.869107, latitude: -18.766947 },
    { name: 'Malawi', longitude: 34.301525, latitude: -13.254308 },
    { name: 'Malaysia', longitude: 101.975766, latitude: 4.210484 },
    { name: 'Maldives', longitude: 73.22068, latitude: 3.202778 },
    { name: 'Mali', longitude: -3.996166, latitude: 17.570692 },
    { name: 'Malta', longitude: 14.375416, latitude: 35.937496 },
    { name: 'Marshall Islands', longitude: 171.184478, latitude: 7.131474 },
    { name: 'Mauritania', longitude: -10.940835, latitude: 21.00789 },
    { name: 'Mauritius', longitude: 57.552152, latitude: -20.348404 },
    { name: 'Mexico', longitude: -102.552784, latitude: 23.634501 },
    { name: 'Micronesia', longitude: 150.550812, latitude: 7.425554 },
    { name: 'Moldova', longitude: 28.369885, latitude: 47.411631 },
    { name: 'Monaco', longitude: 7.412841, latitude: 43.750298 },
    { name: 'Mongolia', longitude: 103.846656, latitude: 46.862496 },
    { name: 'Montenegro', longitude: 19.37439, latitude: 42.708678 },
    { name: 'Morocco', longitude: -7.09262, latitude: 31.791702 },
    { name: 'Mozambique', longitude: 35.529562, latitude: -18.665695 },
    { name: 'Myanmar (Burma)', longitude: 95.956223, latitude: 21.913965 },
    { name: 'Namibia', longitude: 18.49041, latitude: -22.95764 },
    { name: 'Nauru', longitude: 166.931503, latitude: -0.522778 },
    { name: 'Nepal', longitude: 84.124008, latitude: 28.394857 },
    { name: 'Netherlands', longitude: 5.291266, latitude: 52.132633 },
    { name: 'New Zealand', longitude: 174.885971, latitude: -40.900557 },
    { name: 'Nicaragua', longitude: -85.207229, latitude: 12.865416 },
    { name: 'Niger', longitude: 8.081666, latitude: 17.607789 },
    { name: 'Nigeria', longitude: 8.675277, latitude: 9.081999 },
    { name: 'North Korea', longitude: 127.510093, latitude: 40.339852 },
    { name: 'North Macedonia', longitude: 21.745275, latitude: 41.608635 },
    { name: 'Norway', longitude: 8.468946, latitude: 60.472024 },
    { name: 'Oman', longitude: 55.923255, latitude: 21.512583 },
    { name: 'Pakistan', longitude: 69.345116, latitude: 30.375321 },
    { name: 'Palau', longitude: 134.58252, latitude: 7.51498 },
    { name: 'Panama', longitude: -80.782127, latitude: 8.537981 },
    { name: 'Papua New Guinea', longitude: 143.95555, latitude: -6.314993 },
    { name: 'Paraguay', longitude: -58.443832, latitude: -23.442503 },
    { name: 'Peru', longitude: -75.015152, latitude: -9.189967 },
    { name: 'Philippines', longitude: 121.774017, latitude: 12.879721 },
    { name: 'Poland', longitude: 19.145136, latitude: 51.919438 },
    { name: 'Portugal', longitude: -8.224454, latitude: 39.399872 },
    { name: 'Qatar', longitude: 51.183884, latitude: 25.354826 },
    { name: 'Romania', longitude: 24.96676, latitude: 45.943161 },
    { name: 'Russia', longitude: 105.318756, latitude: 61.52401 },
    { name: 'Rwanda', longitude: 29.873888, latitude: -1.940278 },
    { name: 'Saint Kitts and Nevis', longitude: -62.782998, latitude: 17.357822 },
    { name: 'Saint Lucia', longitude: -60.978893, latitude: 13.909444 },
    { name: 'Saint Vincent and the Grenadines', longitude: -61.287228, latitude: 12.984305 },
    { name: 'Samoa', longitude: -172.104629, latitude: -13.759029 },
    { name: 'San Marino', longitude: 12.457777, latitude: 43.94236 },
    { name: 'Sao Tome and Principe', longitude: 6.613081, latitude: 0.18636 },
    { name: 'Saudi Arabia', longitude: 45.079162, latitude: 23.885942 },
    { name: 'Senegal', longitude: -14.452362, latitude: 14.497401 },
    { name: 'Serbia', longitude: 21.005859, latitude: 44.016521 },
    { name: 'Seychelles', longitude: 55.491977, latitude: -4.679574 },
    { name: 'Sierra Leone', longitude: -11.779889, latitude: 8.460555 },
    { name: 'Singapore', longitude: 103.819836, latitude: 1.352083 },
    { name: 'Slovakia', longitude: 19.699024, latitude: 48.669026 },
    { name: 'Slovenia', longitude: 14.995463, latitude: 46.151241 },
    { name: 'Solomon Islands', longitude: 160.156194, latitude: -9.64571 },
    { name: 'Somalia', longitude: 46.199616, latitude: 5.152149 },
    { name: 'South Africa', longitude: 22.937506, latitude: -30.559482 },
    { name: 'South Korea', longitude: 127.766922, latitude: 35.907757 },
    { name: 'South Sudan', longitude: 31.306978, latitude: 7.862684 },
    { name: 'Spain', longitude: -3.74922, latitude: 40.463667 },
    { name: 'Sri Lanka', longitude: 80.771797, latitude: 7.873054 },
    { name: 'Sudan', longitude: 30.217636, latitude: 12.862807 },
    { name: 'Suriname', longitude: -56.027783, latitude: 3.919305 },
    { name: 'Sweden', longitude: 18.643501, latitude: 60.128161 },
    { name: 'Switzerland', longitude: 8.227512, latitude: 46.818188 },
    { name: 'Syria', longitude: 38.996815, latitude: 34.802075 },
    { name: 'Taiwan', longitude: 120.960515, latitude: 23.69781 },
    { name: 'Tajikistan', longitude: 71.276093, latitude: 38.861034 },
    { name: 'Tanzania', longitude: 34.888822, latitude: -6.369028 },
    { name: 'Thailand', longitude: 100.992541, latitude: 15.870032 },
    { name: 'Timor-Leste', longitude: 125.727539, latitude: -8.874217 },
    { name: 'Togo', longitude: 0.824782, latitude: 8.619543 },
    { name: 'Tonga', longitude: -175.198242, latitude: -21.178986 },
    { name: 'Trinidad and Tobago', longitude: -61.222503, latitude: 10.691803 },
    { name: 'Tunisia', longitude: 9.537499, latitude: 33.886917 },
    { name: 'Turkey', longitude: 35.243322, latitude: 38.963745 },
    { name: 'Turkmenistan', longitude: 59.556278, latitude: 38.969719 },
    { name: 'Tuvalu', longitude: 179.194, latitude: -7.109535 },
    { name: 'Uganda', longitude: 32.290275, latitude: 1.373333 },
    { name: 'Ukraine', longitude: 31.16558, latitude: 48.379433 },
    { name: 'United Arab Emirates', longitude: 53.847818, latitude: 23.424076 },
    { name: 'United Kingdom', longitude: -3.435973, latitude: 55.378051 },
    { name: 'United States', longitude: -95.712891, latitude: 37.09024 },
    { name: 'Uruguay', longitude: -55.765835, latitude: -32.522779 },
    { name: 'Uzbekistan', longitude: 64.585262, latitude: 41.377491 },
    { name: 'Vanuatu', longitude: 166.959158, latitude: -15.376706 },
    { name: 'Vatican City', longitude: 12.453389, latitude: 41.902916 },
    { name: 'Venezuela', longitude: -66.58973, latitude: 6.42375 },
    { name: 'Vietnam', longitude: 108.277199, latitude: 14.058324 },
    { name: 'Yemen', longitude: 48.516388, latitude: 15.552727 },
    { name: 'Zambia', longitude: 27.849332, latitude: -13.133897 },
    { name: 'Zimbabwe', longitude: 29.154857, latitude: -19.015438 }
];
