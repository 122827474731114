import React from 'react'
import HomeLayout from '../layout/HomeLayout'
import Login from '../components/Login'
import Signup from '../components/Signup'

export default function Auth() {
    return (
        <HomeLayout>
            <div className='w-full flex justify-center mt-5'>
                <div className='md:w-[908px] w-full flex md:flex-row flex-col gap-[50.5px]'>
                    <Login />
                    <div className='md:h-[630px] h-[1px] md:w-[1px] w-full bg-grey-200'></div>
                    <Signup />
                </div>
            </div>
        </HomeLayout>
    )
}
