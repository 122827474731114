import React, { useState } from 'react'
import Coffee from '../../assets/img/coffee3.png'
import Coffee2 from '../../assets/img/coffee4.png'
import { capitalizeString } from '../../utils/helperFuncs'

export default function CoffeeVideo({data}) {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false); 

    const handleVideoPlay = () => {
        if (data?.youtube_link) {
            setIsVideoPlaying(true);
        }
    }; 
    //  onClick={() => data?.youtube_link && (window.location.href = data.youtube_link)}

    const getYoutubeEmbedLink = (url) => {
        const urlParts = url.split('v=');
        return urlParts.length > 1 ? `https://www.youtube.com/embed/${urlParts[1]}` : url;
    };


    return (
        // data.youtube_link && <div className='md:mt-[60px] mt-10 w-full flex md:flex-row flex-col md:h-[870px]'>
        //     <div className='md:hidden px-5 mb-[39px] md:mb-0 flex flex-col gap-5'>
        //         <p className='nunito-normal text-xl text-[#7A7A7A]'>{capitalizeString(data?.company)} Company was founded providing quality Coffee {data.year && `in the ${data.year}s`}. Watch our videos</p>
        //     </div>
        //     <div className='flex'>
        //         <img src={Coffee} alt='coffee' className='w-[90%] rounded-tr-[30px] rounded-bl-[30px] md:h-[591px] h-full object-cover'/>
        //         <div 
        //             onClick={handleVideoPlay} 
        //             className='md:hidden items-center absolute md:mt-[20%] mt-[16%] md:right-0 right-[23px] w-[90%] flex'
        //         >
        //             {isVideoPlaying ? (
        //                 <iframe
        //                     width="100%"
        //                     height="591px"
        //                     src={`https://www.youtube.com/embed/${data.youtube_link.split('v=')[1]}`}
        //                     title="YouTube video player"
        //                     frameBorder="0"
        //                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        //                     allowFullScreen
        //                 ></iframe>
        //             ) : (
        //                 <>
        //                     <img src={Coffee2} alt='coffee' className='rounded-tl-[30px] rounded-br-[30px] w-full md:h-[591px] h-full object-cover'/>
        //                     <div className='absolute md:h-[591px] flex items-center w-full justify-center mt-[6%] md:mt-0'>
        //                         <div className='cursor-pointer flex justify-center items-center size-[68px] bg-white rounded-full'>
        //                             <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                                 <path d="M1.62305 1.07129L13.588 8.76329L1.62305 16.4553V1.07129Z" fill="#E00707" stroke="#E00707" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //                             </svg>
        //                         </div>
        //                     </div>
        //                 </>
        //             )}
        //         </div>
        //         {/* <div onClick={() => data?.youtube_link && (window.location.href = data.youtube_link)} className='md:hidden items-center absolute md:mt-[20%] mt-[16%] md:right-0 right-[23px] w-[90%] flex'>
        //             <img src={Coffee2} alt='coffee' className='rounded-tl-[30px] rounded-br-[30px] w-full md:h-[591px] h-full object-cover'/>
        //             <div className='absolute md:h-[591px] flex items-center w-full justify-center mt-[6%] md:mt-0'>
        //                 <div className='cursor-pointer flex justify-center items-center size-[68px] bg-white rounded-full'>
        //                     <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                         <path d="M1.62305 1.07129L13.588 8.76329L1.62305 16.4553V1.07129Z" fill="#E00707" stroke="#E00707" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //                     </svg>
        //                 </div>
        //             </div>
        //         </div> */}
        //     </div>
        //     <div className='absolute mt-[68px] w-[59%] md:flex hidden justify-center gap-[83px] pl-5'>
        //         <p style={{textShadow: '0px 0px 10px #00000066'}} className='nunito-normal text-[25px] text-white'>{capitalizeString(data?.company)} Company was founded 
        //         providing quality Coffee {data.year && `in the ${data.year}s`}. Watch our videos</p>
        //     </div>
        //     <div onClick={() => data?.youtube_link && (window.location.href = data.youtube_link)} className='absolute md:mt-[20%] mt-[16%] md:right-0 right-[23px] w-[90%] md:flex hidden'>
        //         <img src={Coffee2} alt='coffee' className='rounded-tl-[30px] rounded-br-[30px] w-full md:h-[591px] h-[178px] object-cover'/>
        //         <div className='absolute md:h-[591px] flex items-center w-full justify-center mt-[6%] md:mt-0'>
        //             <div className='cursor-pointer flex justify-center items-center size-[68px] bg-white rounded-full'>
        //                 <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                     <path d="M1.62305 1.07129L13.588 8.76329L1.62305 16.4553V1.07129Z" fill="#E00707" stroke="#E00707" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //                 </svg>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        data.youtube_link && (
            <div className='md:mt-[60px] mt-10 w-full flex md:flex-row flex-col md:h-[870px]'>
                <div className='md:hidden px-5 mb-[39px] md:mb-0 flex flex-col gap-5'>
                    <p className='nunito-normal text-xl text-[#7A7A7A]'>
                        {capitalizeString(data?.company)} Company was founded providing quality Coffee {data.year && `in the ${data.year}s`}. Watch our videos
                    </p>
                </div>
                <div className='flex'>
                    <img src={Coffee} alt='coffee' className='w-[90%] rounded-tr-[30px] rounded-bl-[30px] md:h-[591px] h-full object-cover' />
                    <div 
                        onClick={handleVideoPlay} 
                        className=' hidden items-center absolute md:mt-[20%] mt-[16%] md:right-0 right-[23px] w-[90%] flex'
                    >
                        {isVideoPlaying ? (
                            <iframe
                                width="100%"
                                height="591px"
                                // src={getYoutubeEmbedLink(data.youtube_link)}
                                src="https://www.youtube.com/embed/lW8NWB1cvMA?si=OaFv_y6JbTNBepdE" 
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <>
                                <img src={Coffee2} alt='coffee' className='rounded-tl-[30px] rounded-br-[30px] w-full md:h-[591px] h-full object-cover' />
                                <div className='absolute md:h-[591px] flex items-center w-full justify-center mt-[6%] md:mt-0'>
                                    <div className='cursor-pointer flex justify-center items-center size-[68px] bg-white rounded-full'>
                                        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.62305 1.07129L13.588 8.76329L1.62305 16.4553V1.07129Z" fill="#E00707" stroke="#E00707" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='absolute mt-[68px] w-[59%] md:flex hidden justify-center gap-[83px] pl-5'>
                    <p style={{textShadow: '0px 0px 10px #00000066'}} className='nunito-normal text-[25px] text-white'>
                        {capitalizeString(data?.company)} Company was founded providing quality Coffee {data.year && `in the ${data.year}s`}. Watch our videos
                    </p>
                </div>
                <div className='absolute  md:right-0 right-[23px] w-[90%] md:flex hidden'> {/* md:mt-[20%] mt-[16%]*/}
                    <img src={Coffee2} alt='coffee' className='hidden rounded-tl-[30px] rounded-br-[30px] w-full md:h-[591px] h-[178px] object-cover' />
                    <div 
                        onClick={handleVideoPlay} 
                        className='items-center absolute md:mt-[20%] mt-[16%] md:right-0 right-[23px] w-[90%] flex'
                    >
                        {isVideoPlaying ? (
                            <iframe
                                width="100%"
                                height="591px"
                                src={getYoutubeEmbedLink(data.youtube_link)}
                                // src="https://www.youtube.com/embed/ODmsC4ybmkA?autoplay=1&mute=0&loop=0&playlist=ODmsC4ybmkA" 
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <>
                                <img src={Coffee2} alt='coffee' className='rounded-tl-[30px] rounded-br-[30px] w-full md:h-[591px] h-full object-cover' />
                                <div className='absolute md:h-[591px] flex items-center w-full justify-center mt-[6%] md:mt-0'>
                                    <div className='cursor-pointer flex justify-center items-center size-[68px] bg-white rounded-full'>
                                        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.62305 1.07129L13.588 8.76329L1.62305 16.4553V1.07129Z" fill="#E00707" stroke="#E00707" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <div className='absolute md:h-[591px] flex items-center w-full justify-center mt-[6%] md:mt-0'>
                        <div className='cursor-pointer flex justify-center items-center size-[68px] bg-white rounded-full'>
                            <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.62305 1.07129L13.588 8.76329L1.62305 16.4553V1.07129Z" fill="#E00707" stroke="#E00707" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    )
}
