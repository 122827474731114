import React, { useState } from 'react'
import BasicButtons from './Button'
import Select from './Select'
import Input from './Input'
import { collection, addDoc } from 'firebase/firestore'
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { db, auth } from '../firebase-config'
import { useSnackbar } from 'notistack'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'

export default function Signup() {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [salut, setSalut] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [street, setStreet] = useState('')
    const [houseNo, setHouseNo] = useState('')
    const [address, setAddress] = useState('')
    const [sameAddress, setSameAddress] = useState(false)
    const [accept, setAccept] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const apiKey = 'AIzaSyDOLl5z9E-Q72ijjDqT7-VD2GBYQ2zp1Lc';
    
    const resetForm = () => {
        setSalut(''); setFirstName(''); setLastName(''); setEmail(''); setPassword('');
        setPhone(''); setZipCode(''); setCity(''); setStreet(''); setHouseNo(''); setAddress('');
        setSameAddress(false); setAccept(false); setError('');
    };
  
    const validatePassword = (password) => {
        if (password.length < 6) {
            return "Password must be at least 6 characters long.";
        }
        if (!/\d/.test(password)) {
            return "Password must contain at least one number.";
        }
        if (!/[a-zA-Z]/.test(password)) {
            return "Password must contain at least one letter.";
        }
        return "";
    };

    // const handleSignup = async () => {
    //     if (!accept || !firstName || !lastName || !email || !password || !phone || !street || !zipCode || !city){
    //         enqueueSnackbar('Please fill all fields', {
    //             variant: 'error',
    //             anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right',
    //             },
    //             style: { 
    //                 backgroundColor: '#ff0000'
    //             },
    //         });
    //         return
    //     }
        
    //     setLoading(true)
    //     const passwordError = validatePassword(password);
    //     if (passwordError) {
    //         setError(passwordError);
    //         setLoading(false); 
    //         return;
    //     }
        
    //     try {
    //         const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    //         const createdAt = new Date();
    //         const user = userCredential?.user
            
    //         const data = {
    //             uid: user.uid,
    //             first_name: firstName,
    //             last_name: lastName,
    //             email,
    //             createdAt,
    //             phone,
    //             address: street + '' + city + '' + address,
    //             zipCode,
    //             delivery_address: sameAddress,
    //         }

    //         await addDoc(collection(db, "users"), data)
    //         await updateProfile(user, {
    //             displayName: `${firstName} ${lastName}`
    //         });
    //         resetForm()
    //         setLoading(false)
    //         enqueueSnackbar(`User created successfully!`, {
    //             variant: 'success',
    //             anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right',
    //             },
    //             style: { 
    //                 backgroundColor: '#503225'
    //             },
    //         });
    //         setTimeout(() => {
    //             navigate(-1)
    //         }, 1500)
    //     } catch(error) {
    //         enqueueSnackbar(`error creating user!`, {
    //             variant: 'error',
    //             anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right',
    //             },
    //             style: { 
    //                 backgroundColor: '#ff0000'
    //             },
    //         });
    //         console.error(error)
    //         setLoading(false)
    //     }
    // }

    const handleSignup = async () => {
        if (!accept || !firstName || !lastName || !email || !password || !phone || !street || !zipCode || !city){
            enqueueSnackbar('Please fill all fields', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return
        }
    
        setLoading(true)
        const passwordError = validatePassword(password);
        if (passwordError) {
            setError(passwordError);
            setLoading(false); 
            return;
        }
    
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const createdAt = new Date();
            const user = userCredential?.user
            
            const data = {
                uid: user.uid,
                first_name: firstName,
                last_name: lastName,
                email,
                createdAt,
                phone,
                address: `${street}, ${city}, ${address}`,
                zipCode,
                delivery_address: sameAddress,
            }
    
            await addDoc(collection(db, "users"), data)
            await updateProfile(user, {
                displayName: `${firstName} ${lastName}`
            });
            resetForm()
            setLoading(false)
            enqueueSnackbar(`User created successfully!`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#503225'
                },
            });
            setTimeout(() => {
                navigate(-1)
            }, 1500)
        } catch(error) {
            if (error.code === 'auth/network-request-failed') {
                enqueueSnackbar('Network error, please check your internet connection.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    style: { 
                        backgroundColor: '#ff0000'
                    },
                });
            } else {
                enqueueSnackbar(`Error creating user: ${error.message}`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    style: { 
                        backgroundColor: '#ff0000'
                    },
                });
            }
            console.error(error)
            setLoading(false)
        }
    }


    const signupUser = async () => {
        if (!accept || !firstName || !lastName || !email || !password || !phone || !street || !zipCode || !city) {
            enqueueSnackbar('Please fill all fields', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return
        }

        setLoading(true)
        const passwordError = validatePassword(password);
        if (passwordError) {
            setError(passwordError);
            setLoading(false); 
            return;
        }
    
        try {
            const response = await fetch(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${apiKey}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email,
                password,
                returnSecureToken: true
              })
            });
          
            const userData = await response.json();
    
            if (response.ok) {
              console.log('User signed up successfully:', userData);
            } else {
              console.error('Error signing up user:', userData);
            }

            const createdAt = new Date();
            // const user = userData?.user
            
            const data = {
                // uid: user.uid,
                first_name: firstName,
                last_name: lastName,
                email,
                createdAt,
                phone,
                address: `${street}, ${city}, ${address}`,
                zipCode,
                delivery_address: sameAddress,
            }
    
            await addDoc(collection(db, "users"), data)
            // await updateProfile(user, {
            //     displayName: `${firstName} ${lastName}`
            // });
            resetForm()
            setLoading(false)
            enqueueSnackbar(`User created successfully!`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#503225'
                },
            });
            setTimeout(() => {
                navigate(-1)
            }, 1500)

        } catch(error) {
            if (error.code === 'auth/network-request-failed') {
                enqueueSnackbar('Network error, please check your internet connection.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    style: { 
                        backgroundColor: '#ff0000'
                    },
                });
            } else {
                enqueueSnackbar(`Error creating user: ${error.message}`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    style: { 
                        backgroundColor: '#ff0000'
                    },
                });
            }
            console.error(error)
            setLoading(false)
        }
      };
      
    
    
    return (
        <div className='w-full md:px-0 px-5 -mt-[18px] md:mt-0'>
            <h1 className='text-xl font-bold text-grey-100'>I'M A NEW CUSTOMER</h1>
            <div className='flex flex-col gap-[22px] mt-7'>
                <form className='flex flex-col gap-[20.5px]'>
                    {/* <Select label={'Customer Type'} placeholder={'Select type'}/> */}
                    <Select value={salut} handleChange={setSalut} label={'Salutation'} placeholder={'Select salutation'}>
                        <option value={'Mr'}>Mr</option>
                        <option value={'Mrs'}>Mrs</option>
                        <option value={'Miss'}>Miss</option>
                    </Select>
                    <Input value={firstName} handleChange={setFirstName} label={'First name'} placeholder={'Enter first name'}/>
                    <Input value={lastName} handleChange={setLastName} label={'Last name'} placeholder={'Enter last name'}/>
                    <Input types={'email'} value={email} handleChange={setEmail} label={'Your Email Address'} placeholder={'Enter email address'}/>
                    <div className='flex flex-col gap-[11px] mb-[9px]'>
                        <Input types='password' value={password} handleChange={setPassword} label={'Your Password'} placeholder={'Enter password'}/>
                        {!error && <p className='text-sm text-grey-100'>Your password must contain at least 6 characters.
                        <br/>The password is case sensitive.</p>}
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                    </div>
                    <Input types={'tel'} value={phone} handleChange={setPhone} label={'Phone (Mobile) for delivery services'} placeholder={'Enter phone'}/>
                </form>
                <div className='flex flex-col gap-7'>
                    <h1 className='font-bold text-xl text-grey-100'>YOUR ADDRESS</h1>
                    <form className='flex flex-col gap-[20.5px]'>
                        <Input label={'Location'} placeholder={'United Kingdom only'} read={true}/>
                        <div className='flex gap-[10px] items-center'>
                            <div className='w-[37%]'>
                                <Input value={zipCode} handleChange={setZipCode} label={'Zip Code'} placeholder={'Enter code'}/>
                            </div>
                            <div className='flex-1'>
                                <Input value={city} handleChange={setCity} label={'City'} placeholder={'Enter city'}/>
                            </div>
                        </div>
                        <Input value={street} handleChange={setStreet} label={'Street'} placeholder={'united kingdom only'}/>
                        <Input value={houseNo} handleChange={setHouseNo} label={'Property Number'} placeholder={'Enter house number'}/>
                        <Input value={address} handleChange={setAddress} label={'Additional Address Line'} placeholder={'Enter Address'}/>
                    </form>
                </div>
            </div>
            <div className='mt-[10px] mb-11 flex flex-col gap-5'>
                <div className='flex items-center gap-[7px]'>
                    <div onClick={() => setSameAddress(!sameAddress)} className='size-[19px] border border-secondary rounded-[4px] cursor-pointer flex justify-center items-center'>
                        {sameAddress && <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5L4.5 9L12 1" stroke="#503225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                    </div>
                    <p className='text-sm text-grey-100'>The <span className='font-bold'>shipping address</span> does not match the billing address</p>
                </div>
                <div onClick={() => setAccept(!accept)} className='flex items-center gap-[7px]'>
                    <div className='size-[19px] border border-secondary rounded-[4px] cursor-pointer flex justify-center items-center'>
                        {accept && <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5L4.5 9L12 1" stroke="#503225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                    </div>
                    <p className='text-sm text-grey-100'>I have read and understood the Privacy Policy.</p>
                </div>
                <BasicButtons text={loading ? <CgSpinner className='animate-spin text-lg text-white'/> :'Continue'} font={'Nunito'} functions={handleSignup}/>
            </div>
        </div>
    )
}