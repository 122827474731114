import React from 'react'

export default function Rate({setRate, rate, count}) {
    return (
        <div onClick={() => setRate(count)} className='size-[30px] bg-grey-200 flex justify-center items-center rounded-lg cursor-pointer'>
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.29 16.451L4.622 20.524L6.435 12.924L0.5 7.84001L8.289 7.21601L11.289 0.00100708L14.289 7.21601L22.078 7.84001L16.143 12.924L17.956 20.524L11.29 16.451Z" fill={rate >= count ? "#503225" : "white"}/>
            </svg>
        </div>
    )
}
