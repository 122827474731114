import React, { useState } from 'react'
import { addDoc, collection, query, where, getDocs  } from 'firebase/firestore'
import { db } from '../firebase-config'
import { toast } from 'react-toastify'
import { CgSpinner } from 'react-icons/cg'

import UploadIcon from "../assets/img/upload_icon.png"
import axios from 'axios';


const AddFilters = () => {
    const [loading, setLoading] = useState(false)
    const [categoryLoading, setCategoryLoading] = useState(false)
    const [regionLoading, setRegionLoading] = useState(false)
    const [manufacturerTypeLoading, setManufacturerTypeLoading] = useState(false)

    const [text, setText] = useState("")
    const [categoryText, setCategoryText] = useState("")
    const [regionText, setRegionText] = useState("")

    const [typeName, setTypeName] = useState("")
    const [icon, setIcon] = useState("")
    const [typeImage, setTypeImage] = useState("")


    const createTags = async () => {
        setLoading(true);
        try {
            const tagsCollection = collection(db, "tags");
            const data = {
                tag: text
            }
            console.log(data, "data")
            const docRef = await addDoc(tagsCollection, data)
            console.log("Document written with ID: ", docRef.id);
            setLoading(false);
            toast("tag Added Successfully", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            })
            setText("")
        } catch (error) {
            console.error("Error creating document: ", error);
            setLoading(false);
            toast("Error", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            })
        }
    }


    const createCategory = async () => {
        setCategoryLoading(true);
        try {
            const categoryCollection = collection(db, "category");
            const data = {
                category: categoryText
            }
            console.log(data, "data")
            const docRef = await addDoc(categoryCollection, data)
            console.log("Document written with ID: ", docRef.id);
            setCategoryLoading(false);
            toast("Category Added Successfully", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            })
            setCategoryText("")
        } catch (error) {
            console.error("Error creating document: ", error);
            setCategoryLoading(false);
            toast("Error", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            })
        }
    }


    const createRegion = async () => {
        setRegionLoading(true);
        try {
            const regionCollection = collection(db, "region");
            const data = {
                region: regionText
            }
            console.log(data, "data")
            const docRef = await addDoc(regionCollection, data)
            console.log("Document written with ID: ", docRef.id);
            setRegionLoading(false);
            toast("Region Added Successfully", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            })
            setRegionText("")
        } catch (error) {
            console.error("Error creating document: ", error);
            setRegionLoading(false);
            toast("Error", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            })
        }
    }

    
    const handleFileUpload = async (e) => {
        const files = e.target.files;
        if (files) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'rztljgso');
    
            try {
              const uploadResponse = await axios.post("https://api.cloudinary.com/v1_1/dizlp3hvp/upload", formData);
              const data = uploadResponse.data;
              console.log(data, "data")
              setTypeImage(data);
           
            } catch (error) {
              console.error('Error uploading file:', error);
            }
        }
        }
    };

    const handleIconUpload = async (e) => {
        const files = e.target.files;
        if (files) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'rztljgso');
    
            try {
              const uploadResponse = await axios.post("https://api.cloudinary.com/v1_1/dizlp3hvp/upload", formData);
              const data = uploadResponse.data;
              console.log(data, "data")
            //   setIcon(data);
           
            } catch (error) {
              console.error('Error uploading file:', error);
            }
        }
        }
    };

    // const onDrop = (acceptedFiles) => {
    //     handleFileUpload(acceptedFiles)
    // }

    // const { getRootProps, getInputProps } = useDropzone({ onDrop });


const createManufacturerType = async () => {
    setManufacturerTypeLoading(true);
    
    try {
        // Reference to the manufacturerType collection
        const manufacturerTypeCollection = collection(db, "manufacturerType");
        
        // Query Firestore to check if the typeName already exists
        const q = query(manufacturerTypeCollection, where("typeName", "==", typeName));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // If the typeName already exists, show an error toast
            toast("Manufacturer Type already exists!", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
            setManufacturerTypeLoading(false);
            return;
        }

        // If the typeName does not exist, add it to Firestore
        const data = {
            typeName,
            typeImage: typeImage.secure_url,  // Assuming the Cloudinary response gives the image URL in 'url' field
        };

        const docRef = await addDoc(manufacturerTypeCollection, { manufacturerType: [data] });
        console.log("Document written with ID: ", docRef.id);

        // Success message
        toast("Manufacturer Type added successfully!", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
        });

        // Clear input fields
        setTypeName("");
        setTypeImage("");
    } catch (error) {
        console.error("Error creating document: ", error);
        toast("Error adding Manufacturer Type", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
        });
    } finally {
        setManufacturerTypeLoading(false);
    }
};



  return (
    <div className='flex flex-col gap-10 w-full min-h-screen py-20'>
        <div className='flex flex-col gap-10'>
            <h1 className='text-3xl font-bold'>Add A Tag</h1>
            <div className='relative w-full px-5'>
                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Tag Name</label>
                <div className='w-full flex gap-3 items-center h-[50px]'>
                    <input name='tag'  type='text' value={text} onChange={(e) => setText(e.target.value)} className='placeholder:text-grey-50 font-normal w-4/12 text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    <button 
                        type='button' 
                        className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                        onClick={createTags}
                    >
                        {loading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
                    </button>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10'>
            <h1 className='text-3xl font-bold'>Add A Category</h1>
            <div className='relative w-full px-5'>
                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Category Name</label>
                <div className='w-full flex gap-3 items-center h-[50px]'>
                    <input name='category' type='text' value={categoryText} onChange={(e) => setCategoryText(e.target.value)} className='placeholder:text-grey-50 font-normal w-4/12 text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    <button 
                        type='button' 
                        className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                        onClick={createCategory}
                    >
                        {categoryLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
                    </button>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10'>
            <h1 className='text-3xl font-bold'>Add A Region</h1>
            <div className='relative w-full px-5'>
                <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Region Name</label>
                <div className='w-full flex gap-3 items-center h-[50px]'>
                    <input name='region'  type='text' value={regionText} onChange={(e) => setRegionText(e.target.value)} className='placeholder:text-grey-50 font-normal w-4/12 text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                    <button 
                        type='button' 
                        className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                        onClick={createRegion}
                    >
                        {regionLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
                    </button>
                </div>
            </div>
        </div>

        <div className='flex flex-col gap-10'>
            <h1 className='text-3xl font-bold'>Add Manufacturer Type</h1>
        </div>
        <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-3'>
                <div className='relative w-[30%]'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Name</label>
                    <input type="text" value={typeName} onChange={(e) => setTypeName(e.target.value)} className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'/>
                </div>
                <div className='flex flex-col w-[30%]  bg-transparent rounded-xl items-center border-dashed border-[#D0D5DD] border px-6 py-[8px]  gap-[16px]'>
                    <div className='p-[9px] w-full cursor-pointer flex justify-center gap-[16px] '>
                        {  
                            typeImage?.original_filename ? 
                                <div className='flex flex-col gap-1'>
                                    <div className='flex items-center justify-between'>
                                        <p className='text-[15px] font-hanken text-[#858585]'>{typeImage?.original_filename}</p>
                                        <p className='text-[#000] text-[11px]'>Completed</p>
                                    </div>
                                    <div className='w-[266px] h-[5px] bg-[#51E38B] rounded-lg'></div>
                                </div> 
                                :
                                <div className='flex items-center gap-[16px]' >
                                    <img src={UploadIcon} alt='upload' className='w-6 h-6' />
                            
                                    <label htmlFor="fileInput" className='cursor-pointer flex  items-center text-[#000] text-sm '>
                                        Click to upload <span className='text-[#475367] ml-1'>image file</span>
                                        <input
                                            name='logo'
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }} 
                                            onChange={(e) => handleFileUpload(e)}
                                        />
                                    </label>
                                </div>
                        }
                    </div>
                </div>

                {/* <div className='flex flex-col w-[30%]  bg-transparent rounded-xl items-center border-dashed border-[#D0D5DD] border px-6 py-[8px]  gap-[16px]'>
                    <div className='p-[9px] w-full cursor-pointer flex justify-center gap-[16px] '>
                        {  
                            icon?.original_filename ? 
                                <div className='flex flex-col gap-1'>
                                    <div className='flex items-center justify-between'>
                                        <p className='text-[15px] font-hanken text-[#858585]'>{icon?.original_filename}</p>
                                        <p className='text-[#000] text-[11px]'>Completed</p>
                                    </div>
                                    <div className='w-[266px] h-[5px] bg-[#51E38B] rounded-lg'></div>
                                </div> 
                                :
                                <div className='flex items-center gap-[16px]' > {/* {...getRootProps()} 
                                    <img src={UploadIcon} alt='upload' className='w-6 h-6' />
                            
                                    <label htmlFor="fileIcon" className='cursor-pointer flex  items-center text-[#000] text-sm '>
                                        Click to upload <span className='text-[#475367] ml-1'>icon file</span>
                                        <input
                                            name='icon'
                                            type="file"
                                            id="fileIcon"
                                            style={{ display: 'none' }} 
                                            onChange={(e) => handleIconUpload(e)}
                                        />
                                    </label>
                                </div>
                        }
                    </div>
                </div> */}
            </div>
            <button 
                type='button' 
                className='bg-secondary rounded-lg p-2 w-[20%] h-full flex justify-center text-white items-center' 
                onClick={createManufacturerType}
            >
                {manufacturerTypeLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
            </button>
        </div>

    </div>
  )
}

export default AddFilters