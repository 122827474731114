import React, { useContext, useState, Fragment } from 'react'
import BasicButtons from './Button'
import ReviewBox from './ReviewBox'
import Rate from './Rate'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { UserContext } from '../context/UserContext'
import { handleReview } from '../utils/helperFuncs'

export default function WriteReview({setOpen, data}) {
    const { user } = useContext(UserContext)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [comment, setComment] = useState('')
    const uid = user?.uid;
    const displayName = user?.displayName
    const docId = data?.id
    const [rate, setRate] = useState(0)

    const handleSubmit = async () => {
        if (!comment) {
            enqueueSnackbar(`Please put in a review!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return;
        }
        if (!uid) {
            enqueueSnackbar(`Please sign in for your reviews to be saved!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return;
        }
        await handleReview(docId, uid, displayName, comment, rate)
        setComment('')
        enqueueSnackbar(`Review added successfully!`, {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            style: { 
                backgroundColor: '#503225'
            },
        });
        setTimeout(() => {
            navigate('/')
        }, 1000)
    }
    return (
        <div className='h-screen flex gap-[20.5px] w-full justify-center items-center'>
            <div className='bg-white rounded-lg flex h-[654px]'>
                <div>
                    <div className='h-[89.5px] flex items-center pt-5 pl-10'>
                        <h1 className='text-xl nunito-bold text-grey-100'>Write Review</h1>
                    </div>
                    <div className='w-full h-[1px] bg-grey-200'></div>
                    <div className='pt-[20.5px] px-10 flex flex-col gap-[21px]'>
                        <div className='border border-grey-200 w-[615px] h-[441px] rounded-lg p-[26px] flex flex-col gap-[39px]'>
                            <div className='flex gap-[17px] items-center'>
                                <div className='flex items-center gap-[6px]'>
                                   <Rate setRate={setRate} rate={rate} count={1}/>
                                   <Rate setRate={setRate} rate={rate} count={2}/>
                                   <Rate setRate={setRate} rate={rate} count={3}/>
                                   <Rate setRate={setRate} rate={rate} count={4}/>
                                   <Rate setRate={setRate} rate={rate} count={5}/>
                                </div>
                                <p className='text-grey-50 text-sm'>Select your rating</p>
                            </div>
                            <textarea 
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className='flex-1 w-full bg-transparent focus:outline-none placeholder:text-grey-100 text-grey-100'
                                placeholder='Type your review here...'
                            />
                        </div>
                        <div className='flex justify-end w-full'>
                            <BasicButtons functions={() => handleSubmit()} text={'Post Review'} width={'126px'}/>
                        </div>
                    </div>
                </div>
                
                <div className='h-full w-[1px] bg-grey-200'></div>
                
                <div className='h-full flex flex-col'>
                    <div className='h-[89.5px] pt-5 flex items-center pl-5'>
                        <h1 className='text-xl nunito-bold text-grey-100'>Recent Reviews</h1>
                    </div>
                    <div className='w-full h-[1px] bg-grey-200'></div>
                    <div className='flex-1 pt-[25.5px] overflow-y-scroll px-[10px] flex flex-col'>
                        {data?.reviews?.map((d, index) => (
                            <Fragment key={index}>
                                <div className='w-[348px] px-[10px] mb-[38.71px]'>
                                    <ReviewBox font={true} data={d}/>
                                </div>
                                <div className='w-full h-[1px] bg-grey-200 mb-[25.5px]'></div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <div className='h-[654px]'>
                <div onClick={() => setOpen(false)} className='cursor-pointer size-[41px] rounded-full flex justify-center items-center bg-grey-100'>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.49996 5.08599L11.45 0.135986L12.864 1.54999L7.91496 6.49899L12.865 11.449L11.45 12.864L6.49996 7.91399L1.54996 12.864L0.136963 11.449L5.08696 6.49899L0.136963 1.54899L1.55096 0.135986L6.49996 5.08599Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}
