import React from 'react'
import { toast } from 'react-toastify'
import { db } from '../firebase-config'
import { arrayRemove, doc, getDoc, updateDoc } from 'firebase/firestore'
import { CgSpinner } from 'react-icons/cg'

const DeleteDelivery = ({ handleClose, deleteDeliveryLoading, setDeleteDeliveryLoading, deleteDeliveryId, deliveryToDelete }) => {
    console.log(deleteDeliveryId, "deleteDeliveryId")
    console.log(deliveryToDelete, "deliveryToDelete")

    const deleteItem = async () => {
        setDeleteDeliveryLoading(true);
    
        const docRef = doc(db, "delivery", deleteDeliveryId);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const currentDeliveries = docSnap.data().delivery;
    
                // Log current deliveries and deliveryToDelete for debugging
                console.log("Current deliveries:", currentDeliveries);
                console.log("delivery to delete:", deliveryToDelete);
    
                // Ensure you're filtering by a unique property like an ID if possible
                const updatedDeliveries = currentDeliveries.filter(d => d.name !== deliveryToDelete.name);
    
                console.log("Updated deliveries after deletion:", updatedDeliveries);
    
                // Update Firestore with the filtered list
                await updateDoc(docRef, { delivery: updatedDeliveries });
    
                setDeleteDeliveryLoading(false);
                toast(`Delivery Partner Deleted Successfully`, { 
                    position: "top-right",
                    autoClose: 3500,
                    closeOnClick: true,
                });
                handleClose();
            } else {
                throw new Error("Document does not exist");
            }
        } catch (error) {
            setDeleteDeliveryLoading(false);
            console.error("Error deleting:", error);
            toast(`Error Deleting Delivery Partner`, { 
                position: "top-right",
                autoClose: 3500,
                closeOnClick: true,
            });
            handleClose();
        }
    };

  return (
    <div className='bg-[#fff] w-full lg:w-[400px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
         <div className='flex flex-col justify-center items-center gap-6'>
            <p className='font-bold text-[24px] '>Delete Delivery 🗑 </p>
        
            <div className='bg-[#EDF2F780] px-4 py-2.5 w-[378px] h-[68px] rounded flex items-center gap-3'>
                <p className='font-Mont text-sm text-[#5C6F7F]'>
                    When you click Yes, Delete,  this Delivery Partner  will be Deleted
                </p>
            </div>
            <div className='flex items-center gap-[18px]'>
                <button
                    type='button'
                    className='w-[160px] h-[48px] bg-[#fff] border border-[#5C6F7F] rounded'
                    onClick={handleClose}
                >
                    <p className='font-medium text-base'>Cancel</p>
                </button>
                <button
                    type='button'
                    className='w-[160px] h-[48px] bg-[#f00] text-center flex items-center justify-center rounded'
                    onClick={() => deleteItem()}
                >
                    <p className='text-[#fff] font-medium text-base'>{deleteDeliveryLoading ? <CgSpinner className='animate-spin text-lg'/> : " Yes, Delete"}</p>
                </button>
            </div>
        </div>

    </div>
  )
}

export default DeleteDelivery