import React from 'react'

const ProductVideo = () => {
  return (
    <div className='mt-[30px]'>
        No Video Available
    </div>
  )
}

export default ProductVideo