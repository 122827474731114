import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import L from 'leaflet';

import HomeLayout from "../layout/HomeLayout";
import Map from "../assets/img/map.png";
import MarkerIcon from "../assets/img/marker.png";
import MachineIcon from "../assets/img/machine.png";
import SparePartIcon from "../assets/img/spare-part.png";
import Language from "../components/Language";
import CoffeeList from "../components/coffee/CoffeeList";
import { ShopMap, ShowMap } from "../components/showMap/ShowMap";
import { CoffeeContext } from "../context/CoffeeContext";


import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
import './components/leafletMap.css'

export default function Home() {
     const location = useLocation()
     const { state } = location
     const {showList, setShowList, coffee, mapFilter} = useContext(CoffeeContext);


     console.log(coffee, "List")
     console.log(mapFilter, "mapFilter")

     const openMap = showList || state

     const navigate = useNavigate()

     const createCustomIcon = (logoUrl) => {
          return new L.Icon({
              iconUrl: logoUrl ? logoUrl : MachineIcon,
              iconSize: [50, 50], // size of the icon
              iconAnchor: [19, 38], // point of the icon which will correspond to marker's location
              popupAnchor: [0, -38] // point from which the popup should open relative to the iconAnchor
          });
      };

     
     const italyBounds = [
          [35.288961, 6.627265], // South-West bound (approximation)
          [47.092146, 18.520501] // North-East bound (approximation)
     ];

     const geoUrl = "https://raw.githubusercontent.com/openpolis/geojson-italy/master/topojson/limits_IT_regions.topo.json"
     const citiesWithCoffeeShops = [
          { name: 'Rome', position: [41.9028, 12.4964] },
          { name: 'Milan', position: [45.4642, 9.1900] },
          { name: 'Naples', position: [40.8518, 14.2681] },
          { name: 'Turin', position: [45.0703, 7.6869] },
          { name: 'Palermo', position: [38.1157, 13.3615] },
          { name: 'Genoa', position: [44.4056, 8.9463] },
          { name: 'Bologna', position: [44.4949, 11.3426] },
          { name: 'Florence', position: [43.7696, 11.2558] },
          { name: 'Bari', position: [41.1171, 16.8719] },
          { name: 'Catania', position: [37.5079, 15.0830] },
          { name: 'Venice', position: [45.4408, 12.3155] },
          { name: 'Verona', position: [45.4384, 10.9916] },
          { name: 'Messina', position: [38.1938, 15.5540] },
          { name: 'Padua', position: [45.4064, 11.8768] },
          { name: 'Trieste', position: [45.6495, 13.7768] }
      ];
      
        
     return (
          <HomeLayout>
               {/* <div className="w-full flex xl:flex-row flex-col" style={{ height: "calc(100vh - 100px)" }}>
                    {openMap && <CoffeeList setOpen={setShowList} open={openMap}/>}
                    <div className="flex-1 w-full h-full xl:block hidden">
                         <MapContainer 
                              center={[40.8518, 14.2681]} 
                              zoom={12} 
                              style={{ height: '100vh', width: '100%' }} 
                              // maxBounds={italyBounds} 
                              // maxBoundsViscosity={1.0} 
                         >
                              <TileLayer
                                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              />
                              {mapFilter?.map(city => {
                                   console.log(city, "city")
                              return (
                                   <Marker 
                                        position={[city?.position[0], city?.position[1]]} 
                                        key={city?.id} 
                                   >
                                        <Popup> 
                                             <div className="flex items-start gap-5" onClick={() => navigate('/coffee', { state: city })}>
                                                  <img src={city?.logo} alt="Shop_logo" className="cursor-pointer w-20" />
                                                  <div className="flex flex-col">
                                                       <p style={{ marginBottom: 0 }}>Company Name: {city?.company}</p>
                                                       <p>Company Address:{city?.address}</p>
                                                  </div>
                                             </div>
                                        </Popup>
                                   </Marker>
                                   
                              )
                              })}
                         </MapContainer>
                    </div>
                    {!openMap &&
                         <div className="flex-1 w-full h-full xl:hidden block">
                              <MapContainer 
                                   center={[40.8518, 14.2681]} 
                                   zoom={7} 
                                   style={{ height: '100vh', width: '100%' }} 
                                   maxBounds={italyBounds} 
                                   maxBoundsViscosity={1.0}
                              >
                                   <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                   />
                                   {mapFilter?.map(city => (
                                        <Marker 
                                             position={[city?.position[0], city?.position[1]]} 
                                             key={city?.id} 
                                        >
                                             <Popup> 
                                                  
                                                  <img src={city?.logo} alt="Shop_logo" onClick={() => navigate('/coffee', { state: city })} className="cursor-pointer" />
                                             </Popup>
                                        </Marker>
                                   ))}
                              </MapContainer>
                         </div>
                    }
                    
                    <div
                         onClick={() => setShowList(!openMap)}
                         className={`${
                              openMap && "left-[53%]"
                         } cursor-pointer absolute z-50 mt-5 w-[101px] h-[39px] gap-2 bg-white rounded-r-md xl:flex hidden justify-center items-center shadow-[3px_0_6px_0_#00000029]`}
                    >
                         <svg
                              width="8"
                              height="13"
                              viewBox="0 0 8 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M5.06011 6.49899L0.110107 1.54899L1.52511 0.135986L7.88911 6.49899L1.52511 12.864L0.111108 11.449L5.06011 6.49899Z"
                                   fill="#503225"
                              />
                         </svg>
                         <p>{openMap ? "Hide list" : "Show list"}</p>
                    </div>
                    <div
                         onClick={() => setShowList(!openMap)}
                         className={`${
                              openMap && "bottom-0 mt-0"
                         } cursor-pointer absolute z-50 mt-5 w-[101px] h-[39px] gap-2 bg-white rounded-r-md xl:hidden flex justify-center items-center shadow-[3px_0_6px_0_#00000029]`}
                    >
                         <p>{openMap ? "Show map" : "Show list"}</p>
                         <svg
                              className={`${openMap && 'rotate-180'}`}
                              width="8"
                              height="13"
                              viewBox="0 0 8 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M5.06011 6.49899L0.110107 1.54899L1.52511 0.135986L7.88911 6.49899L1.52511 12.864L0.111108 11.449L5.06011 6.49899Z"
                                   fill="#503225"
                              />
                         </svg>
                    </div>
                    <Language />
               </div> */}

               <div className="w-full flex xl:flex-row flex-col" style={{ height: "calc(100vh - 100px)" }}>
                    {openMap && <CoffeeList setOpen={setShowList} open={openMap} />}
                    <div className="flex-1 w-full h-full xl:block hidden">
                    <MapContainer 
                         center={[40.8518, 14.2681]} 
                         zoom={7} 
                         style={{ height: '100vh', width: '100%' }} 
                    >
                         {/* <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                         /> */}
                         <TileLayer
                              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://carto.com/attributions">CARTO</a>'
                              style={{
                                   filter: 'brightness(1.5) contrast(1.3) saturate(1.5) hue-rotate(10deg)',
                               }}
                         />
                         {mapFilter?.map(city => (
                              <Marker 
                                   position={[city?.position[0], city?.position[1]]} 
                                   key={city?.id} 
                                   icon={createCustomIcon(city?.manufacturerType[0]?.typeImage)}
                              >
                                   <Popup>
                                        <div className="flex items-start gap-5" onClick={() => navigate('/coffee', { state: city })}>
                                        <img src={city?.logo} alt="Shop_logo" className="cursor-pointer w-20" />
                                        <div className="flex flex-col">
                                             <p style={{ marginBottom: 0 }}>{city?.company}</p>
                                             <p>{city?.address}</p>
                                             <p>Products Sales: {city?.products[0]?.visibility ? "Yes" : "No"}</p>
                                        </div>
                                        </div>
                                   </Popup>
                              </Marker>
                         ))}
                    </MapContainer>
                    </div>
                    {!openMap && (
                    <div className="flex-1 w-full h-full xl:hidden block">
                         <MapContainer 
                              center={[40.8518, 14.2681]} 
                              zoom={7} 
                              style={{ height: '100vh', width: '100%' }} 
                              maxBounds={italyBounds} 
                              maxBoundsViscosity={1.0}
                         >
                              {/* <TileLayer
                                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              /> */}
                              <TileLayer
                                   url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://carto.com/attributions">CARTO</a>'
                                   style={{
                                        filter: 'brightness(1.5) contrast(1.3) saturate(1.5) hue-rotate(10deg)',
                                    }}
                              />
                              {/* <TileLayer
                                url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
                                attribution='&copy; <a href="http://maps.stamen.com">Stamen Design</a>, &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              /> */}
                              {mapFilter?.map(city => (
                                   <Marker 
                                        position={[city?.position[0], city?.position[1]]} 
                                        key={city?.id} 
                                        icon={createCustomIcon(city?.manufacturerType[0]?.typeImage)}
                                   >
                                        <Popup>
                                        <img src={city?.logo} alt="Shop_logo" onClick={() => navigate('/coffee', { state: city })} className="cursor-pointer" />
                                        </Popup>
                                   </Marker>
                              ))}
                         </MapContainer>
                    </div>
                    )}
               {/* <Language /> */}
               </div>
          </HomeLayout>
     );
}
