import React, { useState } from 'react'
import SwiperBrand from './SwiperBrand'

export default function CoffeeBrands({data}) {
    const [brand, setBrand] = useState('COFFEE BEANS')

    return (
        <div className='mt-[2px] text-white'>
            <h1 className='nunito-bold md:text-[40px] text-[28px] xl:pl-0 pl-5'> Popular Categories</h1>
            <h2 className='mt-[9px] text-[18px] nunito-normal opacity-50 xl:pl-0 pl-5'>Contrary to popular belief and going through the cites of the word source.</h2>
            <div className='mt-6 w-full flex flex-col gap-[35.7px]'>
                <div className='xl:w-[900px] w-full flex items-center'>
                    <div className={`${!data?.esePodsVisibility ? "hidden" : "cursor-pointer w-full flex flex-col gap-[14.6px]"}`} onClick={() => setBrand('ESE PODS')}>
                        <p className={`${brand === 'ESE PODS' ? 'opacity-1' : 'opacity-50'} text-[#F4F5F7] md:text-xl sm:text-sm text-xs text-center`}>ESE PODS</p>
                        <div className={`h-1 ${brand === 'ESE PODS' ? 'bg-tertiary' : 'bg-[#EBF0FF] opacity-[9%]'} w-full`}></div>
                    </div>
                    <div className={`${!data?.compatibleCapsuleVisibility ? "hidden" : "cursor-pointer w-full flex flex-col gap-[14.6px]"}`} onClick={() => setBrand('COMPATIBLE CAPSULES')}>
                        <p className={`${brand === 'COMPATIBLE CAPSULES' ? 'opacity-1' : 'opacity-50'} text-[#F4F5F7] md:text-xl sm:text-sm text-xs text-center`}>COMPATIBLE CAPSULES</p>
                        <div className={`h-1 ${brand === 'COMPATIBLE CAPSULES' ? 'bg-tertiary' : 'bg-[#EBF0FF] opacity-[9%]'} w-full`}></div>
                    </div>
                    <div className={`${!data?.grindedCoffeeVisibility ? "hidden" : "cursor-pointer w-full flex flex-col gap-[14.6px]"}`} onClick={() => setBrand('GRINDED COFFEE')}>
                        <p className={`${brand === 'GRINDED COFFEE' ? 'opacity-1' : 'opacity-50'} text-[#F4F5F7] md:text-xl sm:text-sm text-xs text-center`}>GRINDED COFFEE</p>
                        <div className={`h-1 ${brand === 'GRINDED COFFEE' ? 'bg-tertiary' : 'bg-[#EBF0FF] opacity-[9%]'} w-full`}></div>
                    </div>
                    <div className={`${!data?.coffeeBeansVisibility ? "hidden" : "cursor-pointer w-full flex flex-col gap-[14.6px]"}`} onClick={() => setBrand('COFFEE BEANS')}>
                        <p className={`${brand === 'COFFEE BEANS' ? 'opacity-1' : 'opacity-50'} text-[#F4F5F7] md:text-xl sm:text-sm text-xs text-center`}>COFFEE BEANS</p>
                        <div className={`h-1 ${brand === 'COFFEE BEANS' ? 'bg-tertiary' : 'bg-[#EBF0FF] opacity-[9%]'} w-full`}></div>
                    </div>
                </div>
                <div className='xl:pl-0 md:block hidden'>
                    <SwiperBrand width={false} data={data} brand={brand}/>
                </div>
                <div className='md:hidden block pl-5'>
                    <SwiperBrand width={true} data={data} brand={brand}/>
                </div>
            </div>
        </div>
    )
}
