import React, { useState } from 'react'
import BasicButtons from './Button'
import Input from './Input'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase-config'
import { useSnackbar } from 'notistack'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'

export default function Login() {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const resetForm = () => {
        setEmail(''); setPassword('');
    };
    
    const apiKey = 'AIzaSyDOLl5z9E-Q72ijjDqT7-VD2GBYQ2zp1Lc';

    const handleLogin = async () => {
        if (!email || !password) {
            enqueueSnackbar('Please fill all fields', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return
        }

        setLoading(true)
        
        try {
            await signInWithEmailAndPassword(auth, email, password);
            resetForm()
            setLoading(false)
            enqueueSnackbar(`User logged in successfully!`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#503225'
                },
            });
            setTimeout(() => {
                navigate(-1)
            }, 1500)
        } catch(error) {
            setLoading(false)
            if (error.code === 'auth/invalid-credential') {
                enqueueSnackbar('Invalid Email/Password', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    style: { 
                        backgroundColor: '#ff0000'
                    },
                });
            } 
            // else {
            //     enqueueSnackbar(`${error.message}`, {
            //         variant: 'error',
            //         anchorOrigin: {
            //             vertical: 'top',
            //             horizontal: 'right',
            //         },
            //         style: { 
            //             backgroundColor: '#ff0000'
            //         },
            //     });
            // }
            console.error(error);
        }
    }

    // const handleLogin = async () => {
    //     if (!email || !password) {
    //         enqueueSnackbar('Please fill all fields', {
    //             variant: 'error',
    //             anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right',
    //             },
    //             style: { 
    //                 backgroundColor: '#ff0000'
    //             },
    //         });
    //         return;
    //     }
    
    //     setLoading(true);
        
    //     try {
    //         const response = await fetch(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 email,
    //                 password,
    //                 returnSecureToken: true
    //             })
    //         });
          
    //         const userData = await response.json();
    
    //         if (response.ok) {
    //             console.log('User logged in successfully:', userData);
    //             resetForm();
    //             setLoading(false);
    //             enqueueSnackbar(`User logged in successfully!`, {
    //                 variant: 'success',
    //                 anchorOrigin: {
    //                     vertical: 'top',
    //                     horizontal: 'right',
    //                 },
    //                 style: { 
    //                     backgroundColor: '#503225'
    //                 },
    //             });
    //             setTimeout(() => {
    //                 navigate(-1);
    //             }, 1500);
    //         } else {
    //             throw new Error(userData.error.message);
    //         }
    //     } catch(error) {
    //         setLoading(false);
    //         enqueueSnackbar(`${error.message}`, {
    //             variant: 'error',
    //             anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right',
    //             },
    //             style: { 
    //                 backgroundColor: '#ff0000'
    //             },
    //         });
    //         console.error('Error logging in user:', error);
    //     }
    // };
    

    return (
        <div className='w-full md:px-0 px-5'>
            <h1 className='text-xl font-bold text-grey-100 mb-[2px]'>I'M ALREADY A CUSTOMER.</h1>
            <p className='text-grey-100'>Log in with your email address and password</p>
            <div className='flex flex-col gap-[21px] mt-[30px]'>
                <form className='flex flex-col gap-[21.5px]'>
                    <Input value={email} types={'email'} handleChange={setEmail} label={'Your Email Address'}/>
                    <Input value={password} types={'password'} handleChange={setPassword} label={'Your Password'}/>
                </form>
                <div className='flex flex-col gap-[19px]'>
                    <p className='font-bold cursor-pointer' onClick={() => navigate("/forgot-password")}>Forgot your password?</p>
                    <BasicButtons text={loading ? <CgSpinner className='animate-spin text-lg text-white'/> :'Log In'} font={'Nunito'} functions={handleLogin}/>
                </div>
            </div>
        </div>
    )
}
