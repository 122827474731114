import React from 'react'
import HomeLayout from '../layout/HomeLayout'
import { useNavigate } from 'react-router-dom'
import WishBox from '../components/WishBox'

export default function Wishlist() {
  const navigate = useNavigate()
  return (
    <HomeLayout>
      <div className='fixed z-10 w-full bg-white border border-grey-200 xl:pl-[4%] pl-5 h-[71px] flex gap-5 items-center'>
        <div onClick={() => navigate(-1)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.9403 6.99998H16.1123V8.99998H3.9403L9.30431 14.364L7.8903 15.778L0.112305 7.99998L7.8903 0.221985L9.30431 1.63598L3.9403 6.99998Z" fill="#7E3701"/>
          </svg>
        </div>
        <h1 className='nunito-bold text-primary text-2xl'>Wish List</h1>
      </div>
      <div className='md:px-[4%] mt-[84px]'>
        <h2 className='md:px-[0] px-5 nunito-bold text-grey-100 text-[18px]'>Save your personal favorites until your next visit.</h2>
        <p className='md:px-[0] px-5 mt-[7px] nunito-normal text-grey-100 text-[18px]'>Simply add a desired product to the wish list and Espresso International will save it for you. Thus you are able to call up your selected products the next time you visit the online shop.</p>
        <div className='mt-[19px] flex flex-col'>
          <WishBox />
        </div>
      </div>
    </HomeLayout>
  )
}
