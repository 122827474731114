import React, { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { doc, getDoc, addDoc, updateDoc, arrayUnion, collection, getDocs, where, query } from 'firebase/firestore'
import { toast } from 'react-toastify' 
import { db } from '../firebase-config'

const AddDelivery = ({  handleClose, manufacturerData, setAddDeliveryLoading }) => {
    const [deliveryLoading, setDeliveryLoading] = useState(false)
    const [timeFrame, setTimeFrame] = useState("")
    const [serviceType, setServiceType] = useState("")
    const [price, setRate] = useState(0)
    const [name, setName] = useState("")

    const handleServiceType = (e) => {
        setServiceType(e.target.value)
    }

    const createDeliveries = async () => {
        if (!name || !timeFrame || !price) {
            toast.error("Please fill all fields")
            return
        }

        setDeliveryLoading(true)
        setAddDeliveryLoading(true)
        try {
            const deliveryCollection = collection(db, "delivery");
            const q = query(deliveryCollection, where("company", "==", manufacturerData?.company));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                // Create new document if it doesn't exist
                const data = {
                    company: manufacturerData?.company,
                    delivery: [{ name, timeFrame, price: parseFloat(price) }] 
                };
                const docRef = await addDoc(deliveryCollection, data);
                console.log("New document created with ID: ", docRef.id);
                toast.success("Delivery Partner added successfully");
                setDeliveryLoading(false)
                setAddDeliveryLoading(false)
                handleClose()
            } else {
                // Update existing document
                const doc = querySnapshot.docs[0];
                const existingDeliveries = doc.data().delivery || [];
                const deliveryExists = existingDeliveries.some(c => c.name === name);

                if (deliveryExists) {
                    console.log("Delivery Partner already exists for this company");
                    toast.error("Delivery Partner already exists for this company");
                } else {
                    await updateDoc(doc.ref, {
                        currency: [...existingDeliveries, { name: name.toUpperCase(), timeFrame:timeFrame , serviceType:serviceType }] //price: parseFloat(price)
                    });
                    console.log("Document updated with ID: ", doc.id);
                    toast.success("Delivery Partner added successfully");
                }
            }

            handleClose();
        } catch (error) {
            console.error("Error adding Delivery Partner:", error);
            toast.error("Failed to add Delivery Partner");
        } finally {
            setDeliveryLoading(false);
            setAddDeliveryLoading(false)
        }
    }

  return (
    <div className='bg-[#efefef] w-full lg:w-[500px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
         <div className='flex flex-col gap-10'>
            <h1 className='text-3xl text-center font-bold'>Add Delivery</h1>
            <div className='flex'>
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Courier Name</label>
                    <input 
                        type="text"
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        className='placeholder:text-grey-50 font-normal w-full capitalize text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                    />
                </div>
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Service Type</label>
                    <select
                        onChange={handleServiceType}
                        value={serviceType}
                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none appearance-none'
                    >
                        <option value="">Select</option>
                        <option value="normal">Normal</option>
                        <option value="express">Express</option>
                    </select>
                </div>
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Time Frame</label>
                    <input 
                        type="text" 
                        value={timeFrame} 
                        onChange={(e) => setTimeFrame(e.target.value)} 
                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                    />
                </div>
                {/* <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Price</label>
                    <input 
                        type="number" 
                        value={price} 
                        onChange={(e) => setRate(e.target.value)} 
                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                    />
                </div> */}
            </div>
            <div className='flex justify-between'>
                <button 
                    type='button' 
                    className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
                    onClick={createDeliveries}
                >
                    {deliveryLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
                </button>
                <button 
                    type='button' 
                    className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
                    onClick={handleClose}
                >
                    Cancel
                </button>

            </div>
        </div>
    </div>
  )
}

export default AddDelivery