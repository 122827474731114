import React, { useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { toast } from 'react-toastify';

const EditRate = ({ handleClose, editCurrencyLoading, setEditCurrencyLoading, currencyData, editCurrencyId }) => {
    const [symbol, setSymbol] = useState(currencyData?.symbol)
    const [rate, setRate] = useState(currencyData?.rate)
    const [name, setName] = useState(currencyData?.name)

    console.log(currencyData, 'currencyData')
    console.log(editCurrencyId, 'editCurrencyId')

    const submitForm = async () => {
        // setEditCurrencyLoading(true);
        try {
            const docRef = doc(db, 'currency', editCurrencyId); // Replace with your Firestore collection and document ID
    
            // Get the current document
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                const currentData = docSnapshot.data();
                const currencyArray = currentData.currency;

                // Find the specific currency to update
                const updatedCurrencyArray = currencyArray.map((item) => {
                    console.log(item, "saske")
                    if (item.symbol === currencyData.symbol) {
                        return {
                            ...item,
                            name:name.toUpperCase(), 
                            symbol:symbol,
                            rate:rate 
                        } // Update the currency details
                    }  else {
                        return item
                    } 
                });
              
                // Update the document with the modified array
                await updateDoc(docRef, {
                    currency: updatedCurrencyArray,
                });
                setEditCurrencyLoading(false);
                console.log('Document successfully updated');
                toast(`Currency Updated Successfully`, { 
                    position: "top-right",
                    autoClose: 3500,
                    closeOnClick: true,
                }); 
                handleClose();
            } else {
                console.log('No such document!');
                setEditCurrencyLoading(false);
                toast(`Currency Does Not Exist`, { 
                    position: "top-right",
                    autoClose: 3500,
                    closeOnClick: true,
                }); 
                handleClose();
            }
        } catch (error) {
            console.error('Error updating document: ', error);
            toast(`Error Updating Currency`, { 
                position: "top-right",
                autoClose: 3500,
                closeOnClick: true,
            }); 
            handleClose();
        } finally {
            setEditCurrencyLoading(false);
        }
    };

  return (
    <div className='bg-[#efefef] w-full lg:w-[500px] overflow-y-auto p-4 gap-5 flex flex-col h-[250px]'>
         <div className='flex flex-col gap-10'>
            <h1 className='text-3xl text-center font-bold'>Edit Currency</h1>
            <div className='flex'>
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Name</label>
                    <input 
                        type="text"
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        className='placeholder:text-grey-50 font-normal w-full capitalize text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                    />
                </div>
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Symbol</label>
                    <input 
                        type="text" 
                        value={symbol} 
                        onChange={(e) => setSymbol(e.target.value)} 
                        className='placeholder:text-grey-50 font-normal  w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                    />
                </div>
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Rate</label>
                    <input 
                        type="number" 
                        value={rate} 
                        onChange={(e) => setRate(e.target.value)} 
                        className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'
                    />
                </div>
            </div>
            <div className='flex justify-between'>
                <button 
                    type='button' 
                    className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
                    onClick={submitForm}
                >
                    {editCurrencyLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Update" }
                </button>
                <button 
                    type='button' 
                    className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
                    onClick={handleClose}
                >
                    Cancel
                </button>

            </div>
        </div>
    </div>
  )
}

export default EditRate