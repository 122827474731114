import React, { useState } from 'react'
import BasicButtons from './Button'
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore'
import { db } from '../firebase-config'
import { useSnackbar } from 'notistack'

export default function Subscription() {
    const { enqueueSnackbar } = useSnackbar()
    const [email, setEmail] = useState('')

    const handleSubscribe = async () => {
        if (!email) {
            enqueueSnackbar(`Please put an email!`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
            return
        } 
        try {
            // Check if the email is already subscribed
            const emailQuery = query(collection(db, "subscribers"), where("email", "==", email));
            const emailQuerySnapshot = await getDocs(emailQuery);
            
            if (!emailQuerySnapshot.empty) {
              // Email is already subscribed, throw error
              throw new Error('Email is already subscribed');
            }
        
            // Email is not subscribed, add it to the subscribers collection
            await addDoc(collection(db, "subscribers"), { email: email });
            setEmail('');
        
            enqueueSnackbar(`User subscribed successfully!`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#503225'
                },
            });
        } catch(error) {
            console.error(error);
            enqueueSnackbar(`Error: ${error.message}`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#ff0000'
                },
            });
        }
    }

    return (
        <div className='mt-[163.98px] bg-secondary xl:px-[4%] px-5 text-white py-[50px] flex xl:flex-row flex-col xl:gap-0 gap-5 justify-between xl:items-center'>
            <div className='flex flex-col gap-[10px] xl:w-[65%] w-full'>
                <h1 className='nunito-bold text-xl'>SUBSCRIBE TO OUR NEWS LETTER</h1>
                <div>
                    <h3 className='nunito-bold text-[18px]'>Offers and news for you</h3>
                    <p className='text-sm nunito-normal'>Subscribe to the free newsletter and ensure that you will no longer miss any offers or news of Espresso International.</p>
                </div>
            </div>
            <div className='flex-1 flex xl:justify-end'>
                <div className='rounded-lg h-[50px] sm:w-[403px] w-full border border-grey-200 bg-white flex gap-[10px] items-center pl-5'>
                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.75903 0H19.759C20.0243 0 20.2786 0.105357 20.4661 0.292893C20.6537 0.48043 20.759 0.734784 20.759 1V17C20.759 17.2652 20.6537 17.5196 20.4661 17.7071C20.2786 17.8946 20.0243 18 19.759 18H1.75903C1.49382 18 1.23946 17.8946 1.05193 17.7071C0.86439 17.5196 0.759033 17.2652 0.759033 17V1C0.759033 0.734784 0.86439 0.48043 1.05193 0.292893C1.23946 0.105357 1.49382 0 1.75903 0ZM18.759 4.238L10.831 11.338L2.75903 4.216V16H18.759V4.238ZM3.27003 2L10.82 8.662L18.259 2H3.27003Z" fill="#503225"/>
                    </svg>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Your email address' className='placeholder:text-grey-50 caret-secondary w-full h-full focus:outline-none bg-transparent text-black'/>
                    <BasicButtons functions={handleSubscribe} height={'100%'} text={'Subscribe'} font={'Nunito'} width={'auto'}/>
                </div>
            </div>
        </div>
    )
}
