export const currencyOptions = [
    { name: 'EUR', symbol: '€' },         // Euro
    { name: 'USD', symbol: '$' },         // US Dollar
    { name: 'GBP', symbol: '£' },         // British Pound
    { name: 'CAD', symbol: '$' },     // Canadian Dollar
    { name: 'CNY', symbol: '¥' },      // Chinese Yuan
    { name: 'AED', symbol: 'د.إ' },      // UAE Dirham
    { name: 'BRL', symbol: 'R$' },    // Brazilian Real
    { name: 'SAR', symbol: '﷼' }, // Saudi Riyal
    { name: 'INR', symbol: '₹' },      // Indian Rupee
    { name: 'KES', symbol: 'KSh' },    // Kenyan Shilling
    { name: 'ZAR', symbol: 'R' }, // South African Rand
    { name: 'ARS', symbol: '$' },  // Argentine Peso
    { name: 'JPY', symbol: '¥' },      // Japanese Yen
    { name: 'KRW', symbol: '₩' }, // South Korean Won
    { name: 'NZD', symbol: '$' }, // New Zealand Dollar
    { name: 'ALL', symbol: 'L' },    // Albanian Lek
    { name: 'CHF', symbol: 'Fr' }, // Swiss Franc
    { name: 'TRY', symbol: '₺' },     // Turkish Lira
    { name: 'DKK', symbol: 'kr' },   // Danish Krone
    { name: 'NOK', symbol: 'kr' }     // Norwegian Krone
];
