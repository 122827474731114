import React, { useState } from 'react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'

import CloseIcon from "../assets/svg/closeIcon.svg"
import { toast } from 'react-toastify'
import { db } from '../firebase-config'
import { CgSpinner } from 'react-icons/cg'

const EditTag = ({ handleClose, singleRegionData, updateEditRegionLoading, setUpdateEditRegionLoading }) => {
    const [text, setText] = useState("")

    console.log(singleRegionData, "singleRegionData")

    const submitForm = async () => {
        setUpdateEditRegionLoading(true);
        const data = {
            id: singleRegionData?.id,
            region: text
        };

        try {
            const docRef = doc(db, 'region', data?.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                await updateDoc(docRef, data);
                toast(`Region Updated Successfully`, { 
                    position: "top-right",
                    autoClose: 3500, 
                    closeOnClick: true,
                });
                handleClose();
            } else {
                toast(`No document found to update`, { 
                    position: "top-right",
                    autoClose: 3500, 
                    closeOnClick: true,
                });
            }
        } catch (error) {
            console.error('Error updating document: ', error);
        } finally {
            setUpdateEditRegionLoading(false);
        }
    };


  return (
    <div className='bg-[#fff] w-[600px] h-[200px] flex flex-col gap-4 overflow-y-scroll rounded-lg p-4'>
        <div className='flex items-center justify-between'>
            <p className='font-medium font-Hat text-[#3F434A]  text-[24px] text-[#000]'>Edit Region</p>
            <button className="flex justify-center items-center" onClick={handleClose}> 
                <img src={CloseIcon} alt='close' />
            </button>
        </div>

        <div className='flex gap-4'>
            <input 
                name='tag'
                placeholder={`${singleRegionData?.region}`}
                onChange={(e) => setText(e.target.value)}
                className='w-8/12 rounded-lg p-4 border border-[#ccc] outline-none'
            />
            <button
                type='button'
                onClick={() => submitForm()}
                className='rounded-lg bg-secondary flex items-center justify-center w-4/12'
            >
                <p className='text-[#fff] text-base font-nunito font-semibold'>{updateEditRegionLoading ? <CgSpinner className=" animate-spin text-xl " /> : 'Update'}</p>

            </button>
        </div>

    </div>
  )
}

export default EditTag