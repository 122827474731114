import React from 'react'

const ProductPhoto = () => {
  return (
    <div className='mt-[30px]'>
        No Images Available
    </div>
  )
}

export default ProductPhoto