import React from 'react'
import BasicButtons from './Button'
import { useLocation, useNavigate } from 'react-router-dom'

export default function AddToCart({ setOpen, manufacturerData, selectedCurrency }) {
    const navigate = useNavigate()
    const location = useLocation()
    const active = location.pathname


    return (
        <div className='h-screen w-full flex justify-center items-center'>
            <div className='sm:w-[390px] w-[370px] h-[325px] rounded-lg flex flex-col justify-center items-center bg-white'>
                <svg width="44" height="46" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.808 10.417L0 3.60798L2.969 0.638977L9.778 7.44798H41.778C42.1049 7.4482 42.4273 7.52477 42.7195 7.67157C43.0116 7.81838 43.2654 8.03136 43.4607 8.29357C43.656 8.55578 43.7874 8.85997 43.8444 9.18191C43.9014 9.50385 43.8824 9.83466 43.789 10.148L38.75 26.948C38.6209 27.3811 38.3554 27.761 37.9931 28.0313C37.6308 28.3015 37.191 28.4477 36.739 28.448H11.008V32.648H34.108V36.848H8.908C8.35105 36.848 7.8169 36.6267 7.42308 36.2329C7.02925 35.8391 6.808 35.3049 6.808 34.748V10.417ZM11.008 11.647V24.247H35.179L38.958 11.647H11.008ZM9.958 45.247C9.33499 45.247 8.72597 45.0622 8.20795 44.7161C7.68994 44.37 7.2862 43.878 7.04778 43.3024C6.80936 42.7268 6.74698 42.0935 6.86853 41.4824C6.99007 40.8714 7.29008 40.3101 7.73061 39.8696C8.17115 39.4291 8.73243 39.129 9.34347 39.0075C9.95451 38.886 10.5879 38.9483 11.1635 39.1868C11.739 39.4252 12.231 39.8289 12.5771 40.3469C12.9233 40.8649 13.108 41.474 13.108 42.097C13.1067 42.9315 12.7742 43.7315 12.1836 44.3211C11.593 44.9108 10.7926 45.242 9.958 45.242V45.247ZM35.158 45.247C34.535 45.247 33.926 45.0622 33.408 44.7161C32.8899 44.37 32.4862 43.878 32.2478 43.3024C32.0094 42.7268 31.947 42.0935 32.0685 41.4824C32.1901 40.8714 32.4901 40.3101 32.9306 39.8696C33.3712 39.4291 33.9324 39.129 34.5435 39.0075C35.1545 38.886 35.7879 38.9483 36.3635 39.1868C36.939 39.4252 37.431 39.8289 37.7771 40.3469C38.1233 40.8649 38.308 41.474 38.308 42.097C38.3075 42.5106 38.2255 42.9201 38.0667 43.302C37.9079 43.6839 37.6754 44.0308 37.3825 44.3228C37.0896 44.6149 36.7421 44.8464 36.3597 45.0041C35.9773 45.1618 35.5676 45.2426 35.154 45.242L35.158 45.247Z" fill="black"/>
                </svg>
                <p className='mt-[15.75px] nunito-bold text-xl text-grey-100'>Item Added to Cart</p>
                <div className='mt-5 flex flex-col gap-[10px]'>
                    <BasicButtons functions={() => navigate('/cart', { state: { manufacturerData, selectedCurrency } })} width={'251px'} text={'View Cart'} />
                    <BasicButtons functions={active === '/cartbag' ? () => setOpen(false) : () => navigate('/cartbag')} bg={true} width={'251px'} text={'Continue Shopping'} />
                </div>
            </div>
        </div>
  )
}
