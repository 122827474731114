import React from 'react'
import Emptys from '../../assets/svg/empty.svg'

export default function Empty() {
    return (
        <div className='flex-1 w-full flex justify-center items-center flex-col gap-[38.5px] xl:-mt-20'>
            <img src={Emptys} alt='empty' />
            <div className='flex flex-col gap-[10px] items-center'>
                <p className='nunito-bold text-xl'>No Products Available</p>
                <p className='nunito-normal text-[18px] text-center'>We're sorry, but it seems that there are currently no products available in this manufacturer page.
                Our inventory may be updating, or we might be working on bringing in new items.
                Please check back later or explore other maufacturers to find the products you're looking for</p>
            </div>
        </div>
    )
}
