import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Review from '../assets/svg/star3.svg'

export default function AllBrands() {
    const navigate = useNavigate()
    const location = useLocation()
    const { state } = location
    const [brand, setBrand] = useState('All')
    const [search, setSearch] = useState('') 
    const brandList = ['ESE PODS', 'COMPATIBLE CAPSULES', 'GRINDED COFFEE', 'COFFEE BEANS']
    const allBrandData = brandList.reduce((accumulator, brand) => {
        if (state[brand]) {
            accumulator = accumulator.concat(state[brand]);
        }
        return accumulator;
    }, []);

    const [filteredBrands, setFilteredBrands] = useState(allBrandData)

    const handleSearch = (e) => {
        const value = e.target.value
        setSearch(value)
        const newData = allBrandData?.filter((f) => f.name.toLowerCase().includes(value.toLowerCase()))
        setFilteredBrands(newData)
    }

    return (
        <div className='flex flex-col w-full gap-[21.4px] mb-[15px]'>
            <div className='hero xl:h-[319px] h-[392px] w-full border border-grey-150 px-[4%] pt-[31px]'>
                <div onClick={() => navigate('/')} className='cursor-pointer flex gap-[10px] items-center'>
                    <div className='flex justify-center items-center size-10 rounded-lg border border-white'>
                        <p className='poppins-bold text-white text-xl'>IT</p>
                    </div>
                    <p className='nunito-bold text-white text-xl'>italiancoffee</p>
                </div>
                <div className='flex flex-col gap-[17.5px] mt-[60px] w-full items-center'>
                    <h3 className='text-[28px] nunito-bold text-white text-center'>The best coffee brands you have been searching for</h3>
                    <div className='sm:w-[590px] w-full bg-white rounded-lg h-[50px] px-5 flex justify-between items-center'>
                        <input value={search} onChange={(e) => handleSearch(e)} placeholder='Search brands' className='h-full w-full bg-transparent focus:outline-none'/>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 0.186005C10.78 0.186005 12.5201 0.713845 14.0001 1.70278C15.4802 2.69171 16.6337 4.09732 17.3149 5.74185C17.9961 7.38639 18.1743 9.19599 17.8271 10.9418C17.4798 12.6876 16.6226 14.2913 15.364 15.55C14.1053 16.8086 12.5016 17.6658 10.7558 18.0131C9.00998 18.3603 7.20038 18.1821 5.55585 17.5009C3.91131 16.8197 2.50571 15.6662 1.51677 14.1861C0.527841 12.7061 0 10.966 0 9.186C0 6.79906 0.948211 4.50987 2.63604 2.82204C4.32387 1.13422 6.61305 0.186005 9 0.186005ZM9 16.186C10.3845 16.186 11.7378 15.7755 12.889 15.0063C14.0401 14.2371 14.9373 13.1439 15.4672 11.8648C15.997 10.5857 16.1356 9.17824 15.8655 7.82037C15.5954 6.46251 14.9287 5.21522 13.9497 4.23626C12.9708 3.25729 11.7235 2.5906 10.3656 2.32051C9.00776 2.05041 7.6003 2.18903 6.32122 2.71885C5.04213 3.24866 3.94888 4.14587 3.17971 5.29701C2.41054 6.44816 2 7.80154 2 9.186C2 11.0425 2.7375 12.823 4.05025 14.1358C5.36301 15.4485 7.14348 16.186 9 16.186ZM17.485 16.257L20.314 19.086L18.9 20.5L16.072 17.672L17.485 16.257Z" fill="#503225"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className='xl:px-[4%] flex flex-col gap-5'>
                <div className='w-full overflow-scroll'>
                    <div className='sm:w-auto w-[650px] flex items-center'>
                        <div className='cursor-pointer w-full flex flex-col gap-[9px]' onClick={() => setBrand('All')}>
                            <p className='text-[18px] text-grey-50 text-center md:text-xl sm:text-sm text-xs'>All</p>
                            <div className={`h-1 ${brand === 'All' ? 'bg-tertiary' : 'bg-grey-200'} w-full`}></div>
                        </div>
                        <div className='cursor-pointer w-full flex flex-col gap-[9px]' onClick={() => setBrand('ESE PODS')}>
                            <p className='text-[18px] text-grey-100 text-center md:text-xl sm:text-sm text-xs'>ESE PODS</p>
                            <div className={`h-1 ${brand === 'ESE PODS' ? 'bg-tertiary' : 'bg-grey-200'} w-full`}></div>
                        </div>
                        <div className='cursor-pointer w-full flex flex-col gap-[9px]' onClick={() => setBrand('COMPATIBLE CAPSULES')}>
                            <p className='text-[18px] text-grey-100 text-center md:text-xl sm:text-sm text-xs'>COMPATIBLE CAPSULES</p>
                            <div className={`h-1 ${brand === 'COMPATIBLE CAPSULES' ? 'bg-tertiary' : 'bg-grey-200'} w-full`}></div>
                        </div>
                        <div className='cursor-pointer w-full flex flex-col gap-[9px]' onClick={() => setBrand('GRINDED COFFEE')}>
                            <p className='text-[18px] text-grey-100 text-center md:text-xl sm:text-sm text-xs'>GRINDED COFFEE</p>
                            <div className={`h-1 ${brand === 'GRINDED COFFEE' ? 'bg-tertiary' : 'bg-grey-200'} w-full`}></div>
                        </div>
                        <div className='cursor-pointer w-full flex flex-col gap-[9px]' onClick={() => setBrand('COFFEE BEANS')}>
                            <p className='text-[18px] text-grey-100 text-center md:text-xl sm:text-sm text-xs'>COFFEE BEANS</p>
                            <div className={`h-1 ${brand === 'COFFEE BEANS' ? 'bg-tertiary' : 'bg-grey-200'} w-full`}></div>
                        </div>
                    </div>
                </div>
                <div className='xl:px-0 px-5 grid w-full xl:grid-cols-4 grid-cols-2 xl:gap-[14.4px] gap-[9.15px]'>
                    {brand === 'All' ? (
                        filteredBrands?.map((d, index) => (
                            <div key={index} className='md:h-[351.19px] h-[218.85px] flex w-full cursor-pointer' onClick={() => navigate('/cartbag', { state: d })}>
                                <img src={d?.image} alt='coffee' className='w-full h-full rounded-tl-[30px] rounded-br-[30px] object-cover'/>
                                <div className='absolute brand__abs py-7 rounded-br-[30px] md:h-[351.9px] h-[218.85px] md:pl-[26px] pl-[9px] flex items-end gap-2 gradient'>
                                    <img src={state?.logo_small} alt='profile' className='w-[33.1px] h-[32.87px]'/>
                                    <div>
                                        <p className='font-bold text-sm text-white'>{d?.name}</p>
                                        <img src={Review} alt='review' className='w-[66px] h-[10.19px]'/>
                                    </div>
                                </div>
                            </div>
                        ))
                    ):(
                        state[brand]?.map((d, index) => (
                            <div key={index} className='md:h-[351.19px] h-[218.85px] flex w-full cursor-pointer' onClick={() => navigate('/cartbag', { state: d })}>
                                <img src={d?.image} alt='coffee' className='w-full h-full rounded-tl-[30px] rounded-br-[30px] object-cover'/>
                                <div className='absolute brand__abs py-7 rounded-br-[30px] md:h-[351.9px] h-[218.85px] md:pl-[26px] pl-[9px] flex items-end gap-2 gradient'>
                                    <img src={state?.logo_small} alt='profile' className='w-[33.1px] h-[32.87px]'/>
                                    <div>
                                        <p className='font-bold text-sm text-white'>{d?.name}</p>
                                        <img src={Review} alt='review' className='w-[66px] h-[10.19px]'/>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}
