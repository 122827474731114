import React, { useState } from 'react'
import * as Yup from "yup"
import { Form, Formik } from 'formik'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { fetchSignInMethodsForEmail, sendPasswordResetEmail } from "firebase/auth";

import Logo from "../assets/img/logo.png"
import { auth } from '../firebase-config'


const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const navigate = useNavigate()

    const formValidationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Required"),
    });

    // const submitForm = async (values, actions) => {
    //     setLoading(true);
    
    //     try {
    //         // Attempt to fetch sign-in methods for the email provided
    //         const signInMethods = await auth.fetchSignInMethodsForEmail(values.email);
    
    //         if (signInMethods.length === 0) {
    //             // No sign-in methods means the email doesn't exist in Firebase Authentication
    //             enqueueSnackbar('No user found with this email.', {
    //                 variant: 'error',
    //                 anchorOrigin: {
    //                     vertical: 'top',
    //                     horizontal: 'right',
    //                 },
    //                 style: { 
    //                     backgroundColor: '#ff0000'
    //                 },
    //             });
    //         } else {
    //             // If email exists, send the password reset email
    //             await sendPasswordResetEmail(auth, values.email);
    
    //             enqueueSnackbar('Password reset email sent!', {
    //                 variant: 'success',
    //                 anchorOrigin: {
    //                     vertical: 'top',
    //                     horizontal: 'right',
    //                 },
    //                 style: { 
    //                     backgroundColor: '#503225'
    //                 },
    //             });
    
    //             // Uncomment the line below to redirect after sending the reset email
    //             // navigate('/auth');
    //         }
    //     } catch (error) {
    //         // Handle other possible errors here
    //         enqueueSnackbar('Failed to send password reset email. Please try again.', {
    //             variant: 'error',
    //             anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right',
    //             },
    //             style: { 
    //                 backgroundColor: '#ff0000'
    //             },
    //         });
    //     } finally {
    //         setLoading(false);
    //         actions.setSubmitting(false);
    //     }
    // };
    
    


    const submitForm = async (values, actions) => {
        setLoading(true)

        try {
            await sendPasswordResetEmail(auth, values.email); // Send password reset email
            enqueueSnackbar('Password reset email sent!', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                style: { 
                    backgroundColor: '#503225'
                },
            });
            navigate('/auth'); // Redirect to login after successful email
        } catch (error) {
            // Check the error code
            if (error.code === 'auth/user-not-found') {
                enqueueSnackbar('No user found with this email.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    style: { 
                        backgroundColor: '#ff0000'
                    },
                });
            } else {
                // Handle other errors
                enqueueSnackbar('Failed to send password reset email. Please try again.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    style: { 
                        backgroundColor: '#ff0000'
                    },
                });
            }
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }

        
    }

  return (
    <div className='flex flex-col pt-5 px-[32px]'>
       <img src={Logo} alt='logo' className='hidden xl:block cursor-pointer w-[163px] h-10' onClick={() => navigate('/')}/>
        <div className='xl:hidden size-10 flex items-center justify-center border border-secondary rounded-lg' onClick={() => navigate('/')}>
            <p className='poppins-bold text-xl'>IT</p>
        </div>
        <div className='flex flex-col w-full mt-[39px] lg:mt-0 lg:h-[500px] lg:w-[491px] mx-auto gap-[48px]  items-center justify-center'>
            <div className='flex flex-col items-center lg:w-[375px] mx-auto gap-[4px]'>
                <p className='text-secondary font-inter font-bold text-[32px] lg:text-[37px] leading-[44px]'>Forgot Password?</p>
                <p className='font-inter text-tertiary text-center text-[18px]'>Please enter your registered email address to reset your password</p>
            </div>
            <div className='w-full lg:w-[491px] mx-auto'>
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                            validationSchema={formValidationSchema}
                            onSubmit={(values, actions) => {
                            window.scrollTo(0, 0);
                            submitForm(values, actions);
                          

                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            dirty,
                            isValid,
                            setFieldValue,
                            errors,
                            touched,
                            // setFieldTouched,
                            values,
                        }) => (
                            <Form onSubmit={handleSubmit} className="flex">
                                <div className="w-full flex flex-col gap-6">

                                    <div className='flex flex-col gap-1 '>
                                        <label htmlFor='email' className='font-inter font-medium  text-primary text-[14px]' >Email</label>
                                        <input
                                            name="email"
                                            placeholder="you@gmail.com"
                                            type="text" 
                                            value={values?.email}
                                            onChange={handleChange}
                                            className="outline-tertiary w-full lg:w-[491px] text-left font-inter text-base rounded-[8px] bg-[#fff] border  border-secondary p-3 h-[48px] border-solid "
                                        />
                                        {errors.email && touched.email ? (
                                        <div className="text-RED-_100 text-xs">
                                            {errors.email}
                                        </div>
                                        ) : null}
                                    </div>                                
                                    
                
                                    <button
                                        className={`${isValid ? "bg-secondary" : "bg-[#BABABA]"} w-full lg:w-[491px] font-inter flex  items-center  rounded-[6px] justify-center mt-2 h-[46px] text-base  text-center`}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        <p className='text-[#fff] text-base font-bold my-auto'>{loading ? <CgSpinner className=" animate-spin text-lg " /> : 'Continue'}</p>
                                        
                                    </button>
                                </div>

                            </Form>
                        )}
                    </Formik>

                </div>


        </div>
    </div>
  )
}

export default ForgotPassword