import './App.css';
import React, { useEffect } from 'react';
import Routers from './routers';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'


function App() {

  return (
      <>
        <Routers />
        <ToastContainer />
      </>
  );
}

export default App;
