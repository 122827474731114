import React from 'react'
import Star from '../../assets/svg/star.svg'
import Star2 from '../../assets/svg/star2.svg'
import Review from '../../assets/svg/review.svg'
import { useNavigate } from 'react-router-dom'
import { capitalizeString } from '../../utils/helperFuncs'

export default function CoffeeBox({data}) {
    const navigate = useNavigate()
    
    const renderStars = (rate) => {
        // Ensure rate is within the range of 0 to 5
        const clampedRate = Math.max(0, Math.min(5, Math.floor(rate)));
        
        // Calculate star1Count and star2Count based on rate
        const star1Count = Math.min(clampedRate, 5); // Maximum 5 stars
        const star2Count = Math.max(0, 5 - clampedRate); // Maximum 5 stars
        const stars = [];
        
        // Render star1
        for (let i = 0; i < star1Count; i++) {
          stars.push(<img key={`star1_${i}`} src={Star} className='size-[17px]' alt="Star1" />);
        }
        
        // Render star2
        for (let i = 0; i < star2Count; i++) {
          stars.push(<img key={`star2_${i}`} src={Star2} className='size-[17px]' alt="Star2" />);
        }
        
        return stars;
    };  

    return (
        <div onClick={() => navigate('/coffee', { state: data})} className='cursor-pointer border border-grey-200 hover:border-0 hover:shadow-[0_0_10px_3px_#0000001A] transition-all ease-in-out rounded-lg md:h-[405px] h-[500px] p-5 items-center w-full flex flex-col gap-[11px]'>
            <img src={data.logo_small ? data.logo_small : data.logo} alt='coffee' className='w-full md:h-[202px] h-[275px] object-contain rounded-md bg-[#50322521]'/>
            <div className='flex-1 flex flex-col gap-[15px] w-full'>
                <div className='flex items-center gap-1'>
                    <img src={data.logo_small ? data.logo_small : data.logo} alt='coffee' className='size-10 object-contain rounded-full' />
                    <div className='flex flex-col'>
                        <p className='font-bold text-sm'>{capitalizeString(data?.company)}</p>
                        {data?.reviews?.length > 0 && <div className='flex gap-[2px] items-center'>
                            <div className='flex items-center'>
                                {renderStars(data?.company_rate)}
                            </div>
                            <p className='text-grey-100 font-bold text-[10px]'>{data?.company_rate % 1 === 0 ? `${data?.company_rate}.0` : data?.company_rate} <span className='text-grey-50'>{`(${data?.reviews?.length} reviews)`}</span></p>
                        </div>}
                    </div>
                </div>
                <div className='flex flex-wrap gap-1 w-full h-12 overflow-hidden'>
                    {data?.range_products ? data?.range_products?.map((d, index) => (
                        <div key={index} className='bg-[#00000033] rounded-full px-[8px] flex items-center h-[22px]'>
                            <p className='text-[10px] font-bold'>{d}</p>
                        </div>
                    )) : null}
                </div>
                <div className='flex-1 flex items-end'>
                    <div className='md:h-[38px] flex gap-[10px] items-start'>
                        <img src={Review} />
                        <div className='flex items-end'>
                            <p className='text-sm text-grey-100 h-9 overflow-hidden'>{capitalizeString(data.history)}<span className='text-secondary font-bold'>More</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
