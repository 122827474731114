import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'
import MiniHeader from './MiniHeader'

const Admin = () => {
    const location = useLocation()
    
  return (
    <div className='w-full lg:overflow-x-hidden h-full overflow-y-auto'>
        <div className="xs:flex lg:hidden">
            <MiniHeader />
          </div>
        <div className="flex w-full h-full relative gap-8 ">
            <div className="w-[14%] fixed hidden lg:flex h-full ">
                <Sidebar />
            </div>
            <div className="px-4 lg:px-0 lg:w-[85%] lg:overflow-x-hidden lg:ml-[16%] bg-[#FAFAFA]">
                <Outlet />
            </div>
        </div>
    </div>
  )
}

export default Admin