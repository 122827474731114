import React, { useState } from 'react'
import Wish from '../assets/img/wish.png'
import Bus from '../assets/svg/deliver.svg'
import Cart from '../assets/svg/cart.svg'
import BasicButtons from './Button'
import Modals from './Modals'
import { useNavigate } from 'react-router-dom'
import AddToCart from './AddToCart'

export default function WishBox() {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    return (
        <>
            <div className='hover:shadow-[1px_0_4px_0_#00000029] transition-all ease-in-out cursor-pointer border border-grey-200 p-[14px] md:rounded-lg w-full flex justify-between md:items-center items-start'>
                <div className='flex gap-[15px] md:items-center items-start'>
                    <img src={Wish} alt='wish' className='md:w-[146px] w-[79px] md:h-[151px] h-[82px] object-contain'/>
                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-col gap-3'>
                            <p className='text-[19px]'>ICafilas 350ml Portable Coffee</p>
                            <div className='flex gap-[17px] items-center'>
                                <svg width="63" height="12" viewBox="0 0 63 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.37302 9.256L2.29702 11.548L3.13302 7.271L0.39502 4.411L3.98902 4.06L5.37302 0L6.75802 4.06L10.352 4.411L7.61402 7.271L8.45002 11.548L5.37302 9.256Z" fill="#503225"/>
                                    <path d="M18.352 9.256L15.276 11.548L16.112 7.271L13.374 4.411L16.968 4.06L18.352 0L19.737 4.06L23.331 4.411L20.593 7.271L21.429 11.548L18.352 9.256Z" fill="#503225"/>
                                    <path d="M31.331 9.256L28.255 11.548L29.091 7.271L26.353 4.411L29.947 4.06L31.331 0L32.716 4.06L36.31 4.411L33.572 7.271L34.408 11.548L31.331 9.256Z" fill="#503225"/>
                                    <path d="M44.31 9.256L41.234 11.548L42.07 7.271L39.332 4.411L42.926 4.06L44.31 0L45.695 4.06L49.289 4.411L46.551 7.271L47.387 11.548L44.31 9.256Z" fill="#503225"/>
                                    <path d="M57.289 9.256L54.213 11.548L55.049 7.271L52.311 4.411L55.905 4.06L57.289 0L58.674 4.06L62.268 4.411L59.53 7.271L60.366 11.548L57.289 9.256Z" fill="#DDDDDD"/>
                                </svg>
                                <p className='text-[13px] text-black open-sans'>125 Sold</p>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='gap-[15px] flex items-center'>
                                <p className='font-bold text-grey-100'>$<span className='text-2xl'>324</span>.03</p>
                                <p style={{textDecoration: 'line-through'}} className='open-sans text-grey-50'>$524.03</p>
                            </div>
                            <div className='flex gap-[10.33px] items-center'>
                                <img src={Bus} alt='bus' />
                                <p className='text-[13px] open-sans text-[#2ABB07]'>5-7 Workdays</p>
                            </div>
                            <div className='md:hidden block mt-[9.5px]'>
                                <BasicButtons functions={() => setOpen(!open)} text={'Add to Cart'} icon={Cart}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-5 items-center'>
                    <div className='md:block hidden'>
                        <BasicButtons functions={() => setOpen(!open)} width={'218px'} text={'Add to Cart'} icon={Cart}/>
                    </div>
                    <div className='size-11 flex justify-center items-center border border-secondary rounded-lg cursor-pointer'>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.365 4.95L11.315 0L12.729 1.414L7.779 6.363L12.729 11.313L11.315 12.727L6.365 7.778L1.415 12.728L0.000999928 11.313L4.95 6.363L0 1.413L1.415 0L6.365 4.95Z" fill="black"/>
                        </svg>
                    </div>
                </div>
            </div>
            <Modals open={open} setOpen={setOpen}>
                <AddToCart />
            </Modals>
        </>
    )
}
