import React, { useState } from 'react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'

import CloseIcon from "../assets/svg/closeIcon.svg"
import { toast } from 'react-toastify'
import { db } from '../firebase-config'
import { CgSpinner } from 'react-icons/cg'
import { Formik, Form, Field, ErrorMessage  } from 'formik'


const EditAdmin = ({ handleClose, editLoading, setEditLoading, userAdminData }) => {

    console.log(userAdminData, "userAdminData")

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: 'admin',
      };

    const submitForm = async (values) => {
        setEditLoading(true);
        const data = {
            id: userAdminData?.id,
            firstName: values.firstName || userAdminData?.firstName,
            lastName: values.lastName || userAdminData?.lastName,
            email: values.email || userAdminData?.email,
            password: values.password || userAdminData?.password,
            role: values.role || userAdminData?.role,
        };

        try {
            const docRef = doc(db, 'admins', data?.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                await updateDoc(docRef, data);
                toast(`Admin Updated Successfully`, { 
                    position: "top-right",
                    autoClose: 3500, 
                    closeOnClick: true,
                });
                handleClose();
            } else {
                toast(`No document found to update`, { 
                    position: "top-right",
                    autoClose: 3500, 
                    closeOnClick: true,
                });
            }
        } catch (error) {
            console.error('Error updating document: ', error);
        } finally {
            setEditLoading(false);
        }
    };

  return (
    <div className='w-full flex items-center justify-center'>
      <div className='bg-[#fff] w-[500px] h-[500px] overflow-y-auto px-5 items-center flex flex-col gap-10 py-10'>
      <h1 className='text-3xl font-bold'>Edit Admin</h1>
      <Formik
        initialValues={initialValues}
      //   validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ isSubmitting }) => (
          <Form className='w-full flex flex-col gap-4'>
            <div>
              <label htmlFor='firstName'>First Name</label>
              <Field type='text' placeholder={`${userAdminData?.firstName}`} id='firstName' name='firstName' className='border p-2 outline-none rounded w-full' />
              <ErrorMessage name='firstName' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='lastName'>Last Name</label>
              <Field type='text' id='lastName' placeholder={`${userAdminData?.lastName}`} name='lastName' className='border p-2 outline-none rounded w-full' />
              <ErrorMessage name='lastName' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='email'>Email</label>
              <Field type='email' id='email' placeholder={`${userAdminData?.email}`} name='email' className='border p-2 rounded outline-none w-full' />
              <ErrorMessage name='email' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='password'>Password</label>
              <Field type='text' id='password' placeholder={`${userAdminData?.password}`} name='password' className='border p-2 rounded outline-none w-full' />
              <ErrorMessage name='password' component='div' className='text-red-500' />
            </div>

            <div>
              <label htmlFor='role'>Role</label>
              <Field as='select' id='role' name='role' className='border p-2 outline-none rounded w-full'>
                <option value='admin'>Admin</option>
                <option value='superadmin'>Super Admin</option>
              </Field>
              <ErrorMessage name='role' component='div' className='text-red-500' />
            </div>

            <div className='flex items-center justify-between gap-5 w-full'>
              <button type='button' onClick={handleClose} className='bg-[#fff] w-6/12 text-secondary border border-secondary p-2 rounded'>
                Cancel
              </button>
              <button type='submit' disabled={isSubmitting} className='bg-secondary w-6/12 text-white p-2 rounded'>
                {isSubmitting ? 'Adding...' : 'Update Admin'}
              </button>
            </div>

          </Form>
        )}
      </Formik>
      </div>
    </div>
  )
}

export default EditAdmin