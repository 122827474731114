import React from 'react'
import HomeLayout from '../layout/HomeLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import Gallery1 from '../assets/img/gallery.png'
import Gallery2 from '../assets/img/gallery2.png'

export default function Gallery() {
    const location = useLocation()
    const {state} = location
    console.log(state)
    const navigate = useNavigate()
    return (
        <HomeLayout>
            <div className='z-10 fixed w-full bg-white border border-grey-200 pl-[4%] h-[71px] flex gap-5 items-center'>
                <div onClick={() => navigate(-1)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.9403 6.99998H16.1123V8.99998H3.9403L9.30431 14.364L7.8903 15.778L0.112305 7.99998L7.8903 0.221985L9.30431 1.63598L3.9403 6.99998Z" fill="#7E3701"/>
                    </svg>
                </div>
                <h1 className='nunito-bold text-primary md:text-2xl text-xl'>Gallery </h1>
            </div>
            <div className='px-[4%] pt-[95px] pb-6 grid w-full xl:grid-cols-2 grid-cols-1 gap-[25px]'>
                {state?.length > 0 && state?.map((item, index) => (
                    // <iframe key={index} src={img} width={'100%'} height={'513px'} style={{border: 'none', zoom: 1}} allowfullscreen frameborder="0" onScroll="no" className='h-[513px] w-full border-0 object-cover'></iframe>
                    <img key={index} src={item} width={'100%'} height={'513px'} style={{border: 'none', zoom: 1}} className='h-[513px] w-full border-0 object-cover'/>
                ))}
            </div>
        </HomeLayout>
    )
}
