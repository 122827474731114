import { createContext, useEffect, useState } from "react";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config'

export const CartsContext = createContext()

export const CartsProvider = ({children}) => {
    const [cartData, setCartData] = useState([])
    const [cartSearch, setCartSearch] = useState('')
   
    const fetchCartItems = async (user) => {
        if (user) {
            const userId = user.uid;
            const userCartRef = doc(db, 'carts', userId);
            try {
                const userCartSnapshot = await getDoc(userCartRef);
                if (userCartSnapshot.exists()) {
                    const cartData = userCartSnapshot.data();
                    const filteredCartData = cartSearch ? cartData?.items?.filter(c => c.product.name.toLowerCase().includes(cartSearch.toLowerCase())) : cartData.items;
                    setCartData(filteredCartData);
                } else {
                    setCartData([]);
                }
            } catch (error) {
                console.error('Error fetching cart data:', error);
            }
        }
    };
    
    return (
        <CartsContext.Provider value={{setCartData, cartData, cartSearch, fetchCartItems, setCartSearch}}>
            {children}
        </CartsContext.Provider>
    )
} 