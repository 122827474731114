import React, { useEffect, useState } from 'react'
import { Skeleton, Switch } from '@mui/material';
import { db } from '../firebase-config';
import { MdDeleteForever } from 'react-icons/md';
import { CiSettings } from "react-icons/ci";
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FaRegEdit } from 'react-icons/fa';

import Search from "../assets/svg/search.svg"
import { toast } from 'react-toastify';
import UpdateData from './UpdateData';
import ModalPop from '../components/modal/modalPop';
import DeleteData from './DeleteData';
import { Navigate, useNavigate } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import "./css/tooltip.css"


const ViewData = () => {
    const [loading, setLoading] = useState(false)
    const [manufacturerData, setManufacturerData] = useState([])
    const [open, setOpen] = useState(false)
    const [text, setText] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [deleteItemId, setDeleteItemId] = useState()
    const itemsPerPage = 10; 

    const colRef = collection(db, "manufacturer") 

    const fetchData = () => {
        setLoading(true)
        getDocs(colRef)
        .then((snapshot) => {
            setLoading(false)
           let manufacturers = []
           snapshot.docs.forEach((doc) => {
            manufacturers.push({ ...doc.data(), id: doc.id })
           })
          
           console.log(manufacturers, "ouan")
           setManufacturerData(manufacturers)
        })
        .catch((err) => {
            setLoading(false)
            console.log(err.message)
        })
    }

    useEffect(() => {
        fetchData()
    }, [deleteLoading])


    const handleProductVisibility = async (id, currentVisibility) => {
        const docRef = doc(db, "manufacturer", id);
        try {
          await updateDoc(docRef, {
            visibility: !currentVisibility,
          });
          // Update the local state to reflect the change in the UI
          setManufacturerData(prevData => prevData?.map(item => 
            item.id === id ? { ...item, visibility: !currentVisibility } : item
          ));
          toast.success('Product Visibility updated successfully!');
        } catch (error) {
          console.error("Error updating visibility: ", error);
          toast.error('Failed to update visibility');
        }
      };

        // Coffee Bean Visibility
      const handleCoffeeBeansVisibility = async (id, currentVisibility) => {
        const docRef = doc(db, "manufacturer", id);
        try {
          await updateDoc(docRef, {
            coffeeBeansVisibility: !currentVisibility,
          });
          // Update the local state to reflect the change in the UI
          setManufacturerData(prevData => prevData?.map(item => 
            item.id === id ? { ...item, coffeeBeansVisibility: !currentVisibility } : item
          ));
          toast.success('Coffee Beans Visibility updated successfully!');
        } catch (error) {
          console.error("Error updating Coffee Beans Visibility: ", error);
          toast.error('Failed to update Coffee Beans visibility');
        }
      };

      // Compatible Capsule Visibility
      const handleCompatibleCapsuleVisibility = async (id, currentVisibility) => {
        const docRef = doc(db, "manufacturer", id);
        try {
          await updateDoc(docRef, {
            compatibleCapsuleVisibility: !currentVisibility,
          });
          // Update the local state to reflect the change in the UI
          setManufacturerData(prevData => prevData?.map(item => 
            item.id === id ? { ...item, compatibleCapsuleVisibility: !currentVisibility } : item
          ));
          toast.success('Compatible Capsule Visibility updated successfully!');
        } catch (error) {
          console.error("Error updating Compatible Capsule Visibility: ", error);
          toast.error('Failed to update Compatible Capsule visibility');
        }
      };

      // Grinded Coffee Visibility
      const handleGrindedCoffeeVisibility = async (id, currentVisibility) => {
        const docRef = doc(db, "manufacturer", id);
        try {
          await updateDoc(docRef, {
            grindedCoffeeVisibility: !currentVisibility,
          });
          // Update the local state to reflect the change in the UI
          setManufacturerData(prevData => prevData?.map(item => 
            item.id === id ? { ...item, grindedCoffeeVisibility: !currentVisibility } : item
          ));
          toast.success('Grinded Coffee Visibility updated successfully!');
        } catch (error) {
          console.error("Error updating Grinded Coffee Visibility: ", error);
          toast.error('Failed to update Grinded Coffee visibility');
        }
      };

      // Ese Pods Visibility
      const handleEsePodsVisibility = async (id, currentVisibility) => {
        const docRef = doc(db, "manufacturer", id);
        try {
          await updateDoc(docRef, {
            esePodsVisibility: !currentVisibility,
          });
          // Update the local state to reflect the change in the UI
          setManufacturerData(prevData => prevData?.map(item => 
            item.id === id ? { ...item, esePodsVisibility: !currentVisibility } : item
          ));
          toast.success('Ese Pods Visibility updated successfully!');
        } catch (error) {
          console.error("Error updating Ese Pods Visibility: ", error);
          toast.error('Failed to update Ese Pods visibility');
        }
      };
      
      


    // Search Functionality
    const handleSearch = (e) => {
        setText(e.target.value);
    };

    const filteredData = manufacturerData?.filter((item) => {
        console.log(item, "slick")
        return (
            item?.company?.toLowerCase().includes(text?.toLowerCase()) ||
            item?.province?.toLowerCase().includes(text?.toLowerCase())
        )
        }
    );


     // Calculate total pages
     const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

     // Get current items for the page
     const currentData = filteredData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
 
     const handlePageChange = (pageNumber) => {
         setCurrentPage(pageNumber);
     };

     const navigate = useNavigate()
    


  return (
    <div className='w-full lg:overflow-x-hidden mt-[100px] flex flex-col gap-10 lg:px-5'>
        <div className='flex justify-between flex-col gap-4 fixed lg:relative lg:gap-0 lg:flex-row lg:items-center '>
            <h1 className='text-3xl font-bold'>View Manufacturers</h1>
            <div className='flex flex-col lg:flex-row lg:items-center gap-[9px]'>
                <div className='rounded-[23px] border border-[#CCCCCC] w-[300px] lg:w-[372px] h-[46px] flex items-center gap-2 p-3'>
                    <img src={Search} alt='Search' className='w-4 h-4' />
                    <input 
                        placeholder='Search by company name or province'
                        type='text'
                        name='search'
                        value={text}
                        className='outline-none bg-transparent w-full'
                        onChange={handleSearch}
                    />
                </div>
            </div>
        </div>
        {
            loading || deleteLoading ?
            <Skeleton variant="rectangular" width={1185} height={1000} className='mt-[150px] lg:mt-0' style={{ backgroundColor: 'rgba(0,0,0, 0.06)' }} />
            :
            <>
                <table className='w-full mt-[150px] lg:mt-0 lg:overflow-x-hidden'>
                    <tr className='h-[32px] whitespace-nowrap  border-0' >
                        <th className="font-medium pr-2 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Type
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                           Company
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Location
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Email
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Contact
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Created by
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            TimeStamp
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Visibility
                        </th>
                        <th className="font-medium px-4 text-[18px] text-[#0D0D0D] font-Kumbh text-left">
                            Action
                        </th>
                    </tr>
                    {currentData?.length > 0 ? currentData?.map((item, index) => {
                          const createdAt = item?.createdAt?.seconds && item?.createdAt?.nanoseconds
                          ? new Date(item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000)
                          : null;

                        return (
                            <tr key={index} className={`bg-transparent h-[55px] border-b border-grey-100`} >
                               <td className='h-[55px] px-4'>
                                    <p className='text-sm font-kumbh'>{`${item?.manufacturerType[0]?.typeName || "N/A"}`}</p>
                                </td>
                                <td className='h-[55px] cursor-pointer ' onClick={() => navigate("/coffee", {state: item})}>
                                    <p className='text-sm font-kumbh'>{item?.company}</p> 
                                </td>
                                <td className='h-[55px] px-2'>
                                    <p className='text-sm font-kumbh'>{`${item?.address}  ${item?.province}`}</p>
                                </td>
                                <td className='h-[55px] px-2'>
                                    <p className='text-sm font-kumbh'>{item?.email || "N/A"}</p>
                                </td>
                                <td className='h-[55px] px-2 '>
                                    <p className='text-sm font-kumbh'>{`${item?.contact}`}</p>
                                </td> 
                                <td className='h-[55px] px-2 '>
                                    <p className='text-sm font-kumbh'>{`${item?.createdBy || "N/A"}`}</p>
                                </td> 
                                <td className='h-[55px] px-2 '>
                                    <p className='text-sm font-kumbh'>{createdAt ? createdAt.toLocaleString() : "N/A"}</p>
                                </td>
                                <td className='h-[55px]  gap-1 px-2 '>
                                    {/* <Switch
                                        checked={item?.visibility}
                                        onChange={() => handleProductVisibility(item?.id, item?.visibility)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    /> */}
                                    <Switch
                                        checked={item?.coffeeBeansVisibility}
                                        onChange={() => handleCoffeeBeansVisibility(item?.id, item?.coffeeBeansVisibility)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Switch
                                        checked={item?.compatibleCapsuleVisibility}
                                        onChange={() => handleCompatibleCapsuleVisibility(item?.id, item?.compatibleCapsuleVisibility)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Switch
                                        checked={item?.esePodsVisibility}
                                        onChange={() => handleEsePodsVisibility(item?.id, item?.esePodsVisibility)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Switch
                                        checked={item?.grindedCoffeeVisibility}
                                        onChange={() => handleGrindedCoffeeVisibility(item?.id, item?.grindedCoffeeVisibility)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </td>
                                <td className='h-[55px] px-2 '>
                                  <div className='flex items-center gap-2'>
                                    <div className='relative group'>
                                        <IoEyeOutline className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() => {navigate("/view-info", { state: item })}} />
                                        <span className='tooltip'>View Info</span>
                                    </div>
                                    <div className='relative group'>
                                        <CiSettings className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() => {navigate("/view-settings", { state: item })}} />
                                        <span className='tooltip'>Settings</span>
                                    </div>
                                    <div className='relative group'>
                                        <FaRegEdit className='text-[#818181] w-5 h-5 cursor-pointer' onClick={() => {navigate("/edit-manufacturer", { state: item })}} />
                                        <span className='tooltip'>Edit</span>
                                    </div>
                                    <div className='relative group'>
                                        <MdDeleteForever className='text-[#f00] w-5 h-5 cursor-pointer' onClick={() => {setOpenDelete(true); setDeleteItemId(item?.id)}} />
                                        <span className='tooltip'>Delete</span>
                                    </div>
                                  </div>
                                </td> 
                            </tr>
                        )   
                       
                    }) : (
                            <tr className='h-[654px] bg-white border-t border-grey-100'>
                                <td colSpan="8" className="relative">
                                    <div className='absolute inset-0 flex items-center justify-center'>
                                        <div className='flex flex-col gap-2 items-center'>
                                            {/* <img src={Empty} alt='empty' className='w-[159px] h-[103px]'/> */}
                                            <p className='text-[#0C1322] font-medium text-[20px] font-inter'>No Products Available</p>
                                            <p>Oops! Nothing to see here.</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        )
                    }
                </table>
                <div className="flex justify-center mt-1 mb-10">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-3 py-1 mx-1 ${currentPage === index + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
        }
        <ModalPop isOpen={openDelete}>
            <DeleteData 
                handleClose={() => setOpenDelete(false)}
                deleteItemId={deleteItemId}
                deleteLoading={deleteLoading}
                setDeleteLoading={setDeleteLoading} 
            />
        </ModalPop>
    </div>
  )
}

export default ViewData