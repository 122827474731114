import React, { useContext, useEffect } from 'react'
import HomeLayout from '../layout/HomeLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import CoffeeMenu from '../components/coffee/CoffeeMenu'
import CoffeeHeader from '../components/coffee/CoffeeHeader'
import CoffeeBase from '../components/coffee/CoffeeBase'
import Whatsapp from '../components/Whatsapp'
import Language from '../components/Language'
import ManufacturingProcess from '../components/coffee/ManufacturingProcess'
import CoffeeBrands from '../components/coffee/CoffeeBrands'
import CoffeeProducts from '../components/coffee/CoffeeProducts'
import CoffeeHistory from '../components/coffee/CoffeeHistory'
import CoffeeRegion from '../components/coffee/CoffeeRegion'
import CoffeeVideo from '../components/coffee/CoffeeVideo'
import CoffeeReview from '../components/coffee/CoffeeReview'
import CoffeeConversations from '../components/coffee/CoffeeConversations'
import Subscription from '../components/Subscription'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import BasicButtons from '../components/Button'
import { capitalizeString } from '../utils/helperFuncs'
import { ProductContext } from '../context/ProductsContext'
import Empty from '../components/coffee/Empty'


export default function Coffee() {
    const { setProduct, product } = useContext(ProductContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { state } = location

    console.log(state, "dada")

    useEffect(() => {
        if (state?.products) {
            setProduct(state?.products)
        }
    }, [product])

    return (
        <HomeLayout>
            <div className='w-full flex flex-col' style={{ height: 'calc(100% - 100px)' }}>
                <Whatsapp fixed={true} link={state?.contact}/>
                {/* <Language fixed={true}/> */}
                <div className='z-10 fixed w-full bg-white'>
                    <div className='border border-grey-200 xl:pl-[4%] xl:pr-[3.2%] px-5 h-[71px] flex justify-between items-center'>
                        <div className='flex xl:gap-5 gap-[10px] items-center'>
                            <div onClick={() => navigate(-1)} className='cursor-pointer size-[50px] flex items-center justify-center bg-white shadow-[0_0_6px_0_#0000001A] rounded-full'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.9403 6.99998H16.1123V8.99998H3.9403L9.30431 14.364L7.8903 15.778L0.112305 7.99998L7.8903 0.221985L9.30431 1.63598L3.9403 6.99998Z" fill="#7E3701"/>
                                </svg>
                            </div>
                            <h1 className='nunito-bold text-primary xl:text-2xl text-xl'>{capitalizeString(state?.company)}</h1>
                        </div>
                        <div className='xl:block hidden'>
                            <CoffeeMenu data={state}/>
                        </div>
                        <div className='xl:hidden block'>
                            <BasicButtons text={'SHOP NOW'} width={'120px'} functions={() => navigate('/products', { state: state })}/>
                        </div>
                    </div>
                    <div className='xl:hidden px-5 h-[71px] flex items-center border-b border-grey-200 w-full justify-center'>
                        <CoffeeMenu data={state}/>
                    </div>
                </div>
                <div className='xl:mt-[5.5%] mt-[140px] mb-10'>
                    <CoffeeHeader data={state}/>
                    <CoffeeBase data={state}/>
                    <div className='xl:block hidden h-20 bg-pink w-full'></div>
                </div>
                <div className='mt-5 xl:mt-[100px] xl:px-[4%] xl:mb-[134px] mb-[59px]'>
                    <ManufacturingProcess data={state}/>
                </div>
                {state?.['ESE PODS'][0]?.name ? 
                <div className='bg-grey-100 w-full xl:pl-[4%] pt-[35px] xl:pb-20 pb-10 flex flex-col'>
                    <div className='w-full flex justify-end xl:pr-[4%] pr-5'>
                        <div onClick={() => navigate('/brands', { state: state})} className='border border-white rounded-lg h-[43px] px-5 flex items-center cursor-pointer'>
                            <p className='font-medium text-sm text-white'>View More</p>
                        </div>
                    </div>
                    <CoffeeBrands data={state}/>
                </div> 
                :
                null
                // <div className='mt-[66px] bg-pink xl:px-[4%] px-5 py-[62px] xl:h-[799px] flex flex-col'>
                //     <h1 className='nunito-bold md:text-[40px] text-[28px] text-primary'>Products Own by {capitalizeString(state?.company)}</h1>
                //     <p className='nunito-normal text-[18px] opacity-50'>Contrary to popular belief and going through the cites of the word source.</p>
                //     <Empty />
                // </div>
                }
                <CoffeeProducts data={state}/>
                <CoffeeHistory data={state}/>
                <CoffeeRegion data={state}/>
                <CoffeeVideo data={state}/>
                <CoffeeReview data={state}/>
                <CoffeeConversations data={state}/>
                <Subscription />
                <Contact data={state} />
                <Footer />
            </div>
        </HomeLayout>
    )
}
