import React, { createContext, useState, useEffect } from 'react'
import { auth } from '../firebase-config';

export const UserContext = createContext()

export default function UserProvider({children}) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    )
}
