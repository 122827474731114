import React, { useState } from 'react'
import { addDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { paymentOptions } from "../utils/paymentOptions";

const AddPayment = ({ handleClose, manufacturerData, setAddPaymentLoading }) => {
    const [paymentLoading, setPaymentsLoading] = useState(false)
    const [payment, setPayment] = useState("")
    const [text, setText] = useState("")

    console.log(paymentOptions, "paymentOptions")
    

    const createPayments = async () => {
        setPaymentsLoading(true);
        setAddPaymentLoading(true)
        try {
            const paymentsCollection = collection(db, "payment");
            const q = query(paymentsCollection, where("company", "==", manufacturerData?.company));
            const querySnapshot = await getDocs(q);

            const selectedPayment = payment === "Other" ? text : payment;

            if (querySnapshot.empty) {
                // Create new document if it doesn't exist
                const data = {
                    company: manufacturerData?.company,
                    payment: [selectedPayment]
                };
                console.log(data, 'sample')
                const docRef = await addDoc(paymentsCollection, data);
                console.log("New document created with ID: ", docRef.id);
            } else {
                // Update existing document
                const doc = querySnapshot.docs[0];
                const existingPayments = doc.data().payment || [];
                if (!existingPayments.includes(selectedPayment)) {
                    await updateDoc(doc.ref, {
                        payment: [...existingPayments, selectedPayment]
                    });
                    console.log("Document updated with ID: ", doc.id);
                } else {
                    console.log("Payment provider already exists for this company");
                    toast("Payment provider already exists for this company", {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                    });
                    setPaymentsLoading(false);
                    setAddPaymentLoading(false)
                    return;
                }
            }

            setPaymentsLoading(false);
            setAddPaymentLoading(false)
            toast("Payment Provider Added Successfully", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
            setPayment("");
            handleClose()
        } catch (error) {
            console.error("Error creating document: ", error);
            setPaymentsLoading(false);
            setAddPaymentLoading(false)
            toast("Error Adding Payment Provider", {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            }) 
        }
    }

    
  return (
    <div className='bg-[#efefef] w-full lg:w-[400px] overflow-y-auto p-4 gap-5 flex flex-col h-[300px]'>
        <div className='flex flex-col gap-10'>
            <h1 className='text-3xl text-center font-bold'>Add Payment</h1>
            <div className='flex'>
                
                <div className='relative w-full'>
                    <label className='capitalize absolute bg-white px-1 left-3 -top-[14px] text-grey-100'>Payment</label>
                    <select  onChange={(e) => setPayment(e.target.value)}  value={payment}  className='placeholder:text-grey-50 font-normal w-full text-sm h-[50px] px-4 pt-3 pb-1 border-grey-200 border rounded-lg focus:outline-none'>
                        <option>Select</option>
                        {
                            paymentOptions?.map((item, index) => {
                                return(
                                    <option key={index} className='w-full' value={item}>
                                        {item}
                                    </option>
                                )
                            })
                        }
                    </select>
                    {
                        payment === "Other" ? 
                        <input 
                            type='text'
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            className='w-full mt-5 h-10 rounded-lg border-grey-200 border outline-none p-2'
                        />
                        :
                        null
                    }
                </div>
            </div>
            <div className='flex justify-between'>
                <button 
                    type='button' 
                    className='bg-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-white items-center' 
                    onClick={createPayments}
                >
                    {paymentLoading ? <CgSpinner className='animate-spin text-lg text-[#fff]' /> : "Add" }
                </button>
                <button 
                    type='button' 
                    className='border border-secondary rounded-lg p-2 w-[40%] h-full flex justify-center text-secondary items-center' 
                    onClick={handleClose}
                >
                    Cancel
                </button>

            </div>
        </div>

    </div>
  )
}

export default AddPayment