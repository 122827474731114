import React, { createContext, useEffect, useState } from 'react'

export const ProductContext = createContext()

export default function ProductProvider({children}) {
    const [product, setProduct] = useState([])
    const [filteredProduct, setFilteredProduct] = useState([])

    useEffect(() => {
        setFilteredProduct(product)
    }, [product])

    // const filterProducts = (search) => {
    //     if (!search) return
    //     if (!product || product.length == 0) return
    //     const filteredProducts = product.filter((f) => f.name.toLowerCase().includes(search.toLowerCase())) || product
    //     setFilteredProduct(filteredProducts)
    // }

    // const filterProducts = (search) => {
    //     if (!search || !product || product?.length === 0) {
    //         setFilteredProduct(product || []);
    //         return;
    //     }
    
    //     const filteredProducts = product?.filter((f) => 
    //         f.name?.toLowerCase().includes(search?.toLowerCase())
    //     );
    
    //     setFilteredProduct(filteredProducts);
    // };
    const filterProducts = (search) => {
        console.log('Search Term:', search); // Log the search term
        console.log('Products:', product);   // Log the product list
    
        if (!search || !product || product.length === 0) {
            setFilteredProduct(product || []);
            return;
        }
    
        const searchLower = search.toLowerCase();
        console.log('Search Term Lowercase:', searchLower); // Log the normalized search term
    
        const filteredProducts = product.filter((f) => {
            const name = f.name ? f.name.toLowerCase() : '';
            console.log('Product Name Lowercase:', name); // Log each product name in lowercase
            return name.includes(searchLower);
        });
    
        setFilteredProduct(filteredProducts);
    };
    
    


    

    return (
        <ProductContext.Provider value={{product, setProduct, filterProducts, filteredProduct}}>
            {children}
        </ProductContext.Provider>
    )
}